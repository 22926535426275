<template>
  <div class="floor-plan-editor">
    <HeaderEditor
        :isEdit="isEditPoint"
        :back-page="floor.office.id"
    />
    <div v-show="floor.plan.image" class="floor-plan-editor-content d-flex">
      <div class="flex-grow-1 position-relative">
        <div class="h-100">
          <v-zoomer
              ref="zoomer"
              :max-scale="10"
              :min-scale="1"
              :zooming-elastic="true"
              :limitTranslation="false"
              :pivot="'cursor'"
              :zoomed.sync="zoomed"
              class="zoomer-block">
            <div class="points-block">
              <vue-drag-resize
                  v-if="hasPlanLoaded"
                  v-for="(item, index) in floor.points"
                  :key="`item-${item.id}`"
                  :class="item.resource ? item.resource.type === 'desk' ? 'desk-point' : 'meeting-point' : 'default-point'"
                  :x="getRelativeCoordinates('X', item.coordinates.x)"
                  :y="getRelativeCoordinates('Y', item.coordinates.y)"
                  :isActive="item.active"
                  :sticks="['tr']"
                  :stickSize="setStickSize(6)"
                  :w="setResizedPoint(item.size)"
                  :h="setResizedPoint(item.size)"
                  :minw="10"
                  :minh="10"
                  :snapToGrid="true"
                  :gridX="1"
                  :gridY="1"
                  :parentScaleX="parentScale"
                  :parentScaleY="parentScale"
                  :parentLimitation="true"
                  @clicked="onClicked($event, item, index)"
                  @deactivated="onDeactivated(index)"
                  @dragstop="onDragStop"
                  @resizestop="onResizeStop"
                  v-on:resizing="resize"
                  v-on:dragging="resize"
              />
              <vue-drag-resize
                  v-if="isAddNewPoint && hasPlanLoaded"
                  class="default-point"
                  :x="getRelativeCoordinates('X', defaultPoint.x)"
                  :y="getRelativeCoordinates('Y', defaultPoint.y)"
                  :isActive="true"
                  :sticks="isSticks ? ['tr'] : []"
                  :stickSize="setStickSize(6)"
                  :w="setResizedPoint(10)"
                  :h="setResizedPoint(10)"
                  :minw="10"
                  :minh="10"
                  :snapToGrid="true"
                  :gridX="1"
                  :gridY="1"
                  :parentScaleX="parentScale"
                  :parentScaleY="parentScale"
                  :parentLimitation="true"
                  @clicked="onClicked($event)"
                  @deactivated="onDeactivated"
                  v-on:resizing="resize"
                  v-on:dragging="resize">
              </vue-drag-resize>
              <img :src="'data:image/svg+xml;base64,' + floor.plan.image" ref="plan" @load="onPlanLoad" alt="Floor plan image"/>
            </div>
          </v-zoomer>
        </div>
        <div class="floor-plan-editor-filter">
          <MainSelect
              :selected-floor="floor"
              @setSelectedOffice="setSelectedOffice"
          />
        </div>
        <div class="zoom-controls btn-group btn-shadow">
          <button type="button" class="btn btn-white" @click="onZoomIt('in')">
            <i class="fas fa-plus icon-sm"></i>
          </button>
          <button type="button" class="btn btn-white" @click="onZoomIt('out')">
            <i class="fas fa-minus icon-sm"></i>
          </button>
        </div>
        <div class="legend-block">
          <div class="card card-custom card-stretch">
            <div class="card-body px-4 py-4">
              <div class="legend-points d-flex align-items-center justify-content-center justify-content-start">
                <div class="d-flex align-items-center mr-6">
                  <div class="legend-default-point"></div>
                  <p class="font-weight-bolder ml-3 my-0">{{ $t("MANAGEMENT.NOT_ASSIGNED_TITLE") }}</p>
                </div>
                <div class="d-flex align-items-center mr-6">
                  <div class="legend-desk-point"></div>
                  <p class="font-weight-bolder ml-3 my-0">{{ $t("MANAGEMENT.DESK_TITLE") }}</p>
                </div>
                <div class="d-flex align-items-center">
                  <div class="legend-meeting-point"></div>
                  <p class="font-weight-bolder ml-3 my-0">{{ $t("MANAGEMENT.MEETING_ROOM_TITLE") }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isCanEdit" class="points-control-block">
          <b-icon v-if="!isEditPoint && !isAddNewPoint"
                  @click="createNewPoint"
                  icon="plus"
                  class="btn-custom rounded-circle bg-primary cursor-pointer p-2"
                  font-scale="4"
                  variant="light">
          </b-icon>
          <b-icon v-if="isEditPoint"
                  @click="onAddEditPoint"
                  icon="check"
                  class="btn-custom rounded-circle bg-success cursor-pointer p-2"
                  font-scale="4"
                  variant="light">
          </b-icon>
          <b-icon v-if="isEditPoint"
                  @click="clonePoint"
                  icon="files-alt"
                  class="btn-custom rounded-circle bg-primary cursor-pointer p-4 mx-2"
                  font-scale="4"
                  variant="light">
          </b-icon>
          <b-icon v-if="isEditPoint"
                  @click="onDeletePoint"
                  icon="x"
                  class="btn-custom rounded-circle bg-danger cursor-pointer p-2"
                  font-scale="4"
                  variant="light">
          </b-icon>
        </div>
      </div>
      <div class="custom-right-aside flex-shrink-0 flex-row-auto w-250px w-xl-300px h-100">
        <div class="card card-custom card-stretch">
          <div class="card-body">
            <template v-if="!isEditPoint">
              <p class="font-weight-bolder">{{ $t("MANAGEMENT.SETTING_UP_FLOOR_PLAN_TITLE") }}</p>
              <h6>{{ $t("MANAGEMENT.RESOURCE_NOT_ASSIGNED_TITLE") }} <span class="float-right">{{ countDefaultPoints }}</span></h6>
              <h6>{{ $t("MANAGEMENT.DESKS_TITLE") }} <span class="float-right">{{ countDeskPoints }}</span></h6>
              <h6>{{ $t("MANAGEMENT.MEETINGS_ROOMS_TITLE") }} <span class="float-right">{{ countMeetingPoints }}</span></h6>
            </template>
            <template v-if="isEditPoint">
              <p class="font-weight-bolder mb-1">{{ $t("MANAGEMENT.RESOURCE_TYPE_SELECT_TITLE") }}</p>
              <treeselect v-model="selectedPointType"
                          :disabled="!isCanEdit"
                          :placeholder="$t('MANAGEMENT.RESOURCE_TYPE_PLACEHOLDER')"
                          :multiple="false"
                          :normalizer="normalizer"
                          :clearValueText="$t('SELECT.CLEAR_FIELD')"
                          :options="pointsTypesList">
                <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
              </treeselect>
              <template v-if="selectedPointType === 'desk'">
                <p class="font-weight-bolder mt-5 mb-1">{{ $t("MANAGEMENT.DESK_SELECT_TITLE") }}</p>
                <treeselect v-model="selectedDeskOrMeeting"
                            @input="onSelectResource('desk')"
                            :disabled="!isCanEdit"
                            :placeholder="$t('MANAGEMENT.DESK_PLACEHOLDER')"
                            :multiple="false"
                            :normalizer="normalizer"
                            :clearValueText="$t('SELECT.CLEAR_FIELD')"
                            :options="deskList.items">
                  <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
                </treeselect>
              </template>
              <template v-if="selectedPointType === 'meeting_room'">
                <p class="font-weight-bolder mt-5 mb-1">{{ $t("MANAGEMENT.MEET_ROOM_SELECT_TITLE") }}</p>
                <treeselect v-model="selectedDeskOrMeeting"
                            @input="onSelectResource('meeting-room')"
                            :disabled="!isCanEdit"
                            :placeholder="$t('MANAGEMENT.MEET_ROOM_PLACEHOLDER')"
                            :multiple="false"
                            :normalizer="normalizer"
                            :clearValueText="$t('SELECT.CLEAR_FIELD')"
                            :options="meetingRoomsList.items">
                  <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
                </treeselect>
              </template>
              <template v-if="selectedDeskOrMeeting">
                <p class="font-weight-bolder mt-15 mb-1">{{ $t('MANAGEMENT.AMENITIES_SELECT_TITLE') }}</p>
                <treeselect v-model="selectedAmenities"
                            :disabled="!isCanEdit"
                            :placeholder="$t('MANAGEMENT.AMENITIES_PLACEHOLDER')"
                            :multiple="true"
                            :normalizer="normalizer"
                            :clearValueText="$t('SELECT.CLEAR_FIELD')"
                            :options="amenitiesList.items">
                  <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
                </treeselect>
              </template>
            </template>
            <template v-if="!isEditPoint">
              <p class="font-weight-bolder mt-20">
                <span class="d-inline-block mt-2">{{ $t("MANAGEMENT.FLOOR_OCCUPANCY_LIMIT_TITLE") }}</span>
                <span class="switch float-right">
                  <label>
                    <input type="checkbox" :checked="occupancyLimit.status" @change="onChangeStatusOccupancy($event)" name="select"/>
                    <span></span>
                  </label>
                </span>
              </p>
              <h6>{{ $t("MANAGEMENT.FLOOR_OCCUPANCY_LIMIT_DESC") }}</h6>
              <template v-if="occupancyLimit.status">
                <b-input-group>
                  <b-form-input :value="occupancyLimit.value" @change="onChangeValueOccupancy($event)"></b-form-input>
                  <template #append>
                    <b-dropdown
                        :text="occupancy.type !== null ? occupancy.type === 'percent' ? '%' : $t('MANAGEMENT.LIMIT_BY_PC') : occupancyLimit.type === 'percent' ? '%' : $t('MANAGEMENT.LIMIT_BY_PC')"
                        variant="secondary">
                      <b-dropdown-item v-for="type in occupancyTypesList" :key="type.id"
                                       @click="onChangeTypeOccupancy(type.id, 'type')">
                        {{ type.name }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-input-group>
                <p v-if="occupancy.type !== null && occupancy.value !== null" class="d-block text-muted font-weight-lighter pt-2 font-size-xs">
                  <span v-if="occupancy.type === 'absolute'">{{ occupancy.value }}/{{ countDeskPoints }}</span>
                  <span v-if="occupancy.type === 'percent'">{{ occupancy.value }}%</span>
                  {{ $t("MANAGEMENT.DESKS_AVAILABLE_TITLE") }}
                </p>
                <p v-else class="d-block text-muted font-weight-lighter pt-2 font-size-xs">
                  <span v-if="occupancyLimit.type === 'absolute'">{{ occupancyLimit.value }}/{{ countDeskPoints }}</span>
                  <span v-if="occupancyLimit.type === 'percent'">{{ occupancyLimit.value }}%</span>
                  {{ $t("MANAGEMENT.DESKS_AVAILABLE_TITLE") }}
                </p>
                <button class="btn btn-primary btn-sm" @click="onSaveOccupancy">{{ $t("MANAGEMENT.SAVE_LIMIT_BUTTON") }}</button>
              </template>
            </template>
            <div v-if="isCanEdit" class="custom-right-aside-footer">
              <button v-if="isEditPoint"
                      :disabled="selectedPointType && !selectedDeskOrMeeting"
                      class="btn btn-success btn-block mb-4"
                      @click="onAddEditPoint">{{ $t("MANAGEMENT.SAVE_BUTTON") }}</button>
              <div v-if="floor.points"
                   class="row align-items-center bg-light-primary pt-2 mx-0">
                <div class="col-md-2">
                  <b-icon icon="exclamation-circle-fill" style="width: 20px; height: 20px;" variant="primary"></b-icon>
                </div>
                <div class="col-md-10">
                  <p v-if="floor.points && floor.points.length === 0">{{ $t("MANAGEMENT.WARNING_TEXT_1") }}</p>
                  <p v-if="floor.points && floor.points.length > 0 && !isEditPoint">{{ $t("MANAGEMENT.WARNING_TEXT_2") }}</p>
                  <p v-if="floor.points && floor.points.length > 0 && (isEditPoint || isAddNewPoint)">{{ $t("MANAGEMENT.WARNING_TEXT_3") }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--begin::Dummy-->
    <template v-if="floor.plan.image === null">
      <div class="w-100 justify-content-center row pt-30">
        <div class="col-auto d-flex">
          <FloorPlanEditorImage/>
        </div>
      </div>
      <div class="justify-content-center row">
        <h5 class="col-auto font-weight-bolder text-dark my-5 text-center">{{ $t("MANAGEMENT.NO_PLAN_YET_TITLE") }}
          <span class="d-block font-weight-lighter pt-2 font-size-sm">{{ $t("MANAGEMENT.NO_PLAN_YET_TITLE_DESC") }}</span>
          <router-link class="btn bg-primary text-light font-weight-bolder my-4"
                       :to="{ name: 'floor', params: { id: floor.id } }">{{ $t("MANAGEMENT.DOWNLOAD_PLAN_BUTTON") }}
          </router-link>
        </h5>
      </div>
    </template>
    <!--end::Dummy-->
  </div>
</template>

<script>
import VueDragResize from 'vue-drag-resize';
import { mapGetters } from "vuex";
import { GET_PROFILE } from "@/core/services/store/profile.module";
import { GET_AMENITIES_LIST } from "@/core/services/store/management/amenities.module";
import {
  GET_FLOOR,
  POST_NEW_FLOOR_POINT,
  PUT_FLOOR_POINT,
  DELETE_FLOOR_POINT, SET_ACTIVE_POINT, UNSET_ACTIVE_POINT, GET_OCCUPANCY_LIMIT, PUT_OCCUPANCY_LIMIT
} from "@/core/services/store/management.module";
import { GET_DESKS_LIST, GET_MEETING_ROOMS_LIST } from "@/core/services/store/management/resources.module";
import { sendAmplitudeEvent } from "@/core/services/amplitude.service";

import HeaderEditor from "@/view/layout/header/HeaderEditor";
import FloorPlanEditorImage from "@/view/layout/svg/FloorPlanEditorImage";
import MainSelect from "@/view/layout/extras/MainSelect";

export default {
  name: "FloorPlanEditor",
  components: {
    VueDragResize,
    HeaderEditor,
    FloorPlanEditorImage,
    MainSelect
  },
  mounted() {
    this.$store.dispatch(GET_PROFILE);
    this.$store.dispatch(GET_FLOOR, this.$route.params.id);
    this.$store.dispatch(GET_OCCUPANCY_LIMIT, this.$route.params.id);
    // this.$store.dispatch(GET_POINTS_TYPES);
    // this.$store.dispatch(GET_DESKS_LIST, { id: this.$route.params.id, params: { is_available: 1, statuses: 'active'} });
    // this.$store.dispatch(GET_MEETING_ROOMS_LIST, { id: this.$route.params.id, params: { is_available: 1, statuses: 'active'} });
  },
  created() {
    window.addEventListener("resize", this.onWindowResize);
    window.addEventListener("wheel", event => {
      if (event.deltaY < 0) {
        this.onZoomIt('in');
      } else {
        this.onZoomIt('out');
      }
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  data() {
    return {
      defaultPoint: {
        x: 0.5,
        y: 0.5
      },
      zoomed: false,
      parentScale: 1,
      isEditPoint: false,
      isAddNewPoint: false,
      isSticks: false,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      currentPoint: null,
      selectedPointType: null,
      selectedDeskOrMeeting: null,
      selectedAmenities: null,
      hasPlanLoaded: false,
      planSize: {
        width: null,
        height: null,
      },
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        }
      },
      pointsTypesList: [
        { name: this.$t("MANAGEMENT.DESK_POINT_TYPE"), id: "desk" },
        { name: this.$t("MANAGEMENT.MEET_ROOM_POINT_TYPE"), id: "meeting_room" }
      ],
      occupancyTypesList: [
        { name: this.$t("MANAGEMENT.LIMIT_BY_PC"), id: "absolute" },
        { name: "%", id: "percent" }
      ],
      occupancy: {
        status: null,
        type: null,
        value: null
      }
    }
  },
  watch: {
    selectedPointType(val) {
      this.$store.dispatch(GET_AMENITIES_LIST, { 'resource-type': val })
    }
  },
  computed: {
    ...mapGetters([
      "floor",
      // "pointsTypesList",
      "occupancyLimit",
      "deskList",
      "meetingRoomsList",
      "amenitiesList"
    ]),
    isCanEdit() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[1]));
        return role !== undefined;
      }
    },
    countDefaultPoints() {
      if (this.floor.points) {
        let points = this.floor.points.filter(item => item.resource === null);
        return points.length
      }
      return 0
    },
    countDeskPoints() {
      if (this.floor.points) {
        let points = this.floor.points.filter(item => item.resource && item.resource.type === 'desk');
        return points.length
      }
      return 0
    },
    countMeetingPoints() {
      if (this.floor.points) {
        let points = this.floor.points.filter(item => item.resource && item.resource.type !== 'desk');
        return points.length
      }
      return 0
    },
  },
  methods: {
    onZoomIt(status) {
      if (status === 'in') {
        this.$refs.zoomer.zoomIn();
        this.parentScale < 10 ? this.parentScale = this.$refs.zoomer.scale : this.parentScale = 10;
      } else if (status === 'out') {
        this.$refs.zoomer.zoomOut();
        this.parentScale > 1 ? this.parentScale = this.$refs.zoomer.scale : this.parentScale = 1;
      }
    },
    onChangeStatusOccupancy(event) {
      this.occupancy = {
        status: event.target.checked === true ? 1 : 0,
        type: this.occupancyLimit.type,
        value: this.occupancyLimit.value
      };
      this.$store.dispatch(PUT_OCCUPANCY_LIMIT, { id : this.$route.params.id, data: this.occupancy })
          .then(() => {
            this.occupancy = {
              status: null,
              type: null,
              value: null
            }
          });

      // if (data.type === 'absolute' && name === 'type') {
      //   data.value = Math.floor(data.value * this.countDeskPoints / 100);
      // } else if (data.type === 'percent' && name === 'type') {
      //   data.value = Math.floor(data.value * 100 / this.countDeskPoints);
      // }
    },
    onChangeValueOccupancy(event) {
      this.occupancy.value = parseInt(event);
    },
    onChangeTypeOccupancy(event) {
      this.occupancy.type = event;
    },
    onSaveOccupancy() {
      this.occupancy = {
        status: this.occupancyLimit.status,
        type: this.occupancy.type !== null ? this.occupancy.type : this.occupancyLimit.type,
        value: this.occupancy.value !== null ? this.occupancy.value : this.occupancyLimit.value
      };
      this.$store.dispatch(PUT_OCCUPANCY_LIMIT, { id : this.$route.params.id, data: this.occupancy })
          .then(() => {
            this.occupancy = {
              status: null,
              type: null,
              value: null
            }
          });
    },
    setSelectedOffice() {
      // this.$router.go(0)
    },
    onPlanLoad () {
      this.planSize = this.getPlanSize();
      this.hasPlanLoaded = true;
    },
    getPlanSize() {
      return {
        width: this.$refs.plan.width,
        height: this.$refs.plan.height,
      }
    },
    getRelativeCoordinates(coordinateType, coordinateValue) {
      switch (coordinateType) {
        case 'X':
          return coordinateValue * this.planSize.width;
        case 'Y':
          return coordinateValue * this.planSize.height;
        default:
          return 0;
      }
    },
    setStickSize(size) {
      if (this.planSize.width < 720) {
        size = 3;
      }
      return size;
    },
    setResizedPoint(pointSize) {
      // pointSize = Math.floor(this.planSize.width / 100);
      if (this.planSize.width < 600) {
        pointSize = Math.round(this.planSize.width * pointSize / 1000);
        pointSize = pointSize > 5 ? pointSize - 2 : pointSize - 1
      } else if (this.planSize.width > 1200) {
        pointSize = Math.round(this.planSize.width * pointSize / 1000);
        pointSize = pointSize > 20 ? pointSize - 3 : pointSize - 2;
      } else {
        pointSize = Math.round(this.planSize.width * pointSize / 1000);
        pointSize = pointSize > 10 ? pointSize - 2 : pointSize - 1;
      }
      return pointSize;
    },
    onWindowResize() {
      this.isEditPoint = false;
      this.isAddNewPoint = false;
      this.currentPoint = null;
      this.hasPlanLoaded = false;
      this.onPlanLoad();
    },
    createNewPoint() {
      this.isSticks = false;
      this.isAddNewPoint = true;
    },
    clonePoint() {
      let cloneCurrentPoint = {
        active: true,
        coordinates: {
          x: this.currentPoint.coordinates.x + 0.01,
          y: this.currentPoint.coordinates.y + 0.01,
        },
        size: this.currentPoint.size,
        resource: this.currentPoint.resource
      };
      this.currentPoint = null;
      this.floor.points.push(cloneCurrentPoint);
      sendAmplitudeEvent('management-floorplan-point clone');
    },
    onClicked(event, item, index) {
      if (event.type === 'touchstart' || event.type === 'mousedown') {
        this.currentPoint = null;
        if (item !== undefined) {
          this.isAddNewPoint = false;
          this.currentPoint = item;
          this.$store.dispatch(GET_DESKS_LIST, { id: this.$route.params.id, params: { is_available: 0, statuses: 'active', limit: 100 } });
          this.$store.dispatch(GET_MEETING_ROOMS_LIST, { id: this.$route.params.id, params: { is_available: 0, statuses: 'active', limit: 100 } });
        } else {
          this.$store.dispatch(GET_DESKS_LIST, { id: this.$route.params.id, params: { is_available: 1, statuses: 'active', limit: 100 } });
          this.$store.dispatch(GET_MEETING_ROOMS_LIST, { id: this.$route.params.id, params: { is_available: 1, statuses: 'active', limit: 100 } });
        }
        setTimeout(() => this.isEditPoint = true, 300)
        this.$store.dispatch(SET_ACTIVE_POINT, { id: index });
        if (this.currentPoint && this.currentPoint.resource) {
          this.selectedPointType = this.currentPoint.resource.type && this.currentPoint.resource.type === 'desk' ? 'desk' : 'meeting_room';
          this.selectedDeskOrMeeting = this.currentPoint.resource.id;
          this.selectedAmenities = this.currentPoint.amenities.map(item => item.id);
        } else {
          this.selectedPointType = null;
          this.selectedDeskOrMeeting = null;
          this.selectedAmenities = null;
        }
      }
    },
    onDeactivated(index) {
      this.isAddNewPoint = false;
      this.$store.dispatch(UNSET_ACTIVE_POINT, { index })
          .then(() => setTimeout(() => this.isEditPoint = false, 200));
    },
    onAddEditPoint() {
      let point = {
        coordinates: {
          x: this.left / this.planSize.width,
          y: this.top / this.planSize.height,
        },
        size: this.width === 0 ? 10 : this.width > 20 ? 20 : this.width
      };
      if (this.currentPoint && this.currentPoint.id) point.pointId = this.currentPoint.id;
      if (this.selectedDeskOrMeeting) point.resourceId = this.selectedDeskOrMeeting;
      if (this.selectedAmenities && this.selectedAmenities.length > 0) point.amenities = this.selectedAmenities;
      // if (this.selectedDeskOrMeeting || this.selectedPointType) point.resource = {};
      // if (this.selectedDeskOrMeeting) point.resource.id = this.selectedDeskOrMeeting;
      // if (this.selectedPointType) point.resource.type = this.selectedPointType;

      if (point.pointId) {
        this.$store.dispatch(PUT_FLOOR_POINT, { point, id: this.$route.params.id })
            .then(() => {
              this.isEditPoint = false;
              this.floor.points = [];
              this.$store.dispatch(GET_FLOOR, this.$route.params.id)
            });
      } else {
        this.$store.dispatch(POST_NEW_FLOOR_POINT, { point, id: this.$route.params.id })
            .then(() => {
              this.isEditPoint = false;
              this.isAddNewPoint = false;
              this.floor.points = [];
              this.$store.dispatch(GET_FLOOR, this.$route.params.id)
            });
      }
    },
    onDeletePoint() {
      if (this.currentPoint && this.currentPoint.id) {
        this.$store.dispatch(DELETE_FLOOR_POINT, {pointId: {pointId: this.currentPoint.id}, id: this.$route.params.id})
            .then(() => {
              this.isEditPoint = false;
              this.$store.dispatch(GET_FLOOR, this.$route.params.id)
            });
      }
      else if (this.currentPoint && !this.currentPoint.id) {
        this.floor.points = this.floor.points.filter(item => item.id);
      }
      else {
        this.isEditPoint = false;
        this.isAddNewPoint = false;
      }
    },
    onSelectResource(name) {
      sendAmplitudeEvent('management-floorplan-point assign', { 'type': name });
    },
    resize(newRect) {
      this.isSticks = true;
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
    },
    onResizeStop() {
      sendAmplitudeEvent('management-floorplan-point resize');
    },
    onDragStop() {
      sendAmplitudeEvent('management-floorplan-point move');
    },
  }
}
</script>

<style lang="scss">
.floor-plan-editor {
  margin: -25px;
  height: 100vh;
}
.floor-plan-editor-content {
  width: 100%;
  height: calc(100vh - 52px);
  position: relative;
  .content-container {
    max-width: 20px!important;
    max-height: 20px!important;
  }
}
.zoom-controls {
  position: absolute;
  z-index: 100;
  top: 15px;
  right: 25%;
}
.floor-plan-editor-filter {
  width: 50%;
  position: absolute;
  top: 15px;
  left: 25px;
  z-index: 100;
}
.box {
  margin: auto;
  display: block;
}
.default-point {
  border-radius: 50%;
  background-color: #B6B4C3;
  max-height: 20px;
  max-width: 20px;
  &:after {
    content: "";
    position: absolute;
    top: 62%;
    border-top: 2px solid #82839D;
    width: 100%;
    transform: rotate(130deg);
    transform-origin: 54.5% 0;
  }
  .vdr-stick {
    z-index: 1;
    overflow: hidden;
  }
}
.desk-point {
  border-radius: 50%;
  background-color: #5A628A;
  max-height: 20px;
  max-width: 20px;
}
.meeting-point {
  border-radius: 50%;
  background-color: #5A628A;
  max-height: 20px;
  max-width: 20px;
  box-shadow: inset 0 0 0 1px #5A628A, inset 0 0 0 2px #fff;
}
.legend-block {
  position: absolute;
  bottom: 25px;
  left: 25px;
  display: flex;

  .legend-default-point {
    border-radius: 50%;
    background-color: #B6B4C3;
    width: 20px;
    height: 20px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 11px;
      border-top: 2px solid #82839D;
      width: 20px;
      transform: rotate(130deg);
      transform-origin: 52% 0;
    }
  }
  .legend-desk-point {
    border-radius: 50%;
    background-color: #5A628A;
    width: 20px;
    height: 20px;
  }
  .legend-meeting-point {
    border-radius: 50%;
    background-color: #5A628A;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 1px #5A628A, inset 0 0 0 2px #fff;
  }
}
.points-control-block {
  position: absolute;
  bottom: 25px;
  right: 25%;
}
.btn-custom {
  box-shadow: 0 8px 10px 2px rgba(34, 60, 80, 0.2);
}
.custom-right-aside {
  height: calc(100% - 52px);
  position: absolute;
  right: 0;
  &-footer {
    width: 86%;
    position: absolute;
    bottom: 5%;
  }
}
.vue-treeselect__multi-value-item {
  background: #EBEDF3;
  color: #5A6072;
}
.vue-treeselect__value-remove {
  color: #C1D3E1;
}
@media (max-width: 991.98px) {
  .floor-plan-editor {
    margin: -15px;
  }
}
</style>
