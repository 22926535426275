<template>
  <div>
    <div class="editor-header d-flex bg-primary align-items-center">
      <div class="container-fluid d-flex align-items-center justify-content-between">
        <div class="flex-grow-1">
          <b-button class="btn btn-sm bg-primary text-light font-weight-bolder" @click="onCloseFloorPlanEditor">{{ $t("MANAGEMENT.CLOSE_FLOOR_PLAN_EDIT_BUTTON") }}</b-button>
        </div>
        <div class="flex-grow-1 text-center">
          <h6 class="text-light font-weight-bolder pt-2 font-size-base">{{ $t("MANAGEMENT.FLOOR_PLAN_EDIT_TITLE") }}</h6>
        </div>
        <div class="flex-grow-1"></div>
      </div>
    </div>
    <!--begin::Close Floor Plan Editor Modal-->
    <b-modal
        id="modal-close-floor-plan-editor"
        centered>
      <template #modal-header="{ close }">
        <div class="w-100">
          <h5 class="float-left font-weight-bolder text-dark w-75">{{ $t("MANAGEMENT.EXIT_FLOOR_PLAN_EDIT_DESC") }}</h5>
          <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
        </div>
      </template>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="light-primary"
              class="float-left font-weight-bolder"
              @click="resetModal">
            {{ $t("MANAGEMENT.CANCEL_BUTTON") }}
          </b-button>
          <p class="float-right">
            <b-button
                class="font-weight-bolder"
                variant="warning"
                @click="$router.push({ name: 'office', params: { id: backPage } })">
              {{ $t("MANAGEMENT.EXIT_BUTTON") }}
            </b-button>
          </p>
        </div>
      </template>
    </b-modal>
    <!--end::Close Floor Plan Editor Modal-->
  </div>
</template>

<script>
export default {
  name: "HeaderEditor",
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    backPage: {
      type: String
    }
  },
  methods: {
    onCloseFloorPlanEditor() {
      if (this.isEdit) this.$bvModal.show('modal-close-floor-plan-editor');
      else this.$router.push({ name: 'office', params: { id: this.backPage } })
    },
    resetModal() {
      this.$bvModal.hide('modal-close-floor-plan-editor');
    },
  }
}
</script>

<style lang="scss" scoped>
  .editor-header {
    height: 52px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
  }
</style>
