<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="602.246" height="400" viewBox="0 0 602.246 400">
    <defs>
      <clipPath id="clip-path">
        <path id="Контур_1715" data-name="Контур 1715" d="M497.772-1102.315,11.5-1101.272A11.034,11.034,0,0,0,.337-1090.4v330.7a16.371,16.371,0,0,0,16.6,16.13H501.807v.035l42.56-2.661c13.915-.867,24.728-12.108,24.686-25.655q-2.193-322.905-2.192-324.929c0-2.024-.591-5.528-6.119-5.528" transform="translate(-0.337 1102.315)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2">
        <path id="Контур_1714" data-name="Контур 1714" d="M-876-1031.954H196.57V-1747H-876Z" transform="translate(876 1747)" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-3">
        <path id="Контур_1721" data-name="Контур 1721" d="M616.928-568.614H578.25v-2.167h38.678a2.973,2.973,0,0,0,2.969-2.969V-676.98h2.167v103.231a5.143,5.143,0,0,1-5.135,5.135" transform="translate(-578.25 676.98)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-4">
        <path id="Контур_1720" data-name="Контур 1720" d="M-876-1031.954H196.57V-1747H-876Z" transform="translate(876 1747)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-5">
        <rect id="Прямоугольник_235" data-name="Прямоугольник 235" width="44.203" height="108.774" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-7">
        <path id="Контур_1718" data-name="Контур 1718" d="M578-568.226h44.2V-677H578Z" transform="translate(-578 677)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-8">
        <path id="Контур_1727" data-name="Контур 1727" d="M629.135-745.587H578.25v-2.167h50.885a2.015,2.015,0,0,0,1.72-.945,2,2,0,0,0,.13-1.954l-.03-.074-18.24-48.48,2.028-.763,18.227,48.441a4.189,4.189,0,0,1-.29,4,4.185,4.185,0,0,1-3.545,1.946" transform="translate(-578.25 799.969)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-10">
        <rect id="Прямоугольник_236" data-name="Прямоугольник 236" width="55.47" height="54.604" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-12">
        <path id="Контур_1724" data-name="Контур 1724" d="M578-745.4h55.47V-800H578Z" transform="translate(-578 800)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-13">
        <path id="Контур_1733" data-name="Контур 1733" d="M517.738-803.735l-.048-1.733,56.389-1.514-.23-1.433c0-1.436-1.807-2.65-3.948-2.65l-60.471,1.079-.03-1.733,60.484-1.079c3.116,0,5.647,1.925,5.7,4.306l.533,3.189-58.378,1.568" transform="translate(-509.4 812.798)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-15">
        <rect id="Прямоугольник_237" data-name="Прямоугольник 237" width="67.171" height="9.534" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-17">
        <path id="Контур_1730" data-name="Контур 1730" d="M509-803.466h67.171V-813H509Z" transform="translate(-509 813)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-18">
        <path id="Контур_1739" data-name="Контур 1739" d="M607.878-573.425H578.24v-1.734h29v-51.908h-29V-628.8h29.638a1.1,1.1,0,0,1,1.1,1.1v53.182a1.1,1.1,0,0,1-1.1,1.1" transform="translate(-578.24 628.8)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-20">
        <rect id="Прямоугольник_238" data-name="Прямоугольник 238" width="31.202" height="55.47" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-22">
        <path id="Контур_1736" data-name="Контур 1736" d="M578-573.53h31.2V-629H578Z" transform="translate(-578 629)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-23">
        <path id="Контур_1745" data-name="Контур 1745" d="M589.23-572.534H578.24v-1.733h10.253v-46.11H578.24v-1.733h10.99a1,1,0,0,1,1,.992v47.588a1,1,0,0,1-1,1" transform="translate(-578.24 622.11)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-25">
        <rect id="Прямоугольник_239" data-name="Прямоугольник 239" width="12.134" height="50.27" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-27">
        <path id="Контур_1742" data-name="Контур 1742" d="M578-572.73h12.134V-623H578Z" transform="translate(-578 623)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-28">
        <path id="Контур_1751" data-name="Контур 1751" d="M622.236-572.534h-12.97a1.14,1.14,0,0,1-1.135-1.14v-47.3a1.139,1.139,0,0,1,1.135-1.135h12.97a1.136,1.136,0,0,1,1.135,1.135v47.3a1.137,1.137,0,0,1-1.135,1.14Zm-12.372-1.733h11.774v-46.11H609.864Z" transform="translate(-608.13 622.11)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-30">
        <rect id="Прямоугольник_240" data-name="Прямоугольник 240" width="15.601" height="50.27" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-32">
        <path id="Контур_1748" data-name="Контур 1748" d="M608-572.73h15.6V-623H608Z" transform="translate(-608 623)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-33">
        <path id="Контур_1757" data-name="Контур 1757" d="M133.763-568.614H95.321a3.908,3.908,0,0,1-3.9-3.9V-676.98h2.167v104.462a1.739,1.739,0,0,0,1.736,1.738h38.442v2.167" transform="translate(-91.418 676.98)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-35">
        <rect id="Прямоугольник_241" data-name="Прямоугольник 241" width="42.903" height="108.774" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-37">
        <path id="Контур_1754" data-name="Контур 1754" d="M91-568.226h42.9V-677H91Z" transform="translate(-91 677)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-38">
        <path id="Контур_1763" data-name="Контур 1763" d="M118.448-745.587H69.594a4.189,4.189,0,0,1-3.546-1.946,4.188,4.188,0,0,1-.289-4l18.225-48.441,2.028.763L67.743-750.653a2,2,0,0,0,.131,1.954,2.01,2.01,0,0,0,1.72.945h48.853v2.167" transform="translate(-65.384 799.969)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-40">
        <rect id="Прямоугольник_242" data-name="Прямоугольник 242" width="53.737" height="54.604" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-42">
        <path id="Контур_1760" data-name="Контур 1760" d="M64-745.4h53.737V-800H64Z" transform="translate(-64 800)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-43">
        <path id="Контур_1769" data-name="Контур 1769" d="M166.146-804.879l-57.5-.393v-3.144c0-2.457,2.66-4.382,6.055-4.382h59.549v1.733H114.7c-2.343,0-4.322,1.213-4.322,2.649v1.423l55.78.38-.013,1.734" transform="translate(-108.645 812.798)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-45">
        <rect id="Прямоугольник_243" data-name="Прямоугольник 243" width="66.304" height="8.234" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-47">
        <path id="Контур_1766" data-name="Контур 1766" d="M108-804.766h66.3V-813H108Z" transform="translate(-108 813)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-48">
        <path id="Контур_1775" data-name="Контур 1775" d="M150.306-573.425h-27.6a1.1,1.1,0,0,1-1.1-1.1V-627.7a1.1,1.1,0,0,1,1.1-1.1h27.6v1.733H123.342v51.908h26.964v1.734" transform="translate(-121.608 628.8)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-50">
        <rect id="Прямоугольник_244" data-name="Прямоугольник 244" width="29.035" height="55.47" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-52">
        <path id="Контур_1772" data-name="Контур 1772" d="M121-573.53h29.035V-629H121Z" transform="translate(-121 629)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-53">
        <path id="Контур_1781" data-name="Контур 1781" d="M174.82-572.534h-8.953a1,1,0,0,1-1-1v-47.588a1,1,0,0,1,1-.992h8.953v1.733H166.6v46.11h8.216v1.733" transform="translate(-164.87 622.11)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-55">
        <rect id="Прямоугольник_245" data-name="Прямоугольник 245" width="10.401" height="50.27" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-57">
        <path id="Контур_1778" data-name="Контур 1778" d="M164-572.73h10.4V-623H164Z" transform="translate(-164 623)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-58">
        <path id="Контур_1787" data-name="Контур 1787" d="M141.566-572.534H128.6a1.141,1.141,0,0,1-1.14-1.14v-47.3a1.14,1.14,0,0,1,1.14-1.135h12.966a1.14,1.14,0,0,1,1.14,1.135v47.3a1.141,1.141,0,0,1-1.14,1.14Zm-12.372-1.733h11.774v-46.11H129.193Z" transform="translate(-127.46 622.11)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-62">
        <path id="Контур_1784" data-name="Контур 1784" d="M127-572.73h15.6V-623H127Z" transform="translate(-127 623)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-63">
        <path id="Контур_1793" data-name="Контур 1793" d="M492.935-586.94H434.83v-2.167h58.105a2.969,2.969,0,0,0,2.964-2.969V-718.47h2.167v126.394a5.14,5.14,0,0,1-5.131,5.135" transform="translate(-434.83 718.47)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-65">
        <rect id="Прямоугольник_247" data-name="Прямоугольник 247" width="63.704" height="132.175" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-67">
        <path id="Контур_1790" data-name="Контур 1790" d="M434-586.825h63.7V-719H434Z" transform="translate(-434 719)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-68">
        <path id="Контур_1799" data-name="Контур 1799" d="M241.03-587.91H192.116a6.8,6.8,0,0,1-6.786-6.786V-720.18H187.5V-594.7a4.624,4.624,0,0,0,4.62,4.62H241.03v2.167" transform="translate(-185.33 720.18)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-70">
        <rect id="Прямоугольник_248" data-name="Прямоугольник 248" width="55.904" height="133.042" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-72">
        <path id="Контур_1796" data-name="Контур 1796" d="M185-587.958h55.9V-721H185Z" transform="translate(-185 721)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-73">
        <path id="Контур_1805" data-name="Контур 1805" d="M365.982-443.548h-1.733v-6.678H313.523v6.678H311.79v-6.895a1.518,1.518,0,0,1,1.517-1.517h51.154a1.519,1.519,0,0,1,1.521,1.517v6.895" transform="translate(-311.79 451.96)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-75">
        <rect id="Прямоугольник_249" data-name="Прямоугольник 249" width="54.604" height="8.667" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-77">
        <path id="Контур_1802" data-name="Контур 1802" d="M311-443.333h54.6V-452H311Z" transform="translate(-311 452)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-78">
        <path id="Контур_1811" data-name="Контур 1811" d="M313.523-427.153h50.725v-6.656a.508.508,0,0,0-.507-.507H314.03a.5.5,0,0,0-.507.507Zm52.458,1.733H311.79v-8.39a2.243,2.243,0,0,1,2.24-2.24h49.711a2.243,2.243,0,0,1,2.24,2.24v8.39Z" transform="translate(-311.79 436.05)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-80">
        <rect id="Прямоугольник_250" data-name="Прямоугольник 250" width="54.604" height="11.267" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-82">
        <path id="Контур_1808" data-name="Контур 1808" d="M311-425.733h54.6V-437H311Z" transform="translate(-311 437)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-83">
        <path id="Контур_1817" data-name="Контур 1817" d="M332.734-543.285H366.8v-67.435a.677.677,0,0,0-.676-.676H333.41a.677.677,0,0,0-.676.676Zm35.8,1.734H331v-69.169a2.413,2.413,0,0,1,2.41-2.409h32.719a2.41,2.41,0,0,1,2.41,2.409v69.169Z" transform="translate(-331 613.13)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-85">
        <rect id="Прямоугольник_251" data-name="Прямоугольник 251" width="37.702" height="72.371" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-87">
        <path id="Контур_1814" data-name="Контур 1814" d="M331-541.629h37.7V-614H331Z" transform="translate(-331 614)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-88">
        <path id="Контур_1823" data-name="Контур 1823" d="M365.145-634.537H334.4a3.4,3.4,0,0,1-3.4-3.4v-2.206a3.411,3.411,0,0,1,3.159-3.389l30.747-2.132a2.112,2.112,0,0,1,.234-.009,3.416,3.416,0,0,1,2.318.91,3.417,3.417,0,0,1,1.079,2.483v4.342a3.4,3.4,0,0,1-3.393,3.4m0-9.4a1.15,1.15,0,0,0-.121,0L334.281-641.8a1.669,1.669,0,0,0-1.547,1.66v2.206a1.664,1.664,0,0,0,1.664,1.664h30.747a1.664,1.664,0,0,0,1.66-1.664v-4.342a1.636,1.636,0,0,0-.529-1.213,1.63,1.63,0,0,0-1.127-.446" transform="translate(-331 645.67)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-90">
        <rect id="Прямоугольник_252" data-name="Прямоугольник 252" width="37.702" height="11.701" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-92">
        <path id="Контур_1820" data-name="Контур 1820" d="M331-634.3h37.7V-646H331Z" transform="translate(-331 646)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-93">
        <path id="Контур_1829" data-name="Контур 1829" d="M232.413-721.314h10.448v-18.73c-5.907.26-10.448,4.156-10.448,9.088ZM244.6-719.58H230.68v-11.376c0-6.076,5.625-10.834,12.806-10.834H244.6v22.21Z" transform="translate(-230.68 741.79)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-95">
        <rect id="Прямоугольник_253" data-name="Прямоугольник 253" width="14.301" height="22.535" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-97">
        <path id="Контур_1826" data-name="Контур 1826" d="M230-719.465h14.3V-742H230Z" transform="translate(-230 742)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-98">
        <path id="Контур_1835" data-name="Контур 1835" d="M232.413-685.647V-655.6c0,.6.975,1.79,1.6,1.79h8.845v-31.839ZM244.6-652.074H234.017c-1.655,0-3.337-2.05-3.337-3.523V-687.38H244.6v35.306Z" transform="translate(-230.68 687.38)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-100">
        <rect id="Прямоугольник_254" data-name="Прямоугольник 254" width="14.301" height="35.969" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-102">
        <path id="Контур_1832" data-name="Контур 1832" d="M230-652.031h14.3V-688H230Z" transform="translate(-230 688)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-103">
        <path id="Контур_1841" data-name="Контур 1841" d="M266.773-721.314h10.448v-9.642c0-5.118-4.472-8.992-10.448-9.1Zm12.182,1.734H265.04v-22.21h1.486c7.086,0,12.429,4.659,12.429,10.834v11.376Z" transform="translate(-265.04 741.79)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-107">
        <path id="Контур_1838" data-name="Контур 1838" d="M265-719.465h14.3V-742H265Z" transform="translate(-265 742)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-108">
        <path id="Контур_1847" data-name="Контур 1847" d="M266.773-653.807H276c.494,0,1.226-1.07,1.226-1.79v-30.049H266.773ZM276-652.074H265.04V-687.38h13.915V-655.6c0,1.417-1.244,3.523-2.96,3.523Z" transform="translate(-265.04 687.38)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-112">
        <path id="Контур_1844" data-name="Контур 1844" d="M265-652.031h14.3V-688H265Z" transform="translate(-265 688)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-113">
        <path id="Контур_1853" data-name="Контур 1853" d="M236.338-748.133c-7.4,0-13.413,5.486-13.413,12.225v46.547a1.8,1.8,0,0,0,1.9,1.673h29.33a1.8,1.8,0,0,0,1.9-1.673v-46.547c0-6.739-6.015-12.225-13.413-12.225ZM254.153-685.7h-29.33a3.8,3.8,0,0,1-3.913-3.662v-46.547c0-7.839,6.921-14.216,15.428-14.216h6.3c8.507,0,15.428,6.376,15.428,14.216v46.547a3.8,3.8,0,0,1-3.913,3.662Z" transform="translate(-220.91 750.124)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-115">
        <rect id="Прямоугольник_257" data-name="Прямоугольник 257" width="37.702" height="65.004" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-117">
        <path id="Контур_1850" data-name="Контур 1850" d="M220-686h37.7v-65H220Z" transform="translate(-220 751)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-118">
        <path id="Контур_1859" data-name="Контур 1859" d="M332.86-541.552H332a1.48,1.48,0,0,1-1.478-1.478v-68.714a1.388,1.388,0,0,1,1.387-1.387h.953v1.733h-.607v68.111h.607v1.734" transform="translate(-330.52 613.13)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-120">
        <rect id="Прямоугольник_258" data-name="Прямоугольник 258" width="2.6" height="72.371" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-122">
        <path id="Контур_1856" data-name="Контур 1856" d="M330-541.629h2.6V-614H330Z" transform="translate(-330 614)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-123">
        <path id="Контур_1863" data-name="Контур 1863" d="M498.81-839.128h1.734v-19.094H498.81Z" transform="translate(-498.81 858.222)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-125">
        <path id="Контур_1867" data-name="Контур 1867" d="M466.52-850.169h1.733v-8.053H466.52Z" transform="translate(-466.52 858.222)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-127">
        <path id="Контур_1873" data-name="Контур 1873" d="M458.994-869.371h20.888v-2.11a3.159,3.159,0,0,0-3.155-3.154H460.77a1.778,1.778,0,0,0-1.777,1.775Zm22.621,1.734H457.26v-5.222a3.512,3.512,0,0,1,3.51-3.509h15.956a4.893,4.893,0,0,1,4.888,4.887v3.843Z" transform="translate(-457.26 876.368)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-129">
        <rect id="Прямоугольник_259" data-name="Прямоугольник 259" width="24.702" height="9.101" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-131">
        <path id="Контур_1870" data-name="Контур 1870" d="M457-867.9h24.7V-877H457Z" transform="translate(-457 877)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-132">
        <path id="Контур_1879" data-name="Контур 1879" d="M259.237-905.175l-91.149,69.62a3.5,3.5,0,0,0-1.109,4.1l2.943,7.164,89.32-68.5,85.884,66.616a4.185,4.185,0,0,0,4.039.624,4.189,4.189,0,0,0,2.661-3.1l1.062-5.183ZM168.99-820.851l-4.013-9.781a5.664,5.664,0,0,1,1.8-6.644L259.237-907.9l.659.5,95.383,72.663-1.33,6.513a6.324,6.324,0,0,1-4.03,4.7,6.336,6.336,0,0,1-6.123-.94l-84.562-65.591L168.99-820.851Z" transform="translate(-164.554 907.9)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-134">
        <rect id="Прямоугольник_260" data-name="Прямоугольник 260" width="191.546" height="87.106" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-136">
        <path id="Контур_1876" data-name="Контур 1876" d="M163-820.894H354.546V-908H163Z" transform="translate(-163 908)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-137">
        <path id="Контур_1885" data-name="Контур 1885" d="M225.733-532.167h32.957v-42.569H225.733Zm33.824,1.733H224.867A.864.864,0,0,1,224-531.3v-44.3a.864.864,0,0,1,.867-.867h34.691a.864.864,0,0,1,.867.867v44.3a.864.864,0,0,1-.867.867Z" transform="translate(-224 576.47)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-139">
        <rect id="Прямоугольник_261" data-name="Прямоугольник 261" width="36.836" height="46.37" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-141">
        <path id="Контур_1882" data-name="Контур 1882" d="M224-530.63h36.836V-577H224Z" transform="translate(-224 577)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-142">
        <path id="Контур_1891" data-name="Контур 1891" d="M232.263-531.24h11.809v-36.537H232.263Zm12.676,1.734H231.4a.867.867,0,0,1-.867-.867v-38.27a.869.869,0,0,1,.867-.867h13.543a.869.869,0,0,1,.867.867v38.27a.867.867,0,0,1-.867.867Z" transform="translate(-230.53 569.51)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-144">
        <rect id="Прямоугольник_262" data-name="Прямоугольник 262" width="15.601" height="40.303" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-146">
        <path id="Контур_1888" data-name="Контур 1888" d="M230-529.7h15.6V-570H230Z" transform="translate(-230 570)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-147">
        <path id="Контур_1897" data-name="Контур 1897" d="M267.913-530.94h11.809v-36.537H267.913Zm12.676,1.733H267.047a.869.869,0,0,1-.867-.867v-38.27a.866.866,0,0,1,.867-.867h13.543a.864.864,0,0,1,.867.867v38.27a.866.866,0,0,1-.867.867Z" transform="translate(-266.18 569.21)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-149">
        <rect id="Прямоугольник_263" data-name="Прямоугольник 263" width="15.601" height="40.736" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-151">
        <path id="Контур_1894" data-name="Контур 1894" d="M266-529.264h15.6V-570H266Z" transform="translate(-266 570)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-152">
        <path id="Контур_1903" data-name="Контур 1903" d="M456.063-722.264h10.448v-18.73c-5.907.256-10.448,4.156-10.448,9.088Zm12.182,1.734H454.33v-11.376c0-6.076,5.625-10.834,12.8-10.834h1.114v22.21Z" transform="translate(-454.33 742.74)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-156">
        <path id="Контур_1900" data-name="Контур 1900" d="M454-720.465h14.3V-743H454Z" transform="translate(-454 743)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-157">
        <path id="Контур_1909" data-name="Контур 1909" d="M456.063-686.6v30.049c0,.6.971,1.785,1.6,1.785h8.849V-686.6Zm12.182,33.568H457.663c-1.651,0-3.333-2.045-3.333-3.519V-688.33h13.915v35.3Z" transform="translate(-454.33 688.33)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-161">
        <path id="Контур_1906" data-name="Контур 1906" d="M454-653.031h14.3V-689H454Z" transform="translate(-454 689)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-162">
        <path id="Контур_1915" data-name="Контур 1915" d="M490.424-722.264h10.448v-9.642c0-5.118-4.477-9-10.448-9.1Zm12.182,1.734H488.69v-22.21h1.482c7.09,0,12.433,4.659,12.433,10.834v11.376Z" transform="translate(-488.69 742.74)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-166">
        <path id="Контур_1912" data-name="Контур 1912" d="M488-720.465h14.3V-743H488Z" transform="translate(-488 743)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-167">
        <path id="Контур_1921" data-name="Контур 1921" d="M490.424-654.762h9.218c.494,0,1.231-1.066,1.231-1.785V-686.6H490.424Zm9.218,1.733H488.69v-35.3h13.915v31.783c0,1.417-1.244,3.519-2.964,3.519Z" transform="translate(-488.69 688.33)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-171">
        <path id="Контур_1918" data-name="Контур 1918" d="M488-653.031h14.3V-689H488Z" transform="translate(-488 689)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-172">
        <path id="Контур_1927" data-name="Контур 1927" d="M459.978-749.085c-7.393,0-13.412,5.482-13.412,12.225v46.547a1.8,1.8,0,0,0,1.9,1.668h29.326a1.8,1.8,0,0,0,1.9-1.668V-736.86c0-6.743-6.015-12.225-13.412-12.225Zm17.815,62.434H448.468a3.8,3.8,0,0,1-3.918-3.662V-736.86c0-7.84,6.921-14.218,15.428-14.218h6.3c8.511,0,15.432,6.378,15.432,14.218v46.547a3.8,3.8,0,0,1-3.918,3.662Z" transform="translate(-444.55 751.078)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-176">
        <path id="Контур_1924" data-name="Контур 1924" d="M444-687h37.7v-65H444Z" transform="translate(-444 752)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-177">
        <path id="Контур_1933" data-name="Контур 1933" d="M449.374-533.123h32.961V-575.7H449.374ZM483.2-531.39H448.507a.866.866,0,0,1-.867-.867v-44.307a.869.869,0,0,1,.867-.867H483.2a.869.869,0,0,1,.867.867v44.307a.866.866,0,0,1-.867.867Z" transform="translate(-447.64 577.43)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-181">
        <path id="Контур_1930" data-name="Контур 1930" d="M447-531.63h36.836V-578H447Z" transform="translate(-447 578)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-182">
        <path id="Контур_1939" data-name="Контур 1939" d="M455.914-532.19h11.8v-36.537h-11.8Zm12.671,1.733H455.047a.867.867,0,0,1-.867-.867v-38.27a.864.864,0,0,1,.867-.867h13.538a.864.864,0,0,1,.867.867v38.27a.867.867,0,0,1-.867.867Z" transform="translate(-454.18 570.46)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-186">
        <path id="Контур_1936" data-name="Контур 1936" d="M454-530.7h15.6V-571H454Z" transform="translate(-454 571)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-187">
        <path id="Контур_1945" data-name="Контур 1945" d="M491.564-531.89h11.809v-36.537H491.564Zm12.676,1.733H490.7a.869.869,0,0,1-.867-.867v-38.27a.866.866,0,0,1,.867-.867h13.543a.866.866,0,0,1,.867.867v38.27a.869.869,0,0,1-.867.867Z" transform="translate(-489.83 570.16)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-189">
        <rect id="Прямоугольник_271" data-name="Прямоугольник 271" width="16.034" height="40.736" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-191">
        <path id="Контур_1942" data-name="Контур 1942" d="M489-530.264h16.034V-571H489Z" transform="translate(-489 571)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-192">
        <path id="Контур_1950" data-name="Контур 1950" d="M32.735-1092.928a18.364,18.364,0,0,0-18.343,18.344V-759.54A15.126,15.126,0,0,0,29.5-744.432H541.667a19.693,19.693,0,0,0,19.675-19.675v-312.087a16.752,16.752,0,0,0-16.732-16.734Zm508.931,349.8H29.5A16.426,16.426,0,0,1,13.092-759.54v-315.045a19.666,19.666,0,0,1,19.643-19.644H544.609a18.052,18.052,0,0,1,18.032,18.034v312.087a21,21,0,0,1-20.975,20.975Z" transform="translate(-13.092 1094.228)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-194">
        <rect id="Прямоугольник_272" data-name="Прямоугольник 272" width="549.936" height="351.456" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-196">
        <path id="Контур_1947" data-name="Контур 1947" d="M13-743.544H562.936V-1095H13Z" transform="translate(-13 1095)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-197">
        <path id="Контур_1956" data-name="Контур 1956" d="M749.953-898.706H952.342v-145.83H749.953Zm-1.733,1.733H954.075v-149.3H748.22Z" transform="translate(-748.22 1046.269)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-199">
        <rect id="Прямоугольник_273" data-name="Прямоугольник 273" width="206.28" height="149.943" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-201">
        <path id="Контур_1953" data-name="Контур 1953" d="M748-897.057H954.28V-1047H748Z" transform="translate(-748 1047)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-202">
        <path id="Контур_1962" data-name="Контур 1962" d="M757.054-897.669h194.9v-139.125h-194.9Zm-1.734,1.733H953.691v-142.592H755.32Z" transform="translate(-755.32 1038.528)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-204">
        <rect id="Прямоугольник_274" data-name="Прямоугольник 274" width="198.913" height="143.009" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-206">
        <path id="Контур_1959" data-name="Контур 1959" d="M755-895.991H953.913V-1039H755Z" transform="translate(-755 1039)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-207">
        <path id="Контур_1968" data-name="Контур 1968" d="M988.63-985.722v-50.807h4.546v46.261a4.547,4.547,0,0,1-4.546,4.546" transform="translate(-988.63 1036.529)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-209">
        <rect id="Прямоугольник_275" data-name="Прямоугольник 275" width="5.2" height="51.137" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-211">
        <path id="Контур_1965" data-name="Контур 1965" d="M988-985.863h5.2V-1037H988Z" transform="translate(-988 1037)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-212">
        <path id="Контур_1972" data-name="Контур 1972" d="M1117.97-992.091h4.017v-44.438h-4.017Z" transform="translate(-1117.97 1036.529)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-214">
        <path id="Контур_1978" data-name="Контур 1978" d="M859.156-852.184H757.32v-4.627H858.358a2.7,2.7,0,0,1,2.7,2.7v.024a1.9,1.9,0,0,1-1.9,1.9" transform="translate(-757.32 856.811)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-216">
        <rect id="Прямоугольник_276" data-name="Прямоугольник 276" width="104.007" height="4.767" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-218">
        <path id="Контур_1975" data-name="Контур 1975" d="M757-852.233H861.007V-857H757Z" transform="translate(-757 857)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-219">
        <path id="Контур_1984" data-name="Контур 1984" d="M1034.51-978.85h21.062v-50.564H1034.51Zm21.712,1.3H1033.86a.65.65,0,0,1-.65-.65v-51.864a.65.65,0,0,1,.65-.65h22.362a.65.65,0,0,1,.65.65V-978.2a.65.65,0,0,1-.65.65Z" transform="translate(-1033.21 1030.714)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-221">
        <rect id="Прямоугольник_277" data-name="Прямоугольник 277" width="23.835" height="53.303" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-223">
        <path id="Контур_1981" data-name="Контур 1981" d="M1033-977.7h23.835V-1031H1033Z" transform="translate(-1033 1031)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-224">
        <path id="Контур_1990" data-name="Контур 1990" d="M1034.51-1013.411h21.062v-4.412H1034.51Zm-1.3,1.3h23.662v-7.013H1033.21Z" transform="translate(-1033.21 1019.123)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-226">
        <rect id="Прямоугольник_278" data-name="Прямоугольник 278" width="23.835" height="7.801" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-228">
        <path id="Контур_1987" data-name="Контур 1987" d="M1033-1012.2h23.835v-7.8H1033Z" transform="translate(-1033 1020)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-229">
        <path id="Контур_1996" data-name="Контур 1996" d="M1034.51-985.334h21.062v-4.412H1034.51Zm-1.3,1.3h23.662v-7.012H1033.21Z" transform="translate(-1033.21 991.046)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-233">
        <path id="Контур_1993" data-name="Контур 1993" d="M1033-984.2h23.835V-992H1033Z" transform="translate(-1033 992)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-234">
        <path id="Контур_2002" data-name="Контур 2002" d="M1034.51-959.631h21.062v-4.412H1034.51Zm-1.3,1.3h23.662v-7.013H1033.21Z" transform="translate(-1033.21 965.343)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-236">
        <rect id="Прямоугольник_280" data-name="Прямоугольник 280" width="23.835" height="7.367" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-238">
        <path id="Контур_1999" data-name="Контур 1999" d="M1033-958.633h23.835V-966H1033Z" transform="translate(-1033 966)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-239">
        <path id="Контур_2008" data-name="Контур 2008" d="M1034.51-932.345h21.062v-4.413H1034.51Zm-1.3,1.3h23.662v-7.013H1033.21Z" transform="translate(-1033.21 938.058)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-243">
        <path id="Контур_2005" data-name="Контур 2005" d="M1033-931.2h23.835V-939H1033Z" transform="translate(-1033 939)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-244">
        <path id="Контур_2014" data-name="Контур 2014" d="M805.13-805.012a2.933,2.933,0,0,0-2.929,2.932V-783.8a2.933,2.933,0,0,0,2.929,2.931H850.4a2.937,2.937,0,0,0,2.934-2.931V-802.08a2.937,2.937,0,0,0-2.934-2.932ZM850.4-779.564H805.13a4.237,4.237,0,0,1-4.23-4.232V-802.08a4.236,4.236,0,0,1,4.23-4.232H850.4a4.237,4.237,0,0,1,4.234,4.232V-783.8a4.238,4.238,0,0,1-4.234,4.232Z" transform="translate(-800.9 806.312)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-246">
        <rect id="Прямоугольник_282" data-name="Прямоугольник 282" width="54.17" height="27.302" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-248">
        <path id="Контур_2011" data-name="Контур 2011" d="M800-779.7h54.17V-807H800Z" transform="translate(-800 807)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-249">
        <path id="Контур_2020" data-name="Контур 2020" d="M932.558-784.594a1.77,1.77,0,0,0-1.768,1.767,1.769,1.769,0,0,0,1.768,1.766h.394a1.768,1.768,0,0,0,1.764-1.766,1.769,1.769,0,0,0-1.764-1.767Zm.394,4.834h-.394a3.072,3.072,0,0,1-3.068-3.067,3.072,3.072,0,0,1,3.068-3.067h.394a3.069,3.069,0,0,1,3.064,3.067,3.068,3.068,0,0,1-3.064,3.067Z" transform="translate(-929.49 785.894)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-251">
        <rect id="Прямоугольник_283" data-name="Прямоугольник 283" width="6.934" height="6.5" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-253">
        <path id="Контур_2017" data-name="Контур 2017" d="M929-779.5h6.934V-786H929Z" transform="translate(-929 786)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-254">
        <path id="Контур_2026" data-name="Контур 2026" d="M941.711-779.945h0a.772.772,0,0,1-.771-.771v-5.2a.772.772,0,0,1,.771-.771.769.769,0,0,1,.771.771v5.2a.769.769,0,0,1-.771.771" transform="translate(-940.94 786.685)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-256">
        <rect id="Прямоугольник_284" data-name="Прямоугольник 284" width="2.167" height="6.934" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-258">
        <path id="Контур_2023" data-name="Контур 2023" d="M940-780.066h2.167V-787H940Z" transform="translate(-940 787)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-259">
        <path id="Контур_2032" data-name="Контур 2032" d="M894.224-825.361a1.766,1.766,0,0,0-1.764,1.767v.393a1.766,1.766,0,0,0,1.764,1.767,1.77,1.77,0,0,0,1.768-1.767v-.393A1.77,1.77,0,0,0,894.224-825.361Zm0,5.227a3.068,3.068,0,0,1-3.064-3.067v-.393a3.069,3.069,0,0,1,3.064-3.067,3.072,3.072,0,0,1,3.068,3.067v.393a3.072,3.072,0,0,1-3.068,3.067Z" transform="translate(-891.16 826.661)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-261">
        <rect id="Прямоугольник_285" data-name="Прямоугольник 285" width="6.5" height="6.934" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-263">
        <path id="Контур_2029" data-name="Контур 2029" d="M891-820.066h6.5V-827H891Z" transform="translate(-891 827)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-264">
        <path id="Контур_2038" data-name="Контур 2038" d="M897.1-825.839h0a.772.772,0,0,1-.771.771h-5.2a.772.772,0,0,1-.771-.771.772.772,0,0,1,.771-.771h5.2a.772.772,0,0,1,.771.771" transform="translate(-890.36 826.61)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-266">
        <rect id="Прямоугольник_286" data-name="Прямоугольник 286" width="6.934" height="1.733" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-268">
        <path id="Контур_2035" data-name="Контур 2035" d="M890-825.267h6.934V-827H890Z" transform="translate(-890 827)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-269">
        <path id="Контур_2044" data-name="Контур 2044" d="M823.738-825.361a1.77,1.77,0,0,0-1.768,1.767v.393a1.77,1.77,0,0,0,1.768,1.767A1.769,1.769,0,0,0,825.5-823.2v-.393A1.769,1.769,0,0,0,823.738-825.361Zm0,5.227a3.069,3.069,0,0,1-3.068-3.067v-.393a3.069,3.069,0,0,1,3.068-3.067,3.069,3.069,0,0,1,3.064,3.067v.393a3.068,3.068,0,0,1-3.064,3.067Z" transform="translate(-820.67 826.661)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-273">
        <path id="Контур_2041" data-name="Контур 2041" d="M820-820.066h6.5V-827H820Z" transform="translate(-820 827)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-274">
        <path id="Контур_2050" data-name="Контур 2050" d="M826.619-825.839h0a.772.772,0,0,1-.771.771h-5.2a.77.77,0,0,1-.771-.771.769.769,0,0,1,.771-.771h5.2a.772.772,0,0,1,.771.771" transform="translate(-819.88 826.61)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-276">
        <rect id="Прямоугольник_288" data-name="Прямоугольник 288" width="7.367" height="1.733" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-278">
        <path id="Контур_2047" data-name="Контур 2047" d="M819-825.267h7.367V-827H819Z" transform="translate(-819 827)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-279">
        <path id="Контур_2056" data-name="Контур 2056" d="M858.978-826.051a1.769,1.769,0,0,0-1.768,1.767v.394a1.769,1.769,0,0,0,1.768,1.766,1.765,1.765,0,0,0,1.764-1.766v-.394A1.766,1.766,0,0,0,858.978-826.051Zm0,5.227a3.072,3.072,0,0,1-3.068-3.067v-.394a3.072,3.072,0,0,1,3.068-3.067,3.068,3.068,0,0,1,3.064,3.067v.394a3.068,3.068,0,0,1-3.064,3.067Z" transform="translate(-855.91 827.351)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-281">
        <rect id="Прямоугольник_289" data-name="Прямоугольник 289" width="6.934" height="6.934" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-283">
        <path id="Контур_2053" data-name="Контур 2053" d="M855-821.066h6.934V-828H855Z" transform="translate(-855 828)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-284">
        <path id="Контур_2062" data-name="Контур 2062" d="M861.859-826.53h0a.769.769,0,0,1-.771.771h-5.2a.772.772,0,0,1-.771-.771.772.772,0,0,1,.771-.771h5.2a.77.77,0,0,1,.771.771" transform="translate(-855.12 827.301)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-286">
        <rect id="Прямоугольник_290" data-name="Прямоугольник 290" width="6.934" height="2.167" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-288">
        <path id="Контур_2059" data-name="Контур 2059" d="M855-825.833h6.934V-828H855Z" transform="translate(-855 828)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-289">
        <path id="Контур_2068" data-name="Контур 2068" d="M826.124-737.31a1.762,1.762,0,0,0-1.764,1.764v.394a1.766,1.766,0,0,0,1.764,1.768,1.77,1.77,0,0,0,1.768-1.768v-.394A1.766,1.766,0,0,0,826.124-737.31Zm0,5.226a3.07,3.07,0,0,1-3.064-3.068v-.394a3.066,3.066,0,0,1,3.064-3.064,3.07,3.07,0,0,1,3.068,3.064v.394a3.074,3.074,0,0,1-3.068,3.068Z" transform="translate(-823.06 738.61)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-293">
        <path id="Контур_2065" data-name="Контур 2065" d="M823-732.066h6.5V-739H823Z" transform="translate(-823 739)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-294">
        <path id="Контур_2074" data-name="Контур 2074" d="M822.45-726.389h0a.768.768,0,0,1,.771-.771h5.2a.77.77,0,0,1,.771.771.773.773,0,0,1-.771.771h-5.2a.77.77,0,0,1-.771-.771" transform="translate(-822.45 727.16)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-298">
        <path id="Контур_2071" data-name="Контур 2071" d="M822-725.833h6.934V-728H822Z" transform="translate(-822 728)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-299">
        <path id="Контур_2080" data-name="Контур 2080" d="M896.608-737.31a1.766,1.766,0,0,0-1.768,1.764v.394a1.77,1.77,0,0,0,1.768,1.768,1.77,1.77,0,0,0,1.768-1.768v-.394A1.766,1.766,0,0,0,896.608-737.31Zm0,5.226a3.074,3.074,0,0,1-3.068-3.068v-.394a3.07,3.07,0,0,1,3.068-3.064,3.07,3.07,0,0,1,3.068,3.064v.394a3.074,3.074,0,0,1-3.068,3.068Z" transform="translate(-893.54 738.61)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-303">
        <path id="Контур_2077" data-name="Контур 2077" d="M893-732.066h6.5V-739H893Z" transform="translate(-893 739)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-304">
        <path id="Контур_2086" data-name="Контур 2086" d="M892.93-726.389h0a.77.77,0,0,1,.771-.771h5.2a.77.77,0,0,1,.771.771.773.773,0,0,1-.771.771h-5.2a.773.773,0,0,1-.771-.771" transform="translate(-892.93 727.16)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-306">
        <rect id="Прямоугольник_294" data-name="Прямоугольник 294" width="7.367" height="2.167" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-308">
        <path id="Контур_2083" data-name="Контур 2083" d="M892-725.833h7.367V-728H892Z" transform="translate(-892 728)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-309">
        <path id="Контур_2092" data-name="Контур 2092" d="M861.368-736.62a1.769,1.769,0,0,0-1.768,1.764v.394a1.77,1.77,0,0,0,1.768,1.768,1.769,1.769,0,0,0,1.764-1.768v-.394A1.768,1.768,0,0,0,861.368-736.62Zm0,5.226a3.071,3.071,0,0,1-3.068-3.068v-.394a3.067,3.067,0,0,1,3.068-3.064,3.066,3.066,0,0,1,3.064,3.064v.394a3.07,3.07,0,0,1-3.064,3.068Z" transform="translate(-858.3 737.92)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-313">
        <path id="Контур_2089" data-name="Контур 2089" d="M858-731.066h6.5V-738H858Z" transform="translate(-858 738)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-314">
        <path id="Контур_2098" data-name="Контур 2098" d="M857.69-725.7h0a.773.773,0,0,1,.771-.771h5.2a.77.77,0,0,1,.771.771.77.77,0,0,1-.771.771h-5.2a.773.773,0,0,1-.771-.771" transform="translate(-857.69 726.47)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-318">
        <path id="Контур_2095" data-name="Контур 2095" d="M857-724.833h7.367V-727H857Z" transform="translate(-857 727)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-319">
        <path id="Контур_2104" data-name="Контур 2104" d="M786.324-784.5a1.768,1.768,0,0,0-1.764,1.766,1.769,1.769,0,0,0,1.764,1.767h.394a1.77,1.77,0,0,0,1.768-1.767,1.769,1.769,0,0,0-1.768-1.766Zm.394,4.834h-.394a3.069,3.069,0,0,1-3.064-3.068,3.069,3.069,0,0,1,3.064-3.066h.394a3.072,3.072,0,0,1,3.068,3.066,3.072,3.072,0,0,1-3.068,3.068Z" transform="translate(-783.26 785.802)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-323">
        <path id="Контур_2101" data-name="Контур 2101" d="M783-779.5h6.934V-786H783Z" transform="translate(-783 786)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-324">
        <path id="Контур_2110" data-name="Контур 2110" d="M784.082-779.854h0a.772.772,0,0,0,.771-.771v-5.2a.772.772,0,0,0-.771-.771.772.772,0,0,0-.771.771v5.2a.772.772,0,0,0,.771.771" transform="translate(-783.31 786.594)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-326">
        <rect id="Прямоугольник_298" data-name="Прямоугольник 298" width="1.733" height="6.934" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-328">
        <path id="Контур_2107" data-name="Контур 2107" d="M783-780.066h1.734V-787H783Z" transform="translate(-783 787)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-329">
        <path id="Контур_2116" data-name="Контур 2116" d="M1148.651-1027.246c-8.814,0-14.734,2.656-14.734,5.136s5.92,5.137,14.734,5.137,14.73-2.656,14.73-5.137S1157.461-1027.246,1148.651-1027.246Zm0,11.139c-7.67,0-15.6-2.246-15.6-6s7.931-6,15.6-6,15.6,2.245,15.6,6-7.931,6-15.6,6Z" transform="translate(-1133.05 1028.113)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-331">
        <rect id="Прямоугольник_299" data-name="Прямоугольник 299" width="31.635" height="12.567" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-333">
        <path id="Контур_2113" data-name="Контур 2113" d="M1133-1016.433h31.636V-1029H1133Z" transform="translate(-1133 1029)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-334">
        <path id="Контур_2122" data-name="Контур 2122" d="M1127-968.691h6.093a8.586,8.586,0,0,0,3.662-6.857,10.707,10.707,0,0,0-3.688-8.728H1127Zm6.223.867h-6.656a.434.434,0,0,1-.434-.433v-16.451a.434.434,0,0,1,.434-.433h6.656a.429.429,0,0,1,.269.1,11.585,11.585,0,0,1,4.13,9.546,9.422,9.422,0,0,1-4.169,7.608.421.421,0,0,1-.23.068Z" transform="translate(-1126.13 985.142)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-336">
        <rect id="Прямоугольник_300" data-name="Прямоугольник 300" width="12.134" height="17.768" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-338">
        <path id="Контур_2119" data-name="Контур 2119" d="M1126-968.232h12.134V-986H1126Z" transform="translate(-1126 986)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-339">
        <path id="Контур_2128" data-name="Контур 2128" d="M1127.523-967.783h4.641a1.978,1.978,0,0,0,1.257-.437,7.472,7.472,0,0,0,2.587-5.571,9.2,9.2,0,0,0-2.661-6.88,1.7,1.7,0,0,0-1.192-.48h-4.633Zm4.641.433h-4.858a.216.216,0,0,1-.217-.217v-13.8a.216.216,0,0,1,.217-.217h4.849a2.121,2.121,0,0,1,1.495.6,9.635,9.635,0,0,1,2.791,7.207,7.9,7.9,0,0,1-2.743,5.888,2.417,2.417,0,0,1-1.534.536Z" transform="translate(-1127.09 981.585)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-341">
        <rect id="Прямоугольник_301" data-name="Прямоугольник 301" width="9.534" height="14.734" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-343">
        <path id="Контур_2125" data-name="Контур 2125" d="M1127-967.266h9.534V-982H1127Z" transform="translate(-1127 982)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-344">
        <path id="Контур_2134" data-name="Контур 2134" d="M1203.215-977.685a1.28,1.28,0,0,0-1.278,1.281v6.283a1.28,1.28,0,0,0,1.278,1.281,1.28,1.28,0,0,0,1.278-1.281V-976.4A1.28,1.28,0,0,0,1203.215-977.685Zm0,9.711a2.148,2.148,0,0,1-2.145-2.147V-976.4a2.149,2.149,0,0,1,2.145-2.147,2.148,2.148,0,0,1,2.145,2.147v6.283a2.148,2.148,0,0,1-2.145,2.147Z" transform="translate(-1201.07 978.552)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-346">
        <rect id="Прямоугольник_302" data-name="Прямоугольник 302" width="4.334" height="10.834" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-348">
        <path id="Контур_2131" data-name="Контур 2131" d="M1201-968.166h4.333V-979H1201Z" transform="translate(-1201 979)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-349">
        <path id="Контур_2140" data-name="Контур 2140" d="M1187.773-973.862a3.146,3.146,0,0,0-2.852,1.46,2.56,2.56,0,0,0-.026,2.565,3.105,3.105,0,0,0,2.86,1.364h3.471v-5.388Zm-.021,6.255a3.863,3.863,0,0,1-3.605-1.8,3.431,3.431,0,0,1,.026-3.432,3.984,3.984,0,0,1,3.6-1.894h3.887a.434.434,0,0,1,.433.433v6.255a.434.434,0,0,1-.433.433Z" transform="translate(-1183.698 974.729)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-351">
        <rect id="Прямоугольник_303" data-name="Прямоугольник 303" width="9.101" height="7.367" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-353">
        <path id="Контур_2137" data-name="Контур 2137" d="M1183-967.633h9.1V-975H1183Z" transform="translate(-1183 975)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-354">
        <path id="Контур_2146" data-name="Контур 2146" d="M1189.688-971.637a3.081,3.081,0,0,0-2.769,1.257,1.555,1.555,0,0,0-.013,1.6,3.048,3.048,0,0,0,2.765,1.175h3.224v-4.029Zm-.017,4.463a3.456,3.456,0,0,1-3.142-1.391,2.016,2.016,0,0,1,.013-2.032,3.458,3.458,0,0,1,3.146-1.474h3.424a.218.218,0,0,1,.217.217v4.463a.219.219,0,0,1-.217.217Z" transform="translate(-1186.261 972.07)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-356">
        <rect id="Прямоугольник_304" data-name="Прямоугольник 304" width="7.367" height="5.634" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-358">
        <path id="Контур_2143" data-name="Контур 2143" d="M1186-967.366h7.367V-973H1186Z" transform="translate(-1186 973)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-359">
        <path id="Контур_2152" data-name="Контур 2152" d="M1204.459-968.458a.484.484,0,0,0-.486.484.484.484,0,0,0,.486.483.483.483,0,0,0,.481-.483A.483.483,0,0,0,1204.459-968.458Zm0,1.4a.918.918,0,0,1-.919-.916.919.919,0,0,1,.919-.917.918.918,0,0,1,.914.917.917.917,0,0,1-.914.916Z" transform="translate(-1203.54 968.891)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-361">
        <rect id="Прямоугольник_305" data-name="Прямоугольник 305" width="2.167" height="2.167" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-363">
        <path id="Контур_2149" data-name="Контур 2149" d="M1203-966.833h2.167V-969H1203Z" transform="translate(-1203 969)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-364">
        <path id="Контур_2158" data-name="Контур 2158" d="M1134.407-930.994h-15.28a1.155,1.155,0,0,1-1.157-1.156v-2.755h16.477a1.8,1.8,0,0,1,1.8,1.8v.266a1.841,1.841,0,0,1-1.842,1.842" transform="translate(-1117.97 934.905)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-366">
        <rect id="Прямоугольник_306" data-name="Прямоугольник 306" width="19.068" height="4.334" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-368">
        <path id="Контур_2155" data-name="Контур 2155" d="M1117-930.667h19.068V-935H1117Z" transform="translate(-1117 935)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-369">
        <path id="Контур_2164" data-name="Контур 2164" d="M1191.181-931.029H1177.9a1.929,1.929,0,0,1-1.929-1.931v-.158a1.821,1.821,0,0,1,1.82-1.822h13.391v3.911" transform="translate(-1175.97 934.94)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-371">
        <rect id="Прямоугольник_307" data-name="Прямоугольник 307" width="16.034" height="4.334" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-373">
        <path id="Контур_2161" data-name="Контур 2161" d="M1175-930.667h16.034V-935H1175Z" transform="translate(-1175 935)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-374">
        <path id="Контур_2170" data-name="Контур 2170" d="M1171.747-937.3l-.919.616-4.628-6.931a1.109,1.109,0,0,1,1.539.307l4.009,6.008" transform="translate(-1166.2 943.805)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-376">
        <rect id="Прямоугольник_308" data-name="Прямоугольник 308" width="5.634" height="7.801" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-378">
        <path id="Контур_2167" data-name="Контур 2167" d="M1166-937.2h5.634V-945H1166Z" transform="translate(-1166 945)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-379">
        <path id="Контур_2176" data-name="Контур 2176" d="M767.718-1004.768a4.371,4.371,0,0,0-4.368,4.365v21.843a4.371,4.371,0,0,0,4.368,4.365H808.4a4.37,4.37,0,0,0,4.364-4.365V-1000.4a4.37,4.37,0,0,0-4.364-4.365ZM808.4-972.9h-40.68a5.674,5.674,0,0,1-5.668-5.665V-1000.4a5.674,5.674,0,0,1,5.668-5.665H808.4a5.67,5.67,0,0,1,5.664,5.665v21.843A5.67,5.67,0,0,1,808.4-972.9Z" transform="translate(-762.05 1006.068)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-381">
        <rect id="Прямоугольник_309" data-name="Прямоугольник 309" width="52.437" height="33.802" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-383">
        <path id="Контур_2173" data-name="Контур 2173" d="M762-973.2h52.437V-1007H762Z" transform="translate(-762 1007)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-384">
        <path id="Контур_2182" data-name="Контур 2182" d="M804.253-1004.768a4.117,4.117,0,0,0-4.113,4.111v22.351a4.117,4.117,0,0,0,4.113,4.111H829.5a4.117,4.117,0,0,0,4.113-4.111v-22.351a4.117,4.117,0,0,0-4.113-4.111ZM829.5-972.9H804.253a5.417,5.417,0,0,1-5.413-5.411v-22.351a5.417,5.417,0,0,1,5.413-5.411H829.5a5.417,5.417,0,0,1,5.413,5.411v22.351A5.417,5.417,0,0,1,829.5-972.9Z" transform="translate(-798.84 1006.068)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-386">
        <rect id="Прямоугольник_310" data-name="Прямоугольник 310" width="36.836" height="33.802" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-388">
        <path id="Контур_2179" data-name="Контур 2179" d="M798-973.2h36.836V-1007H798Z" transform="translate(-798 1007)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-389">
        <path id="Контур_2188" data-name="Контур 2188" d="M770.209-1001.616a3.752,3.752,0,0,0-3.749,3.747v5.092a3.752,3.752,0,0,0,3.749,3.746h.459a3.751,3.751,0,0,0,3.744-3.746v-5.092a3.751,3.751,0,0,0-3.744-3.747Zm.459,13.885h-.459a5.055,5.055,0,0,1-5.049-5.046v-5.092a5.055,5.055,0,0,1,5.049-5.047h.459a5.051,5.051,0,0,1,5.044,5.047v5.092a5.051,5.051,0,0,1-5.044,5.046Z" transform="translate(-765.16 1002.916)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-391">
        <rect id="Прямоугольник_311" data-name="Прямоугольник 311" width="10.834" height="15.601" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-393">
        <path id="Контур_2185" data-name="Контур 2185" d="M765-987.4h10.834V-1003H765Z" transform="translate(-765 1003)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-394">
        <path id="Контур_2194" data-name="Контур 2194" d="M770.438-966.3a3.982,3.982,0,0,0-3.978,3.976v4.634a3.982,3.982,0,0,0,3.978,3.975,3.978,3.978,0,0,0,3.974-3.975v-4.634A3.978,3.978,0,0,0,770.438-966.3Zm0,13.885a5.285,5.285,0,0,1-5.278-5.275v-4.634a5.285,5.285,0,0,1,5.278-5.276,5.281,5.281,0,0,1,5.274,5.276v4.634a5.281,5.281,0,0,1-5.274,5.275Z" transform="translate(-765.16 967.599)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-398">
        <path id="Контур_2191" data-name="Контур 2191" d="M765-952.4h10.834V-968H765Z" transform="translate(-765 968)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-399">
        <path id="Контур_2200" data-name="Контур 2200" d="M757.12-1007.562v34.129a2.053,2.053,0,0,0,1.4-1.946v-30.236A2.053,2.053,0,0,0,757.12-1007.562Zm-.65,35.534h-.65v-36.939h.65a3.355,3.355,0,0,1,3.35,3.352v30.236a3.355,3.355,0,0,1-3.35,3.352Z" transform="translate(-755.82 1008.967)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-401">
        <rect id="Прямоугольник_313" data-name="Прямоугольник 313" width="4.767" height="37.269" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-403">
        <path id="Контур_2197" data-name="Контур 2197" d="M755-971.731h4.767V-1009H755Z" transform="translate(-755 1009)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-404">
        <path id="Контур_2206" data-name="Контур 2206" d="M915.836-1040.778H884.383a2.093,2.093,0,0,1-2.093-2.094v-1.523a2.093,2.093,0,0,1,2.093-2.094h31.453a2.1,2.1,0,0,1,2.1,2.094v1.523a2.1,2.1,0,0,1-2.1,2.094" transform="translate(-882.29 1046.49)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-406">
        <rect id="Прямоугольник_314" data-name="Прямоугольник 314" width="35.969" height="6.067" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-408">
        <path id="Контур_2203" data-name="Контур 2203" d="M882-1040.933h35.969V-1047H882Z" transform="translate(-882 1047)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-409">
        <path id="Контур_2212" data-name="Контур 2212" d="M969.808-708.964H939.876a2.858,2.858,0,0,1-2.856-2.856,2.859,2.859,0,0,1,2.856-2.86h29.932a2.859,2.859,0,0,1,2.856,2.86,2.858,2.858,0,0,1-2.856,2.856" transform="translate(-937.02 714.68)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-413">
        <path id="Контур_2209" data-name="Контур 2209" d="M937-708.933h35.969V-715H937Z" transform="translate(-937 715)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-414">
        <path id="Контур_2218" data-name="Контур 2218" d="M955.49-916.62h0l17.213-6.756.646,1.648-15.562,6.109a1.77,1.77,0,0,1-2.3-1" transform="translate(-955.49 923.376)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-416">
        <rect id="Прямоугольник_316" data-name="Прямоугольник 316" width="18.201" height="8.667" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-418">
        <path id="Контур_2215" data-name="Контур 2215" d="M955-915.333h18.2V-924H955Z" transform="translate(-955 924)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-419">
        <path id="Контур_2224" data-name="Контур 2224" d="M757.187-911.144h5.647a6.292,6.292,0,0,0,6.284-6.282,4.512,4.512,0,0,0-4.507-4.5h-7.423Zm5.647.867H756.32V-922.8h8.29a5.378,5.378,0,0,1,5.374,5.371,7.158,7.158,0,0,1-7.15,7.149Z" transform="translate(-756.32 922.797)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-421">
        <rect id="Прямоугольник_317" data-name="Прямоугольник 317" width="13.868" height="13.001" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-423">
        <path id="Контур_2221" data-name="Контур 2221" d="M756-910h13.868v-13H756Z" transform="translate(-756 923)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-424">
        <path id="Контур_2230" data-name="Контур 2230" d="M757.187-1025.875h5.647a6.292,6.292,0,0,0,6.284-6.282,4.512,4.512,0,0,0-4.507-4.5h-7.423Zm5.647.867H756.32v-12.519h8.29a5.377,5.377,0,0,1,5.374,5.37,7.157,7.157,0,0,1-7.15,7.149Z" transform="translate(-756.32 1037.528)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-428">
        <path id="Контур_2227" data-name="Контур 2227" d="M756-1025h13.868v-13H756Z" transform="translate(-756 1038)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-429">
        <path id="Контур_2236" data-name="Контур 2236" d="M1152.773-990.689v.806h.273a.3.3,0,0,0,.247-.1.493.493,0,0,0,.082-.317q0-.387-.338-.386Zm0-.448h.247a.278.278,0,0,0,.239-.091.431.431,0,0,0,.07-.27.355.355,0,0,0-.078-.258.339.339,0,0,0-.247-.078h-.229Zm-.5-1.16H1153a1.012,1.012,0,0,1,.659.175.7.7,0,0,1,.2.556.747.747,0,0,1-.108.418.436.436,0,0,1-.294.2v.02a.557.557,0,0,1,.351.229.8.8,0,0,1,.108.446.862.862,0,0,1-.208.614.762.762,0,0,1-.576.222h-.867V-992.3Z" transform="translate(-1152.27 992.297)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-431">
        <rect id="Прямоугольник_319" data-name="Прямоугольник 319" width="2.167" height="3.467" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-433">
        <path id="Контур_2233" data-name="Контур 2233" d="M1152-989.533h2.167V-993H1152Z" transform="translate(-1152 993)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-434">
        <path id="Контур_2242" data-name="Контур 2242" d="M1157.762-990.632l-.156-.67c-.009-.041-.031-.143-.061-.307s-.052-.273-.061-.328c-.013.113-.039.238-.065.374s-.091.447-.2.932Zm.264,1.2-.165-.728h-.754l-.165.728h-.533l.711-2.9h.728l.715,2.9Z" transform="translate(-1156.41 992.324)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-436">
        <rect id="Прямоугольник_320" data-name="Прямоугольник 320" width="2.6" height="3.467" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-438">
        <path id="Контур_2239" data-name="Контур 2239" d="M1156-989.533h2.6V-993H1156Z" transform="translate(-1156 993)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-439">
        <path id="Контур_2248" data-name="Контур 2248" d="M1162.278-989.413h-.537v-2.412h-.551v-.471h1.638v.471h-.551v2.412" transform="translate(-1161.19 992.297)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-441">
        <rect id="Прямоугольник_321" data-name="Прямоугольник 321" width="1.733" height="3.467" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-443">
        <path id="Контур_2245" data-name="Контур 2245" d="M1161-989.533h1.734V-993H1161Z" transform="translate(-1161 993)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-444">
        <path id="Контур_2254" data-name="Контур 2254" d="M1167.461-989.413h-.537v-1.264h-.707v1.264h-.537V-992.3h.537v1.146h.707V-992.3h.537v2.884" transform="translate(-1165.68 992.297)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-448">
        <path id="Контур_2251" data-name="Контур 2251" d="M1165-989.533h2.167V-993H1165Z" transform="translate(-1165 993)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-449">
        <path id="Контур_2260" data-name="Контур 2260" d="M1171.5-991h.138a.327.327,0,0,0,.273-.107.514.514,0,0,0,.082-.321.465.465,0,0,0-.087-.314.364.364,0,0,0-.273-.094h-.134Zm0,.461v1.122h-.538V-992.3h.7a.9.9,0,0,1,.659.211.869.869,0,0,1,.212.645.808.808,0,0,1-.368.763l.555,1.264h-.572l-.459-1.122Z" transform="translate(-1170.96 992.297)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-453">
        <path id="Контур_2257" data-name="Контур 2257" d="M1170-989.533h2.6V-993H1170Z" transform="translate(-1170 993)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-454">
        <path id="Контур_2266" data-name="Контур 2266" d="M1175.97-990.918a1.8,1.8,0,0,0,.122.752.392.392,0,0,0,.364.258c.325,0,.485-.337.485-1.01s-.16-1.01-.481-1.01a.4.4,0,0,0-.368.258A1.8,1.8,0,0,0,1175.97-990.918Zm1.521,0a1.885,1.885,0,0,1-.269,1.1.876.876,0,0,1-.767.385.878.878,0,0,1-.767-.384,1.927,1.927,0,0,1-.269-1.1,1.908,1.908,0,0,1,.269-1.1.887.887,0,0,1,.771-.381.878.878,0,0,1,.767.384,1.918,1.918,0,0,1,.264,1.1Z" transform="translate(-1175.42 992.401)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-458">
        <path id="Контур_2263" data-name="Контур 2263" d="M1175-989.533h2.6V-993H1175Z" transform="translate(-1175 993)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-459">
        <path id="Контур_2272" data-name="Контур 2272" d="M1181.516-990.918a1.794,1.794,0,0,0,.126.752.392.392,0,0,0,.364.258c.325,0,.485-.337.485-1.01s-.16-1.01-.481-1.01a.4.4,0,0,0-.368.258A1.789,1.789,0,0,0,1181.516-990.918Zm1.525,0a1.918,1.918,0,0,1-.269,1.1.877.877,0,0,1-.767.385.877.877,0,0,1-.767-.384,1.893,1.893,0,0,1-.269-1.1,1.874,1.874,0,0,1,.269-1.1.882.882,0,0,1,.771-.381.877.877,0,0,1,.767.384,1.918,1.918,0,0,1,.264,1.1Z" transform="translate(-1180.97 992.401)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-463">
        <path id="Контур_2269" data-name="Контур 2269" d="M1180-989.533h2.6V-993H1180Z" transform="translate(-1180 993)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-464">
        <path id="Контур_2278" data-name="Контур 2278" d="M1187.751-989.413l-.55-2.456h-.021a3.872,3.872,0,0,1,.039.521v1.935h-.5V-992.3h.823l.494,2.266h.017l.49-2.266h.836v2.884h-.512v-1.955a3.9,3.9,0,0,1,.031-.5h-.017l-.546,2.451h-.585" transform="translate(-1186.72 992.297)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-466">
        <rect id="Прямоугольник_326" data-name="Прямоугольник 326" width="3.034" height="3.467" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-468">
        <path id="Контур_2275" data-name="Контур 2275" d="M1186-989.533h3.034V-993H1186Z" transform="translate(-1186 993)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-469">
        <path id="Контур_2284" data-name="Контур 2284" d="M989.42-809.907v-3.9h.728v3.262h1.006v.635H989.42" transform="translate(-989.42 813.804)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-471">
        <rect id="Прямоугольник_327" data-name="Прямоугольник 327" width="2.167" height="4.334" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-473">
        <path id="Контур_2281" data-name="Контур 2281" d="M989-809.666h2.167V-814H989Z" transform="translate(-989 814)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-474">
        <path id="Контур_2290" data-name="Контур 2290" d="M994.951-811.941a2.482,2.482,0,0,0,.165,1.015.533.533,0,0,0,.494.349c.438,0,.659-.455.659-1.364s-.217-1.365-.65-1.365a.54.54,0,0,0-.5.348A2.48,2.48,0,0,0,994.951-811.941Zm2.058,0a2.573,2.573,0,0,1-.364,1.487,1.181,1.181,0,0,1-1.036.52,1.194,1.194,0,0,1-1.04-.519,2.618,2.618,0,0,1-.36-1.494,2.589,2.589,0,0,1,.36-1.483,1.207,1.207,0,0,1,1.049-.516,1.18,1.18,0,0,1,1.031.518,2.6,2.6,0,0,1,.36,1.486Z" transform="translate(-994.21 813.945)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-476">
        <rect id="Прямоугольник_328" data-name="Прямоугольник 328" width="3.034" height="4.334" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-478">
        <path id="Контур_2287" data-name="Контур 2287" d="M994-809.666h3.034V-814H994Z" transform="translate(-994 814)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-479">
        <path id="Контур_2296" data-name="Контур 2296" d="M1004.354-813.8v2.522a1.573,1.573,0,0,1-.316,1.062,1.126,1.126,0,0,1-.906.367,1.094,1.094,0,0,1-.888-.364,1.661,1.661,0,0,1-.3-1.076v-2.51h.719v2.564q0,.744.494.743a.4.4,0,0,0,.355-.189.984.984,0,0,0,.125-.539v-2.58h.719" transform="translate(-1001.94 813.804)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-483">
        <path id="Контур_2293" data-name="Контур 2293" d="M1001-809.666h3.033V-814H1001Z" transform="translate(-1001 814)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-484">
        <path id="Контур_2302" data-name="Контур 2302" d="M1011.79-809.907h-.888l-1.2-2.823h-.035a3.492,3.492,0,0,1,.065.692v2.13h-.672v-3.9h.888l1.2,2.794h.03a3.7,3.7,0,0,1-.052-.667V-813.8h.667v3.9" transform="translate(-1009.06 813.804)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-488">
        <path id="Контур_2299" data-name="Контур 2299" d="M1009-809.666h3.033V-814H1009Z" transform="translate(-1009 814)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-489">
        <path id="Контур_2308" data-name="Контур 2308" d="M1017.966-812.142h1.192v2a2.721,2.721,0,0,1-1.075.216,1.211,1.211,0,0,1-1.049-.517,2.57,2.57,0,0,1-.364-1.49,2.3,2.3,0,0,1,.42-1.469,1.4,1.4,0,0,1,1.17-.53,1.786,1.786,0,0,1,.893.24l-.23.592a1.236,1.236,0,0,0-.62-.192.747.747,0,0,0-.654.37,1.823,1.823,0,0,0-.239,1.006,2.3,2.3,0,0,0,.173,1,.55.55,0,0,0,.507.349,1.432,1.432,0,0,0,.355-.045v-.893h-.481v-.64" transform="translate(-1016.67 813.933)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-493">
        <path id="Контур_2305" data-name="Контур 2305" d="M1016-809.666h3.033V-814H1016Z" transform="translate(-1016 814)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-494">
        <path id="Контур_2314" data-name="Контур 2314" d="M1025.535-809.907h-1.755v-3.9h1.755v.627h-1.027v.927h.953v.629h-.953v1.08h1.027v.635" transform="translate(-1023.78 813.804)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-498">
        <path id="Контур_2311" data-name="Контур 2311" d="M1023-809.666h2.167V-814H1023Z" transform="translate(-1023 814)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-499">
        <path id="Контур_2320" data-name="Контур 2320" d="M852.233-873.966h-.906l-.381-2.042a7.683,7.683,0,0,1-.126-.988l-.022.2-.108.8-.368,2.031h-.906l-.706-3.9h.711l.347,2.2c.035.22.082.566.139,1.037.043-.476.087-.817.126-1.021l.416-2.215h.75l.416,2.215q.085.493.13,1.026c.061-.526.108-.873.134-1.042l.342-2.2h.715l-.7,3.9" transform="translate(-848.71 877.862)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-501">
        <rect id="Прямоугольник_333" data-name="Прямоугольник 333" width="4.767" height="4.334" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-503">
        <path id="Контур_2317" data-name="Контур 2317" d="M848-873.667h4.767V-878H848Z" transform="translate(-848 878)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-504">
        <path id="Контур_2326" data-name="Контур 2326" d="M860.209-875.612l-.208-.907c-.013-.055-.039-.193-.082-.414s-.074-.369-.082-.443c-.022.152-.052.321-.087.5s-.126.6-.277,1.259Zm.36,1.626-.225-.984h-1.014l-.225.984h-.724l.966-3.912h.984l.966,3.912Z" transform="translate(-858.38 877.899)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-506">
        <rect id="Прямоугольник_334" data-name="Прямоугольник 334" width="3.467" height="4.334" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-508">
        <path id="Контур_2323" data-name="Контур 2323" d="M858-873.667h3.467V-878H858Z" transform="translate(-858 878)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-509">
        <path id="Контур_2332" data-name="Контур 2332" d="M866.624-876.106h.186a.431.431,0,0,0,.368-.145.68.68,0,0,0,.113-.433.62.62,0,0,0-.117-.425.485.485,0,0,0-.368-.126h-.182Zm0,.624v1.516H865.9v-3.9h.949a1.211,1.211,0,0,1,.888.285,1.184,1.184,0,0,1,.286.872,1.094,1.094,0,0,1-.5,1.031l.75,1.708H867.5l-.615-1.516Z" transform="translate(-865.9 877.862)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-513">
        <path id="Контур_2329" data-name="Контур 2329" d="M865-873.667h3.033V-878H865Z" transform="translate(-865 878)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-514">
        <path id="Контур_2338" data-name="Контур 2338" d="M873.933-875.93c0-.871-.238-1.306-.719-1.306h-.29v2.636h.234a.637.637,0,0,0,.589-.332A2.075,2.075,0,0,0,873.933-875.93Zm.745-.021a2.365,2.365,0,0,1-.394,1.475,1.337,1.337,0,0,1-1.131.51H872.2v-3.9h1.04a1.244,1.244,0,0,1,1.057.5,2.277,2.277,0,0,1,.381,1.409Z" transform="translate(-872.2 877.862)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-516">
        <rect id="Прямоугольник_336" data-name="Прямоугольник 336" width="2.6" height="4.334" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-518">
        <path id="Контур_2335" data-name="Контур 2335" d="M872-873.667h2.6V-878H872Z" transform="translate(-872 878)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-519">
        <path id="Контур_2344" data-name="Контур 2344" d="M879.954-876.106h.186a.437.437,0,0,0,.368-.145.708.708,0,0,0,.113-.433.62.62,0,0,0-.117-.425.492.492,0,0,0-.368-.126h-.182Zm0,.624v1.516h-.724v-3.9h.949a1.218,1.218,0,0,1,.888.285,1.184,1.184,0,0,1,.286.872,1.094,1.094,0,0,1-.5,1.031l.75,1.708h-.776l-.615-1.516Z" transform="translate(-879.23 877.862)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-523">
        <path id="Контур_2341" data-name="Контур 2341" d="M879-873.667h2.6V-878H879Z" transform="translate(-879 878)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-524">
        <path id="Контур_2350" data-name="Контур 2350" d="M885.991-876a2.4,2.4,0,0,0,.169,1.015.524.524,0,0,0,.49.349c.438,0,.659-.455.659-1.365s-.217-1.364-.65-1.364a.53.53,0,0,0-.5.348A2.4,2.4,0,0,0,885.991-876Zm2.058,0a2.608,2.608,0,0,1-.36,1.487,1.193,1.193,0,0,1-1.04.52,1.194,1.194,0,0,1-1.04-.518,2.621,2.621,0,0,1-.36-1.494,2.586,2.586,0,0,1,.36-1.482,1.208,1.208,0,0,1,1.049-.516,1.18,1.18,0,0,1,1.031.518,2.569,2.569,0,0,1,.36,1.486Z" transform="translate(-885.25 878.004)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-526">
        <rect id="Прямоугольник_338" data-name="Прямоугольник 338" width="3.034" height="4.767" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-528">
        <path id="Контур_2347" data-name="Контур 2347" d="M885-874.233h3.034V-879H885Z" transform="translate(-885 879)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-529">
        <path id="Контур_2356" data-name="Контур 2356" d="M893.7-875.69v1.09h.364a.407.407,0,0,0,.338-.138.686.686,0,0,0,.108-.43c0-.348-.152-.522-.459-.522Zm0-.6h.329a.385.385,0,0,0,.325-.123.609.609,0,0,0,.091-.365.483.483,0,0,0-.1-.348.467.467,0,0,0-.338-.105h-.3Zm-.685-1.567h1a1.356,1.356,0,0,1,.884.236.941.941,0,0,1,.277.75,1.049,1.049,0,0,1-.147.567.592.592,0,0,1-.4.275v.027a.771.771,0,0,1,.477.31,1.113,1.113,0,0,1,.147.6,1.155,1.155,0,0,1-.286.831,1.018,1.018,0,0,1-.776.3H893.02v-3.9Z" transform="translate(-893.02 877.862)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-533">
        <path id="Контур_2353" data-name="Контур 2353" d="M893-873.667h2.6V-878H893Z" transform="translate(-893 878)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-534">
        <path id="Контур_2362" data-name="Контур 2362" d="M901.165-873.966H899.41v-3.9h1.755v.626h-1.031v.928h.958v.629h-.958v1.079h1.031v.635" transform="translate(-899.41 877.862)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-538">
        <path id="Контур_2359" data-name="Контур 2359" d="M899-873.667h2.167V-878H899Z" transform="translate(-899 878)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-539">
        <path id="Контур_2368" data-name="Контур 2368" d="M911.211-974.47l-.741-3.318h-.03a5.266,5.266,0,0,1,.052.7v2.614h-.672v-3.9h1.114l.667,3.062h.021l.659-3.062h1.131v3.9h-.685v-2.641a5.92,5.92,0,0,1,.035-.672h-.022L912-974.47h-.793" transform="translate(-909.82 978.366)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-541">
        <rect id="Прямоугольник_341" data-name="Прямоугольник 341" width="4.334" height="4.334" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-543">
        <path id="Контур_2365" data-name="Контур 2365" d="M909-974.667h4.334V-979H909Z" transform="translate(-909 979)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-544">
        <path id="Контур_2374" data-name="Контур 2374" d="M920.734-976.116l-.208-.907c-.009-.055-.039-.193-.082-.414s-.069-.369-.078-.443c-.021.153-.052.321-.087.5s-.13.6-.277,1.259Zm.36,1.625-.221-.984h-1.018l-.221.984h-.724l.962-3.912h.984l.967,3.912Z" transform="translate(-918.91 978.403)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-548">
        <path id="Контур_2371" data-name="Контур 2371" d="M918-974.667h3.467V-979H918Z" transform="translate(-918 979)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-549">
        <path id="Контур_2380" data-name="Контур 2380" d="M927.987-975.617a1.177,1.177,0,0,1-.273.837,1.055,1.055,0,0,1-.8.29,1.754,1.754,0,0,1-.867-.2v-.717a1.5,1.5,0,0,0,.8.285.417.417,0,0,0,.316-.114.446.446,0,0,0,.1-.312.575.575,0,0,0-.126-.356,1.858,1.858,0,0,0-.412-.358,1.8,1.8,0,0,1-.381-.332,1.2,1.2,0,0,1-.2-.356,1.342,1.342,0,0,1-.069-.458,1.1,1.1,0,0,1,.277-.794.978.978,0,0,1,.745-.293,1.646,1.646,0,0,1,.863.25l-.239.581a2.666,2.666,0,0,0-.269-.133.782.782,0,0,0-.3-.059.332.332,0,0,0-.273.116.5.5,0,0,0-.1.316.537.537,0,0,0,.117.344,1.59,1.59,0,0,0,.395.333,1.658,1.658,0,0,1,.529.522,1.178,1.178,0,0,1,.152.607" transform="translate(-926.05 978.495)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-553">
        <path id="Контур_2377" data-name="Контур 2377" d="M926-974.667h2.167V-979H926Z" transform="translate(-926 979)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-554">
        <path id="Контур_2386" data-name="Контур 2386" d="M932.469-974.47h-.724v-3.259H931v-.637h2.214v.637h-.745v3.259" transform="translate(-931 978.366)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-558">
        <path id="Контур_2383" data-name="Контур 2383" d="M931-974.667h2.6V-979H931Z" transform="translate(-931 979)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-559">
        <path id="Контур_2392" data-name="Контур 2392" d="M938.825-974.47H937.07v-3.9h1.755v.626h-1.031v.928h.958v.629h-.958v1.079h1.031v.635" transform="translate(-937.07 978.366)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-563">
        <path id="Контур_2389" data-name="Контур 2389" d="M937-974.667h2.167V-979H937Z" transform="translate(-937 979)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-564">
        <path id="Контур_2398" data-name="Контур 2398" d="M943.158-976.61h.186a.444.444,0,0,0,.368-.145.712.712,0,0,0,.108-.433.621.621,0,0,0-.117-.426.474.474,0,0,0-.364-.127h-.182Zm0,.624v1.516h-.728v-3.9h.949a1.213,1.213,0,0,1,.889.285,1.167,1.167,0,0,1,.29.872,1.1,1.1,0,0,1-.5,1.031l.745,1.708h-.776l-.615-1.516Z" transform="translate(-942.43 978.366)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-568">
        <path id="Контур_2395" data-name="Контур 2395" d="M942-974.667h2.6V-979H942Z" transform="translate(-942 979)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-569">
        <path id="Контур_2404" data-name="Контур 2404" d="M907.395-961.081v1.089h.364a.406.406,0,0,0,.338-.138.683.683,0,0,0,.108-.429c0-.348-.152-.522-.459-.522Zm0-.605h.329a.384.384,0,0,0,.325-.122.608.608,0,0,0,.091-.365.482.482,0,0,0-.1-.348.472.472,0,0,0-.338-.106h-.3Zm-.685-1.567h.992a1.363,1.363,0,0,1,.888.236.941.941,0,0,1,.277.75,1.045,1.045,0,0,1-.147.566.6.6,0,0,1-.4.276v.026a.771.771,0,0,1,.481.31,1.118,1.118,0,0,1,.143.6,1.153,1.153,0,0,1-.282.83,1.023,1.023,0,0,1-.776.3H906.71v-3.9Z" transform="translate(-906.71 963.253)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-573">
        <path id="Контур_2401" data-name="Контур 2401" d="M906-959.667h2.6V-964H906Z" transform="translate(-906 964)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-574">
        <path id="Контур_2410" data-name="Контур 2410" d="M914.85-959.357h-1.76v-3.9h1.76v.626h-1.031v.928h.953v.629h-.953v1.079h1.031v.635" transform="translate(-913.09 963.253)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-578">
        <path id="Контур_2407" data-name="Контур 2407" d="M913-959.667h2.167V-964H913Z" transform="translate(-913 964)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-579">
        <path id="Контур_2416" data-name="Контур 2416" d="M920.193-961.321c0-.871-.238-1.306-.719-1.306h-.29v2.636h.234a.626.626,0,0,0,.585-.332A2.07,2.07,0,0,0,920.193-961.321Zm.745-.022a2.394,2.394,0,0,1-.394,1.476,1.348,1.348,0,0,1-1.131.51h-.953v-3.9h1.04a1.245,1.245,0,0,1,1.057.5,2.306,2.306,0,0,1,.381,1.408Z" transform="translate(-918.46 963.253)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-583">
        <path id="Контур_2413" data-name="Контур 2413" d="M918-959.667h3.034V-964H918Z" transform="translate(-918 964)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-584">
        <path id="Контур_2422" data-name="Контур 2422" d="M926.214-961.5h.186a.438.438,0,0,0,.368-.145.71.71,0,0,0,.113-.433.621.621,0,0,0-.121-.426.474.474,0,0,0-.364-.127h-.182Zm0,.624v1.516h-.724v-3.9h.945a1.224,1.224,0,0,1,.893.285,1.185,1.185,0,0,1,.286.872,1.1,1.1,0,0,1-.5,1.031l.745,1.708h-.771l-.62-1.516Z" transform="translate(-925.49 963.253)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-588">
        <path id="Контур_2419" data-name="Контур 2419" d="M925-959.667h2.6V-964H925Z" transform="translate(-925 964)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-589">
        <path id="Контур_2428" data-name="Контур 2428" d="M932.251-961.391a2.413,2.413,0,0,0,.165,1.015.533.533,0,0,0,.494.349c.438,0,.654-.455.654-1.365s-.217-1.364-.65-1.364a.539.539,0,0,0-.5.348A2.41,2.41,0,0,0,932.251-961.391Zm2.058,0a2.574,2.574,0,0,1-.364,1.487,1.18,1.18,0,0,1-1.036.52,1.194,1.194,0,0,1-1.04-.518,2.618,2.618,0,0,1-.36-1.494,2.587,2.587,0,0,1,.36-1.483,1.2,1.2,0,0,1,1.044-.516,1.182,1.182,0,0,1,1.036.519,2.6,2.6,0,0,1,.36,1.485Z" transform="translate(-931.51 963.395)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-593">
        <path id="Контур_2425" data-name="Контур 2425" d="M931-959.667h3.034V-964H931Z" transform="translate(-931 964)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-594">
        <path id="Контур_2434" data-name="Контур 2434" d="M939.741-961.391a2.4,2.4,0,0,0,.169,1.015.533.533,0,0,0,.494.349c.438,0,.654-.455.654-1.365s-.217-1.364-.65-1.364a.535.535,0,0,0-.5.348A2.4,2.4,0,0,0,939.741-961.391Zm2.063,0a2.574,2.574,0,0,1-.364,1.487,1.191,1.191,0,0,1-1.036.52,1.194,1.194,0,0,1-1.04-.518A2.583,2.583,0,0,1,939-961.4a2.553,2.553,0,0,1,.364-1.483,1.2,1.2,0,0,1,1.044-.516,1.188,1.188,0,0,1,1.036.519,2.6,2.6,0,0,1,.36,1.485Z" transform="translate(-939 963.395)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-598">
        <path id="Контур_2431" data-name="Контур 2431" d="M939-959.667h3.034V-964H939Z" transform="translate(-939 964)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-599">
        <path id="Контур_2440" data-name="Контур 2440" d="M948.171-959.357l-.741-3.318h-.03a5.263,5.263,0,0,1,.052.7v2.614h-.672v-3.9h1.114l.663,3.062h.022l.663-3.062h1.131v3.9h-.689V-962a5.963,5.963,0,0,1,.039-.672H949.7l-.741,3.313h-.789" transform="translate(-946.78 963.253)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-603">
        <path id="Контур_2437" data-name="Контур 2437" d="M946-959.667h4.334V-964H946Z" transform="translate(-946 964)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-604">
        <path id="Контур_2446" data-name="Контур 2446" d="M1065.772-818.261h0a1.713,1.713,0,0,1-1.712-1.713v-32.618a1.713,1.713,0,0,1,1.712-1.713,1.714,1.714,0,0,1,1.716,1.713v32.618a1.714,1.714,0,0,1-1.716,1.713" transform="translate(-1064.06 854.306)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-606">
        <rect id="Прямоугольник_354" data-name="Прямоугольник 354" width="3.467" height="36.402" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-608">
        <path id="Контур_2443" data-name="Контур 2443" d="M1064-818.6h3.467V-855H1064Z" transform="translate(-1064 855)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-609">
        <path id="Контур_2452" data-name="Контур 2452" d="M1063.409-827.434a2.656,2.656,0,0,0-2.652,2.651v6.291a2.656,2.656,0,0,0,2.652,2.651,2.656,2.656,0,0,0,2.652-2.651v-6.291A2.656,2.656,0,0,0,1063.409-827.434Zm0,12.46a3.524,3.524,0,0,1-3.519-3.518v-6.291a3.525,3.525,0,0,1,3.519-3.518,3.522,3.522,0,0,1,3.519,3.518v6.291a3.521,3.521,0,0,1-3.519,3.518Z" transform="translate(-1059.89 828.301)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-611">
        <rect id="Прямоугольник_355" data-name="Прямоугольник 355" width="7.801" height="13.868" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-613">
        <path id="Контур_2449" data-name="Контур 2449" d="M1059-815.133h7.8V-829H1059Z" transform="translate(-1059 829)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-614">
        <path id="Контур_2458" data-name="Контур 2458" d="M1176.951-863.2a4.555,4.555,0,0,0-4.551,4.55v6a4.556,4.556,0,0,0,4.551,4.55h3.224a4.555,4.555,0,0,0,4.55-4.55v-6a4.555,4.555,0,0,0-4.55-4.55Zm3.224,16.4h-3.224a5.858,5.858,0,0,1-5.851-5.85v-6a5.858,5.858,0,0,1,5.851-5.85h3.224a5.855,5.855,0,0,1,5.85,5.85v6a5.855,5.855,0,0,1-5.85,5.85Z" transform="translate(-1171.1 864.505)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-616">
        <rect id="Прямоугольник_356" data-name="Прямоугольник 356" width="15.168" height="18.201" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-618">
        <path id="Контур_2455" data-name="Контур 2455" d="M1171-846.8h15.168V-865H1171Z" transform="translate(-1171 865)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-619">
        <path id="Контур_2464" data-name="Контур 2464" d="M1176.951-824.8a4.555,4.555,0,0,0-4.551,4.55v6a4.555,4.555,0,0,0,4.551,4.55h3.224a4.555,4.555,0,0,0,4.55-4.55v-6a4.555,4.555,0,0,0-4.55-4.55Zm3.224,16.4h-3.224a5.858,5.858,0,0,1-5.851-5.85v-6a5.858,5.858,0,0,1,5.851-5.85h3.224a5.855,5.855,0,0,1,5.85,5.85v6a5.855,5.855,0,0,1-5.85,5.85Z" transform="translate(-1171.1 826.104)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-623">
        <path id="Контур_2461" data-name="Контур 2461" d="M1171-808.8h15.168V-827H1171Z" transform="translate(-1171 827)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-624">
        <path id="Контур_2470" data-name="Контур 2470" d="M1059.34-748.949a4.555,4.555,0,0,0-4.55,4.55v2.466a4.555,4.555,0,0,0,4.55,4.55h7.679a4.555,4.555,0,0,0,4.55-4.55V-744.4a4.555,4.555,0,0,0-4.55-4.55Zm7.679,12.866h-7.679a5.859,5.859,0,0,1-5.85-5.85V-744.4a5.859,5.859,0,0,1,5.85-5.85h7.679a5.856,5.856,0,0,1,5.85,5.85v2.466a5.856,5.856,0,0,1-5.85,5.85Z" transform="translate(-1053.49 750.249)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-626">
        <rect id="Прямоугольник_358" data-name="Прямоугольник 358" width="19.935" height="14.734" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-628">
        <path id="Контур_2467" data-name="Контур 2467" d="M1053-736.266h19.935V-751H1053Z" transform="translate(-1053 751)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-629">
        <path id="Контур_2476" data-name="Контур 2476" d="M1101.461-748.949a4.555,4.555,0,0,0-4.551,4.55v2.466a4.555,4.555,0,0,0,4.551,4.55h7.679a4.555,4.555,0,0,0,4.55-4.55V-744.4a4.555,4.555,0,0,0-4.55-4.55Zm7.679,12.866h-7.679a5.859,5.859,0,0,1-5.851-5.85V-744.4a5.859,5.859,0,0,1,5.851-5.85h7.679a5.859,5.859,0,0,1,5.85,5.85v2.466a5.859,5.859,0,0,1-5.85,5.85Z" transform="translate(-1095.61 750.249)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-633">
        <path id="Контур_2473" data-name="Контур 2473" d="M1095-736.266h19.935V-751H1095Z" transform="translate(-1095 751)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-634">
        <path id="Контур_2482" data-name="Контур 2482" d="M1140.477-770.394a1.4,1.4,0,0,0-1.387,1.408v8.75a1.408,1.408,0,0,0,1.4,1.408h23.952a1.407,1.407,0,0,0,1.4-1.408v-25.152a1.406,1.406,0,0,0-1.4-1.406h-9.512a1.41,1.41,0,0,0-1.408,1.406v14.344a.686.686,0,0,1-.2.478C1153.083-770.333,1152.815-770.069,1140.477-770.394Zm23.969,12.866h-23.952a2.711,2.711,0,0,1-2.7-2.708v-8.75a2.709,2.709,0,0,1,2.7-2.708c4.806.125,10.136.194,11.731.1v-13.8a2.709,2.709,0,0,1,2.708-2.706h9.512a2.709,2.709,0,0,1,2.7,2.706v25.152a2.711,2.711,0,0,1-2.7,2.708Z" transform="translate(-1137.79 788.094)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-636">
        <rect id="Прямоугольник_360" data-name="Прямоугольник 360" width="29.902" height="31.202" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-638">
        <path id="Контур_2479" data-name="Контур 2479" d="M1137-757.8h29.9V-789H1137Z" transform="translate(-1137 789)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-639">
        <path id="Контур_2488" data-name="Контур 2488" d="M1194.95-863.2a1.633,1.633,0,0,0-1.629,1.631v11.839a1.633,1.633,0,0,0,1.629,1.631,1.63,1.63,0,0,0,1.629-1.631v-11.839A1.63,1.63,0,0,0,1194.95-863.2Zm0,16.4a2.933,2.933,0,0,1-2.93-2.931v-11.839a2.933,2.933,0,0,1,2.93-2.931,2.933,2.933,0,0,1,2.929,2.931v11.839a2.933,2.933,0,0,1-2.929,2.931Z" transform="translate(-1192.02 864.505)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-641">
        <rect id="Прямоугольник_361" data-name="Прямоугольник 361" width="6.067" height="18.201" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-643">
        <path id="Контур_2485" data-name="Контур 2485" d="M1192-846.8h6.067V-865H1192Z" transform="translate(-1192 865)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-644">
        <path id="Контур_2494" data-name="Контур 2494" d="M1194.95-824.8a1.632,1.632,0,0,0-1.629,1.63v11.839a1.632,1.632,0,0,0,1.629,1.63,1.63,1.63,0,0,0,1.629-1.63v-11.839A1.63,1.63,0,0,0,1194.95-824.8Zm0,16.4a2.933,2.933,0,0,1-2.93-2.93v-11.839a2.933,2.933,0,0,1,2.93-2.93,2.933,2.933,0,0,1,2.929,2.93v11.839a2.933,2.933,0,0,1-2.929,2.93Z" transform="translate(-1192.02 826.104)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-648">
        <path id="Контур_2491" data-name="Контур 2491" d="M1192-808.8h6.067V-827H1192Z" transform="translate(-1192 827)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-649">
        <path id="Контур_2500" data-name="Контур 2500" d="M1194.95-786.794a1.633,1.633,0,0,0-1.629,1.631V-763.2a1.634,1.634,0,0,0,1.629,1.634,1.631,1.631,0,0,0,1.629-1.634v-21.963A1.63,1.63,0,0,0,1194.95-786.794Zm0,26.528a2.935,2.935,0,0,1-2.93-2.934v-21.963a2.933,2.933,0,0,1,2.93-2.931,2.933,2.933,0,0,1,2.929,2.931V-763.2a2.935,2.935,0,0,1-2.929,2.934Z" transform="translate(-1192.02 788.094)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-651">
        <rect id="Прямоугольник_363" data-name="Прямоугольник 363" width="6.067" height="28.602" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-653">
        <path id="Контур_2497" data-name="Контур 2497" d="M1192-760.4h6.067V-789H1192Z" transform="translate(-1192 789)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-654">
        <path id="Контур_2506" data-name="Контур 2506" d="M1056.4-729.68a1.61,1.61,0,0,0-1.608,1.608,1.613,1.613,0,0,0,1.608,1.608h13.569a1.609,1.609,0,0,0,1.6-1.608,1.606,1.606,0,0,0-1.6-1.608Zm13.569,4.516H1056.4a2.911,2.911,0,0,1-2.908-2.908,2.911,2.911,0,0,1,2.908-2.908h13.569a2.907,2.907,0,0,1,2.9,2.908,2.907,2.907,0,0,1-2.9,2.908Z" transform="translate(-1053.49 730.98)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-656">
        <rect id="Прямоугольник_364" data-name="Прямоугольник 364" width="19.935" height="6.067" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-658">
        <path id="Контур_2503" data-name="Контур 2503" d="M1053-724.933h19.935V-731H1053Z" transform="translate(-1053 731)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-659">
        <path id="Контур_2512" data-name="Контур 2512" d="M1098.514-729.68a1.606,1.606,0,0,0-1.6,1.608,1.609,1.609,0,0,0,1.6,1.608h13.569a1.61,1.61,0,0,0,1.608-1.608,1.607,1.607,0,0,0-1.608-1.608Zm13.569,4.516h-13.569a2.91,2.91,0,0,1-2.9-2.908,2.91,2.91,0,0,1,2.9-2.908h13.569a2.911,2.911,0,0,1,2.908,2.908,2.911,2.911,0,0,1-2.908,2.908Z" transform="translate(-1095.61 730.98)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-663">
        <path id="Контур_2509" data-name="Контур 2509" d="M1095-724.933h19.935V-731H1095Z" transform="translate(-1095 731)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-664">
        <path id="Контур_2518" data-name="Контур 2518" d="M1162.167-725.164h-21.672a2.711,2.711,0,0,1-2.7-2.708v-.4a2.709,2.709,0,0,1,2.7-2.708h21.447a.652.652,0,0,1,.65.65.652.652,0,0,1-.65.65h-21.447a1.408,1.408,0,0,0-1.4,1.408v.4a1.407,1.407,0,0,0,1.4,1.408h21.672a1.41,1.41,0,0,0,1.4-1.408v-.4a.649.649,0,0,1,.65-.65.649.649,0,0,1,.65.65v.4a2.712,2.712,0,0,1-2.7,2.708" transform="translate(-1137.79 730.98)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-666">
        <rect id="Прямоугольник_366" data-name="Прямоугольник 366" width="27.735" height="6.067" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-668">
        <path id="Контур_2515" data-name="Контур 2515" d="M1137-724.933h27.735V-731H1137Z" transform="translate(-1137 731)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-669">
        <path id="Контур_2524" data-name="Контур 2524" d="M750.4-789.665h0a2.558,2.558,0,0,1-2.557-2.559V-818.4a2.558,2.558,0,0,1,2.557-2.56,2.562,2.562,0,0,1,2.561,2.56v26.174a2.562,2.562,0,0,1-2.561,2.559" transform="translate(-747.84 820.957)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-671">
        <rect id="Прямоугольник_367" data-name="Прямоугольник 367" width="5.634" height="31.635" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-673">
        <path id="Контур_2521" data-name="Контур 2521" d="M747-789.365h5.634V-821H747Z" transform="translate(-747 821)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-674">
        <path id="Контур_2530" data-name="Контур 2530" d="M796.309-887.649H758.06v-2.378h38.413a1.109,1.109,0,0,1,1.109,1.107,1.274,1.274,0,0,1-1.274,1.271" transform="translate(-758.06 890.027)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-676">
        <rect id="Прямоугольник_368" data-name="Прямоугольник 368" width="39.869" height="3.034" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-678">
        <path id="Контур_2527" data-name="Контур 2527" d="M758-887.967h39.869V-891H758Z" transform="translate(-758 891)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-679">
        <path id="Контур_2536" data-name="Контур 2536" d="M953.239-888.416H919.463a1.314,1.314,0,0,1-1.313-1.313,1.066,1.066,0,0,1,1.066-1.065h32.441a1.58,1.58,0,0,1,1.582,1.581v.8" transform="translate(-918.15 890.794)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-681">
        <rect id="Прямоугольник_369" data-name="Прямоугольник 369" width="35.536" height="2.6" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-683">
        <path id="Контур_2533" data-name="Контур 2533" d="M918-888.4h35.536V-891H918Z" transform="translate(-918 891)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-684">
        <path id="Контур_2542" data-name="Контур 2542" d="M991.646-871.44H990.16a1.53,1.53,0,0,1-1.53-1.531v-16.291a1.53,1.53,0,0,1,1.53-1.531,3.014,3.014,0,0,1,3.016,3.015v14.808a1.531,1.531,0,0,1-1.53,1.531" transform="translate(-988.63 890.794)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-686">
        <rect id="Прямоугольник_370" data-name="Прямоугольник 370" width="5.2" height="19.501" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-688">
        <path id="Контур_2539" data-name="Контур 2539" d="M988-871.5h5.2V-891H988Z" transform="translate(-988 891)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-689">
        <path id="Контур_2546" data-name="Контур 2546" d="M839.46-887.851h40.1v-1.2h-40.1Z" transform="translate(-839.46 889.05)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-691">
        <path id="Контур_2552" data-name="Контур 2552" d="M743.353-504.64H952.68V-655.9H743.353Zm-1.733,1.733H954.413V-657.63H741.62Z" transform="translate(-741.62 657.63)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-693">
        <rect id="Прямоугольник_371" data-name="Прямоугольник 371" width="213.214" height="155.143" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-695">
        <path id="Контур_2549" data-name="Контур 2549" d="M741-502.857H954.214V-658H741Z" transform="translate(-741 658)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-696">
        <path id="Контур_2558" data-name="Контур 2558" d="M751.543-504.816H953.932V-650.647H751.543Zm-1.733,1.733H955.665v-149.3H749.81Z" transform="translate(-749.81 652.38)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-700">
        <path id="Контур_2555" data-name="Контур 2555" d="M749-503.057H955.28V-653H749Z" transform="translate(-749 653)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-701">
        <path id="Контур_2564" data-name="Контур 2564" d="M939.02-611.356V-650.51h4.546V-615.9a4.545,4.545,0,0,1-4.546,4.546" transform="translate(-939.02 650.51)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-703">
        <rect id="Прямоугольник_373" data-name="Прямоугольник 373" width="4.767" height="39.436" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-705">
        <path id="Контур_2561" data-name="Контур 2561" d="M939-611.564h4.767V-651H939Z" transform="translate(-939 651)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-706">
        <path id="Контур_2570" data-name="Контур 2570" d="M836.347-521.592H752.86v-4.628h82.694a2.7,2.7,0,0,1,2.7,2.7v.022a1.9,1.9,0,0,1-1.9,1.907" transform="translate(-752.86 526.22)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-708">
        <rect id="Прямоугольник_374" data-name="Прямоугольник 374" width="85.806" height="5.2" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-710">
        <path id="Контур_2567" data-name="Контур 2567" d="M752-521.8h85.806V-527H752Z" transform="translate(-752 527)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-711">
        <path id="Контур_2576" data-name="Контур 2576" d="M1004.78-590.2h21.066V-640.76H1004.78Zm21.716,1.3H1004.13a.652.652,0,0,1-.65-.65V-641.41a.652.652,0,0,1,.65-.65H1026.5a.649.649,0,0,1,.65.65v51.865a.649.649,0,0,1-.65.65Z" transform="translate(-1003.48 642.06)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-713">
        <rect id="Прямоугольник_375" data-name="Прямоугольник 375" width="24.268" height="53.737" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-715">
        <path id="Контур_2573" data-name="Контур 2573" d="M1003-589.263h24.268V-643H1003Z" transform="translate(-1003 643)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-716">
        <path id="Контур_2582" data-name="Контур 2582" d="M1004.78-624.758h21.066v-4.412H1004.78Zm-1.3,1.3h23.666v-7.012H1003.48Z" transform="translate(-1003.48 630.47)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-718">
        <rect id="Прямоугольник_376" data-name="Прямоугольник 376" width="24.268" height="7.367" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-720">
        <path id="Контур_2579" data-name="Контур 2579" d="M1003-623.633h24.268V-631H1003Z" transform="translate(-1003 631)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-721">
        <path id="Контур_2588" data-name="Контур 2588" d="M1004.78-596.678h21.066v-4.412H1004.78Zm-1.3,1.3h23.666v-7.012H1003.48Z" transform="translate(-1003.48 602.39)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-725">
        <path id="Контур_2585" data-name="Контур 2585" d="M1003-595.633h24.268V-603H1003Z" transform="translate(-1003 603)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-726">
        <path id="Контур_2594" data-name="Контур 2594" d="M1004.78-570.978h21.066v-4.412H1004.78Zm-1.3,1.3h23.666v-7.012H1003.48Z" transform="translate(-1003.48 576.69)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-730">
        <path id="Контур_2591" data-name="Контур 2591" d="M1003-569.633h24.268V-577H1003Z" transform="translate(-1003 577)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-731">
        <path id="Контур_2600" data-name="Контур 2600" d="M1004.78-543.688h21.066V-548.1H1004.78Zm-1.3,1.3h23.666V-549.4H1003.48Z" transform="translate(-1003.48 549.4)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-735">
        <path id="Контур_2597" data-name="Контур 2597" d="M1003-542.633h24.268V-550H1003Z" transform="translate(-1003 550)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-736">
        <path id="Контур_2606" data-name="Контур 2606" d="M1064.642-579.324a.432.432,0,0,1-.433-.433l-.039-14.18a.431.431,0,0,1,.429-.433h0a.431.431,0,0,1,.433.429l.039,14.184a.435.435,0,0,1-.433.433" transform="translate(-1064.17 594.37)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-738">
        <rect id="Прямоугольник_380" data-name="Прямоугольник 380" width="1.3" height="15.601" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-740">
        <path id="Контур_2603" data-name="Контур 2603" d="M1064-579.4h1.3V-595H1064Z" transform="translate(-1064 595)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-741">
        <path id="Контур_2612" data-name="Контур 2612" d="M1060.933-594.674a.457.457,0,0,1-.182-.039.431.431,0,0,1-.212-.572l1.413-3.086a.431.431,0,0,1,.368-.251.429.429,0,0,1,.394.2,26.1,26.1,0,0,1,1.894,3.207.431.431,0,0,1-.312.525.431.431,0,0,1-.516-.277c-.087-.182-.711-1.222-1.378-2.31l-1.075,2.349a.43.43,0,0,1-.394.251" transform="translate(-1060.499 598.623)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-745">
        <path id="Контур_2609" data-name="Контур 2609" d="M1060-594.667h4.767V-599H1060Z" transform="translate(-1060 599)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-746">
        <path id="Контур_2618" data-name="Контур 2618" d="M781.768-473.05A1.77,1.77,0,0,0,780-471.282v.394a1.77,1.77,0,0,0,1.768,1.768,1.77,1.77,0,0,0,1.768-1.768v-.394A1.77,1.77,0,0,0,781.768-473.05Zm0,5.231a3.074,3.074,0,0,1-3.068-3.068v-.394a3.074,3.074,0,0,1,3.068-3.068,3.074,3.074,0,0,1,3.068,3.068v.394a3.074,3.074,0,0,1-3.068,3.068Z" transform="translate(-778.7 474.35)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-750">
        <path id="Контур_2615" data-name="Контур 2615" d="M778-468.066h6.5V-475H778Z" transform="translate(-778 475)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-751">
        <path id="Контур_2624" data-name="Контур 2624" d="M784.649-462.119h0a.767.767,0,0,0-.767-.771h-5.2a.77.77,0,0,0-.771.771.773.773,0,0,0,.771.771h5.2a.77.77,0,0,0,.767-.771" transform="translate(-777.91 462.89)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-755">
        <path id="Контур_2621" data-name="Контур 2621" d="M777-461.267h7.367V-463H777Z" transform="translate(-777 463)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-756">
        <path id="Контур_2630" data-name="Контур 2630" d="M1115.1-445.74a4.58,4.58,0,0,0-4.576,4.576v25.69a4.584,4.584,0,0,0,4.576,4.581h14.994a4.584,4.584,0,0,0,4.576-4.581v-25.69a4.58,4.58,0,0,0-4.576-4.576Zm14.994,36.147H1115.1a5.885,5.885,0,0,1-5.876-5.881v-25.69a5.882,5.882,0,0,1,5.876-5.876h14.994a5.884,5.884,0,0,1,5.876,5.876v25.69a5.888,5.888,0,0,1-5.876,5.881Z" transform="translate(-1109.22 447.04)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-758">
        <rect id="Прямоугольник_384" data-name="Прямоугольник 384" width="26.868" height="38.136" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-760">
        <path id="Контур_2627" data-name="Контур 2627" d="M1109-409.864h26.868V-448H1109Z" transform="translate(-1109 448)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-761">
        <path id="Контур_2636" data-name="Контур 2636" d="M1136.158-465.95a1.769,1.769,0,0,0-1.768,1.764v.394a1.77,1.77,0,0,0,1.768,1.768,1.77,1.77,0,0,0,1.768-1.768v-.394A1.769,1.769,0,0,0,1136.158-465.95Zm0,5.226a3.074,3.074,0,0,1-3.068-3.068v-.394a3.07,3.07,0,0,1,3.068-3.064,3.07,3.07,0,0,1,3.068,3.064v.394a3.074,3.074,0,0,1-3.068,3.068Z" transform="translate(-1133.09 467.25)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-765">
        <path id="Контур_2633" data-name="Контур 2633" d="M1133-461.066h6.5V-468H1133Z" transform="translate(-1133 468)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-766">
        <path id="Контур_2642" data-name="Контур 2642" d="M1139.039-466.429h0a.769.769,0,0,1-.767.771h-5.2a.773.773,0,0,1-.771-.771.77.77,0,0,1,.771-.771h5.2a.767.767,0,0,1,.767.771" transform="translate(-1132.3 467.2)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-770">
        <path id="Контур_2639" data-name="Контур 2639" d="M1132-465.833h6.934V-468H1132Z" transform="translate(-1132 468)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-771">
        <path id="Контур_2648" data-name="Контур 2648" d="M1091.938-393.26a1.77,1.77,0,0,0-1.768,1.768,1.77,1.77,0,0,0,1.768,1.768h.39a1.77,1.77,0,0,0,1.768-1.768,1.77,1.77,0,0,0-1.768-1.768Zm.39,4.836h-.39a3.071,3.071,0,0,1-3.068-3.068,3.071,3.071,0,0,1,3.068-3.068h.39a3.071,3.071,0,0,1,3.068,3.068,3.071,3.071,0,0,1-3.068,3.068Z" transform="translate(-1088.87 394.56)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-775">
        <path id="Контур_2645" data-name="Контур 2645" d="M1088-388.5h6.934V-395H1088Z" transform="translate(-1088 395)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-776">
        <path id="Контур_2654" data-name="Контур 2654" d="M1089.691-395.17h0a.773.773,0,0,1,.771.771v5.2a.773.773,0,0,1-.771.771.773.773,0,0,1-.771-.771v-5.2a.773.773,0,0,1,.771-.771" transform="translate(-1088.92 395.17)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-778">
        <rect id="Прямоугольник_388" data-name="Прямоугольник 388" width="2.167" height="7.367" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-780">
        <path id="Контур_2651" data-name="Контур 2651" d="M1088-388.633h2.167V-396H1088Z" transform="translate(-1088 396)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-781">
        <path id="Контур_2660" data-name="Контур 2660" d="M1091.248-428.5a1.77,1.77,0,0,0-1.768,1.768,1.769,1.769,0,0,0,1.768,1.764h.39a1.769,1.769,0,0,0,1.768-1.764,1.77,1.77,0,0,0-1.768-1.768Zm.39,4.832h-.39a3.067,3.067,0,0,1-3.068-3.064,3.068,3.068,0,0,1,3.068-3.068h.39a3.071,3.071,0,0,1,3.068,3.068,3.07,3.07,0,0,1-3.068,3.064Z" transform="translate(-1088.18 429.8)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-785">
        <path id="Контур_2657" data-name="Контур 2657" d="M1088-423.5h6.934V-430H1088Z" transform="translate(-1088 430)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-786">
        <path id="Контур_2666" data-name="Контур 2666" d="M1089-430.41h0a.773.773,0,0,1,.771.771v5.2a.771.771,0,0,1-.771.771.77.77,0,0,1-.771-.771v-5.2a.773.773,0,0,1,.771-.771" transform="translate(-1088.23 430.41)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-788">
        <rect id="Прямоугольник_390" data-name="Прямоугольник 390" width="1.733" height="7.367" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-790">
        <path id="Контур_2663" data-name="Контур 2663" d="M1088-423.633h1.733V-431H1088Z" transform="translate(-1088 431)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-791">
        <path id="Контур_2672" data-name="Контур 2672" d="M1179.984-395.65a1.766,1.766,0,0,0-1.764,1.768,1.766,1.766,0,0,0,1.764,1.768h.395a1.77,1.77,0,0,0,1.768-1.768,1.77,1.77,0,0,0-1.768-1.768Zm.395,4.836h-.395a3.07,3.07,0,0,1-3.064-3.068,3.07,3.07,0,0,1,3.064-3.068h.395a3.074,3.074,0,0,1,3.068,3.068,3.074,3.074,0,0,1-3.068,3.068Z" transform="translate(-1176.92 396.95)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-795">
        <path id="Контур_2669" data-name="Контур 2669" d="M1176-390.5h6.934V-397H1176Z" transform="translate(-1176 397)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-796">
        <path id="Контур_2678" data-name="Контур 2678" d="M1189.142-391h0a.77.77,0,0,1-.771-.771v-5.2a.77.77,0,0,1,.771-.771.773.773,0,0,1,.771.771v5.2a.773.773,0,0,1-.771.771" transform="translate(-1188.37 397.74)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-800">
        <path id="Контур_2675" data-name="Контур 2675" d="M1188-391.066h1.733V-398H1188Z" transform="translate(-1188 398)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-801">
        <path id="Контур_2684" data-name="Контур 2684" d="M1180.674-430.89a1.766,1.766,0,0,0-1.764,1.768,1.763,1.763,0,0,0,1.764,1.764h.394a1.766,1.766,0,0,0,1.768-1.764,1.77,1.77,0,0,0-1.768-1.768Zm.394,4.832h-.394a3.067,3.067,0,0,1-3.064-3.064,3.07,3.07,0,0,1,3.064-3.068h.394a3.074,3.074,0,0,1,3.068,3.068,3.07,3.07,0,0,1-3.068,3.064Z" transform="translate(-1177.61 432.19)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-805">
        <path id="Контур_2681" data-name="Контур 2681" d="M1177-426.5h6.934V-433H1177Z" transform="translate(-1177 433)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-806">
        <path id="Контур_2690" data-name="Контур 2690" d="M1189.832-426.241h0a.768.768,0,0,1-.771-.771v-5.2a.77.77,0,0,1,.771-.771.773.773,0,0,1,.771.771v5.2a.771.771,0,0,1-.771.771" transform="translate(-1189.06 432.98)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-810">
        <path id="Контур_2687" data-name="Контур 2687" d="M1189-426.066h1.734V-433H1189Z" transform="translate(-1189 433)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-811">
        <path id="Контур_2696" data-name="Контур 2696" d="M1132.794-356.76a1.769,1.769,0,0,0-1.764,1.768v.394a1.765,1.765,0,0,0,1.764,1.764,1.766,1.766,0,0,0,1.768-1.764v-.394A1.77,1.77,0,0,0,1132.794-356.76Zm0,5.226a3.067,3.067,0,0,1-3.064-3.064v-.394a3.07,3.07,0,0,1,3.064-3.068,3.071,3.071,0,0,1,3.068,3.068v.394a3.067,3.067,0,0,1-3.068,3.064Z" transform="translate(-1129.73 358.06)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-815">
        <path id="Контур_2693" data-name="Контур 2693" d="M1129-352.066h6.5V-359H1129Z" transform="translate(-1129 359)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-816">
        <path id="Контур_2702" data-name="Контур 2702" d="M1135.679-345.829h0a.771.771,0,0,0-.772-.771h-5.2a.768.768,0,0,0-.771.771.77.77,0,0,0,.771.771h5.2a.773.773,0,0,0,.772-.771" transform="translate(-1128.94 346.6)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-820">
        <path id="Контур_2699" data-name="Контур 2699" d="M1128-345.267h7.367V-347H1128Z" transform="translate(-1128 347)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-821">
        <path id="Контур_2708" data-name="Контур 2708" d="M851.338-652.688H821.406a2.856,2.856,0,0,1-2.856-2.856,2.856,2.856,0,0,1,2.856-2.856h29.932a2.856,2.856,0,0,1,2.86,2.856,2.857,2.857,0,0,1-2.86,2.856" transform="translate(-818.55 658.4)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-825">
        <path id="Контур_2705" data-name="Контур 2705" d="M818-652.933h35.969V-659H818Z" transform="translate(-818 659)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-826">
        <path id="Контур_2714" data-name="Контур 2714" d="M1033.5-307.358h-29.936a2.858,2.858,0,0,1-2.856-2.856,2.858,2.858,0,0,1,2.856-2.856H1033.5a2.856,2.856,0,0,1,2.856,2.856,2.856,2.856,0,0,1-2.856,2.856" transform="translate(-1000.71 313.07)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-828">
        <rect id="Прямоугольник_398" data-name="Прямоугольник 398" width="35.969" height="6.5" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-830">
        <path id="Контур_2711" data-name="Контур 2711" d="M1000-307.5h35.969V-314H1000Z" transform="translate(-1000 314)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-831">
        <path id="Контур_2720" data-name="Контур 2720" d="M1225.292-447.184v29.932a2.856,2.856,0,0,1-2.856,2.856,2.856,2.856,0,0,1-2.856-2.856v-29.932a2.856,2.856,0,0,1,2.856-2.856,2.856,2.856,0,0,1,2.856,2.856" transform="translate(-1219.58 450.04)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-833">
        <rect id="Прямоугольник_399" data-name="Прямоугольник 399" width="6.067" height="36.402" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-835">
        <path id="Контур_2717" data-name="Контур 2717" d="M1219-414.6h6.067V-451H1219Z" transform="translate(-1219 451)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-836">
        <path id="Контур_2726" data-name="Контур 2726" d="M755.147-516.653v11.168a4.3,4.3,0,0,0,4.295,4.295h17.07a4.99,4.99,0,0,0,4.984-4.984v-10.479Zm21.365,16.329h-17.07a5.166,5.166,0,0,1-5.161-5.161V-517.52h28.082v11.345a5.856,5.856,0,0,1-5.85,5.85Z" transform="translate(-754.28 517.52)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-838">
        <rect id="Прямоугольник_400" data-name="Прямоугольник 400" width="28.602" height="17.768" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-840">
        <path id="Контур_2723" data-name="Контур 2723" d="M754-500.232h28.6V-518H754Z" transform="translate(-754 518)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-841">
        <path id="Контур_2732" data-name="Контур 2732" d="M902.66-557.557h0L920.9-564.72l.65,1.651-16.6,6.513a1.775,1.775,0,0,1-2.3-1" transform="translate(-902.66 564.72)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-843">
        <rect id="Прямоугольник_401" data-name="Прямоугольник 401" width="19.501" height="8.667" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-845">
        <path id="Контур_2729" data-name="Контур 2729" d="M902-556.333h19.5V-565H902Z" transform="translate(-902 565)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-846">
        <path id="Контур_2736" data-name="Контур 2736" d="M1090.27-606.069h4.017V-650.51h-4.017Z" transform="translate(-1090.27 650.51)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-848">
        <path id="Контур_2742" data-name="Контур 2742" d="M1099.3-582.67h6.093a8.582,8.582,0,0,0,3.662-6.856,10.712,10.712,0,0,0-3.688-8.728H1099.3Zm6.223.867h-6.656a.432.432,0,0,1-.433-.433v-16.45a.435.435,0,0,1,.433-.433h6.656a.433.433,0,0,1,.269.1,11.572,11.572,0,0,1,4.13,9.547,9.425,9.425,0,0,1-4.169,7.61.429.429,0,0,1-.23.065Z" transform="translate(-1098.43 599.12)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-852">
        <path id="Контур_2739" data-name="Контур 2739" d="M1098-582.232h12.134V-600H1098Z" transform="translate(-1098 600)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-853">
        <path id="Контур_2748" data-name="Контур 2748" d="M1099.824-581.758h4.641a1.978,1.978,0,0,0,1.257-.438,7.48,7.48,0,0,0,2.587-5.573,9.2,9.2,0,0,0-2.661-6.882,1.7,1.7,0,0,0-1.192-.477h-4.632Zm4.641.433h-4.858a.22.22,0,0,1-.217-.217v-13.8a.217.217,0,0,1,.217-.217h4.849a2.118,2.118,0,0,1,1.5.6,9.636,9.636,0,0,1,2.791,7.207,7.9,7.9,0,0,1-2.743,5.889,2.4,2.4,0,0,1-1.534.537Z" transform="translate(-1099.39 595.56)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-855">
        <rect id="Прямоугольник_403" data-name="Прямоугольник 403" width="9.967" height="14.734" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-857">
        <path id="Контур_2745" data-name="Контур 2745" d="M1099-581.266h9.968V-596H1099Z" transform="translate(-1099 596)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-858">
        <path id="Контур_2754" data-name="Контур 2754" d="M1107.185-649.173a1.278,1.278,0,0,0-1.278,1.278,1.282,1.282,0,0,0,1.278,1.283h6.284a1.285,1.285,0,0,0,1.282-1.283,1.282,1.282,0,0,0-1.282-1.278Zm6.284,3.428h-6.284a2.151,2.151,0,0,1-2.145-2.149,2.147,2.147,0,0,1,2.145-2.145h6.284a2.151,2.151,0,0,1,2.15,2.145,2.155,2.155,0,0,1-2.15,2.149Z" transform="translate(-1105.04 650.04)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-860">
        <rect id="Прямоугольник_404" data-name="Прямоугольник 404" width="10.834" height="4.767" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-862">
        <path id="Контур_2751" data-name="Контур 2751" d="M1105-646.233h10.834V-651H1105Z" transform="translate(-1105 651)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-863">
        <path id="Контур_2760" data-name="Контур 2760" d="M1109.727-641.263v3.449a3.147,3.147,0,0,0,1.46,2.852,2.572,2.572,0,0,0,2.565.03,3.124,3.124,0,0,0,1.361-2.882v-3.449Zm2.782,7.527a3.547,3.547,0,0,1-1.755-.472,3.989,3.989,0,0,1-1.894-3.606V-641.7a.432.432,0,0,1,.433-.433h6.254a.429.429,0,0,1,.433.433v3.883a3.88,3.88,0,0,1-1.794,3.632,3.363,3.363,0,0,1-1.677.446Z" transform="translate(-1108.86 642.13)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-865">
        <rect id="Прямоугольник_405" data-name="Прямоугольник 405" width="7.801" height="9.101" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-867">
        <path id="Контур_2757" data-name="Контур 2757" d="M1108-633.9h7.8V-643H1108Z" transform="translate(-1108 643)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-868">
        <path id="Контур_2766" data-name="Контур 2766" d="M1111.954-641.2v3.207a3.07,3.07,0,0,0,1.257,2.765,1.568,1.568,0,0,0,1.6.017,3.079,3.079,0,0,0,1.174-2.782V-641.2Zm2.076,6.63a2.092,2.092,0,0,1-1.036-.281,3.456,3.456,0,0,1-1.474-3.142v-3.424a.215.215,0,0,1,.217-.217h4.464a.217.217,0,0,1,.217.217v3.424a3.458,3.458,0,0,1-1.391,3.155,1.96,1.96,0,0,1-1,.269Z" transform="translate(-1111.52 641.63)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-870">
        <rect id="Прямоугольник_406" data-name="Прямоугольник 406" width="5.2" height="7.367" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-872">
        <path id="Контур_2763" data-name="Контур 2763" d="M1111-634.633h5.2V-642H1111Z" transform="translate(-1111 642)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-873">
        <path id="Контур_2772" data-name="Контур 2772" d="M1115.615-646.407a.484.484,0,0,0-.481.485.483.483,0,0,0,.481.481.484.484,0,0,0,.485-.481A.484.484,0,0,0,1115.615-646.407Zm0,1.4a.918.918,0,0,1-.915-.914.918.918,0,0,1,.915-.919.919.919,0,0,1,.919.919.918.918,0,0,1-.919.914Z" transform="translate(-1114.7 646.84)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-877">
        <path id="Контур_2769" data-name="Контур 2769" d="M1114-644.833h2.167V-647H1114Z" transform="translate(-1114 647)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-878">
        <path id="Контур_2778" data-name="Контур 2778" d="M1106.708-544.971h-15.28a1.153,1.153,0,0,1-1.157-1.153v-2.756h16.477a1.8,1.8,0,0,1,1.8,1.8v.264a1.84,1.84,0,0,1-1.842,1.842" transform="translate(-1090.27 548.88)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-880">
        <rect id="Прямоугольник_408" data-name="Прямоугольник 408" width="18.635" height="4.334" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-882">
        <path id="Контур_2775" data-name="Контур 2775" d="M1090-544.667h18.635V-549H1090Z" transform="translate(-1090 549)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-883">
        <path id="Контур_2784" data-name="Контур 2784" d="M1187.234-544.861h-25.291a1.931,1.931,0,0,1-1.933-1.928v-.16a1.821,1.821,0,0,1,1.824-1.82h25.4v3.909" transform="translate(-1160.01 548.77)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-885">
        <rect id="Прямоугольник_409" data-name="Прямоугольник 409" width="27.302" height="4.334" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-887">
        <path id="Контур_2781" data-name="Контур 2781" d="M1160-544.667h27.3V-549H1160Z" transform="translate(-1160 549)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-888">
        <path id="Контур_2790" data-name="Контур 2790" d="M1155.8-551.133l-.923.615-4.624-6.934a1.108,1.108,0,0,1,1.534.308l4.013,6.011" transform="translate(-1150.25 557.637)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-890">
        <rect id="Прямоугольник_410" data-name="Прямоугольник 410" width="6.067" height="7.367" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-892">
        <path id="Контур_2787" data-name="Контур 2787" d="M1150-550.633h6.067V-558H1150Z" transform="translate(-1150 558)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-893">
        <path id="Контур_2796" data-name="Контур 2796" d="M1125.351-603.476a1.744,1.744,0,0,0,.295.017c.364,0,.7-.134.7-.529,0-.373-.321-.524-.707-.524h-.29Zm0-1.3h.321c.373,0,.589-.195.589-.455,0-.321-.243-.446-.6-.446a1.432,1.432,0,0,0-.312.026Zm-.351-1.114a3.26,3.26,0,0,1,.641-.056,1.151,1.151,0,0,1,.75.195.576.576,0,0,1,.226.485.643.643,0,0,1-.459.594v.009a.706.706,0,0,1,.559.685.732.732,0,0,1-.23.546,1.366,1.366,0,0,1-.94.251,3.99,3.99,0,0,1-.546-.03v-2.678Z" transform="translate(-1125 605.95)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-895">
        <rect id="Прямоугольник_411" data-name="Прямоугольник 411" width="1.733" height="3.034" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-897">
        <path id="Контур_2793" data-name="Контур 2793" d="M1125-602.967h1.734V-606H1125Z" transform="translate(-1125 606)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-898">
        <path id="Контур_2802" data-name="Контур 2802" d="M1131.119-604.315l-.269-.784c-.061-.178-.1-.338-.139-.5h-.009c-.039.165-.087.329-.139.494l-.264.789Zm-.893.273-.282.858h-.364l.923-2.726h.425l.932,2.726h-.377l-.29-.858Z" transform="translate(-1129.58 605.91)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-900">
        <rect id="Прямоугольник_412" data-name="Прямоугольник 412" width="2.6" height="3.034" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-902">
        <path id="Контур_2799" data-name="Контур 2799" d="M1129-602.967h2.6V-606H1129Z" transform="translate(-1129 606)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-903">
        <path id="Контур_2808" data-name="Контур 2808" d="M1135.148-605.607h-.828v-.3h2.015v.3h-.832v2.422h-.355v-2.422" transform="translate(-1134.32 605.91)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-905">
        <rect id="Прямоугольник_413" data-name="Прямоугольник 413" width="2.167" height="3.034" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-907">
        <path id="Контур_2805" data-name="Контур 2805" d="M1134-602.967h2.167V-606H1134Z" transform="translate(-1134 606)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-908">
        <path id="Контур_2814" data-name="Контур 2814" d="M1140.021-605.91v1.14h1.317v-1.14h.355v2.726h-.355v-1.278h-1.317v1.278h-.351v-2.726h.351" transform="translate(-1139.67 605.91)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-912">
        <path id="Контур_2811" data-name="Контур 2811" d="M1139-602.967h2.6V-606H1139Z" transform="translate(-1139 606)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-913">
        <path id="Контур_2820" data-name="Контур 2820" d="M1146.1-604.654h.36c.377,0,.615-.2.615-.516,0-.351-.256-.507-.628-.511a1.344,1.344,0,0,0-.346.035Zm-.351-1.239a3.468,3.468,0,0,1,.676-.056,1.138,1.138,0,0,1,.789.221.688.688,0,0,1,.212.516.72.72,0,0,1-.511.7v.013a.673.673,0,0,1,.4.546,4.172,4.172,0,0,0,.208.75h-.364a3.8,3.8,0,0,1-.182-.65c-.078-.377-.225-.52-.542-.529h-.333v1.179h-.351v-2.687Z" transform="translate(-1145.75 605.95)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-917">
        <path id="Контур_2817" data-name="Контур 2817" d="M1145-602.967h2.167V-606H1145Z" transform="translate(-1145 606)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-918">
        <path id="Контур_2826" data-name="Контур 2826" d="M1150.8-604.589c0,.585.312,1.105.867,1.105s.875-.511.875-1.131c0-.542-.286-1.109-.871-1.109S1150.8-605.187,1150.8-604.589Zm2.111-.043a1.275,1.275,0,0,1-1.265,1.434,1.245,1.245,0,0,1-1.222-1.382,1.3,1.3,0,0,1,1.265-1.43,1.241,1.241,0,0,1,1.222,1.378Z" transform="translate(-1150.42 606.01)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-922">
        <path id="Контур_2823" data-name="Контур 2823" d="M1150-603.533h3.034V-607H1150Z" transform="translate(-1150 607)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-923">
        <path id="Контур_2832" data-name="Контур 2832" d="M1157.217-604.589c0,.585.316,1.105.867,1.105s.875-.511.875-1.131c0-.542-.286-1.109-.871-1.109S1157.217-605.187,1157.217-604.589Zm2.11-.043a1.274,1.274,0,0,1-1.261,1.434,1.248,1.248,0,0,1-1.227-1.382,1.3,1.3,0,0,1,1.265-1.43,1.241,1.241,0,0,1,1.222,1.378Z" transform="translate(-1156.84 606.01)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-927">
        <path id="Контур_2829" data-name="Контур 2829" d="M1156-603.533h3.034V-607H1156Z" transform="translate(-1156 607)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-928">
        <path id="Контур_2838" data-name="Контур 2838" d="M1165.836-604.38c-.017-.381-.043-.836-.039-1.174h-.013c-.091.316-.208.659-.342,1.031l-.481,1.322h-.269l-.437-1.3c-.13-.386-.239-.737-.316-1.057h-.009c-.009.338-.026.793-.052,1.2l-.073,1.17h-.334l.191-2.726h.447l.464,1.317c.117.338.208.637.277.919h.013a9.611,9.611,0,0,1,.286-.919l.485-1.317h.446l.169,2.726h-.343l-.069-1.2" transform="translate(-1163.47 605.91)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-930">
        <rect id="Прямоугольник_418" data-name="Прямоугольник 418" width="3.034" height="3.034" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-932">
        <path id="Контур_2835" data-name="Контур 2835" d="M1163-602.967h3.034V-606H1163Z" transform="translate(-1163 606)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-933">
        <path id="Контур_2844" data-name="Контур 2844" d="M822.945-587.539v1.092h.364a.41.41,0,0,0,.338-.139.7.7,0,0,0,.109-.429c0-.351-.152-.524-.455-.524Zm0-.607h.334a.374.374,0,0,0,.321-.121.578.578,0,0,0,.1-.364.48.48,0,0,0-.108-.347.446.446,0,0,0-.338-.108h-.3Zm-.685-1.564h1a1.365,1.365,0,0,1,.884.234.932.932,0,0,1,.277.75,1.015,1.015,0,0,1-.147.568.605.605,0,0,1-.4.277v.026a.76.76,0,0,1,.477.308,1.115,1.115,0,0,1,.147.6,1.154,1.154,0,0,1-.286.832,1.012,1.012,0,0,1-.776.3H822.26v-3.9Z" transform="translate(-822.26 589.71)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-937">
        <path id="Контур_2841" data-name="Контур 2841" d="M822-585.667h2.6V-590H822Z" transform="translate(-822 590)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-938">
        <path id="Контур_2850" data-name="Контур 2850" d="M830.405-585.814H828.65v-3.9h1.755v.624h-1.031v.927h.958v.633h-.958v1.079h1.031v.633" transform="translate(-828.65 589.71)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-942">
        <path id="Контур_2847" data-name="Контур 2847" d="M828-585.667h2.167V-590H828Z" transform="translate(-828 590)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-943">
        <path id="Контур_2856" data-name="Контур 2856" d="M835.744-587.777c0-.871-.238-1.309-.719-1.309h-.29v2.639h.234a.635.635,0,0,0,.589-.334A2.076,2.076,0,0,0,835.744-587.777Zm.745-.022a2.359,2.359,0,0,1-.394,1.473,1.332,1.332,0,0,1-1.131.511h-.953v-3.9h1.044a1.247,1.247,0,0,1,1.057.5,2.3,2.3,0,0,1,.377,1.408Z" transform="translate(-834.01 589.71)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-947">
        <path id="Контур_2853" data-name="Контур 2853" d="M834-585.667h2.6V-590H834Z" transform="translate(-834 590)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-948">
        <path id="Контур_2862" data-name="Контур 2862" d="M841.764-587.955h.186a.436.436,0,0,0,.368-.143.718.718,0,0,0,.113-.433.614.614,0,0,0-.117-.425.483.483,0,0,0-.368-.13h-.182Zm0,.624v1.517h-.724v-3.9h.949a1.21,1.21,0,0,1,.889.286,1.179,1.179,0,0,1,.286.871,1.1,1.1,0,0,1-.5,1.031l.75,1.707h-.776l-.615-1.517Z" transform="translate(-841.04 589.71)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-952">
        <path id="Контур_2859" data-name="Контур 2859" d="M841-585.667h2.6V-590H841Z" transform="translate(-841 590)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-953">
        <path id="Контур_2868" data-name="Контур 2868" d="M847.8-587.848a2.38,2.38,0,0,0,.169,1.014.528.528,0,0,0,.49.351c.438,0,.659-.455.659-1.365s-.217-1.365-.65-1.365a.535.535,0,0,0-.5.351A2.381,2.381,0,0,0,847.8-587.848Zm2.058,0a2.606,2.606,0,0,1-.36,1.486,1.193,1.193,0,0,1-1.04.52,1.187,1.187,0,0,1-1.036-.516,2.592,2.592,0,0,1-.364-1.5,2.546,2.546,0,0,1,.364-1.482,1.187,1.187,0,0,1,1.044-.516,1.185,1.185,0,0,1,1.031.516,2.573,2.573,0,0,1,.36,1.487Z" transform="translate(-847.06 589.85)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-957">
        <path id="Контур_2865" data-name="Контур 2865" d="M847-585.667h3.033V-590H847Z" transform="translate(-847 590)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-958">
        <path id="Контур_2874" data-name="Контур 2874" d="M855.3-587.848a2.458,2.458,0,0,0,.165,1.014.533.533,0,0,0,.494.351c.438,0,.654-.455.654-1.365s-.217-1.365-.65-1.365a.535.535,0,0,0-.5.351A2.459,2.459,0,0,0,855.3-587.848Zm2.058,0a2.57,2.57,0,0,1-.364,1.486,1.181,1.181,0,0,1-1.036.52,1.2,1.2,0,0,1-1.04-.516,2.592,2.592,0,0,1-.36-1.5,2.547,2.547,0,0,1,.36-1.482,1.192,1.192,0,0,1,1.044-.516,1.187,1.187,0,0,1,1.036.516,2.606,2.606,0,0,1,.36,1.487Z" transform="translate(-854.56 589.85)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-962">
        <path id="Контур_2871" data-name="Контур 2871" d="M854-585.667h3.467V-590H854Z" transform="translate(-854 590)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-963">
        <path id="Контур_2880" data-name="Контур 2880" d="M863.721-585.814l-.741-3.319h-.031a5.23,5.23,0,0,1,.052.706v2.613h-.672v-3.9h1.114l.667,3.06h.022l.659-3.06h1.131v3.9h-.689v-2.644a5.962,5.962,0,0,1,.039-.672h-.022l-.737,3.315h-.793" transform="translate(-862.33 589.71)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-965">
        <rect id="Прямоугольник_425" data-name="Прямоугольник 425" width="3.9" height="4.334" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-967">
        <path id="Контур_2877" data-name="Контур 2877" d="M862-585.667h3.9V-590H862Z" transform="translate(-862 590)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-968">
        <path id="Контур_2886" data-name="Контур 2886" d="M877.023-585.888h-2.089v-.542l.693-.979a6.81,6.81,0,0,0,.39-.6,1.716,1.716,0,0,0,.16-.377,1.476,1.476,0,0,0,.048-.368.477.477,0,0,0-.1-.338.331.331,0,0,0-.26-.113.527.527,0,0,0-.264.074,1.7,1.7,0,0,0-.3.273l-.4-.429a1.758,1.758,0,0,1,.52-.433,1.173,1.173,0,0,1,.52-.117.97.97,0,0,1,.737.277,1.069,1.069,0,0,1,.264.771,1.867,1.867,0,0,1-.048.42,2.143,2.143,0,0,1-.143.4,4.02,4.02,0,0,1-.247.42c-.1.143-.351.472-.754.992v.022h1.274v.646" transform="translate(-874.9 589.84)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-972">
        <path id="Контур_2883" data-name="Контур 2883" d="M874-585.667h2.6V-590H874Z" transform="translate(-874 590)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-973">
        <path id="Контур_2892" data-name="Контур 2892" d="M963.484-418.5h-1.057l-.875-2.279-.4.4v1.881h-.992v-5.33h.992v2.643l.347-.8.91-1.846h1.062l-1.235,2.31,1.248,3.021" transform="translate(-960.16 423.83)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-975">
        <rect id="Прямоугольник_427" data-name="Прямоугольник 427" width="3.467" height="5.634" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-977">
        <path id="Контур_2889" data-name="Контур 2889" d="M960-418.366h3.467V-424H960Z" transform="translate(-960 424)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-978">
        <path id="Контур_2896" data-name="Контур 2896" d="M969.33-418.5h.932v-5.33h-.932Z" transform="translate(-969.33 423.83)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-980">
        <path id="Контур_2902" data-name="Контур 2902" d="M975.237-418.5h-.988v-4.459H973.23v-.871h3.029v.871h-1.023v4.459" transform="translate(-973.23 423.83)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-984">
        <path id="Контур_2899" data-name="Контур 2899" d="M973-418.366h3.467V-424H973Z" transform="translate(-973 424)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-985">
        <path id="Контур_2908" data-name="Контур 2908" d="M982.863-423.139a.74.74,0,0,0-.672.5,3.2,3.2,0,0,0-.247,1.391q0,1.853.966,1.85a1.775,1.775,0,0,0,.884-.269v.88a1.765,1.765,0,0,1-1,.26,1.577,1.577,0,0,1-1.387-.715,3.59,3.59,0,0,1-.481-2.015,3.521,3.521,0,0,1,.5-2.024,1.582,1.582,0,0,1,1.383-.728,1.838,1.838,0,0,1,.559.082,2.2,2.2,0,0,1,.576.282l-.33.806a2.322,2.322,0,0,0-.351-.208.889.889,0,0,0-.4-.091" transform="translate(-980.93 424.01)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-987">
        <rect id="Прямоугольник_429" data-name="Прямоугольник 429" width="3.467" height="6.067" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-989">
        <path id="Контур_2905" data-name="Контур 2905" d="M980-418.933h3.467V-425H980Z" transform="translate(-980 425)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-990">
        <path id="Контур_2914" data-name="Контур 2914" d="M992.584-418.5h-.992v-2.336h-1.313v2.336h-.988v-5.33h.988v2.119h1.313v-2.119h.992v5.33" transform="translate(-989.29 423.83)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-994">
        <path id="Контур_2911" data-name="Контур 2911" d="M989-418.366h3.467V-424H989Z" transform="translate(-989 424)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-995">
        <path id="Контур_2920" data-name="Контур 2920" d="M1001.465-418.5H999.06v-5.33h2.405v.858h-1.413v1.27h1.309v.858h-1.309v1.478h1.413v.867" transform="translate(-999.06 423.83)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-997">
        <rect id="Прямоугольник_431" data-name="Прямоугольник 431" width="2.6" height="5.634" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-999">
        <path id="Контур_2917" data-name="Контур 2917" d="M999-418.366h2.6V-424H999Z" transform="translate(-999 424)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1000">
        <path id="Контур_2926" data-name="Контур 2926" d="M1010.136-418.5h-1.214l-1.647-3.861h-.047a4.714,4.714,0,0,1,.091.949v2.912h-.919v-5.33h1.218l1.634,3.822h.039a5.045,5.045,0,0,1-.069-.91v-2.912h.915v5.33" transform="translate(-1006.4 423.83)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1002">
        <rect id="Прямоугольник_432" data-name="Прямоугольник 432" width="4.334" height="5.634" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1004">
        <path id="Контур_2923" data-name="Контур 2923" d="M1006-418.366h4.334V-424H1006Z" transform="translate(-1006 424)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1005">
        <path id="Контур_2932" data-name="Контур 2932" d="M1186.1-646.493c-4.78,0-8.671,8.459-8.671,18.86s3.892,18.864,8.671,18.864,8.672-8.464,8.672-18.864S1190.878-646.493,1186.1-646.493Zm0,38.591c-5.348,0-9.538-8.667-9.538-19.731s4.191-19.727,9.538-19.727,9.538,8.667,9.538,19.727-4.191,19.731-9.538,19.731Z" transform="translate(-1176.56 647.36)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1007">
        <rect id="Прямоугольник_433" data-name="Прямоугольник 433" width="19.501" height="39.869" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1009">
        <path id="Контур_2929" data-name="Контур 2929" d="M1176-608.131h19.5V-648H1176Z" transform="translate(-1176 648)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1010">
        <path id="Контур_2936" data-name="Контур 2936" d="M1069.61-506.587h66.4v-5.733h-66.4Z" transform="translate(-1069.61 512.32)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1012">
        <path id="Контур_2942" data-name="Контур 2942" d="M752.66-389.776H835.1v-17.04a1.014,1.014,0,0,0-1.01-1.01h-57.91a2.669,2.669,0,0,1-2.665-2.665v-36.242a1.708,1.708,0,0,0-1.708-1.707H752.66Zm83.093,1.3H752.01a.652.652,0,0,1-.65-.65V-449.09a.652.652,0,0,1,.65-.65h19.8a3.012,3.012,0,0,1,3.008,3.007v36.242a1.365,1.365,0,0,0,1.365,1.365h57.91a2.312,2.312,0,0,1,2.31,2.31v17.69a.655.655,0,0,1-.65.65Z" transform="translate(-751.36 449.74)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1014">
        <rect id="Прямоугольник_434" data-name="Прямоугольник 434" width="85.372" height="61.537" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1016">
        <path id="Контур_2939" data-name="Контур 2939" d="M751-388.463h85.372V-450H751Z" transform="translate(-751 450)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1017">
        <path id="Контур_2948" data-name="Контур 2948" d="M767.153-433.663a2.168,2.168,0,0,0-2.167,2.167v17.534a2.17,2.17,0,0,0,2.167,2.167h7.593a2.17,2.17,0,0,0,2.167-2.167V-431.5a2.168,2.168,0,0,0-2.167-2.167Zm7.593,22.734h-7.593a3.037,3.037,0,0,1-3.033-3.034V-431.5a3.037,3.037,0,0,1,3.033-3.034h7.593a3.037,3.037,0,0,1,3.034,3.034v17.534a3.037,3.037,0,0,1-3.034,3.034Z" transform="translate(-764.12 434.53)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1019">
        <rect id="Прямоугольник_435" data-name="Прямоугольник 435" width="13.868" height="23.835" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1021">
        <path id="Контур_2945" data-name="Контур 2945" d="M764-411.165h13.868V-435H764Z" transform="translate(-764 435)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1022">
        <path id="Контур_2954" data-name="Контур 2954" d="M769.784-417.763a2.17,2.17,0,0,0-2.167,2.167v9.27a2.17,2.17,0,0,0,2.167,2.167h5.244a2.167,2.167,0,0,0,2.167-2.167v-9.27a2.167,2.167,0,0,0-2.167-2.167Zm5.244,14.47h-5.244a3.037,3.037,0,0,1-3.034-3.033v-9.27a3.037,3.037,0,0,1,3.034-3.034h5.244a3.037,3.037,0,0,1,3.034,3.034v9.27a3.037,3.037,0,0,1-3.034,3.033Z" transform="translate(-766.75 418.63)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1024">
        <rect id="Прямоугольник_436" data-name="Прямоугольник 436" width="11.701" height="15.601" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1026">
        <path id="Контур_2951" data-name="Контур 2951" d="M766-403.4h11.7V-419H766Z" transform="translate(-766 419)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1027">
        <path id="Контур_2960" data-name="Контур 2960" d="M771.231-396.957a.928.928,0,0,0-.927.927.927.927,0,0,0,.927.923.928.928,0,0,0,.927-.923A.929.929,0,0,0,771.231-396.957Zm0,2.284a1.359,1.359,0,0,1-1.361-1.356,1.363,1.363,0,0,1,1.361-1.361,1.363,1.363,0,0,1,1.361,1.361,1.359,1.359,0,0,1-1.361,1.356Z" transform="translate(-769.87 397.39)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1029">
        <rect id="Прямоугольник_437" data-name="Прямоугольник 437" width="3.467" height="3.034" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1031">
        <path id="Контур_2957" data-name="Контур 2957" d="M769-394.967h3.467V-398H769Z" transform="translate(-769 398)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1032">
        <path id="Контур_2966" data-name="Контур 2966" d="M770.574-431.39v4.8a.1.1,0,0,1-.1.1.1.1,0,0,1-.1-.1v-4.8a.1.1,0,0,1,.1-.1.1.1,0,0,1,.1.1" transform="translate(-770.37 431.49)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1034">
        <rect id="Прямоугольник_438" data-name="Прямоугольник 438" width="0.433" height="5.634" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1036">
        <path id="Контур_2963" data-name="Контур 2963" d="M770-426.366h.433V-432H770Z" transform="translate(-770 432)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1037">
        <path id="Контур_2972" data-name="Контур 2972" d="M772.369-431.39v4.8a.1.1,0,0,1-.1.1.1.1,0,0,1-.1-.1v-4.8a.1.1,0,0,1,.1-.1.1.1,0,0,1,.1.1" transform="translate(-772.17 431.49)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1041">
        <path id="Контур_2969" data-name="Контур 2969" d="M772-426.366h.434V-432H772Z" transform="translate(-772 432)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1042">
        <path id="Контур_2978" data-name="Контур 2978" d="M774.489-431.39v4.8a.1.1,0,0,1-.1.1.1.1,0,0,1-.1-.1v-4.8a.1.1,0,0,1,.1-.1.1.1,0,0,1,.1.1" transform="translate(-774.29 431.49)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1046">
        <path id="Контур_2975" data-name="Контур 2975" d="M774-426.366h.433V-432H774Z" transform="translate(-774 432)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1047">
        <path id="Контур_2984" data-name="Контур 2984" d="M776.289-431.39v4.8a.1.1,0,0,1-.1.1.1.1,0,0,1-.1-.1v-4.8a.1.1,0,0,1,.1-.1.1.1,0,0,1,.1.1" transform="translate(-776.09 431.49)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1051">
        <path id="Контур_2981" data-name="Контур 2981" d="M776-426.366h.433V-432H776Z" transform="translate(-776 432)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1052">
        <path id="Контур_2990" data-name="Контур 2990" d="M778.5-431.39v4.8a.1.1,0,0,1-.1.1.1.1,0,0,1-.1-.1v-4.8a.1.1,0,0,1,.1-.1.1.1,0,0,1,.1.1" transform="translate(-778.3 431.49)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1056">
        <path id="Контур_2987" data-name="Контур 2987" d="M778-426.366h.433V-432H778Z" transform="translate(-778 432)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1057">
        <path id="Контур_2996" data-name="Контур 2996" d="M780.694-431.39v4.8a.1.1,0,0,1-.1.1.1.1,0,0,1-.1-.1v-4.8a.1.1,0,0,1,.1-.1.1.1,0,0,1,.1.1" transform="translate(-780.49 431.49)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1061">
        <path id="Контур_2993" data-name="Контур 2993" d="M780-426.366h.433V-432H780Z" transform="translate(-780 432)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1062">
        <path id="Контур_3002" data-name="Контур 3002" d="M782.809-431.39v4.8a.1.1,0,0,1-.1.1.1.1,0,0,1-.1-.1v-4.8a.1.1,0,0,1,.1-.1.1.1,0,0,1,.1.1" transform="translate(-782.61 431.49)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1064">
        <rect id="Прямоугольник_444" data-name="Прямоугольник 444" width="0.867" height="5.634" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1066">
        <path id="Контур_2999" data-name="Контур 2999" d="M782-426.366h.867V-432H782Z" transform="translate(-782 432)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1067">
        <path id="Контур_3008" data-name="Контур 3008" d="M784.609-431.39v4.8a.1.1,0,0,1-.1.1.1.1,0,0,1-.1-.1v-4.8a.1.1,0,0,1,.1-.1.1.1,0,0,1,.1.1" transform="translate(-784.41 431.49)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1071">
        <path id="Контур_3005" data-name="Контур 3005" d="M784-426.366h.433V-432H784Z" transform="translate(-784 432)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1072">
        <path id="Контур_3014" data-name="Контур 3014" d="M786.6-431.39v4.8a.1.1,0,0,1-.1.1.1.1,0,0,1-.1-.1v-4.8a.1.1,0,0,1,.1-.1.1.1,0,0,1,.1.1" transform="translate(-786.4 431.49)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1076">
        <path id="Контур_3011" data-name="Контур 3011" d="M786-426.366h.433V-432H786Z" transform="translate(-786 432)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1077">
        <path id="Контур_3020" data-name="Контур 3020" d="M788.4-431.39v4.8a.1.1,0,0,1-.1.1.1.1,0,0,1-.1-.1v-4.8a.1.1,0,0,1,.1-.1.1.1,0,0,1,.1.1" transform="translate(-788.2 431.49)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1081">
        <path id="Контур_3017" data-name="Контур 3017" d="M788-426.366h.433V-432H788Z" transform="translate(-788 432)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1082">
        <path id="Контур_3026" data-name="Контур 3026" d="M843.97-350.8a1.084,1.084,0,0,0-1.083,1.083v12.637a.718.718,0,0,0,.719.719h11.839a1.084,1.084,0,0,0,1.083-1.083v-11.7a1.663,1.663,0,0,0-1.66-1.66ZM855.446-335.5H843.606a1.587,1.587,0,0,1-1.586-1.586V-349.72a1.953,1.953,0,0,1,1.95-1.95h10.9a2.529,2.529,0,0,1,2.527,2.526v11.7a1.953,1.953,0,0,1-1.95,1.95Z" transform="translate(-842.02 351.67)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1084">
        <rect id="Прямоугольник_448" data-name="Прямоугольник 448" width="15.601" height="16.468" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1086">
        <path id="Контур_3023" data-name="Контур 3023" d="M842-335.532h15.6V-352H842Z" transform="translate(-842 352)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1087">
        <path id="Контур_3032" data-name="Контур 3032" d="M848.666-346.517a1.641,1.641,0,0,0-1.642,1.638,1.641,1.641,0,0,0,1.642,1.638,1.639,1.639,0,0,0,1.638-1.638A1.64,1.64,0,0,0,848.666-346.517Zm0,3.71a2.075,2.075,0,0,1-2.076-2.072,2.075,2.075,0,0,1,2.076-2.071,2.074,2.074,0,0,1,2.072,2.071,2.074,2.074,0,0,1-2.072,2.072Z" transform="translate(-846.59 346.95)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1091">
        <path id="Контур_3029" data-name="Контур 3029" d="M846-342.666h4.767V-347H846Z" transform="translate(-846 347)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1092">
        <path id="Контур_3038" data-name="Контур 3038" d="M849.072-328.987a1.64,1.64,0,0,0-1.638,1.638,1.64,1.64,0,0,0,1.638,1.638,1.641,1.641,0,0,0,1.643-1.638A1.641,1.641,0,0,0,849.072-328.987Zm0,3.71A2.071,2.071,0,0,1,847-327.349a2.071,2.071,0,0,1,2.072-2.071,2.075,2.075,0,0,1,2.076,2.071,2.075,2.075,0,0,1-2.076,2.071Z" transform="translate(-847 329.42)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1094">
        <rect id="Прямоугольник_450" data-name="Прямоугольник 450" width="4.334" height="4.767" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1096">
        <path id="Контур_3035" data-name="Контур 3035" d="M847-325.233h4.334V-330H847Z" transform="translate(-847 330)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1097">
        <path id="Контур_3044" data-name="Контур 3044" d="M865.021-346.127a1.643,1.643,0,0,0-1.638,1.642,1.642,1.642,0,0,0,1.638,1.638,1.643,1.643,0,0,0,1.643-1.638A1.644,1.644,0,0,0,865.021-346.127Zm0,3.714a2.074,2.074,0,0,1-2.071-2.071,2.075,2.075,0,0,1,2.071-2.076,2.079,2.079,0,0,1,2.076,2.076,2.078,2.078,0,0,1-2.076,2.071Z" transform="translate(-862.95 346.56)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1099">
        <rect id="Прямоугольник_451" data-name="Прямоугольник 451" width="4.767" height="4.767" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1101">
        <path id="Контур_3041" data-name="Контур 3041" d="M862-342.233h4.767V-347H862Z" transform="translate(-862 347)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1102">
        <path id="Контур_3050" data-name="Контур 3050" d="M865.432-328.6a1.64,1.64,0,0,0-1.638,1.642,1.64,1.64,0,0,0,1.638,1.638,1.64,1.64,0,0,0,1.638-1.638A1.641,1.641,0,0,0,865.432-328.6Zm0,3.714a2.074,2.074,0,0,1-2.072-2.071,2.075,2.075,0,0,1,2.072-2.076,2.075,2.075,0,0,1,2.071,2.076,2.074,2.074,0,0,1-2.071,2.071Z" transform="translate(-863.36 329.03)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1106">
        <path id="Контур_3047" data-name="Контур 3047" d="M863-325.233h4.334V-330H863Z" transform="translate(-863 330)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1107">
        <path id="Контур_3056" data-name="Контур 3056" d="M952.724-1064.735H951.38v-7.271h3.259v1.169h-1.916v2.02h1.786v1.169h-1.786v2.914" transform="translate(-951.38 1072.006)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1109">
        <rect id="Прямоугольник_453" data-name="Прямоугольник 453" width="3.467" height="7.801" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1111">
        <path id="Контур_3053" data-name="Контур 3053" d="M951-1065.2h3.467v-7.8H951Z" transform="translate(-951 1073)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1112">
        <path id="Контур_3060" data-name="Контур 3060" d="M961.56-1064.735h1.274v-7.271H961.56Z" transform="translate(-961.56 1072.006)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1114">
        <path id="Контур_3066" data-name="Контур 3066" d="M969.4-1068.728h.347a.815.815,0,0,0,.689-.271,1.314,1.314,0,0,0,.208-.808,1.147,1.147,0,0,0-.221-.793.9.9,0,0,0-.685-.236H969.4Zm0,1.164v2.83H968.05v-7.271h1.768a2.268,2.268,0,0,1,1.66.532,2.213,2.213,0,0,1,.537,1.626,2.047,2.047,0,0,1-.932,1.925l1.4,3.188h-1.447l-1.148-2.83Z" transform="translate(-968.05 1072.006)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1116">
        <rect id="Прямоугольник_454" data-name="Прямоугольник 454" width="4.767" height="7.801" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1118">
        <path id="Контур_3063" data-name="Контур 3063" d="M968-1065.2h4.767v-7.8H968Z" transform="translate(-968 1073)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1119">
        <path id="Контур_3072" data-name="Контур 3072" d="M982.719-1066.876a2.186,2.186,0,0,1-.516,1.562,1.961,1.961,0,0,1-1.486.542,3.276,3.276,0,0,1-1.617-.373v-1.338a2.808,2.808,0,0,0,1.5.532.749.749,0,0,0,.581-.214.812.812,0,0,0,.2-.582,1.057,1.057,0,0,0-.234-.664,3.447,3.447,0,0,0-.771-.669,3.431,3.431,0,0,1-.711-.62,2.315,2.315,0,0,1-.373-.664,2.564,2.564,0,0,1-.13-.855,2.061,2.061,0,0,1,.52-1.482,1.832,1.832,0,0,1,1.4-.547,3.041,3.041,0,0,1,1.6.468l-.446,1.084a4.452,4.452,0,0,0-.5-.249,1.429,1.429,0,0,0-.559-.109.615.615,0,0,0-.507.216.91.91,0,0,0-.178.589,1.034,1.034,0,0,0,.212.642,3.213,3.213,0,0,0,.741.622,3.13,3.13,0,0,1,.992.975,2.277,2.277,0,0,1,.282,1.134" transform="translate(-979.1 1072.247)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1121">
        <rect id="Прямоугольник_455" data-name="Прямоугольник 455" width="3.9" height="8.234" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1123">
        <path id="Контур_3069" data-name="Контур 3069" d="M979-1064.766h3.9V-1073H979Z" transform="translate(-979 1073)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1124">
        <path id="Контур_3078" data-name="Контур 3078" d="M991.079-1064.735h-1.348v-6.083H988.34v-1.188h4.134v1.188h-1.4v6.083" transform="translate(-988.34 1072.006)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1126">
        <rect id="Прямоугольник_456" data-name="Прямоугольник 456" width="4.334" height="7.801" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1128">
        <path id="Контур_3075" data-name="Контур 3075" d="M988-1065.2h4.334v-7.8H988Z" transform="translate(-988 1073)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1129">
        <path id="Контур_3084" data-name="Контур 3084" d="M1006.813-1064.735h-1.343v-7.271h3.259v1.169h-1.916v2.02h1.785v1.169h-1.785v2.914" transform="translate(-1005.47 1072.006)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1133">
        <path id="Контур_3081" data-name="Контур 3081" d="M1005-1065.2h3.467v-7.8H1005Z" transform="translate(-1005 1073)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1134">
        <path id="Контур_3090" data-name="Контур 3090" d="M1015.09-1064.735v-7.271h1.352v6.088h1.876v1.184h-3.229" transform="translate(-1015.09 1072.006)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1138">
        <path id="Контур_3087" data-name="Контур 3087" d="M1015-1065.2h3.467v-7.8H1015Z" transform="translate(-1015 1073)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1139">
        <path id="Контур_3096" data-name="Контур 3096" d="M1025.4-1068.531a4.453,4.453,0,0,0,.316,1.9.99.99,0,0,0,.919.652q1.222,0,1.222-2.547t-1.214-2.546a.992.992,0,0,0-.927.649A4.448,4.448,0,0,0,1025.4-1068.531Zm3.844,0a4.824,4.824,0,0,1-.676,2.776,2.216,2.216,0,0,1-1.933.97,2.228,2.228,0,0,1-1.941-.967,4.839,4.839,0,0,1-.676-2.788,4.784,4.784,0,0,1,.676-2.768,2.237,2.237,0,0,1,1.95-.963,2.214,2.214,0,0,1,1.933.968,4.847,4.847,0,0,1,.667,2.773Z" transform="translate(-1024.02 1072.271)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1141">
        <rect id="Прямоугольник_459" data-name="Прямоугольник 459" width="5.634" height="8.234" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1143">
        <path id="Контур_3093" data-name="Контур 3093" d="M1024-1064.766h5.634V-1073H1024Z" transform="translate(-1024 1073)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1144">
        <path id="Контур_3102" data-name="Контур 3102" d="M1039.392-1068.531a4.453,4.453,0,0,0,.316,1.9.99.99,0,0,0,.919.652q1.222,0,1.222-2.547t-1.214-2.546a.992.992,0,0,0-.927.649A4.448,4.448,0,0,0,1039.392-1068.531Zm3.848,0a4.823,4.823,0,0,1-.68,2.776,2.211,2.211,0,0,1-1.933.97,2.229,2.229,0,0,1-1.942-.967,4.841,4.841,0,0,1-.676-2.788,4.786,4.786,0,0,1,.676-2.768,2.238,2.238,0,0,1,1.95-.963,2.215,2.215,0,0,1,1.933.968,4.845,4.845,0,0,1,.672,2.773Z" transform="translate(-1038.01 1072.271)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1148">
        <path id="Контур_3099" data-name="Контур 3099" d="M1038-1064.766h5.634V-1073H1038Z" transform="translate(-1038 1073)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1149">
        <path id="Контур_3108" data-name="Контур 3108" d="M1053.872-1068.728h.351a.811.811,0,0,0,.685-.271,1.314,1.314,0,0,0,.208-.808,1.149,1.149,0,0,0-.221-.793.9.9,0,0,0-.685-.236h-.338Zm0,1.164v2.83h-1.352v-7.271h1.772a2.262,2.262,0,0,1,1.655.532,2.194,2.194,0,0,1,.538,1.626,2.047,2.047,0,0,1-.932,1.925l1.4,3.188h-1.448l-1.148-2.83Z" transform="translate(-1052.52 1072.006)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1153">
        <path id="Контур_3105" data-name="Контур 3105" d="M1052-1065.2h4.767v-7.8H1052Z" transform="translate(-1052 1073)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1154">
        <path id="Контур_3114" data-name="Контур 3114" d="M69.754-1037.379H66.489v-17.68H74.41v2.842H69.754v4.91H74.1v2.842H69.754v7.086" transform="translate(-66.489 1055.059)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1156">
        <rect id="Прямоугольник_462" data-name="Прямоугольник 462" width="8.234" height="18.201" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1158">
        <path id="Контур_3111" data-name="Контур 3111" d="M66-1037.8h8.234V-1056H66Z" transform="translate(-66 1056)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1159">
        <path id="Контур_3120" data-name="Контур 3120" d="M89.873-1037.379v-17.68h3.289v14.8h4.559v2.878H89.873" transform="translate(-89.873 1055.059)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1163">
        <path id="Контур_3117" data-name="Контур 3117" d="M89-1037.8h8.234V-1056H89Z" transform="translate(-89 1056)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1164">
        <path id="Контур_3126" data-name="Контур 3126" d="M114.945-1046.606a10.968,10.968,0,0,0,.762,4.607,2.409,2.409,0,0,0,2.237,1.584q2.977,0,2.977-6.191t-2.951-6.191a2.425,2.425,0,0,0-2.263,1.578A10.961,10.961,0,0,0,114.945-1046.606Zm9.348,0a11.719,11.719,0,0,1-1.642,6.748,5.392,5.392,0,0,1-4.706,2.358,5.405,5.405,0,0,1-4.716-2.352q-1.645-2.352-1.645-6.778,0-4.39,1.645-6.73a5.44,5.44,0,0,1,4.742-2.34,5.361,5.361,0,0,1,4.689,2.352,11.756,11.756,0,0,1,1.634,6.741Z" transform="translate(-111.583 1055.7)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1166">
        <rect id="Прямоугольник_464" data-name="Прямоугольник 464" width="13.001" height="18.635" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1168">
        <path id="Контур_3123" data-name="Контур 3123" d="M111-1037.365h13V-1056H111Z" transform="translate(-111 1056)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1169">
        <path id="Контур_3132" data-name="Контур 3132" d="M148.963-1046.606a10.99,10.99,0,0,0,.758,4.607,2.416,2.416,0,0,0,2.24,1.584q2.971,0,2.973-6.191t-2.951-6.191a2.427,2.427,0,0,0-2.262,1.578A10.983,10.983,0,0,0,148.963-1046.606Zm9.348,0a11.717,11.717,0,0,1-1.647,6.748,5.39,5.39,0,0,1-4.7,2.358,5.409,5.409,0,0,1-4.719-2.352q-1.645-2.352-1.642-6.778,0-4.39,1.642-6.73a5.441,5.441,0,0,1,4.741-2.34,5.363,5.363,0,0,1,4.693,2.352,11.756,11.756,0,0,1,1.634,6.741Z" transform="translate(-145.6 1055.7)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1173">
        <path id="Контур_3129" data-name="Контур 3129" d="M145-1037.365h13V-1056H145Z" transform="translate(-145 1056)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1174">
        <path id="Контур_3138" data-name="Контур 3138" d="M184.159-1047.089H185a1.974,1.974,0,0,0,1.668-.659,3.164,3.164,0,0,0,.511-1.966,2.782,2.782,0,0,0-.542-1.929,2.166,2.166,0,0,0-1.66-.574h-.823Zm0,2.829v6.881H180.87v-17.68h4.3a5.517,5.517,0,0,1,4.035,1.294,5.366,5.366,0,0,1,1.3,3.955q0,3.35-2.262,4.68l3.4,7.751h-3.519l-2.791-6.881Z" transform="translate(-180.87 1055.059)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1176">
        <rect id="Прямоугольник_466" data-name="Прямоугольник 466" width="11.267" height="18.201" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1178">
        <path id="Контур_3135" data-name="Контур 3135" d="M180-1037.8h11.267V-1056H180Z" transform="translate(-180 1056)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1179">
        <path id="Контур_3144" data-name="Контур 3144" d="M226.849-1046.642h.628a2.291,2.291,0,0,0,1.8-.665,3.242,3.242,0,0,0,.594-2.177,3.233,3.233,0,0,0-.546-2.062,2.03,2.03,0,0,0-1.677-.671h-.8Zm6.349-2.975a6.056,6.056,0,0,1-1.421,4.323,5.369,5.369,0,0,1-4.1,1.505h-.823v6.409H223.56v-17.68h4.49q5.148,0,5.148,5.442Z" transform="translate(-223.56 1055.059)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1181">
        <rect id="Прямоугольник_467" data-name="Прямоугольник 467" width="9.967" height="18.201" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1183">
        <path id="Контур_3141" data-name="Контур 3141" d="M223-1037.8h9.967V-1056H223Z" transform="translate(-223 1056)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1184">
        <path id="Контур_3150" data-name="Контур 3150" d="M250.94-1037.379v-17.68h3.289v14.8h4.559v2.878H250.94" transform="translate(-250.94 1055.059)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1186">
        <rect id="Прямоугольник_468" data-name="Прямоугольник 468" width="8.667" height="18.201" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1188">
        <path id="Контур_3147" data-name="Контур 3147" d="M250-1037.8h8.667V-1056H250Z" transform="translate(-250 1056)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1189">
        <path id="Контур_3156" data-name="Контур 3156" d="M278.6-1044.85l-.945-4.112c-.056-.25-.182-.876-.381-1.88s-.316-1.675-.355-2.013q-.156,1.04-.4,2.291c-.165.835-.581,2.74-1.261,5.714Zm1.629,7.376-1.014-4.462h-4.607l-1.018,4.462H270.3l4.381-17.752h4.459l4.39,17.752Z" transform="translate(-270.3 1055.226)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1191">
        <rect id="Прямоугольник_469" data-name="Прямоугольник 469" width="13.434" height="18.201" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1193">
        <path id="Контур_3153" data-name="Контур 3153" d="M270-1037.8h13.434V-1056H270Z" transform="translate(-270 1056)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1194">
        <path id="Контур_3162" data-name="Контур 3162" d="M316.811-1037.379H312.8l-5.465-12.806h-.156a15.692,15.692,0,0,1,.3,3.144v9.662H304.43v-17.68h4.039l5.417,12.674h.134a17.37,17.37,0,0,1-.23-3.023v-9.651h3.021v17.68" transform="translate(-304.43 1055.059)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1196">
        <rect id="Прямоугольник_470" data-name="Прямоугольник 470" width="12.567" height="18.201" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1198">
        <path id="Контур_3159" data-name="Контур 3159" d="M304-1037.8h12.568V-1056H304Z" transform="translate(-304 1056)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1199">
        <path id="Контур_3168" data-name="Контур 3168" d="M935.384-676.4a2.191,2.191,0,0,1-.511,1.56,1.968,1.968,0,0,1-1.487.542,3.291,3.291,0,0,1-1.616-.373v-1.339a2.807,2.807,0,0,0,1.5.533.765.765,0,0,0,.585-.213.818.818,0,0,0,.2-.585,1.05,1.05,0,0,0-.234-.663,3.406,3.406,0,0,0-.771-.668,3.534,3.534,0,0,1-.711-.62,2.373,2.373,0,0,1-.373-.663,2.589,2.589,0,0,1-.13-.858,2.061,2.061,0,0,1,.52-1.482,1.834,1.834,0,0,1,1.4-.546,3.042,3.042,0,0,1,1.6.468l-.446,1.083a4.854,4.854,0,0,0-.5-.247,1.412,1.412,0,0,0-.559-.113.619.619,0,0,0-.507.217.919.919,0,0,0-.178.589,1.029,1.029,0,0,0,.212.641,3.164,3.164,0,0,0,.741.624,3.085,3.085,0,0,1,.992.975,2.271,2.271,0,0,1,.277,1.135" transform="translate(-931.77 681.77)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1203">
        <path id="Контур_3165" data-name="Контур 3165" d="M931-674.2h4.334V-682H931Z" transform="translate(-931 682)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1204">
        <path id="Контур_3174" data-name="Контур 3174" d="M945.456-674.258H942.18v-7.272h3.276v1.17h-1.924v1.729h1.785v1.174h-1.785v2.015h1.924v1.183" transform="translate(-942.18 681.53)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1208">
        <path id="Контур_3171" data-name="Контур 3171" d="M942-674.2h3.467V-682H942Z" transform="translate(-942 682)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1209">
        <path id="Контур_3180" data-name="Контур 3180" d="M954.3-680.587a1.021,1.021,0,0,0-.914.68,4.351,4.351,0,0,0-.338,1.9c0,1.681.442,2.526,1.317,2.526a2.432,2.432,0,0,0,1.209-.368v1.2a2.422,2.422,0,0,1-1.361.351,2.142,2.142,0,0,1-1.889-.979,4.885,4.885,0,0,1-.659-2.748,4.824,4.824,0,0,1,.68-2.76,2.163,2.163,0,0,1,1.885-.988,2.649,2.649,0,0,1,.767.108,3.087,3.087,0,0,1,.78.386l-.447,1.1a4.606,4.606,0,0,0-.477-.282,1.255,1.255,0,0,0-.555-.125" transform="translate(-951.67 681.77)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1213">
        <path id="Контур_3177" data-name="Контур 3177" d="M951-674.2h4.767V-682H951Z" transform="translate(-951 682)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1214">
        <path id="Контур_3186" data-name="Контур 3186" d="M963.582-678.05a4.522,4.522,0,0,0,.312,1.894.99.99,0,0,0,.919.65q1.228,0,1.226-2.544c0-1.7-.407-2.548-1.213-2.548a1,1,0,0,0-.932.65A4.532,4.532,0,0,0,963.582-678.05Zm3.844,0a4.82,4.82,0,0,1-.676,2.774,2.221,2.221,0,0,1-1.937.971,2.217,2.217,0,0,1-1.937-.966,4.853,4.853,0,0,1-.676-2.791,4.781,4.781,0,0,1,.676-2.765,2.237,2.237,0,0,1,1.95-.962,2.2,2.2,0,0,1,1.928.966,4.845,4.845,0,0,1,.672,2.773Z" transform="translate(-962.2 681.79)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1218">
        <path id="Контур_3183" data-name="Контур 3183" d="M962-674.2h5.634V-682H962Z" transform="translate(-962 682)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1219">
        <path id="Контур_3192" data-name="Контур 3192" d="M981.8-674.258h-1.651l-2.249-5.265h-.065a6.4,6.4,0,0,1,.126,1.291v3.974H976.7v-7.272h1.664l2.228,5.213h.056a6.865,6.865,0,0,1-.1-1.244v-3.97H981.8v7.272" transform="translate(-976.7 681.53)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1223">
        <path id="Контур_3189" data-name="Контур 3189" d="M976-674.2h5.634V-682H976Z" transform="translate(-976 682)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1224">
        <path id="Контур_3198" data-name="Контур 3198" d="M994.657-677.924q0-2.438-1.343-2.436h-.542v4.919h.438a1.172,1.172,0,0,0,1.092-.62A3.826,3.826,0,0,0,994.657-677.924Zm1.387-.039a4.431,4.431,0,0,1-.732,2.752,2.5,2.5,0,0,1-2.111.953H991.42v-7.272h1.946a2.329,2.329,0,0,1,1.972.936,4.294,4.294,0,0,1,.707,2.631Z" transform="translate(-991.42 681.53)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1226">
        <rect id="Прямоугольник_476" data-name="Прямоугольник 476" width="5.2" height="7.801" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1228">
        <path id="Контур_3195" data-name="Контур 3195" d="M991-674.2h5.2V-682H991Z" transform="translate(-991 682)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1229">
        <path id="Контур_3204" data-name="Контур 3204" d="M1011.683-674.258h-1.343v-7.272h3.259v1.17h-1.915v2.02h1.785v1.166h-1.785v2.917" transform="translate(-1010.34 681.53)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1233">
        <path id="Контур_3201" data-name="Контур 3201" d="M1010-674.2h3.467V-682H1010Z" transform="translate(-1010 682)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1234">
        <path id="Контур_3210" data-name="Контур 3210" d="M1019.96-674.258v-7.272h1.352v6.089h1.876v1.183h-3.229" transform="translate(-1019.96 681.53)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1236">
        <rect id="Прямоугольник_478" data-name="Прямоугольник 478" width="3.9" height="7.801" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1238">
        <path id="Контур_3207" data-name="Контур 3207" d="M1019-674.2h3.9V-682H1019Z" transform="translate(-1019 682)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1239">
        <path id="Контур_3216" data-name="Контур 3216" d="M1030.273-678.05a4.522,4.522,0,0,0,.312,1.894,1,1,0,0,0,.923.65q1.222,0,1.222-2.544t-1.213-2.548a1,1,0,0,0-.932.65A4.532,4.532,0,0,0,1030.273-678.05Zm3.844,0a4.822,4.822,0,0,1-.676,2.774,2.22,2.22,0,0,1-1.933.971,2.22,2.22,0,0,1-1.941-.966,4.853,4.853,0,0,1-.676-2.791,4.781,4.781,0,0,1,.676-2.765,2.238,2.238,0,0,1,1.95-.962,2.2,2.2,0,0,1,1.928.966,4.814,4.814,0,0,1,.672,2.773Z" transform="translate(-1028.89 681.79)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1243">
        <path id="Контур_3213" data-name="Контур 3213" d="M1028-674.2h5.634V-682H1028Z" transform="translate(-1028 682)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1244">
        <path id="Контур_3222" data-name="Контур 3222" d="M1044.262-678.05a4.522,4.522,0,0,0,.312,1.894,1,1,0,0,0,.923.65q1.222,0,1.222-2.544t-1.213-2.548a1,1,0,0,0-.932.65A4.531,4.531,0,0,0,1044.262-678.05Zm3.844,0a4.82,4.82,0,0,1-.676,2.774,2.218,2.218,0,0,1-1.933.971,2.22,2.22,0,0,1-1.942-.966,4.855,4.855,0,0,1-.676-2.791,4.782,4.782,0,0,1,.676-2.765,2.237,2.237,0,0,1,1.95-.962,2.2,2.2,0,0,1,1.928.966,4.812,4.812,0,0,1,.672,2.773Z" transform="translate(-1042.88 681.79)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1248">
        <path id="Контур_3219" data-name="Контур 3219" d="M1042-674.2h5.634V-682H1042Z" transform="translate(-1042 682)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1249">
        <path id="Контур_3228" data-name="Контур 3228" d="M1058.742-678.254h.346a.815.815,0,0,0,.689-.269,1.32,1.32,0,0,0,.208-.81,1.157,1.157,0,0,0-.221-.793.9.9,0,0,0-.685-.234h-.338Zm0,1.166v2.83h-1.352v-7.272h1.768a2.267,2.267,0,0,1,1.66.533,2.211,2.211,0,0,1,.537,1.625,2.045,2.045,0,0,1-.932,1.924l1.4,3.19h-1.447l-1.148-2.83Z" transform="translate(-1057.39 681.53)" fill="#c4d3e0" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1253">
        <path id="Контур_3225" data-name="Контур 3225" d="M1057-674.2h4.767V-682H1057Z" transform="translate(-1057 682)" fill="#c4d3e0"/>
      </clipPath>
      <clipPath id="clip-path-1254">
        <path id="Контур_3234" data-name="Контур 3234" d="M126.194-236.01s-.771,4.364-1.148,8.685a2.974,2.974,0,0,0,2.847,3.224c5.755.238,18.058.706,22.474.507a.965.965,0,0,0,.849-1.326,2.156,2.156,0,0,0-1.105-1.166l-20.329-9.343-3.588-.581" transform="translate(-125.034 236.01)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1256">
        <rect id="Прямоугольник_482" data-name="Прямоугольник 482" width="27.302" height="13.434" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1258">
        <path id="Контур_3231" data-name="Контур 3231" d="M124-223.566h27.3V-237H124Z" transform="translate(-124 237)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient" x1="0.09" y1="0.938" x2="0.09" y2="2.047" gradientUnits="objectBoundingBox">
        <stop offset="0"/>
        <stop offset="0" stop-color="#08005d"/>
        <stop offset="0.1" stop-color="#0a0162"/>
        <stop offset="0.2" stop-color="#0c0367"/>
        <stop offset="0.3" stop-color="#0d046d"/>
        <stop offset="0.4" stop-color="#0f0672"/>
        <stop offset="0.5" stop-color="#110877"/>
        <stop offset="0.6" stop-color="#13097c"/>
        <stop offset="0.7" stop-color="#150a81"/>
        <stop offset="0.8" stop-color="#160c87"/>
        <stop offset="0.9" stop-color="#180d8c"/>
        <stop offset="1" stop-color="#1a0f91"/>
        <stop offset="1"/>
      </linearGradient>
      <linearGradient id="linear-gradient-2" x1="0.131" y1="0.923" x2="0.007" y2="1.125" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#08005d"/>
        <stop offset="0.1" stop-color="#0a0162"/>
        <stop offset="0.2" stop-color="#0c0367"/>
        <stop offset="0.3" stop-color="#0d046d"/>
        <stop offset="0.4" stop-color="#0f0672"/>
        <stop offset="0.5" stop-color="#110877"/>
        <stop offset="0.6" stop-color="#13097c"/>
        <stop offset="0.7" stop-color="#150a81"/>
        <stop offset="0.8" stop-color="#160c87"/>
        <stop offset="0.9" stop-color="#180d8c"/>
        <stop offset="1" stop-color="#1a0f91"/>
      </linearGradient>
      <clipPath id="clip-path-1259">
        <path id="Контур_3240" data-name="Контур 3240" d="M126.25-242.974l.62,5.218s3.562,1.66,5.993-.208l-1.473-5.816-5.14.806" transform="translate(-126.25 243.78)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1261">
        <rect id="Прямоугольник_483" data-name="Прямоугольник 483" width="6.934" height="7.801" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1263">
        <path id="Контур_3237" data-name="Контур 3237" d="M126-236.2h6.934V-244H126Z" transform="translate(-126 244)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-3" x1="0.091" y1="0.93" x2="0.091" y2="2.038" gradientUnits="objectBoundingBox">
        <stop offset="0"/>
        <stop offset="0" stop-color="#ff928e"/>
        <stop offset="0.1" stop-color="#ff9591"/>
        <stop offset="0.2" stop-color="#ff9995"/>
        <stop offset="0.3" stop-color="#fe9c98"/>
        <stop offset="0.4" stop-color="#fe9f9c"/>
        <stop offset="0.5" stop-color="#fea39f"/>
        <stop offset="0.6" stop-color="#fea6a2"/>
        <stop offset="0.7" stop-color="#fea9a6"/>
        <stop offset="0.8" stop-color="#fdaca9"/>
        <stop offset="0.9" stop-color="#fdb0ad"/>
        <stop offset="1" stop-color="#fdb3b0"/>
        <stop offset="1"/>
      </linearGradient>
      <linearGradient id="linear-gradient-4" x1="0.091" y1="0.938" x2="0.102" y2="0.938" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#ff928e"/>
        <stop offset="0.1" stop-color="#ff9591"/>
        <stop offset="0.2" stop-color="#ff9995"/>
        <stop offset="0.3" stop-color="#fe9c98"/>
        <stop offset="0.4" stop-color="#fe9f9c"/>
        <stop offset="0.5" stop-color="#fea39f"/>
        <stop offset="0.6" stop-color="#fea6a2"/>
        <stop offset="0.7" stop-color="#fea9a6"/>
        <stop offset="0.8" stop-color="#fdaca9"/>
        <stop offset="0.9" stop-color="#fdb0ad"/>
        <stop offset="1" stop-color="#fdb3b0"/>
      </linearGradient>
      <clipPath id="clip-path-1264">
        <path id="Контур_3246" data-name="Контур 3246" d="M106.223-468.366c-.269,1.361-1.434,68.02,5.69,97.892a1.012,1.012,0,0,1-.6,1.179,17.673,17.673,0,0,1-5.8,1.18,1.749,1.749,0,0,1-1.73-1.253c-2.037-6.934-11.8-41.655-13.069-74.829-1.423-37.065,0-1.261,0-1.261l.834-22.912,14.676,0" transform="translate(-90.08 468.37)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1266">
        <rect id="Прямоугольник_484" data-name="Прямоугольник 484" width="22.968" height="113.974" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1268">
        <path id="Контур_3243" data-name="Контур 3243" d="M88-370.86h22.968V-474H88Z" transform="translate(-88 474)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-5" x1="0.065" y1="0.687" x2="0.065" y2="1.795" xlink:href="#linear-gradient"/>
      <linearGradient id="linear-gradient-6" x1="0.065" y1="0.812" x2="0.101" y2="0.812" xlink:href="#linear-gradient-2"/>
      <clipPath id="clip-path-1269">
        <path id="Контур_3252" data-name="Контур 3252" d="M70.554-230.75s-1.227,2.2-2.415,8.056a1.817,1.817,0,0,0,1.6,2.175c1.9.186,4.824.425,7.166.407A1.807,1.807,0,0,0,78.7-222.13,15.26,15.26,0,0,0,75.13-230.5l-4.576-.247" transform="translate(-68.102 230.75)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1271">
        <rect id="Прямоугольник_485" data-name="Прямоугольник 485" width="11.267" height="10.834" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1273">
        <path id="Контур_3249" data-name="Контур 3249" d="M67-220.166H78.267V-231H67Z" transform="translate(-67 231)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-7" x1="0.049" y1="0.944" x2="0.049" y2="2.052" xlink:href="#linear-gradient"/>
      <linearGradient id="linear-gradient-8" x1="0.103" y1="0.885" x2="-0.02" y2="1.087" xlink:href="#linear-gradient-2"/>
      <clipPath id="clip-path-1274">
        <path id="Контур_3258" data-name="Контур 3258" d="M71.273-246.647l1.447,7.9s1.431,1.062,4.261.29l-.691-8.49-5.017.3" transform="translate(-71.273 246.95)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1276">
        <rect id="Прямоугольник_486" data-name="Прямоугольник 486" width="6.067" height="9.534" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1278">
        <path id="Контур_3255" data-name="Контур 3255" d="M71-237.466h6.067V-247H71Z" transform="translate(-71 247)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-9" x1="0.051" y1="0.926" x2="0.051" y2="2.035" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-10" x1="0.051" y1="0.937" x2="0.061" y2="0.937" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1279">
        <path id="Контур_3264" data-name="Контур 3264" d="M59.319-468.468c-5.827,35.167,1.264,86.924,3.656,97.051a1.581,1.581,0,0,0,1.275,1.2,10.605,10.605,0,0,0,4.558-.182,1.447,1.447,0,0,0,1.041-1.335c.321-8.191-.257-58.465,5.173-78.334a4.507,4.507,0,0,1,1.741-2.487L87.3-460.048l.81-12.112-28.792,3.692" transform="translate(-57.075 472.16)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1281">
        <rect id="Прямоугольник_487" data-name="Прямоугольник 487" width="35.102" height="102.707" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1283">
        <path id="Контур_3261" data-name="Контур 3261" d="M52-370.293H85.369V-473H52Z" transform="translate(-52 473)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-11" x1="0.041" y1="0.683" x2="0.041" y2="1.791" xlink:href="#linear-gradient"/>
      <linearGradient id="linear-gradient-12" x1="0.041" y1="0.81" x2="0.093" y2="0.81" xlink:href="#linear-gradient-2"/>
      <clipPath id="clip-path-1284">
        <path id="Контур_3270" data-name="Контур 3270" d="M96.541-621.149c-17.465-.286-36.734,4.589-36.734,4.589-5.206,30.374-.91,65.407-.91,65.407,13.52,3.545,28.773-2.167,28.773-2.167,2.245-12.7,12.728-48.428,12.728-48.428a57.513,57.513,0,0,0,21.352-6.773c-4.416-5.118-6.5-11.632-7.4-15.48a58.6,58.6,0,0,1-17.807,2.851" transform="translate(-57.231 624)" fill="#7e8299" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1285">
        <path id="Контур_3269" data-name="Контур 3269" d="M-876-1031.954H196.57V-1747H-876Z" transform="translate(876 1747)" fill="#7e8299"/>
      </clipPath>
      <clipPath id="clip-path-1286">
        <rect id="Прямоугольник_488" data-name="Прямоугольник 488" width="67.604" height="76.705" fill="#7e8299"/>
      </clipPath>
      <clipPath id="clip-path-1288">
        <path id="Контур_3267" data-name="Контур 3267" d="M52-547.728h67.171V-624H52Z" transform="translate(-52 624)" fill="#7e8299"/>
      </clipPath>
      <clipPath id="clip-path-1289">
        <path id="Контур_3276" data-name="Контур 3276" d="M222.806-678.5a3.336,3.336,0,0,0-3.887-1.092c-2.57,10.856-16.043,19.657-29.889,23.96.906,3.848,2.986,10.362,7.4,15.48,22.977-12.918,26.374-38.348,26.374-38.348" transform="translate(-189.03 679.828)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1291">
        <rect id="Прямоугольник_489" data-name="Прямоугольник 489" width="33.802" height="40.736" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1293">
        <path id="Контур_3273" data-name="Контур 3273" d="M189-641.264h33.8V-682H189Z" transform="translate(-189 682)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-13" x1="0.136" y1="0.458" x2="0.136" y2="1.566" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-14" x1="0.206" y1="0.412" x2="0.132" y2="0.571" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1294">
        <path id="Контур_3282" data-name="Контур 3282" d="M261.475-699.948c.182.572-1.9,1.439-3.125-.893-.039-1.118-.719-5.911-.052-6.821,3.558-4.854,10.461-10.938,10.461-10.938a1.64,1.64,0,0,1,.2,2.279l-3.346,3.489a16.428,16.428,0,0,1,1.954,3.866,4.7,4.7,0,0,1-.468,3.454c-.589,1.057-1.391,2.436-2.149,3.51a4.622,4.622,0,0,1-3.48,2.054" transform="translate(-258.006 718.6)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1296">
        <rect id="Прямоугольник_490" data-name="Прямоугольник 490" width="12.567" height="20.368" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1298">
        <path id="Контур_3279" data-name="Контур 3279" d="M257-698.632h12.568V-719H257Z" transform="translate(-257 719)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-15" x1="0.186" y1="0.416" x2="0.186" y2="1.524" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-16" x1="0.208" y1="0.415" x2="0.134" y2="0.574" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1299">
        <path id="Контур_3288" data-name="Контур 3288" d="M266.949-700.07l-3.051,2.587-.958,2.574,2.717,3.021s.919-5.109,1.595-6.214.329-1.642-.3-1.968" transform="translate(-262.94 700.07)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1301">
        <rect id="Прямоугольник_491" data-name="Прямоугольник 491" width="5.634" height="8.667" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1303">
        <path id="Контур_3285" data-name="Контур 3285" d="M262-692.333h5.634V-701H262Z" transform="translate(-262 701)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-17" x1="0.189" y1="0.436" x2="0.189" y2="1.544" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-18" x1="0.178" y1="0.364" x2="0.204" y2="0.5" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1304">
        <path id="Контур_3294" data-name="Контур 3294" d="M45.036-589.92l-6.7-16.91S22.026-595.749,19.343-581.7a38.534,38.534,0,0,1,14.18,3.8c1.206-6.635,11.513-12.026,11.513-12.026" transform="translate(-19.343 606.83)" fill="#7e8299" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1306">
        <rect id="Прямоугольник_492" data-name="Прямоугольник 492" width="26.002" height="29.035" fill="#7e8299"/>
      </clipPath>
      <clipPath id="clip-path-1308">
        <path id="Контур_3291" data-name="Контур 3291" d="M19-577.965H45V-607H19Z" transform="translate(-19 607)" fill="#7e8299"/>
      </clipPath>
      <clipPath id="clip-path-1309">
        <path id="Контур_3300" data-name="Контур 3300" d="M58.8-541.147s-18.314,10.353-24.494,1.513a6.94,6.94,0,0,1-1.274-5.4,38.536,38.536,0,0,0-14.18-3.8,19.4,19.4,0,0,0,2.108,13.3c10.825,19.8,40.264-1.1,40.264-1.1L58.8-541.147" transform="translate(-18.485 548.83)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1311">
        <rect id="Прямоугольник_493" data-name="Прямоугольник 493" width="43.77" height="33.369" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1313">
        <path id="Контур_3297" data-name="Контур 3297" d="M17-524.732H60.77V-549H17Z" transform="translate(-17 549)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-19" x1="0.013" y1="0.6" x2="0.013" y2="1.708" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-20" x1="0.013" y1="0.627" x2="0.084" y2="0.627" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1314">
        <path id="Контур_3306" data-name="Контур 3306" d="M106.4-550.313l9.994-34.907a1.018,1.018,0,0,1,.862-.732c1.811-.212,7.059-.806,13.118-1.317,7.3-.615,15.774-1.118,20.836-.689a1.02,1.02,0,0,1,.836,1.469c-2.882,5.824-14.726,29.525-20.658,37.291a1.031,1.031,0,0,1-.823.4l-23.193-.217a1.018,1.018,0,0,1-.972-1.3" transform="translate(-106.355 588.119)" fill="#464e5f" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1315">
        <path id="Контур_3305" data-name="Контур 3305" d="M-876-1031.954H196.57V-1747H-876Z" transform="translate(876 1747)" fill="#464e5f"/>
      </clipPath>
      <clipPath id="clip-path-1316">
        <rect id="Прямоугольник_494" data-name="Прямоугольник 494" width="46.37" height="39.869" fill="#464e5f"/>
      </clipPath>
      <clipPath id="clip-path-1318">
        <path id="Контур_3303" data-name="Контур 3303" d="M106-549.131h46.37V-589H106Z" transform="translate(-106 589)" fill="#464e5f"/>
      </clipPath>
      <clipPath id="clip-path-1319">
        <path id="Контур_3312" data-name="Контур 3312" d="M163.009-594.731l7.246-1.209a.521.521,0,0,1,.581.676l-.719,2.176a.517.517,0,0,1-.425.351l-6.973.932a.52.52,0,0,1-.576-.633l.446-1.9a.519.519,0,0,1,.42-.394" transform="translate(-162.128 595.947)" fill="#b5b5c3" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1320">
        <path id="Контур_3311" data-name="Контур 3311" d="M-876-1031.954H196.57V-1747H-876Z" transform="translate(876 1747)" fill="#b5b5c3"/>
      </clipPath>
      <clipPath id="clip-path-1321">
        <rect id="Прямоугольник_495" data-name="Прямоугольник 495" width="9.534" height="4.767" fill="#b5b5c3"/>
      </clipPath>
      <clipPath id="clip-path-1323">
        <path id="Контур_3309" data-name="Контур 3309" d="M161-592.233h9.534V-597H161Z" transform="translate(-161 597)" fill="#b5b5c3"/>
      </clipPath>
      <clipPath id="clip-path-1324">
        <path id="Контур_3318" data-name="Контур 3318" d="M151.623-585.76l.6-3.107a.718.718,0,0,1,.633-.581l14.635-1.486a.719.719,0,0,1,.771.884l-.927,3.874a.719.719,0,0,1-.663.55l-14.305.719a.718.718,0,0,1-.741-.854" transform="translate(-151.61 590.938)" fill="#313846" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1325">
        <path id="Контур_3317" data-name="Контур 3317" d="M-876-1031.954H196.57V-1747H-876Z" transform="translate(876 1747)" fill="#313846"/>
      </clipPath>
      <clipPath id="clip-path-1326">
        <rect id="Прямоугольник_496" data-name="Прямоугольник 496" width="17.334" height="6.934" fill="#313846"/>
      </clipPath>
      <clipPath id="clip-path-1328">
        <path id="Контур_3315" data-name="Контур 3315" d="M151-585.066h17.334V-592H151Z" transform="translate(-151 592)" fill="#313846"/>
      </clipPath>
      <clipPath id="clip-path-1329">
        <path id="Контур_3324" data-name="Контур 3324" d="M110.759-535.9s-1.392-5.725-.831-7.757,1.65-4.641,5.277-4.061a52.618,52.618,0,0,1,7.9,2.106,2.077,2.077,0,0,1-2.973,1.794l1.712,1.322s-.247,2.119-2.574.984l1.59,1.755a1.852,1.852,0,0,1-2.713.624c-1.712-1.114-2.535,3.922-7.393,3.233" transform="translate(-109.795 547.8)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1331">
        <rect id="Прямоугольник_497" data-name="Прямоугольник 497" width="14.301" height="13.434" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1333">
        <path id="Контур_3321" data-name="Контур 3321" d="M108-535.566h14.3V-549H108Z" transform="translate(-108 549)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-21" x1="0.079" y1="0.601" x2="0.079" y2="1.709" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-22" x1="0.122" y1="0.541" x2="0.105" y2="0.578" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1334">
        <path id="Контур_3330" data-name="Контур 3330" d="M99.811-650.13l-5.557,16.069a.582.582,0,0,0,.264.7c1.515.836,6.972,3.259,13.549-.91a2.738,2.738,0,0,0,1.257-1.95l1.8-13.906H99.811" transform="translate(-94.222 650.13)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1336">
        <rect id="Прямоугольник_498" data-name="Прямоугольник 498" width="17.334" height="20.801" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1338">
        <path id="Контур_3327" data-name="Контур 3327" d="M94-630.2h17.334V-651H94Z" transform="translate(-94 651)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-23" x1="0.068" y1="0.49" x2="0.068" y2="1.598" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-24" x1="0.085" y1="0.478" x2="0.075" y2="0.583" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1339">
        <path id="Контур_3336" data-name="Контур 3336" d="M110.25-677.8c.182.178,7.58,15.3,7.58,15.3l-8.725,4.039-9.14-13.291s7.229-9.023,10.286-6.05" transform="translate(-99.964 678.397)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1341">
        <rect id="Прямоугольник_499" data-name="Прямоугольник 499" width="18.635" height="22.535" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1343">
        <path id="Контур_3333" data-name="Контур 3333" d="M99-661.465h18.635V-684H99Z" transform="translate(-99 684)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-25" x1="0.072" y1="0.46" x2="0.072" y2="1.568" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-26" x1="0.11" y1="0.428" x2="0.102" y2="0.449" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1344">
        <path id="Контур_3342" data-name="Контур 3342" d="M97.238-679.456a1.38,1.38,0,0,0,.084,1.213l4.2,7.268a1.257,1.257,0,0,0,.232.3c.506.485,2.242,1.907,3.633.035,1.183-1.595,2.3-3.047,3.471-3.636a1.38,1.38,0,0,1,1.85.646,51.129,51.129,0,0,1,2.843,7.055,1.368,1.368,0,0,1-.828,1.673,38.229,38.229,0,0,1-8.329,2.288,1.385,1.385,0,0,1-1.3-.576c-1.294-1.833-4.94-6.96-6.192-8.247-1.163-1.2-3.352-.789-4.34-.52a1.386,1.386,0,0,1-1.1-.165A3.04,3.04,0,0,1,90.4-676.77a1.383,1.383,0,0,0-.4-1.885,3.058,3.058,0,0,1-1.1-4.355,4.313,4.313,0,0,1,4.7-2.206,1.436,1.436,0,0,0,1.518-.815.914.914,0,0,1,.722-.546,1.345,1.345,0,0,0,1.11-1.56,3.532,3.532,0,0,1,2.04-4.017c3.769-2,6.986,1.4,7.775,4.243s-3.424,4.767-6.056,5.057c-1.934.212-3.026,2.3-3.469,3.4" transform="translate(-88.403 692.753)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1346">
        <rect id="Прямоугольник_500" data-name="Прямоугольник 500" width="26.435" height="31.635" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1348">
        <path id="Контур_3339" data-name="Контур 3339" d="M86-664.365h26.435V-696H86Z" transform="translate(-86 696)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-27" x1="0.064" y1="0.444" x2="0.064" y2="1.552" xlink:href="#linear-gradient"/>
      <linearGradient id="linear-gradient-28" x1="0.075" y1="0.505" x2="0.102" y2="0.464" xlink:href="#linear-gradient-2"/>
      <clipPath id="clip-path-1349">
        <path id="Контур_3348" data-name="Контур 3348" d="M103.89-643.83s-1.992-4.672-4.121-4.524-3.837,2.236-1.24,4.659c3.23,3.012,5.361-.135,5.361-.135" transform="translate(-97.217 648.358)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1351">
        <rect id="Прямоугольник_501" data-name="Прямоугольник 501" width="8.234" height="8.234" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1353">
        <path id="Контур_3345" data-name="Контур 3345" d="M94-640.766h8.234V-649H94Z" transform="translate(-94 649)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-29" x1="0.07" y1="0.492" x2="0.07" y2="1.6" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-30" x1="0.076" y1="0.553" x2="0.075" y2="0.498" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1354">
        <path id="Контур_3354" data-name="Контур 3354" d="M984.438-681.3c-.182.581,1.929,1.465,3.172-.91.048-1.135.737-6.007.056-6.934-3.619-4.936-10.635-11.12-10.635-11.12a1.666,1.666,0,0,0-.208,2.318l3.4,3.545a16.559,16.559,0,0,0-1.985,3.931,4.756,4.756,0,0,0,.472,3.51,41.355,41.355,0,0,0,2.184,3.571,4.721,4.721,0,0,0,3.541,2.089" transform="translate(-976.444 700.26)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1356">
        <rect id="Прямоугольник_502" data-name="Прямоугольник 502" width="12.567" height="20.801" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1358">
        <path id="Контур_3351" data-name="Контур 3351" d="M975-680.2h12.568V-701H975Z" transform="translate(-975 701)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-31" x1="0.702" y1="0.436" x2="0.702" y2="1.544" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-32" x1="0.685" y1="0.411" x2="0.735" y2="0.51" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1359">
        <path id="Контур_3360" data-name="Контур 3360" d="M987.713-681.42l3.1,2.63.971,2.617-2.765,3.068s-.927-5.192-1.621-6.318-.334-1.668.312-2" transform="translate(-987.026 681.42)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1363">
        <path id="Контур_3357" data-name="Контур 3357" d="M986-673.333h5.634V-682H986Z" transform="translate(-986 682)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-33" x1="0.71" y1="0.456" x2="0.71" y2="1.564" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-34" x1="0.739" y1="0.402" x2="0.727" y2="0.431" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1364">
        <path id="Контур_3366" data-name="Контур 3366" d="M1102.139-209.61s.78,4.438,1.166,8.828a3.029,3.029,0,0,1-2.9,3.281c-5.846.243-18.357.715-22.847.516a.982.982,0,0,1-.867-1.348,2.176,2.176,0,0,1,1.126-1.187l20.667-9.5,3.649-.589" transform="translate(-1076.625 209.61)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1366">
        <rect id="Прямоугольник_504" data-name="Прямоугольник 504" width="27.302" height="13.001" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1368">
        <path id="Контур_3363" data-name="Контур 3363" d="M1076-197h27.3v-13H1076Z" transform="translate(-1076 210)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-35" x1="0.775" y1="0.967" x2="0.775" y2="2.075" xlink:href="#linear-gradient"/>
      <linearGradient id="linear-gradient-36" x1="0.777" y1="0.952" x2="0.903" y2="1.157" xlink:href="#linear-gradient-2"/>
      <clipPath id="clip-path-1369">
        <path id="Контур_3372" data-name="Контур 3372" d="M1128.191-216.7l-.633,5.309s-3.619,1.686-6.089-.212l1.5-5.915,5.222.819" transform="translate(-1121.47 217.52)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1371">
        <rect id="Прямоугольник_505" data-name="Прямоугольник 505" width="6.934" height="8.234" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1373">
        <path id="Контур_3369" data-name="Контур 3369" d="M1121-209.766h6.934V-218H1121Z" transform="translate(-1121 218)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-37" x1="0.807" y1="0.958" x2="0.807" y2="2.067" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-38" x1="0.818" y1="0.967" x2="0.807" y2="0.967" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1374">
        <path id="Контур_3378" data-name="Контур 3378" d="M1128.288-445.85c.269,1.387,1.456,69.156-5.785,99.53a1.026,1.026,0,0,0,.6,1.2,17.924,17.924,0,0,0,5.9,1.2,1.78,1.78,0,0,0,1.759-1.275c2.071-7.046,12-42.352,13.287-76.077,1.448-37.685,0-1.283,0-1.283l-.845-23.293h-14.921" transform="translate(-1122.474 445.85)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1376">
        <rect id="Прямоугольник_506" data-name="Прямоугольник 506" width="23.402" height="115.708" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1378">
        <path id="Контур_3375" data-name="Контур 3375" d="M1122-346.56h23.4V-451H1122Z" transform="translate(-1122 451)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-39" x1="0.808" y1="0.711" x2="0.808" y2="1.819" xlink:href="#linear-gradient"/>
      <linearGradient id="linear-gradient-40" x1="0.767" y1="0.719" x2="0.874" y2="0.937" xlink:href="#linear-gradient-2"/>
      <clipPath id="clip-path-1379">
        <path id="Контур_3384" data-name="Контур 3384" d="M1179.509-204.27s1.248,2.24,2.453,8.191a1.839,1.839,0,0,1-1.621,2.21c-1.933.191-4.906.438-7.289.42a1.841,1.841,0,0,1-1.82-2.054,15.526,15.526,0,0,1,3.623-8.516l4.654-.251" transform="translate(-1171.218 204.27)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1381">
        <rect id="Прямоугольник_507" data-name="Прямоугольник 507" width="11.701" height="11.267" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1383">
        <path id="Контур_3381" data-name="Контур 3381" d="M1170-193.733h11.7V-205H1170Z" transform="translate(-1170 205)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-41" x1="0.843" y1="0.973" x2="0.843" y2="2.081" xlink:href="#linear-gradient"/>
      <linearGradient id="linear-gradient-42" x1="0.805" y1="0.913" x2="0.931" y2="1.118" xlink:href="#linear-gradient-2"/>
      <clipPath id="clip-path-1384">
        <path id="Контур_3390" data-name="Контур 3390" d="M1185.293-220.428l-1.473,8.026s-1.452,1.083-4.329.3l.7-8.637,5.1.312" transform="translate(-1179.49 220.74)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1388">
        <path id="Контур_3387" data-name="Контур 3387" d="M1179-211.466h6.067V-221H1179Z" transform="translate(-1179 221)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-43" x1="0.849" y1="0.955" x2="0.849" y2="2.063" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-44" x1="0.858" y1="0.966" x2="0.849" y2="0.966" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1389">
        <path id="Контур_3396" data-name="Контур 3396" d="M1163.774-445.951c5.924,35.757-1.287,88.375-3.718,98.672a1.609,1.609,0,0,1-1.3,1.222,10.822,10.822,0,0,1-4.633-.186,1.475,1.475,0,0,1-1.062-1.356c-.325-8.325.264-59.44-5.257-79.639a4.6,4.6,0,0,0-1.772-2.531l-10.713-7.619L1134.5-449.7l29.274,3.749" transform="translate(-1134.5 449.7)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1391">
        <rect id="Прямоугольник_509" data-name="Прямоугольник 509" width="35.536" height="104.44" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1393">
        <path id="Контур_3393" data-name="Контур 3393" d="M1134-345.56h34.236V-450H1134Z" transform="translate(-1134 450)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-45" x1="0.816" y1="0.707" x2="0.816" y2="1.815" xlink:href="#linear-gradient"/>
      <linearGradient id="linear-gradient-46" x1="0.786" y1="0.698" x2="0.893" y2="0.916" xlink:href="#linear-gradient-2"/>
      <clipPath id="clip-path-1394">
        <path id="Контур_3402" data-name="Контур 3402" d="M1081.419-601.181c17.755-.29,37.347,4.663,37.347,4.663,5.291,30.881.927,66.5.927,66.5-13.746,3.6-29.252-2.206-29.252-2.206-2.288-12.906-12.945-49.234-12.945-49.234a58.5,58.5,0,0,1-21.707-6.891c4.49-5.2,6.6-11.822,7.523-15.735a59.594,59.594,0,0,0,18.106,2.9" transform="translate(-1055.79 604.08)" fill="#b5b5c3" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1396">
        <rect id="Прямоугольник_510" data-name="Прямоугольник 510" width="68.904" height="78.438" fill="#b5b5c3"/>
      </clipPath>
      <clipPath id="clip-path-1398">
        <path id="Контур_3399" data-name="Контур 3399" d="M1055-526.995h68.471V-605H1055Z" transform="translate(-1055 605)" fill="#b5b5c3"/>
      </clipPath>
      <clipPath id="clip-path-1399">
        <path id="Контур_3408" data-name="Контур 3408" d="M993.91-660.288a4.744,4.744,0,0,1,3.736-1.946c2.613,11.038,16.529,20.823,30.6,25.2-.919,3.913-3.033,10.535-7.523,15.735-23.358-13.131-26.817-38.99-26.817-38.99" transform="translate(-993.91 662.237)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1401">
        <rect id="Прямоугольник_511" data-name="Прямоугольник 511" width="35.102" height="41.603" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1403">
        <path id="Контур_3405" data-name="Контур 3405" d="M993-621.4h35.1V-663H993Z" transform="translate(-993 663)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-47" x1="0.715" y1="0.477" x2="0.715" y2="1.585" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-48" x1="0.686" y1="0.41" x2="0.736" y2="0.509" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1404">
        <path id="Контур_3414" data-name="Контур 3414" d="M1185.4-569.439l6.808-17.191s16.585,11.267,19.311,25.555a39.164,39.164,0,0,0-14.414,3.866c-1.227-6.747-11.705-12.229-11.705-12.229" transform="translate(-1185.4 586.63)" fill="#b5b5c3" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1406">
        <rect id="Прямоугольник_512" data-name="Прямоугольник 512" width="26.435" height="29.902" fill="#b5b5c3"/>
      </clipPath>
      <clipPath id="clip-path-1408">
        <path id="Контур_3411" data-name="Контур 3411" d="M1185-557.1h26.435V-587H1185Z" transform="translate(-1185 587)" fill="#b5b5c3"/>
      </clipPath>
      <clipPath id="clip-path-1409">
        <path id="Контур_3420" data-name="Контур 3420" d="M1141.4-630.65l5.651,16.338a.587.587,0,0,1-.269.711c-1.538.849-7.09,3.311-13.772-.927a2.766,2.766,0,0,1-1.278-1.981L1129.9-630.65h11.5" transform="translate(-1129.9 630.65)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1411">
        <rect id="Прямоугольник_513" data-name="Прямоугольник 513" width="17.768" height="20.801" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1413">
        <path id="Контур_3417" data-name="Контур 3417" d="M1129-610.2h17.768V-631H1129Z" transform="translate(-1129 631)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-49" x1="0.813" y1="0.511" x2="0.813" y2="1.619" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-50" x1="0.815" y1="0.493" x2="0.846" y2="0.624" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1414">
        <path id="Контур_3426" data-name="Контур 3426" d="M1120.906-665.56s-6.206,11.679-5.408,16.394,8.481,4.381,15.2.546l4.689-12.607-14.479-4.334" transform="translate(-1115.427 665.56)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1416">
        <rect id="Прямоугольник_514" data-name="Прямоугольник 514" width="21.235" height="21.668" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1418">
        <path id="Контур_3423" data-name="Контур 3423" d="M1113-644.332h21.235V-666H1113Z" transform="translate(-1113 666)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-51" x1="0.802" y1="0.473" x2="0.802" y2="1.582" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-52" x1="0.869" y1="0.429" x2="0.829" y2="0.482" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1419">
        <path id="Контур_3432" data-name="Контур 3432" d="M1132.775-666.531a.482.482,0,0,1-.208-.706,12.687,12.687,0,0,0,1.725-3.666s1.114-.711-2.838-1.66c-4.711-1.127-11.233-2.479-11.814-8.412-.147-1.495.785-3.207,2.28-3.35,1.521-.147,2.891,1.309,4.4,1.057a5.777,5.777,0,0,0,2.357-1.5,7.141,7.141,0,0,1,6.349-1.2c1.747.524,3.506,1.257,5.231,1.829a4.613,4.613,0,0,1,3.229,2.917c.386,1.642.021,3.029.984,4.633.364.611.814,1.166,1.187,1.773a8.064,8.064,0,0,1,.7,6.834,10.718,10.718,0,0,1-5.542,5.686,24.33,24.33,0,0,1-5.23,1.746c-.421.1-3.827,1.252-3.433.108l1.76-5.109a.481.481,0,0,0-.264-.6l-.871-.386" transform="translate(-1119.625 686.247)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1421">
        <rect id="Прямоугольник_515" data-name="Прямоугольник 515" width="28.168" height="27.735" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1423">
        <path id="Контур_3429" data-name="Контур 3429" d="M1119-660.265h28.169V-688H1119Z" transform="translate(-1119 688)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-53" x1="0.805" y1="0.451" x2="0.805" y2="1.559" xlink:href="#linear-gradient"/>
      <linearGradient id="linear-gradient-54" x1="0.858" y1="0.444" x2="0.814" y2="0.497" xlink:href="#linear-gradient-2"/>
      <clipPath id="clip-path-1424">
        <path id="Контур_3438" data-name="Контур 3438" d="M1146.55-641.041s4.416-3.029,4.741-.477-3.978,3.7-6.11,2.743l1.369-2.267" transform="translate(-1145.18 642.54)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1426">
        <rect id="Прямоугольник_516" data-name="Прямоугольник 516" width="6.934" height="6.934" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1428">
        <path id="Контур_3435" data-name="Контур 3435" d="M1145-640.066h6.934V-647H1145Z" transform="translate(-1145 647)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-55" x1="0.824" y1="0.498" x2="0.824" y2="1.607" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-56" x1="0.877" y1="0.442" x2="0.836" y2="0.495" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1429">
        <path id="Контур_3444" data-name="Контур 3444" d="M1212.41-524.043s5.794,26.149.546,40.853c6.436,2.982,5.248,2.422,5.248,2.422s12.732-37.208,8.62-47.141c-8.1-.858-12.125,1.317-14.414,3.866" transform="translate(-1212.41 528.099)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1431">
        <rect id="Прямоугольник_517" data-name="Прямоугольник 517" width="19.068" height="48.97" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1433">
        <path id="Контур_3441" data-name="Контур 3441" d="M1212-481.03h17.768V-530H1212Z" transform="translate(-1212 530)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-57" x1="0.872" y1="0.622" x2="0.872" y2="1.73" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-58" x1="0.865" y1="0.85" x2="0.888" y2="0.599" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1434">
        <path id="Контур_3450" data-name="Контур 3450" d="M1209.656-425.413s-3.32,4.29-3.055,6,.329,2.405.329,2.405,1.668-1.14,1.564-2.6c-.234,4.624-1.781,9.881-.373,9.629s8.689-6.968,7.042-14.128c-3.735-2.6-4.784-2.314-5.508-1.313" transform="translate(-1206.585 426.133)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1436">
        <rect id="Прямоугольник_518" data-name="Прямоугольник 518" width="10.834" height="17.334" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1438">
        <path id="Контур_3447" data-name="Контур 3447" d="M1206-410.666h10.834V-428H1206Z" transform="translate(-1206 428)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-59" x1="0.868" y1="0.733" x2="0.868" y2="1.841" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-60" x1="0.868" y1="0.826" x2="0.887" y2="0.626" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1439">
        <path id="Контур_3456" data-name="Контур 3456" d="M1212.637-635.828c1.591-2.622,4.36-4.347,6.032-6.916a12.535,12.535,0,0,0,.8-11.142c-1.148-2.995-3.185-5.582-4.355-8.568-1.569-4.022-1.6-5.746-.884-9.785.633-3.554,2.011-7.337,5.421-9.378a20.4,20.4,0,0,1,14.214-2.084,26.438,26.438,0,0,1,13.543,6.622,19.06,19.06,0,0,1,6,13.625c-.043,3.454-1.153,7.012-.048,10.279a8.093,8.093,0,0,0,5.712,5.183c2.9.68,5.924-.312,8.906-.273a12.959,12.959,0,0,1,8.147,3.042,10.769,10.769,0,0,1,3.818,6.665,8.04,8.04,0,0,1-2.565,7.09c-1.365,1.157-3.285,1.941-3.792,3.662-.563,1.924,1.092,3.857,2.938,4.637s3.922.845,5.8,1.547c4.2,1.564,6.747,6.3,6.418,10.769s-3.155,8.52-6.869,11.038a26.264,26.264,0,0,1-12.68,4.013,105.471,105.471,0,0,1-13.443-.2c-5.122-.242-10.344-.312-15.207-1.954-4.91-1.664-9.157-4.841-13.114-8.195-1.876-1.586-3.774-3.32-4.615-5.629-.611-1.673-.615-3.536-1.318-5.174-1.508-3.519-5.686-5.01-8.1-7.978a9.731,9.731,0,0,1-.759-10.895" transform="translate(-1211.32 684.13)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1441">
        <rect id="Прямоугольник_519" data-name="Прямоугольник 519" width="78.872" height="89.706" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1443">
        <path id="Контур_3453" data-name="Контур 3453" d="M1209-596.294h78.438V-686H1209Z" transform="translate(-1209 686)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-61" x1="0.871" y1="0.453" x2="0.871" y2="1.562" xlink:href="#linear-gradient"/>
      <linearGradient id="linear-gradient-62" x1="0.876" y1="0.496" x2="1.037" y2="0.76" xlink:href="#linear-gradient-2"/>
      <clipPath id="clip-path-1444">
        <path id="Контур_3468" data-name="Контур 3468" d="M1210.3-587.6c2.89-.919,11.333-2.665,19.505,4.637a4.978,4.978,0,0,1,1.517,4.945c-2.037,8-7.363,31.618-2.2,41.715,0,0-8.659,9.863-18.405,2.184,0,0-4.619-18.743-10.405-26.912-4-5.638,5.876-21.508,8.377-25.347a3,3,0,0,1,1.608-1.222" transform="translate(-1199.37 588.472)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1446">
        <rect id="Прямоугольник_520" data-name="Прямоугольник 520" width="35.969" height="64.138" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1448">
        <path id="Контур_3465" data-name="Контур 3465" d="M1194-531.463h35.1V-593H1194Z" transform="translate(-1194 593)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-63" x1="0.863" y1="0.557" x2="0.863" y2="1.665" gradientUnits="objectBoundingBox">
        <stop offset="0"/>
        <stop offset="0" stop-color="#fd6f61"/>
        <stop offset="0.1" stop-color="#fd7265"/>
        <stop offset="0.2" stop-color="#fd766a"/>
        <stop offset="0.3" stop-color="#fe796e"/>
        <stop offset="0.4" stop-color="#fe7d73"/>
        <stop offset="0.5" stop-color="#fe8178"/>
        <stop offset="0.6" stop-color="#fe847c"/>
        <stop offset="0.7" stop-color="#fe8780"/>
        <stop offset="0.8" stop-color="#ff8b85"/>
        <stop offset="0.9" stop-color="#ff8e89"/>
        <stop offset="1" stop-color="#ff928e"/>
        <stop offset="1"/>
      </linearGradient>
      <linearGradient id="linear-gradient-64" x1="0.984" y1="0.603" x2="0.604" y2="0.707" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fd6f61"/>
        <stop offset="0.1" stop-color="#fd7265"/>
        <stop offset="0.2" stop-color="#fd766a"/>
        <stop offset="0.3" stop-color="#fe796e"/>
        <stop offset="0.4" stop-color="#fe7d73"/>
        <stop offset="0.5" stop-color="#fe8178"/>
        <stop offset="0.6" stop-color="#fe847c"/>
        <stop offset="0.7" stop-color="#fe8780"/>
        <stop offset="0.8" stop-color="#ff8b85"/>
        <stop offset="0.9" stop-color="#ff8e89"/>
        <stop offset="1" stop-color="#ff928e"/>
      </linearGradient>
      <clipPath id="clip-path-1449">
        <path id="Контур_3474" data-name="Контур 3474" d="M1235.019-623.28s4.5,12.095,4.906,17.456a9.2,9.2,0,0,1-9.252-1.339s-.667-12.143-1.383-14.305l5.729-1.812" transform="translate(-1229.29 623.28)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1451">
        <rect id="Прямоугольник_521" data-name="Прямоугольник 521" width="10.834" height="20.368" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1453">
        <path id="Контур_3471" data-name="Контур 3471" d="M1229-603.632h10.834V-624H1229Z" transform="translate(-1229 624)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-65" x1="0.884" y1="0.519" x2="0.884" y2="1.627" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-66" x1="0.893" y1="0.502" x2="0.894" y2="0.581" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1454">
        <path id="Контур_3480" data-name="Контур 3480" d="M1219.723-661.5s-10.994,15.922-4.195,20.385,14.622-6.852,14.622-6.852,2.475-8.659-.3-11.536-10.123-2-10.123-2" transform="translate(-1213.309 661.622)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1456">
        <rect id="Прямоугольник_522" data-name="Прямоугольник 522" width="24.268" height="26.435" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1458">
        <path id="Контур_3477" data-name="Контур 3477" d="M1208-638.865h21.668V-664H1208Z" transform="translate(-1208 664)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-67" x1="0.873" y1="0.478" x2="0.873" y2="1.586" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-68" x1="2.583" y1="-3.138" x2="2.557" y2="-3.083" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1459">
        <path id="Контур_3486" data-name="Контур 3486" d="M1232.3-670.523s-5.148,1.3-5.838,3.657,7.4,4.386,9.66,4.455L1238.1-653s5.23-8.962,3.016-14.054-8.81-3.467-8.81-3.467" transform="translate(-1226.425 670.769)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1461">
        <rect id="Прямоугольник_523" data-name="Прямоугольник 523" width="18.201" height="19.501" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1463">
        <path id="Контур_3483" data-name="Контур 3483" d="M1224-654.5h18.2V-674H1224Z" transform="translate(-1224 674)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-69" x1="0.882" y1="0.468" x2="0.882" y2="1.576" xlink:href="#linear-gradient"/>
      <linearGradient id="linear-gradient-70" x1="0.892" y1="0.474" x2="1.053" y2="0.738" xlink:href="#linear-gradient-2"/>
      <clipPath id="clip-path-1464">
        <path id="Контур_3492" data-name="Контур 3492" d="M1247.1-636.555s4.295-5.14,6.089-1.863-3.844,5.85-5.837,4.26l-.251-2.4" transform="translate(-1247.1 639.51)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1466">
        <rect id="Прямоугольник_524" data-name="Прямоугольник 524" width="8.234" height="9.534" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1468">
        <path id="Контур_3489" data-name="Контур 3489" d="M1247-635.466h8.234V-645H1247Z" transform="translate(-1247 645)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-71" x1="0.897" y1="0.502" x2="0.897" y2="1.61" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-72" x1="2.594" y1="-3.126" x2="2.569" y2="-3.07" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1469">
        <path id="Контур_3498" data-name="Контур 3498" d="M1198.188-371.5c.047.854-1.344,7.649-2.1,11.276a5.955,5.955,0,0,0,.711,4.26l29.026,47.111a6.407,6.407,0,0,1,.771,1.837,40.775,40.775,0,0,1,1.235,12.663l-1.413,3.06,6.3-1.538,1.1-17-1.807-1.062a8.543,8.543,0,0,1-3.506-3.974c-2.652-6.167-9.257-21.863-11.016-29.538-2-8.724-11.831-15.675-11.831-15.675l1.642-4.065-9.113-7.354" transform="translate(-1195.961 371.5)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1471">
        <rect id="Прямоугольник_525" data-name="Прямоугольник 525" width="38.569" height="80.605" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1473">
        <path id="Контур_3495" data-name="Контур 3495" d="M1195-291.395h38.569V-372H1195Z" transform="translate(-1195 372)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-73" x1="0.86" y1="0.792" x2="0.86" y2="1.9" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-74" x1="0.909" y1="0.874" x2="0.826" y2="0.954" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1474">
        <path id="Контур_3504" data-name="Контур 3504" d="M1277-219.828s-3.5-5.361-3.939-7.458-1.612-5.807-3.7-4.65c0,0-.828,10.249-.42,14.245s-4.459,3.944-4.459,3.944a4.5,4.5,0,0,0-2.3,1.209,1.139,1.139,0,0,0,.793,1.9,27.579,27.579,0,0,0,6.765-.494,2.072,2.072,0,0,0,1.643-1.937l.511-11.8,4.394,5.369.715-.334" transform="translate(-1261.877 232.155)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1476">
        <rect id="Прямоугольник_526" data-name="Прямоугольник 526" width="15.601" height="22.968" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1478">
        <path id="Контур_3501" data-name="Контур 3501" d="M1261-212.032h15.6V-235H1261Z" transform="translate(-1261 235)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-75" x1="0.908" y1="0.942" x2="0.908" y2="2.051" xlink:href="#linear-gradient"/>
      <linearGradient id="linear-gradient-76" x1="0.933" y1="0.969" x2="0.908" y2="0.969" xlink:href="#linear-gradient-2"/>
      <clipPath id="clip-path-1479">
        <path id="Контур_3510" data-name="Контур 3510" d="M1234.169-356.226s1.682,42.53,1.916,48.571-.641,19.449-6.635,23.549l2.665,1.911a35.2,35.2,0,0,0,6.384-8.295c3.514-6.11,4.589-7.233,4.589-7.233l-1.235-2.44a5.365,5.365,0,0,1-.494-3.458c1.205-6.353,5.231-27.5,6.349-32.056,1.322-5.378,1.3-12.537-3.683-18.444s-9.855-2.106-9.855-2.106" transform="translate(-1229.45 357.431)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1481">
        <rect id="Прямоугольник_527" data-name="Прямоугольник 527" width="19.935" height="78.438" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1483">
        <path id="Контур_3507" data-name="Контур 3507" d="M1229-284.995h19.935V-363H1229Z" transform="translate(-1229 363)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-77" x1="0.884" y1="0.807" x2="0.884" y2="1.915" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-78" x1="1.002" y1="0.757" x2="0.953" y2="0.821" xlink:href="#linear-gradient-4"/>
      <clipPath id="clip-path-1484">
        <path id="Контур_3516" data-name="Контур 3516" d="M1237.157-203.672a.374.374,0,0,0,.368-.325c.177-1.421.927-7.124,1.591-8.607.758-1.7,3.215-8.841-.152-9.387,0,0-8.151,12.329-9.729,14a3.135,3.135,0,0,1-3.615.633,6.061,6.061,0,0,0-2.708,2.011,1.108,1.108,0,0,0,.9,1.764l5.621-.039a3.958,3.958,0,0,0,3.67-2.552c.949-2.487,2.592-6.141,4.546-7.779l-.871,9.872a.381.381,0,0,0,.377.412" transform="translate(-1222.694 221.99)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1486">
        <rect id="Прямоугольник_528" data-name="Прямоугольник 528" width="20.801" height="18.635" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1488">
        <path id="Контур_3513" data-name="Контур 3513" d="M1221-203.365h20.8V-222H1221Z" transform="translate(-1221 222)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-79" x1="0.88" y1="0.953" x2="0.88" y2="2.062" xlink:href="#linear-gradient"/>
      <linearGradient id="linear-gradient-80" x1="0.91" y1="0.976" x2="0.88" y2="0.977" xlink:href="#linear-gradient-2"/>
      <clipPath id="clip-path-1489">
        <path id="Контур_3522" data-name="Контур 3522" d="M1206.686-477.64s1.33,4.017-.507,10.7l-11.4,42.361a1.3,1.3,0,0,0,.841,1.573,80.564,80.564,0,0,0,31.757,3.333,1.843,1.843,0,0,0,1.655-1.456c.988-4.828,4.368-21.287,5.062-24.667,2.652-12.888,5.621-26.786-8.555-34.933-6.427,2.878-12.433,4.607-18.855,3.09" transform="translate(-1194.737 480.73)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1491">
        <rect id="Прямоугольник_529" data-name="Прямоугольник 529" width="45.503" height="62.837" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1493">
        <path id="Контур_3519" data-name="Контур 3519" d="M1194-418.163h44.2V-481H1194Z" transform="translate(-1194 481)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-81" x1="0.86" y1="0.673" x2="0.86" y2="1.782" xlink:href="#linear-gradient"/>
      <linearGradient id="linear-gradient-82" x1="1.024" y1="0.523" x2="0.95" y2="0.657" xlink:href="#linear-gradient-2"/>
      <clipPath id="clip-path-1494">
        <path id="Контур_3528" data-name="Контур 3528" d="M1258.12-573.374s13.92,11.194,17.582,26.743-19.8,17.582-19.8,17.582-3.246-3.51-7.012-2.18-.395-6.206,4.035-6.258,2.435,3.484,8.654.546c6.058-2.865,9.694-11.475-6.089-24.182a7.051,7.051,0,0,1-2.1-2.769c-3.151-7.567-.832-12.593,4.723-9.482" transform="translate(-1247.267 574.309)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-1496">
        <rect id="Прямоугольник_530" data-name="Прямоугольник 530" width="34.669" height="48.103" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-1498">
        <path id="Контур_3525" data-name="Контур 3525" d="M1242-531.9h33.369V-580H1242Z" transform="translate(-1242 580)" fill="none"/>
      </clipPath>
      <linearGradient id="linear-gradient-83" x1="0.897" y1="0.572" x2="0.897" y2="1.68" xlink:href="#linear-gradient-3"/>
      <linearGradient id="linear-gradient-84" x1="0.94" y1="0.695" x2="0.816" y2="0.427" xlink:href="#linear-gradient-4"/>
    </defs>
    <g id="Сгруппировать_4073" data-name="Сгруппировать 4073" transform="translate(-0.337 1102.315)">
      <g id="Сгруппировать_1366" data-name="Сгруппировать 1366" transform="translate(0.337 -1102.315)" clip-path="url(#clip-path)">
        <g id="Сгруппировать_1365" data-name="Сгруппировать 1365" transform="translate(-379.771 -279.382)" clip-path="url(#clip-path-2)">
          <path id="Контур_1713" data-name="Контур 1713" d="M-4.663-1107.315h573.05v363.107H-4.663Z" transform="translate(382.267 1384.53)" fill="none"/>
        </g>
      </g>
      <g id="Сгруппировать_1375" data-name="Сгруппировать 1375" transform="translate(250.782 -917.991)" clip-path="url(#clip-path-3)">
        <g id="Сгруппировать_1374" data-name="Сгруппировать 1374" transform="translate(-630.216 -463.705)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1373" data-name="Сгруппировать 1373" transform="translate(630.108 463.697)" style="isolation: isolate">
            <g id="Сгруппировать_1372" data-name="Сгруппировать 1372" transform="translate(0 0)" clip-path="url(#clip-path-5)">
              <g id="Сгруппировать_1371" data-name="Сгруппировать 1371" transform="translate(0.108 0.009)" clip-path="url(#clip-path-3)">
                <g id="Сгруппировать_1370" data-name="Сгруппировать 1370" transform="translate(-0.108 -0.009)" clip-path="url(#clip-path-7)">
                  <g id="Сгруппировать_1368" data-name="Сгруппировать 1368" transform="translate(-250.483 -184.612)" opacity="0">
                    <g id="Сгруппировать_1367" data-name="Сгруппировать 1367" transform="translate(0 0)">
                      <path id="Контур_1716" data-name="Контур 1716" d="M-578.25-426.02H24.122V-25.594H-578.25Z" transform="translate(578.25 426.02)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1369" data-name="Сгруппировать 1369" transform="translate(-250.483 -184.612)">
                    <path id="Контур_1717" data-name="Контур 1717" d="M-578.25-426.02H24.122V-25.594H-578.25Z" transform="translate(578.25 426.02)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1384" data-name="Сгруппировать 1384" transform="translate(250.782 -971.29)" clip-path="url(#clip-path-8)">
        <g id="Сгруппировать_1383" data-name="Сгруппировать 1383" transform="translate(-630.216 -410.407)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1382" data-name="Сгруппировать 1382" transform="translate(630.108 410.393)" style="isolation: isolate">
            <g id="Сгруппировать_1381" data-name="Сгруппировать 1381" transform="translate(0)" clip-path="url(#clip-path-10)">
              <g id="Сгруппировать_1380" data-name="Сгруппировать 1380" transform="translate(0.108 0.013)" clip-path="url(#clip-path-8)">
                <g id="Сгруппировать_1379" data-name="Сгруппировать 1379" transform="translate(-0.108 -0.013)" clip-path="url(#clip-path-12)">
                  <g id="Сгруппировать_1377" data-name="Сгруппировать 1377" transform="translate(-250.483 -131.309)" opacity="0">
                    <g id="Сгруппировать_1376" data-name="Сгруппировать 1376">
                      <path id="Контур_1722" data-name="Контур 1722" d="M-578.25-303.031H24.122V97.4H-578.25Z" transform="translate(578.25 303.031)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1378" data-name="Сгруппировать 1378" transform="translate(-250.483 -131.309)">
                    <path id="Контур_1723" data-name="Контур 1723" d="M-578.25-303.031H24.122V97.4H-578.25Z" transform="translate(578.25 303.031)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1393" data-name="Сгруппировать 1393" transform="translate(220.945 -976.849)" clip-path="url(#clip-path-13)">
        <g id="Сгруппировать_1392" data-name="Сгруппировать 1392" transform="translate(-600.379 -404.847)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1391" data-name="Сгруппировать 1391" transform="translate(600.206 404.76)" style="isolation: isolate">
            <g id="Сгруппировать_1390" data-name="Сгруппировать 1390" transform="translate(0)" clip-path="url(#clip-path-15)">
              <g id="Сгруппировать_1389" data-name="Сгруппировать 1389" transform="translate(0.173 0.088)" clip-path="url(#clip-path-13)">
                <g id="Сгруппировать_1388" data-name="Сгруппировать 1388" transform="translate(-0.173 -0.088)" clip-path="url(#clip-path-17)">
                  <g id="Сгруппировать_1386" data-name="Сгруппировать 1386" transform="translate(-220.581 -125.675)" opacity="0">
                    <g id="Сгруппировать_1385" data-name="Сгруппировать 1385" transform="translate(0 0)">
                      <path id="Контур_1728" data-name="Контур 1728" d="M-509.4-290.2H92.973V110.224H-509.4Z" transform="translate(509.4 290.202)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1387" data-name="Сгруппировать 1387" transform="translate(-220.581 -125.675)">
                    <path id="Контур_1729" data-name="Контур 1729" d="M-509.4-290.2H92.973V110.224H-509.4Z" transform="translate(509.4 290.202)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1402" data-name="Сгруппировать 1402" transform="translate(250.778 -897.112)" clip-path="url(#clip-path-18)">
        <g id="Сгруппировать_1401" data-name="Сгруппировать 1401" transform="translate(-630.212 -484.585)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1400" data-name="Сгруппировать 1400" transform="translate(630.108 484.498)" style="isolation: isolate">
            <g id="Сгруппировать_1399" data-name="Сгруппировать 1399" transform="translate(0)" clip-path="url(#clip-path-20)">
              <g id="Сгруппировать_1398" data-name="Сгруппировать 1398" transform="translate(0.104 0.087)" clip-path="url(#clip-path-18)">
                <g id="Сгруппировать_1397" data-name="Сгруппировать 1397" transform="translate(-0.104 -0.087)" clip-path="url(#clip-path-22)">
                  <g id="Сгруппировать_1395" data-name="Сгруппировать 1395" transform="translate(-250.483 -205.413)" opacity="0">
                    <g id="Сгруппировать_1394" data-name="Сгруппировать 1394">
                      <path id="Контур_1734" data-name="Контур 1734" d="M-578.24-474.2H24.132V-73.774H-578.24Z" transform="translate(578.24 474.2)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1396" data-name="Сгруппировать 1396" transform="translate(-250.483 -205.413)">
                    <path id="Контур_1735" data-name="Контур 1735" d="M-578.24-474.2H24.132V-73.774H-578.24Z" transform="translate(578.24 474.2)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1411" data-name="Сгруппировать 1411" transform="translate(250.778 -894.213)" clip-path="url(#clip-path-23)">
        <g id="Сгруппировать_1410" data-name="Сгруппировать 1410" transform="translate(-630.212 -487.484)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1409" data-name="Сгруппировать 1409" transform="translate(630.108 487.098)" style="isolation: isolate">
            <g id="Сгруппировать_1408" data-name="Сгруппировать 1408" transform="translate(0)" clip-path="url(#clip-path-25)">
              <g id="Сгруппировать_1407" data-name="Сгруппировать 1407" transform="translate(0.104 0.386)" clip-path="url(#clip-path-23)">
                <g id="Сгруппировать_1406" data-name="Сгруппировать 1406" transform="translate(-0.104 -0.386)" clip-path="url(#clip-path-27)">
                  <g id="Сгруппировать_1404" data-name="Сгруппировать 1404" transform="translate(-250.483 -208.014)" opacity="0">
                    <g id="Сгруппировать_1403" data-name="Сгруппировать 1403">
                      <path id="Контур_1740" data-name="Контур 1740" d="M-578.24-480.89H24.132V-80.464H-578.24Z" transform="translate(578.24 480.89)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1405" data-name="Сгруппировать 1405" transform="translate(-250.483 -208.014)">
                    <path id="Контур_1741" data-name="Контур 1741" d="M-578.24-480.89H24.132V-80.464H-578.24Z" transform="translate(578.24 480.89)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1420" data-name="Сгруппировать 1420" transform="translate(263.731 -894.213)" clip-path="url(#clip-path-28)">
        <g id="Сгруппировать_1419" data-name="Сгруппировать 1419" transform="translate(-643.165 -487.484)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1418" data-name="Сгруппировать 1418" transform="translate(643.108 487.098)" style="isolation: isolate">
            <g id="Сгруппировать_1417" data-name="Сгруппировать 1417" transform="translate(0)" clip-path="url(#clip-path-30)">
              <g id="Сгруппировать_1416" data-name="Сгруппировать 1416" transform="translate(0.056 0.386)" clip-path="url(#clip-path-28)">
                <g id="Сгруппировать_1415" data-name="Сгруппировать 1415" transform="translate(-0.056 -0.386)" clip-path="url(#clip-path-32)">
                  <g id="Сгруппировать_1413" data-name="Сгруппировать 1413" transform="translate(-263.484 -208.014)" opacity="0">
                    <g id="Сгруппировать_1412" data-name="Сгруппировать 1412">
                      <path id="Контур_1746" data-name="Контур 1746" d="M-608.13-480.89H-5.758V-80.464H-608.13Z" transform="translate(608.13 480.89)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1414" data-name="Сгруппировать 1414" transform="translate(-263.484 -208.014)">
                    <path id="Контур_1747" data-name="Контур 1747" d="M-608.13-480.89H-5.758V-80.464H-608.13Z" transform="translate(608.13 480.89)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1429" data-name="Сгруппировать 1429" transform="translate(39.808 -917.991)" clip-path="url(#clip-path-33)">
        <g id="Сгруппировать_1428" data-name="Сгруппировать 1428" transform="translate(-419.242 -463.705)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1427" data-name="Сгруппировать 1427" transform="translate(419.061 463.697)" style="isolation: isolate">
            <g id="Сгруппировать_1426" data-name="Сгруппировать 1426" transform="translate(0 0)" clip-path="url(#clip-path-35)">
              <g id="Сгруппировать_1425" data-name="Сгруппировать 1425" transform="translate(0.181 0.009)" clip-path="url(#clip-path-33)">
                <g id="Сгруппировать_1424" data-name="Сгруппировать 1424" transform="translate(-0.181 -0.009)" clip-path="url(#clip-path-37)">
                  <g id="Сгруппировать_1422" data-name="Сгруппировать 1422" transform="translate(-39.436 -184.612)" opacity="0">
                    <g id="Сгруппировать_1421" data-name="Сгруппировать 1421" transform="translate(0 0)">
                      <path id="Контур_1752" data-name="Контур 1752" d="M-91.418-426.02H510.955V-25.594H-91.418Z" transform="translate(91.418 426.02)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1423" data-name="Сгруппировать 1423" transform="translate(-39.436 -184.612)">
                    <path id="Контур_1753" data-name="Контур 1753" d="M-91.418-426.02H510.955V-25.594H-91.418Z" transform="translate(91.418 426.02)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1438" data-name="Сгруппировать 1438" transform="translate(28.526 -971.29)" clip-path="url(#clip-path-38)">
        <g id="Сгруппировать_1437" data-name="Сгруппировать 1437" transform="translate(-407.96 -410.407)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1436" data-name="Сгруппировать 1436" transform="translate(407.36 410.393)" style="isolation: isolate">
            <g id="Сгруппировать_1435" data-name="Сгруппировать 1435" transform="translate(0)" clip-path="url(#clip-path-40)">
              <g id="Сгруппировать_1434" data-name="Сгруппировать 1434" transform="translate(0.6 0.013)" clip-path="url(#clip-path-38)">
                <g id="Сгруппировать_1433" data-name="Сгруппировать 1433" transform="translate(-0.6 -0.013)" clip-path="url(#clip-path-42)">
                  <g id="Сгруппировать_1431" data-name="Сгруппировать 1431" transform="translate(-27.735 -131.309)" opacity="0">
                    <g id="Сгруппировать_1430" data-name="Сгруппировать 1430">
                      <path id="Контур_1758" data-name="Контур 1758" d="M-65.384-303.031H536.988V97.4H-65.384Z" transform="translate(65.384 303.031)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1432" data-name="Сгруппировать 1432" transform="translate(-27.735 -131.309)">
                    <path id="Контур_1759" data-name="Контур 1759" d="M-65.384-303.031H536.988V97.4H-65.384Z" transform="translate(65.384 303.031)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1447" data-name="Сгруппировать 1447" transform="translate(47.274 -976.849)" clip-path="url(#clip-path-43)">
        <g id="Сгруппировать_1446" data-name="Сгруппировать 1446" transform="translate(-426.707 -404.847)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1445" data-name="Сгруппировать 1445" transform="translate(426.428 404.76)" style="isolation: isolate">
            <g id="Сгруппировать_1444" data-name="Сгруппировать 1444" transform="translate(0)" clip-path="url(#clip-path-45)">
              <g id="Сгруппировать_1443" data-name="Сгруппировать 1443" transform="translate(0.28 0.088)" clip-path="url(#clip-path-43)">
                <g id="Сгруппировать_1442" data-name="Сгруппировать 1442" transform="translate(-0.28 -0.088)" clip-path="url(#clip-path-47)">
                  <g id="Сгруппировать_1440" data-name="Сгруппировать 1440" transform="translate(-46.803 -125.675)" opacity="0">
                    <g id="Сгруппировать_1439" data-name="Сгруппировать 1439" transform="translate(0 0)">
                      <path id="Контур_1764" data-name="Контур 1764" d="M-108.645-290.2H493.728V110.224H-108.645Z" transform="translate(108.645 290.202)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1441" data-name="Сгруппировать 1441" transform="translate(-46.803 -125.675)">
                    <path id="Контур_1765" data-name="Контур 1765" d="M-108.645-290.2H493.728V110.224H-108.645Z" transform="translate(108.645 290.202)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1456" data-name="Сгруппировать 1456" transform="translate(52.891 -897.112)" clip-path="url(#clip-path-48)">
        <g id="Сгруппировать_1455" data-name="Сгруппировать 1455" transform="translate(-432.325 -484.585)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1454" data-name="Сгруппировать 1454" transform="translate(432.061 484.498)" style="isolation: isolate">
            <g id="Сгруппировать_1453" data-name="Сгруппировать 1453" transform="translate(0)" clip-path="url(#clip-path-50)">
              <g id="Сгруппировать_1452" data-name="Сгруппировать 1452" transform="translate(0.263 0.087)" clip-path="url(#clip-path-48)">
                <g id="Сгруппировать_1451" data-name="Сгруппировать 1451" transform="translate(-0.263 -0.087)" clip-path="url(#clip-path-52)">
                  <g id="Сгруппировать_1449" data-name="Сгруппировать 1449" transform="translate(-52.437 -205.413)" opacity="0">
                    <g id="Сгруппировать_1448" data-name="Сгруппировать 1448">
                      <path id="Контур_1770" data-name="Контур 1770" d="M-121.608-474.2H480.765V-73.774H-121.608Z" transform="translate(121.608 474.2)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1450" data-name="Сгруппировать 1450" transform="translate(-52.437 -205.413)">
                    <path id="Контур_1771" data-name="Контур 1771" d="M-121.608-474.2H480.765V-73.774H-121.608Z" transform="translate(121.608 474.2)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1465" data-name="Сгруппировать 1465" transform="translate(71.639 -894.213)" clip-path="url(#clip-path-53)">
        <g id="Сгруппировать_1464" data-name="Сгруппировать 1464" transform="translate(-451.073 -487.484)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1463" data-name="Сгруппировать 1463" transform="translate(450.696 487.098)" style="isolation: isolate">
            <g id="Сгруппировать_1462" data-name="Сгруппировать 1462" clip-path="url(#clip-path-55)">
              <g id="Сгруппировать_1461" data-name="Сгруппировать 1461" transform="translate(0.377 0.386)" clip-path="url(#clip-path-53)">
                <g id="Сгруппировать_1460" data-name="Сгруппировать 1460" transform="translate(-0.377 -0.386)" clip-path="url(#clip-path-57)">
                  <g id="Сгруппировать_1458" data-name="Сгруппировать 1458" transform="translate(-71.071 -208.014)" opacity="0">
                    <g id="Сгруппировать_1457" data-name="Сгруппировать 1457">
                      <path id="Контур_1776" data-name="Контур 1776" d="M-164.87-480.89H437.5V-80.464H-164.87Z" transform="translate(164.87 480.89)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1459" data-name="Сгруппировать 1459" transform="translate(-71.071 -208.014)">
                    <path id="Контур_1777" data-name="Контур 1777" d="M-164.87-480.89H437.5V-80.464H-164.87Z" transform="translate(164.87 480.89)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1474" data-name="Сгруппировать 1474" transform="translate(55.427 -894.213)" clip-path="url(#clip-path-58)">
        <g id="Сгруппировать_1473" data-name="Сгруппировать 1473" transform="translate(-434.861 -487.484)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1472" data-name="Сгруппировать 1472" transform="translate(434.662 487.098)" style="isolation: isolate">
            <g id="Сгруппировать_1471" data-name="Сгруппировать 1471" transform="translate(0)" clip-path="url(#clip-path-30)">
              <g id="Сгруппировать_1470" data-name="Сгруппировать 1470" transform="translate(0.199 0.386)" clip-path="url(#clip-path-58)">
                <g id="Сгруппировать_1469" data-name="Сгруппировать 1469" transform="translate(-0.199 -0.386)" clip-path="url(#clip-path-62)">
                  <g id="Сгруппировать_1467" data-name="Сгруппировать 1467" transform="translate(-55.037 -208.014)" opacity="0">
                    <g id="Сгруппировать_1466" data-name="Сгруппировать 1466">
                      <path id="Контур_1782" data-name="Контур 1782" d="M-127.46-480.89H474.913V-80.464H-127.46Z" transform="translate(127.46 480.89)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1468" data-name="Сгруппировать 1468" transform="translate(-55.037 -208.014)">
                    <path id="Контур_1783" data-name="Контур 1783" d="M-127.46-480.89H474.913V-80.464H-127.46Z" transform="translate(127.46 480.89)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1483" data-name="Сгруппировать 1483" transform="translate(188.63 -935.971)" clip-path="url(#clip-path-63)">
        <g id="Сгруппировать_1482" data-name="Сгруппировать 1482" transform="translate(-568.063 -445.725)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1481" data-name="Сгруппировать 1481" transform="translate(567.704 445.496)" style="isolation: isolate">
            <g id="Сгруппировать_1480" data-name="Сгруппировать 1480" transform="translate(0 0)" clip-path="url(#clip-path-65)">
              <g id="Сгруппировать_1479" data-name="Сгруппировать 1479" transform="translate(0.36 0.23)" clip-path="url(#clip-path-63)">
                <g id="Сгруппировать_1478" data-name="Сгруппировать 1478" transform="translate(-0.36 -0.23)" clip-path="url(#clip-path-67)">
                  <g id="Сгруппировать_1476" data-name="Сгруппировать 1476" transform="translate(-188.079 -166.411)" opacity="0">
                    <g id="Сгруппировать_1475" data-name="Сгруппировать 1475" transform="translate(0 0)">
                      <path id="Контур_1788" data-name="Контур 1788" d="M-434.83-384.53H167.542V15.9H-434.83Z" transform="translate(434.83 384.53)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1477" data-name="Сгруппировать 1477" transform="translate(-188.079 -166.411)">
                    <path id="Контур_1789" data-name="Контур 1789" d="M-434.83-384.53H167.542V15.9H-434.83Z" transform="translate(434.83 384.53)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1492" data-name="Сгруппировать 1492" transform="translate(80.506 -936.712)" clip-path="url(#clip-path-68)">
        <g id="Сгруппировать_1491" data-name="Сгруппировать 1491" transform="translate(-459.94 -444.984)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1490" data-name="Сгруппировать 1490" transform="translate(459.797 444.629)" style="isolation: isolate">
            <g id="Сгруппировать_1489" data-name="Сгруппировать 1489" transform="translate(0 0)" clip-path="url(#clip-path-70)">
              <g id="Сгруппировать_1488" data-name="Сгруппировать 1488" transform="translate(0.143 0.355)" clip-path="url(#clip-path-68)">
                <g id="Сгруппировать_1487" data-name="Сгруппировать 1487" transform="translate(-0.143 -0.355)" clip-path="url(#clip-path-72)">
                  <g id="Сгруппировать_1485" data-name="Сгруппировать 1485" transform="translate(-80.172 -165.544)" opacity="0">
                    <g id="Сгруппировать_1484" data-name="Сгруппировать 1484">
                      <path id="Контур_1794" data-name="Контур 1794" d="M-185.33-382.82H417.043V17.606H-185.33Z" transform="translate(185.33 382.82)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1486" data-name="Сгруппировать 1486" transform="translate(-80.172 -165.544)">
                    <path id="Контур_1795" data-name="Контур 1795" d="M-185.33-382.82H417.043V17.606H-185.33Z" transform="translate(185.33 382.82)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1501" data-name="Сгруппировать 1501" transform="translate(135.309 -820.476)" clip-path="url(#clip-path-73)">
        <g id="Сгруппировать_1500" data-name="Сгруппировать 1500" transform="translate(-514.742 -561.22)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1499" data-name="Сгруппировать 1499" transform="translate(514.4 561.203)" style="isolation: isolate">
            <g id="Сгруппировать_1498" data-name="Сгруппировать 1498" transform="translate(0)" clip-path="url(#clip-path-75)">
              <g id="Сгруппировать_1497" data-name="Сгруппировать 1497" transform="translate(0.342 0.017)" clip-path="url(#clip-path-73)">
                <g id="Сгруппировать_1496" data-name="Сгруппировать 1496" transform="translate(-0.342 -0.017)" clip-path="url(#clip-path-77)">
                  <g id="Сгруппировать_1494" data-name="Сгруппировать 1494" transform="translate(-134.775 -282.118)" opacity="0">
                    <g id="Сгруппировать_1493" data-name="Сгруппировать 1493" transform="translate(0 0)">
                      <path id="Контур_1800" data-name="Контур 1800" d="M-311.79-651.04H290.582v400.426H-311.79Z" transform="translate(311.79 651.04)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1495" data-name="Сгруппировать 1495" transform="translate(-134.775 -282.118)">
                    <path id="Контур_1801" data-name="Контур 1801" d="M-311.79-651.04H290.582v400.426H-311.79Z" transform="translate(311.79 651.04)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1510" data-name="Сгруппировать 1510" transform="translate(135.309 -813.581)" clip-path="url(#clip-path-78)">
        <g id="Сгруппировать_1509" data-name="Сгруппировать 1509" transform="translate(-514.742 -568.115)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1508" data-name="Сгруппировать 1508" transform="translate(514.4 567.704)" style="isolation: isolate">
            <g id="Сгруппировать_1507" data-name="Сгруппировать 1507" transform="translate(0 0)" clip-path="url(#clip-path-80)">
              <g id="Сгруппировать_1506" data-name="Сгруппировать 1506" transform="translate(0.342 0.412)" clip-path="url(#clip-path-78)">
                <g id="Сгруппировать_1505" data-name="Сгруппировать 1505" transform="translate(-0.342 -0.412)" clip-path="url(#clip-path-82)">
                  <g id="Сгруппировать_1503" data-name="Сгруппировать 1503" transform="translate(-134.775 -288.619)" opacity="0">
                    <g id="Сгруппировать_1502" data-name="Сгруппировать 1502">
                      <path id="Контур_1806" data-name="Контур 1806" d="M-311.79-666.95H290.582v400.426H-311.79Z" transform="translate(311.79 666.95)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1504" data-name="Сгруппировать 1504" transform="translate(-134.775 -288.619)">
                    <path id="Контур_1807" data-name="Контур 1807" d="M-311.79-666.95H290.582v400.426H-311.79Z" transform="translate(311.79 666.95)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1519" data-name="Сгруппировать 1519" transform="translate(143.634 -890.321)" clip-path="url(#clip-path-83)">
        <g id="Сгруппировать_1518" data-name="Сгруппировать 1518" transform="translate(-523.067 -491.376)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1517" data-name="Сгруппировать 1517" transform="translate(523.067 490.999)" style="isolation: isolate">
            <g id="Сгруппировать_1516" data-name="Сгруппировать 1516" transform="translate(0)" clip-path="url(#clip-path-85)">
              <g id="Сгруппировать_1515" data-name="Сгруппировать 1515" transform="translate(0 0.377)" clip-path="url(#clip-path-83)">
                <g id="Сгруппировать_1514" data-name="Сгруппировать 1514" transform="translate(0 -0.377)" clip-path="url(#clip-path-87)">
                  <g id="Сгруппировать_1512" data-name="Сгруппировать 1512" transform="translate(-143.443 -211.914)" opacity="0">
                    <g id="Сгруппировать_1511" data-name="Сгруппировать 1511">
                      <path id="Контур_1812" data-name="Контур 1812" d="M-331-489.87H271.372V-89.444H-331Z" transform="translate(331 489.87)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1513" data-name="Сгруппировать 1513" transform="translate(-143.443 -211.914)">
                    <path id="Контур_1813" data-name="Контур 1813" d="M-331-489.87H271.372V-89.444H-331Z" transform="translate(331 489.87)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1528" data-name="Сгруппировать 1528" transform="translate(143.634 -904.423)" clip-path="url(#clip-path-88)">
        <g id="Сгруппировать_1527" data-name="Сгруппировать 1527" transform="translate(-523.067 -477.274)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1526" data-name="Сгруппировать 1526" transform="translate(523.067 477.131)" style="isolation: isolate">
            <g id="Сгруппировать_1525" data-name="Сгруппировать 1525" transform="translate(0 0)" clip-path="url(#clip-path-90)">
              <g id="Сгруппировать_1524" data-name="Сгруппировать 1524" transform="translate(0 0.143)" clip-path="url(#clip-path-88)">
                <g id="Сгруппировать_1523" data-name="Сгруппировать 1523" transform="translate(0 -0.143)" clip-path="url(#clip-path-92)">
                  <g id="Сгруппировать_1521" data-name="Сгруппировать 1521" transform="translate(-143.443 -198.046)" opacity="0">
                    <g id="Сгруппировать_1520" data-name="Сгруппировать 1520" transform="translate(0 0)">
                      <path id="Контур_1818" data-name="Контур 1818" d="M-331-457.33H271.372V-56.9H-331Z" transform="translate(331 457.33)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1522" data-name="Сгруппировать 1522" transform="translate(-143.443 -198.046)">
                    <path id="Контур_1819" data-name="Контур 1819" d="M-331-457.33H271.372V-56.9H-331Z" transform="translate(331 457.33)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1537" data-name="Сгруппировать 1537" transform="translate(100.159 -946.077)" clip-path="url(#clip-path-93)">
        <g id="Сгруппировать_1536" data-name="Сгруппировать 1536" transform="translate(-479.592 -435.619)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1535" data-name="Сгруппировать 1535" transform="translate(479.298 435.528)" style="isolation: isolate">
            <g id="Сгруппировать_1534" data-name="Сгруппировать 1534" transform="translate(0)" clip-path="url(#clip-path-95)">
              <g id="Сгруппировать_1533" data-name="Сгруппировать 1533" transform="translate(0.295 0.091)" clip-path="url(#clip-path-93)">
                <g id="Сгруппировать_1532" data-name="Сгруппировать 1532" transform="translate(-0.295 -0.091)" clip-path="url(#clip-path-97)">
                  <g id="Сгруппировать_1530" data-name="Сгруппировать 1530" transform="translate(-99.673 -156.443)" opacity="0">
                    <g id="Сгруппировать_1529" data-name="Сгруппировать 1529" transform="translate(0 0)">
                      <path id="Контур_1824" data-name="Контур 1824" d="M-230.68-361.21H371.693V39.216H-230.68Z" transform="translate(230.68 361.21)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1531" data-name="Сгруппировать 1531" transform="translate(-99.673 -156.443)">
                    <path id="Контур_1825" data-name="Контур 1825" d="M-230.68-361.21H371.693V39.216H-230.68Z" transform="translate(230.68 361.21)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1546" data-name="Сгруппировать 1546" transform="translate(100.159 -922.498)" clip-path="url(#clip-path-98)">
        <g id="Сгруппировать_1545" data-name="Сгруппировать 1545" transform="translate(-479.592 -459.199)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1544" data-name="Сгруппировать 1544" transform="translate(479.298 458.93)" style="isolation: isolate">
            <g id="Сгруппировать_1543" data-name="Сгруппировать 1543" transform="translate(0 0)" clip-path="url(#clip-path-100)">
              <g id="Сгруппировать_1542" data-name="Сгруппировать 1542" transform="translate(0.295 0.269)" clip-path="url(#clip-path-98)">
                <g id="Сгруппировать_1541" data-name="Сгруппировать 1541" transform="translate(-0.295 -0.269)" clip-path="url(#clip-path-102)">
                  <g id="Сгруппировать_1539" data-name="Сгруппировать 1539" transform="translate(-99.673 -179.845)" opacity="0">
                    <g id="Сгруппировать_1538" data-name="Сгруппировать 1538">
                      <path id="Контур_1830" data-name="Контур 1830" d="M-230.68-415.62H371.693V-15.194H-230.68Z" transform="translate(230.68 415.62)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1540" data-name="Сгруппировать 1540" transform="translate(-99.673 -179.845)">
                    <path id="Контур_1831" data-name="Контур 1831" d="M-230.68-415.62H371.693V-15.194H-230.68Z" transform="translate(230.68 415.62)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1555" data-name="Сгруппировать 1555" transform="translate(115.049 -946.077)" clip-path="url(#clip-path-103)">
        <g id="Сгруппировать_1554" data-name="Сгруппировать 1554" transform="translate(-494.483 -435.619)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1553" data-name="Сгруппировать 1553" transform="translate(494.465 435.528)" style="isolation: isolate">
            <g id="Сгруппировать_1552" data-name="Сгруппировать 1552" transform="translate(0)" clip-path="url(#clip-path-95)">
              <g id="Сгруппировать_1551" data-name="Сгруппировать 1551" transform="translate(0.017 0.091)" clip-path="url(#clip-path-103)">
                <g id="Сгруппировать_1550" data-name="Сгруппировать 1550" transform="translate(-0.017 -0.091)" clip-path="url(#clip-path-107)">
                  <g id="Сгруппировать_1548" data-name="Сгруппировать 1548" transform="translate(-114.841 -156.443)" opacity="0">
                    <g id="Сгруппировать_1547" data-name="Сгруппировать 1547" transform="translate(0 0)">
                      <path id="Контур_1836" data-name="Контур 1836" d="M-265.04-361.21H337.333V39.216H-265.04Z" transform="translate(265.04 361.21)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1549" data-name="Сгруппировать 1549" transform="translate(-114.841 -156.443)">
                    <path id="Контур_1837" data-name="Контур 1837" d="M-265.04-361.21H337.333V39.216H-265.04Z" transform="translate(265.04 361.21)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1564" data-name="Сгруппировать 1564" transform="translate(115.049 -922.498)" clip-path="url(#clip-path-108)">
        <g id="Сгруппировать_1563" data-name="Сгруппировать 1563" transform="translate(-494.483 -459.199)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1562" data-name="Сгруппировать 1562" transform="translate(494.465 458.93)" style="isolation: isolate">
            <g id="Сгруппировать_1561" data-name="Сгруппировать 1561" transform="translate(0 0)" clip-path="url(#clip-path-100)">
              <g id="Сгруппировать_1560" data-name="Сгруппировать 1560" transform="translate(0.017 0.269)" clip-path="url(#clip-path-108)">
                <g id="Сгруппировать_1559" data-name="Сгруппировать 1559" transform="translate(-0.017 -0.269)" clip-path="url(#clip-path-112)">
                  <g id="Сгруппировать_1557" data-name="Сгруппировать 1557" transform="translate(-114.841 -179.845)" opacity="0">
                    <g id="Сгруппировать_1556" data-name="Сгруппировать 1556">
                      <path id="Контур_1842" data-name="Контур 1842" d="M-265.04-415.62H337.333V-15.194H-265.04Z" transform="translate(265.04 415.62)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1558" data-name="Сгруппировать 1558" transform="translate(-114.841 -179.845)">
                    <path id="Контур_1843" data-name="Контур 1843" d="M-265.04-415.62H337.333V-15.194H-265.04Z" transform="translate(265.04 415.62)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1573" data-name="Сгруппировать 1573" transform="translate(95.925 -949.689)" clip-path="url(#clip-path-113)">
        <g id="Сгруппировать_1572" data-name="Сгруппировать 1572" transform="translate(-475.359 -432.008)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1571" data-name="Сгруппировать 1571" transform="translate(474.964 431.628)" style="isolation: isolate">
            <g id="Сгруппировать_1570" data-name="Сгруппировать 1570" transform="translate(0)" clip-path="url(#clip-path-115)">
              <g id="Сгруппировать_1569" data-name="Сгруппировать 1569" transform="translate(0.394 0.38)" clip-path="url(#clip-path-113)">
                <g id="Сгруппировать_1568" data-name="Сгруппировать 1568" transform="translate(-0.394 -0.38)" clip-path="url(#clip-path-117)">
                  <g id="Сгруппировать_1566" data-name="Сгруппировать 1566" transform="translate(-95.34 -152.543)" opacity="0">
                    <g id="Сгруппировать_1565" data-name="Сгруппировать 1565" transform="translate(0 0)">
                      <path id="Контур_1848" data-name="Контур 1848" d="M-220.91-352.876H381.462V47.55H-220.91Z" transform="translate(220.91 352.876)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1567" data-name="Сгруппировать 1567" transform="translate(-95.34 -152.543)">
                    <path id="Контур_1849" data-name="Контур 1849" d="M-220.91-352.876H381.462V47.55H-220.91Z" transform="translate(220.91 352.876)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1582" data-name="Сгруппировать 1582" transform="translate(143.426 -890.321)" clip-path="url(#clip-path-118)">
        <g id="Сгруппировать_1581" data-name="Сгруппировать 1581" transform="translate(-522.859 -491.376)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1580" data-name="Сгруппировать 1580" transform="translate(522.634 490.999)" style="isolation: isolate">
            <g id="Сгруппировать_1579" data-name="Сгруппировать 1579" transform="translate(0)" clip-path="url(#clip-path-120)">
              <g id="Сгруппировать_1578" data-name="Сгруппировать 1578" transform="translate(0.225 0.377)" clip-path="url(#clip-path-118)">
                <g id="Сгруппировать_1577" data-name="Сгруппировать 1577" transform="translate(-0.225 -0.377)" clip-path="url(#clip-path-122)">
                  <g id="Сгруппировать_1575" data-name="Сгруппировать 1575" transform="translate(-143.009 -211.914)" opacity="0">
                    <g id="Сгруппировать_1574" data-name="Сгруппировать 1574">
                      <path id="Контур_1854" data-name="Контур 1854" d="M-330.52-489.87H271.852V-89.444H-330.52Z" transform="translate(330.52 489.87)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1576" data-name="Сгруппировать 1576" transform="translate(-143.009 -211.914)">
                    <path id="Контур_1855" data-name="Контур 1855" d="M-330.52-489.87H271.852V-89.444H-330.52Z" transform="translate(330.52 489.87)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1587" data-name="Сгруппировать 1587" transform="translate(216.356 -996.534)" clip-path="url(#clip-path-123)">
        <g id="Сгруппировать_1586" data-name="Сгруппировать 1586" transform="translate(-595.79 -385.162)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1584" data-name="Сгруппировать 1584" transform="translate(379.625 279.085)" opacity="0">
            <g id="Сгруппировать_1583" data-name="Сгруппировать 1583" transform="translate(0 0)">
              <path id="Контур_1860" data-name="Контур 1860" d="M-498.81-244.778H103.563V155.648H-498.81Z" transform="translate(498.81 244.778)" fill="#c4d3e0"/>
            </g>
          </g>
          <g id="Сгруппировать_1585" data-name="Сгруппировать 1585" transform="translate(379.625 279.085)">
            <path id="Контур_1861" data-name="Контур 1861" d="M-498.81-244.778H103.563V155.648H-498.81Z" transform="translate(498.81 244.778)" fill="#c4d3e0"/>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1592" data-name="Сгруппировать 1592" transform="translate(202.363 -996.534)" clip-path="url(#clip-path-125)">
        <g id="Сгруппировать_1591" data-name="Сгруппировать 1591" transform="translate(-581.797 -385.162)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1589" data-name="Сгруппировать 1589" transform="translate(379.625 279.085)" opacity="0">
            <g id="Сгруппировать_1588" data-name="Сгруппировать 1588" transform="translate(0 0)">
              <path id="Контур_1864" data-name="Контур 1864" d="M-466.52-244.778H135.852V155.648H-466.52Z" transform="translate(466.52 244.778)" fill="#c4d3e0"/>
            </g>
          </g>
          <g id="Сгруппировать_1590" data-name="Сгруппировать 1590" transform="translate(379.625 279.085)">
            <path id="Контур_1865" data-name="Контур 1865" d="M-466.52-244.778H135.852V155.648H-466.52Z" transform="translate(466.52 244.778)" fill="#c4d3e0"/>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1601" data-name="Сгруппировать 1601" transform="translate(198.35 -1004.398)" clip-path="url(#clip-path-127)">
        <g id="Сгруппировать_1600" data-name="Сгруппировать 1600" transform="translate(-577.784 -377.298)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1599" data-name="Сгруппировать 1599" transform="translate(577.671 377.024)" style="isolation: isolate">
            <g id="Сгруппировать_1598" data-name="Сгруппировать 1598" transform="translate(0)" clip-path="url(#clip-path-129)">
              <g id="Сгруппировать_1597" data-name="Сгруппировать 1597" transform="translate(0.113 0.274)" clip-path="url(#clip-path-127)">
                <g id="Сгруппировать_1596" data-name="Сгруппировать 1596" transform="translate(-0.113 -0.274)" clip-path="url(#clip-path-131)">
                  <g id="Сгруппировать_1594" data-name="Сгруппировать 1594" transform="translate(-198.046 -97.94)" opacity="0">
                    <g id="Сгруппировать_1593" data-name="Сгруппировать 1593" transform="translate(0 0)">
                      <path id="Контур_1868" data-name="Контур 1868" d="M-457.26-226.632H145.112V173.794H-457.26Z" transform="translate(457.26 226.632)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1595" data-name="Сгруппировать 1595" transform="translate(-198.046 -97.94)">
                    <path id="Контур_1869" data-name="Контур 1869" d="M-457.26-226.632H145.112V173.794H-457.26Z" transform="translate(457.26 226.632)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1610" data-name="Сгруппировать 1610" transform="translate(71.502 -1018.063)" clip-path="url(#clip-path-132)">
        <g id="Сгруппировать_1609" data-name="Сгруппировать 1609" transform="translate(-450.936 -363.634)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1608" data-name="Сгруппировать 1608" transform="translate(450.263 363.59)" style="isolation: isolate">
            <g id="Сгруппировать_1607" data-name="Сгруппировать 1607" clip-path="url(#clip-path-134)">
              <g id="Сгруппировать_1606" data-name="Сгруппировать 1606" transform="translate(0.673 0.043)" clip-path="url(#clip-path-132)">
                <g id="Сгруппировать_1605" data-name="Сгруппировать 1605" transform="translate(-0.673 -0.043)" clip-path="url(#clip-path-136)">
                  <g id="Сгруппировать_1603" data-name="Сгруппировать 1603" transform="translate(-70.638 -84.506)" opacity="0">
                    <g id="Сгруппировать_1602" data-name="Сгруппировать 1602" transform="translate(0 0)">
                      <path id="Контур_1874" data-name="Контур 1874" d="M-164.554-195.1H437.819V205.326H-164.554Z" transform="translate(164.554 195.1)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1604" data-name="Сгруппировать 1604" transform="translate(-70.638 -84.506)">
                    <path id="Контур_1875" data-name="Контур 1875" d="M-164.554-195.1H437.819V205.326H-164.554Z" transform="translate(164.554 195.1)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1619" data-name="Сгруппировать 1619" transform="translate(97.264 -874.434)" clip-path="url(#clip-path-137)">
        <g id="Сгруппировать_1618" data-name="Сгруппировать 1618" transform="translate(-476.698 -507.263)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1617" data-name="Сгруппировать 1617" transform="translate(476.698 507.033)" style="isolation: isolate">
            <g id="Сгруппировать_1616" data-name="Сгруппировать 1616" transform="translate(0 0)" clip-path="url(#clip-path-139)">
              <g id="Сгруппировать_1615" data-name="Сгруппировать 1615" transform="translate(0 0.23)" clip-path="url(#clip-path-137)">
                <g id="Сгруппировать_1614" data-name="Сгруппировать 1614" transform="translate(0 -0.23)" clip-path="url(#clip-path-141)">
                  <g id="Сгруппировать_1612" data-name="Сгруппировать 1612" transform="translate(-97.073 -227.948)" opacity="0">
                    <g id="Сгруппировать_1611" data-name="Сгруппировать 1611">
                      <path id="Контур_1880" data-name="Контур 1880" d="M-224-526.53H378.372V-126.1H-224Z" transform="translate(224 526.53)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1613" data-name="Сгруппировать 1613" transform="translate(-97.073 -227.948)">
                    <path id="Контур_1881" data-name="Контур 1881" d="M-224-526.53H378.372V-126.1H-224Z" transform="translate(224 526.53)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1628" data-name="Сгруппировать 1628" transform="translate(100.094 -871.418)" clip-path="url(#clip-path-142)">
        <g id="Сгруппировать_1627" data-name="Сгруппировать 1627" transform="translate(-479.527 -510.279)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1626" data-name="Сгруппировать 1626" transform="translate(479.298 510.066)" style="isolation: isolate">
            <g id="Сгруппировать_1625" data-name="Сгруппировать 1625" transform="translate(0)" clip-path="url(#clip-path-144)">
              <g id="Сгруппировать_1624" data-name="Сгруппировать 1624" transform="translate(0.23 0.212)" clip-path="url(#clip-path-142)">
                <g id="Сгруппировать_1623" data-name="Сгруппировать 1623" transform="translate(-0.23 -0.212)" clip-path="url(#clip-path-146)">
                  <g id="Сгруппировать_1621" data-name="Сгруппировать 1621" transform="translate(-99.673 -230.982)" opacity="0">
                    <g id="Сгруппировать_1620" data-name="Сгруппировать 1620">
                      <path id="Контур_1886" data-name="Контур 1886" d="M-230.53-533.49H371.842v400.426H-230.53Z" transform="translate(230.53 533.49)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1622" data-name="Сгруппировать 1622" transform="translate(-99.673 -230.982)">
                    <path id="Контур_1887" data-name="Контур 1887" d="M-230.53-533.49H371.842v400.426H-230.53Z" transform="translate(230.53 533.49)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1637" data-name="Сгруппировать 1637" transform="translate(115.543 -871.288)" clip-path="url(#clip-path-147)">
        <g id="Сгруппировать_1636" data-name="Сгруппировать 1636" transform="translate(-494.977 -510.409)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1635" data-name="Сгруппировать 1635" transform="translate(494.899 510.066)" style="isolation: isolate">
            <g id="Сгруппировать_1634" data-name="Сгруппировать 1634" clip-path="url(#clip-path-149)">
              <g id="Сгруппировать_1633" data-name="Сгруппировать 1633" transform="translate(0.078 0.342)" clip-path="url(#clip-path-147)">
                <g id="Сгруппировать_1632" data-name="Сгруппировать 1632" transform="translate(-0.078 -0.342)" clip-path="url(#clip-path-151)">
                  <g id="Сгруппировать_1630" data-name="Сгруппировать 1630" transform="translate(-115.274 -230.982)" opacity="0">
                    <g id="Сгруппировать_1629" data-name="Сгруппировать 1629">
                      <path id="Контур_1892" data-name="Контур 1892" d="M-266.18-533.79H336.192v400.426H-266.18Z" transform="translate(266.18 533.79)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1631" data-name="Сгруппировать 1631" transform="translate(-115.274 -230.982)">
                    <path id="Контур_1893" data-name="Контур 1893" d="M-266.18-533.79H336.192v400.426H-266.18Z" transform="translate(266.18 533.79)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1646" data-name="Сгруппировать 1646" transform="translate(197.08 -946.489)" clip-path="url(#clip-path-152)">
        <g id="Сгруппировать_1645" data-name="Сгруппировать 1645" transform="translate(-576.514 -435.208)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1644" data-name="Сгруппировать 1644" transform="translate(576.371 435.095)" style="isolation: isolate">
            <g id="Сгруппировать_1643" data-name="Сгруппировать 1643" transform="translate(0)" clip-path="url(#clip-path-95)">
              <g id="Сгруппировать_1642" data-name="Сгруппировать 1642" transform="translate(0.143 0.113)" clip-path="url(#clip-path-152)">
                <g id="Сгруппировать_1641" data-name="Сгруппировать 1641" transform="translate(-0.143 -0.113)" clip-path="url(#clip-path-156)">
                  <g id="Сгруппировать_1639" data-name="Сгруппировать 1639" transform="translate(-196.746 -156.01)" opacity="0">
                    <g id="Сгруппировать_1638" data-name="Сгруппировать 1638">
                      <path id="Контур_1898" data-name="Контур 1898" d="M-454.33-360.26H148.043V40.166H-454.33Z" transform="translate(454.33 360.26)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1640" data-name="Сгруппировать 1640" transform="translate(-196.746 -156.01)">
                    <path id="Контур_1899" data-name="Контур 1899" d="M-454.33-360.26H148.043V40.166H-454.33Z" transform="translate(454.33 360.26)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1655" data-name="Сгруппировать 1655" transform="translate(197.08 -922.91)" clip-path="url(#clip-path-157)">
        <g id="Сгруппировать_1654" data-name="Сгруппировать 1654" transform="translate(-576.514 -458.787)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1653" data-name="Сгруппировать 1653" transform="translate(576.371 458.496)" style="isolation: isolate">
            <g id="Сгруппировать_1652" data-name="Сгруппировать 1652" transform="translate(0 0)" clip-path="url(#clip-path-100)">
              <g id="Сгруппировать_1651" data-name="Сгруппировать 1651" transform="translate(0.143 0.29)" clip-path="url(#clip-path-157)">
                <g id="Сгруппировать_1650" data-name="Сгруппировать 1650" transform="translate(-0.143 -0.29)" clip-path="url(#clip-path-161)">
                  <g id="Сгруппировать_1648" data-name="Сгруппировать 1648" transform="translate(-196.746 -179.412)" opacity="0">
                    <g id="Сгруппировать_1647" data-name="Сгруппировать 1647" transform="translate(0 0)">
                      <path id="Контур_1904" data-name="Контур 1904" d="M-454.33-414.67H148.043V-14.244H-454.33Z" transform="translate(454.33 414.67)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1649" data-name="Сгруппировать 1649" transform="translate(-196.746 -179.412)">
                    <path id="Контур_1905" data-name="Контур 1905" d="M-454.33-414.67H148.043V-14.244H-454.33Z" transform="translate(454.33 414.67)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1664" data-name="Сгруппировать 1664" transform="translate(211.97 -946.489)" clip-path="url(#clip-path-162)">
        <g id="Сгруппировать_1663" data-name="Сгруппировать 1663" transform="translate(-591.404 -435.208)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1662" data-name="Сгруппировать 1662" transform="translate(591.105 435.095)" style="isolation: isolate">
            <g id="Сгруппировать_1661" data-name="Сгруппировать 1661" clip-path="url(#clip-path-95)">
              <g id="Сгруппировать_1660" data-name="Сгруппировать 1660" transform="translate(0.299 0.113)" clip-path="url(#clip-path-162)">
                <g id="Сгруппировать_1659" data-name="Сгруппировать 1659" transform="translate(-0.299 -0.113)" clip-path="url(#clip-path-166)">
                  <g id="Сгруппировать_1657" data-name="Сгруппировать 1657" transform="translate(-211.48 -156.01)" opacity="0">
                    <g id="Сгруппировать_1656" data-name="Сгруппировать 1656">
                      <path id="Контур_1910" data-name="Контур 1910" d="M-488.69-360.26H113.682V40.166H-488.69Z" transform="translate(488.69 360.26)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1658" data-name="Сгруппировать 1658" transform="translate(-211.48 -156.01)">
                    <path id="Контур_1911" data-name="Контур 1911" d="M-488.69-360.26H113.682V40.166H-488.69Z" transform="translate(488.69 360.26)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1673" data-name="Сгруппировать 1673" transform="translate(211.97 -922.91)" clip-path="url(#clip-path-167)">
        <g id="Сгруппировать_1672" data-name="Сгруппировать 1672" transform="translate(-591.404 -458.787)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1671" data-name="Сгруппировать 1671" transform="translate(591.105 458.496)" style="isolation: isolate">
            <g id="Сгруппировать_1670" data-name="Сгруппировать 1670" transform="translate(0 0)" clip-path="url(#clip-path-100)">
              <g id="Сгруппировать_1669" data-name="Сгруппировать 1669" transform="translate(0.299 0.29)" clip-path="url(#clip-path-167)">
                <g id="Сгруппировать_1668" data-name="Сгруппировать 1668" transform="translate(-0.299 -0.29)" clip-path="url(#clip-path-171)">
                  <g id="Сгруппировать_1666" data-name="Сгруппировать 1666" transform="translate(-211.48 -179.412)" opacity="0">
                    <g id="Сгруппировать_1665" data-name="Сгруппировать 1665" transform="translate(0 0)">
                      <path id="Контур_1916" data-name="Контур 1916" d="M-488.69-414.67H113.682V-14.244H-488.69Z" transform="translate(488.69 414.67)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1667" data-name="Сгруппировать 1667" transform="translate(-211.48 -179.412)">
                    <path id="Контур_1917" data-name="Контур 1917" d="M-488.69-414.67H113.682V-14.244H-488.69Z" transform="translate(488.69 414.67)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1682" data-name="Сгруппировать 1682" transform="translate(192.842 -950.102)" clip-path="url(#clip-path-172)">
        <g id="Сгруппировать_1681" data-name="Сгруппировать 1681" transform="translate(-572.276 -431.594)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1680" data-name="Сгруппировать 1680" transform="translate(572.037 431.195)" style="isolation: isolate">
            <g id="Сгруппировать_1679" data-name="Сгруппировать 1679" transform="translate(0)" clip-path="url(#clip-path-115)">
              <g id="Сгруппировать_1678" data-name="Сгруппировать 1678" transform="translate(0.238 0.4)" clip-path="url(#clip-path-172)">
                <g id="Сгруппировать_1677" data-name="Сгруппировать 1677" transform="translate(-0.238 -0.4)" clip-path="url(#clip-path-176)">
                  <g id="Сгруппировать_1675" data-name="Сгруппировать 1675" transform="translate(-192.413 -152.11)" opacity="0">
                    <g id="Сгруппировать_1674" data-name="Сгруппировать 1674">
                      <path id="Контур_1922" data-name="Контур 1922" d="M-444.55-351.922H157.823V48.5H-444.55Z" transform="translate(444.55 351.922)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1676" data-name="Сгруппировать 1676" transform="translate(-192.413 -152.11)">
                    <path id="Контур_1923" data-name="Контур 1923" d="M-444.55-351.922H157.823V48.5H-444.55Z" transform="translate(444.55 351.922)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1691" data-name="Сгруппировать 1691" transform="translate(194.181 -874.85)" clip-path="url(#clip-path-177)">
        <g id="Сгруппировать_1690" data-name="Сгруппировать 1690" transform="translate(-573.615 -506.847)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1689" data-name="Сгруппировать 1689" transform="translate(573.337 506.6)" style="isolation: isolate">
            <g id="Сгруппировать_1688" data-name="Сгруппировать 1688" transform="translate(0 0)" clip-path="url(#clip-path-139)">
              <g id="Сгруппировать_1687" data-name="Сгруппировать 1687" transform="translate(0.277 0.247)" clip-path="url(#clip-path-177)">
                <g id="Сгруппировать_1686" data-name="Сгруппировать 1686" transform="translate(-0.277 -0.247)" clip-path="url(#clip-path-181)">
                  <g id="Сгруппировать_1684" data-name="Сгруппировать 1684" transform="translate(-193.713 -227.515)" opacity="0">
                    <g id="Сгруппировать_1683" data-name="Сгруппировать 1683">
                      <path id="Контур_1928" data-name="Контур 1928" d="M-447.64-525.57H154.732v400.426H-447.64Z" transform="translate(447.64 525.57)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1685" data-name="Сгруппировать 1685" transform="translate(-193.713 -227.515)">
                    <path id="Контур_1929" data-name="Контур 1929" d="M-447.64-525.57H154.732v400.426H-447.64Z" transform="translate(447.64 525.57)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1700" data-name="Сгруппировать 1700" transform="translate(197.015 -871.83)" clip-path="url(#clip-path-182)">
        <g id="Сгруппировать_1699" data-name="Сгруппировать 1699" transform="translate(-576.449 -509.867)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1698" data-name="Сгруппировать 1698" transform="translate(576.371 509.633)" style="isolation: isolate">
            <g id="Сгруппировать_1697" data-name="Сгруппировать 1697" clip-path="url(#clip-path-144)">
              <g id="Сгруппировать_1696" data-name="Сгруппировать 1696" transform="translate(0.078 0.234)" clip-path="url(#clip-path-182)">
                <g id="Сгруппировать_1695" data-name="Сгруппировать 1695" transform="translate(-0.078 -0.234)" clip-path="url(#clip-path-186)">
                  <g id="Сгруппировать_1693" data-name="Сгруппировать 1693" transform="translate(-196.746 -230.548)" opacity="0">
                    <g id="Сгруппировать_1692" data-name="Сгруппировать 1692">
                      <path id="Контур_1934" data-name="Контур 1934" d="M-454.18-532.54H148.192v400.426H-454.18Z" transform="translate(454.18 532.54)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1694" data-name="Сгруппировать 1694" transform="translate(-196.746 -230.548)">
                    <path id="Контур_1935" data-name="Контур 1935" d="M-454.18-532.54H148.192v400.426H-454.18Z" transform="translate(454.18 532.54)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1709" data-name="Сгруппировать 1709" transform="translate(212.464 -871.699)" clip-path="url(#clip-path-187)">
        <g id="Сгруппировать_1708" data-name="Сгруппировать 1708" transform="translate(-591.898 -509.997)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1707" data-name="Сгруппировать 1707" transform="translate(591.538 509.633)" style="isolation: isolate">
            <g id="Сгруппировать_1706" data-name="Сгруппировать 1706" clip-path="url(#clip-path-189)">
              <g id="Сгруппировать_1705" data-name="Сгруппировать 1705" transform="translate(0.36 0.364)" clip-path="url(#clip-path-187)">
                <g id="Сгруппировать_1704" data-name="Сгруппировать 1704" transform="translate(-0.36 -0.364)" clip-path="url(#clip-path-191)">
                  <g id="Сгруппировать_1702" data-name="Сгруппировать 1702" transform="translate(-211.914 -230.548)" opacity="0">
                    <g id="Сгруппировать_1701" data-name="Сгруппировать 1701">
                      <path id="Контур_1940" data-name="Контур 1940" d="M-489.83-532.84H112.542v400.426H-489.83Z" transform="translate(489.83 532.84)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1703" data-name="Сгруппировать 1703" transform="translate(-211.914 -230.548)">
                    <path id="Контур_1941" data-name="Контур 1941" d="M-489.83-532.84H112.542v400.426H-489.83Z" transform="translate(489.83 532.84)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1716" data-name="Сгруппировать 1716" transform="translate(5.865 -1098.811)" clip-path="url(#clip-path-192)">
        <g id="Сгруппировать_1715" data-name="Сгруппировать 1715" transform="translate(-385.298 -282.886)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1714" data-name="Сгруппировать 1714" transform="translate(385.258 282.552)" style="isolation: isolate">
            <g id="Сгруппировать_1713" data-name="Сгруппировать 1713" transform="translate(0)" clip-path="url(#clip-path-194)">
              <g id="Сгруппировать_1712" data-name="Сгруппировать 1712" transform="translate(0.04 0.335)" clip-path="url(#clip-path-192)">
                <g id="Сгруппировать_1711" data-name="Сгруппировать 1711" transform="translate(-0.04 -0.335)" clip-path="url(#clip-path-196)">
                  <g id="Сгруппировать_1710" data-name="Сгруппировать 1710" transform="translate(-5.634 -3.467)">
                    <path id="Контур_1946" data-name="Контур 1946" d="M-13.092-8.772H589.28V391.654H-13.092Z" transform="translate(13.092 8.772)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1725" data-name="Сгруппировать 1725" transform="translate(324.441 -1078.027)" clip-path="url(#clip-path-197)">
        <g id="Сгруппировать_1724" data-name="Сгруппировать 1724" transform="translate(-703.874 -303.67)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1723" data-name="Сгруппировать 1723" transform="translate(703.779 303.353)" style="isolation: isolate">
            <g id="Сгруппировать_1722" data-name="Сгруппировать 1722" transform="translate(0)" clip-path="url(#clip-path-199)">
              <g id="Сгруппировать_1721" data-name="Сгруппировать 1721" transform="translate(0.095 0.317)" clip-path="url(#clip-path-197)">
                <g id="Сгруппировать_1720" data-name="Сгруппировать 1720" transform="translate(-0.095 -0.317)" clip-path="url(#clip-path-201)">
                  <g id="Сгруппировать_1718" data-name="Сгруппировать 1718" transform="translate(-324.154 -24.268)" opacity="0">
                    <g id="Сгруппировать_1717" data-name="Сгруппировать 1717" transform="translate(0 0)">
                      <path id="Контур_1951" data-name="Контур 1951" d="M-748.22-56.731h602.373V343.7H-748.22Z" transform="translate(748.22 56.731)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1719" data-name="Сгруппировать 1719" transform="translate(-324.154 -24.268)">
                    <path id="Контур_1952" data-name="Контур 1952" d="M-748.22-56.731h602.373V343.7H-748.22Z" transform="translate(748.22 56.731)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1734" data-name="Сгруппировать 1734" transform="translate(327.518 -1074.672)" clip-path="url(#clip-path-202)">
        <g id="Сгруппировать_1733" data-name="Сгруппировать 1733" transform="translate(-706.951 -307.025)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1732" data-name="Сгруппировать 1732" transform="translate(706.813 306.82)" style="isolation: isolate">
            <g id="Сгруппировать_1731" data-name="Сгруппировать 1731" clip-path="url(#clip-path-204)">
              <g id="Сгруппировать_1730" data-name="Сгруппировать 1730" transform="translate(0.139 0.205)" clip-path="url(#clip-path-202)">
                <g id="Сгруппировать_1729" data-name="Сгруппировать 1729" transform="translate(-0.139 -0.205)" clip-path="url(#clip-path-206)">
                  <g id="Сгруппировать_1727" data-name="Сгруппировать 1727" transform="translate(-327.188 -27.735)" opacity="0">
                    <g id="Сгруппировать_1726" data-name="Сгруппировать 1726" transform="translate(0 0)">
                      <path id="Контур_1957" data-name="Контур 1957" d="M-755.32-64.472h602.372V335.954H-755.32Z" transform="translate(755.32 64.472)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1728" data-name="Сгруппировать 1728" transform="translate(-327.188 -27.735)">
                    <path id="Контур_1958" data-name="Контур 1958" d="M-755.32-64.472h602.372V335.954H-755.32Z" transform="translate(755.32 64.472)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1743" data-name="Сгруппировать 1743" transform="translate(428.625 -1073.806)" clip-path="url(#clip-path-207)">
        <g id="Сгруппировать_1742" data-name="Сгруппировать 1742" transform="translate(-808.059 -307.891)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1741" data-name="Сгруппировать 1741" transform="translate(807.786 307.687)" style="isolation: isolate">
            <g id="Сгруппировать_1740" data-name="Сгруппировать 1740" transform="translate(0)" clip-path="url(#clip-path-209)">
              <g id="Сгруппировать_1739" data-name="Сгруппировать 1739" transform="translate(0.273 0.204)" clip-path="url(#clip-path-207)">
                <g id="Сгруппировать_1738" data-name="Сгруппировать 1738" transform="translate(-0.273 -0.204)" clip-path="url(#clip-path-211)">
                  <g id="Сгруппировать_1736" data-name="Сгруппировать 1736" transform="translate(-428.161 -28.602)" opacity="0">
                    <g id="Сгруппировать_1735" data-name="Сгруппировать 1735" transform="translate(0 0)">
                      <path id="Контур_1963" data-name="Контур 1963" d="M-988.63-66.471h602.372V333.955H-988.63Z" transform="translate(988.63 66.471)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1737" data-name="Сгруппировать 1737" transform="translate(-428.161 -28.602)">
                    <path id="Контур_1964" data-name="Контур 1964" d="M-988.63-66.471h602.372V333.955H-988.63Z" transform="translate(988.63 66.471)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1748" data-name="Сгруппировать 1748" transform="translate(484.676 -1073.806)" clip-path="url(#clip-path-212)">
        <g id="Сгруппировать_1747" data-name="Сгруппировать 1747" transform="translate(-864.11 -307.891)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1745" data-name="Сгруппировать 1745" transform="translate(379.625 279.085)" opacity="0">
            <g id="Сгруппировать_1744" data-name="Сгруппировать 1744" transform="translate(0 0)">
              <path id="Контур_1969" data-name="Контур 1969" d="M-1117.97-66.471H-515.6V333.955H-1117.97Z" transform="translate(1117.97 66.471)" fill="#c4d3e0"/>
            </g>
          </g>
          <g id="Сгруппировать_1746" data-name="Сгруппировать 1746" transform="translate(379.625 279.085)">
            <path id="Контур_1970" data-name="Контур 1970" d="M-1117.97-66.471H-515.6V333.955H-1117.97Z" transform="translate(1117.97 66.471)" fill="#c4d3e0"/>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1757" data-name="Сгруппировать 1757" transform="translate(328.384 -995.923)" clip-path="url(#clip-path-214)">
        <g id="Сгруппировать_1756" data-name="Сгруппировать 1756" transform="translate(-707.818 -385.774)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1755" data-name="Сгруппировать 1755" transform="translate(707.679 385.692)" style="isolation: isolate">
            <g id="Сгруппировать_1754" data-name="Сгруппировать 1754" transform="translate(0)" clip-path="url(#clip-path-216)">
              <g id="Сгруппировать_1753" data-name="Сгруппировать 1753" transform="translate(0.139 0.082)" clip-path="url(#clip-path-214)">
                <g id="Сгруппировать_1752" data-name="Сгруппировать 1752" transform="translate(-0.139 -0.082)" clip-path="url(#clip-path-218)">
                  <g id="Сгруппировать_1750" data-name="Сгруппировать 1750" transform="translate(-328.055 -106.607)" opacity="0">
                    <g id="Сгруппировать_1749" data-name="Сгруппировать 1749" transform="translate(0 0)">
                      <path id="Контур_1973" data-name="Контур 1973" d="M-757.32-246.189h602.373V154.237H-757.32Z" transform="translate(757.32 246.189)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1751" data-name="Сгруппировать 1751" transform="translate(-328.055 -106.607)">
                    <path id="Контур_1974" data-name="Контур 1974" d="M-757.32-246.189h602.373V154.237H-757.32Z" transform="translate(757.32 246.189)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1766" data-name="Сгруппировать 1766" transform="translate(447.944 -1071.286)" clip-path="url(#clip-path-219)">
        <g id="Сгруппировать_1765" data-name="Сгруппировать 1765" transform="translate(-827.378 -310.411)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1764" data-name="Сгруппировать 1764" transform="translate(827.287 310.287)" style="isolation: isolate">
            <g id="Сгруппировать_1763" data-name="Сгруппировать 1763" transform="translate(0)" clip-path="url(#clip-path-221)">
              <g id="Сгруппировать_1762" data-name="Сгруппировать 1762" transform="translate(0.091 0.124)" clip-path="url(#clip-path-219)">
                <g id="Сгруппировать_1761" data-name="Сгруппировать 1761" transform="translate(-0.091 -0.124)" clip-path="url(#clip-path-223)">
                  <g id="Сгруппировать_1759" data-name="Сгруппировать 1759" transform="translate(-447.662 -31.202)" opacity="0">
                    <g id="Сгруппировать_1758" data-name="Сгруппировать 1758" transform="translate(0 0)">
                      <path id="Контур_1979" data-name="Контур 1979" d="M-1033.21-72.286h602.373V328.14H-1033.21Z" transform="translate(1033.21 72.286)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1760" data-name="Сгруппировать 1760" transform="translate(-447.662 -31.202)">
                    <path id="Контур_1980" data-name="Контур 1980" d="M-1033.21-72.286h602.373V328.14H-1033.21Z" transform="translate(1033.21 72.286)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1775" data-name="Сгруппировать 1775" transform="translate(447.944 -1066.263)" clip-path="url(#clip-path-224)">
        <g id="Сгруппировать_1774" data-name="Сгруппировать 1774" transform="translate(-827.378 -315.434)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1773" data-name="Сгруппировать 1773" transform="translate(827.287 315.054)" style="isolation: isolate">
            <g id="Сгруппировать_1772" data-name="Сгруппировать 1772" transform="translate(0)" clip-path="url(#clip-path-226)">
              <g id="Сгруппировать_1771" data-name="Сгруппировать 1771" transform="translate(0.091 0.38)" clip-path="url(#clip-path-224)">
                <g id="Сгруппировать_1770" data-name="Сгруппировать 1770" transform="translate(-0.091 -0.38)" clip-path="url(#clip-path-228)">
                  <g id="Сгруппировать_1768" data-name="Сгруппировать 1768" transform="translate(-447.662 -35.969)" opacity="0">
                    <g id="Сгруппировать_1767" data-name="Сгруппировать 1767" transform="translate(0 0)">
                      <path id="Контур_1985" data-name="Контур 1985" d="M-1033.21-83.877h602.373V316.549H-1033.21Z" transform="translate(1033.21 83.877)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1769" data-name="Сгруппировать 1769" transform="translate(-447.662 -35.969)">
                    <path id="Контур_1986" data-name="Контур 1986" d="M-1033.21-83.877h602.373V316.549H-1033.21Z" transform="translate(1033.21 83.877)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1784" data-name="Сгруппировать 1784" transform="translate(447.944 -1054.095)" clip-path="url(#clip-path-229)">
        <g id="Сгруппировать_1783" data-name="Сгруппировать 1783" transform="translate(-827.378 -327.601)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1782" data-name="Сгруппировать 1782" transform="translate(827.287 327.188)" style="isolation: isolate">
            <g id="Сгруппировать_1781" data-name="Сгруппировать 1781" transform="translate(0)" clip-path="url(#clip-path-226)">
              <g id="Сгруппировать_1780" data-name="Сгруппировать 1780" transform="translate(0.091 0.413)" clip-path="url(#clip-path-229)">
                <g id="Сгруппировать_1779" data-name="Сгруппировать 1779" transform="translate(-0.091 -0.413)" clip-path="url(#clip-path-233)">
                  <g id="Сгруппировать_1777" data-name="Сгруппировать 1777" transform="translate(-447.662 -48.103)" opacity="0">
                    <g id="Сгруппировать_1776" data-name="Сгруппировать 1776" transform="translate(0 0)">
                      <path id="Контур_1991" data-name="Контур 1991" d="M-1033.21-111.954h602.373V288.472H-1033.21Z" transform="translate(1033.21 111.954)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1778" data-name="Сгруппировать 1778" transform="translate(-447.662 -48.103)">
                    <path id="Контур_1992" data-name="Контур 1992" d="M-1033.21-111.954h602.373V288.472H-1033.21Z" transform="translate(1033.21 111.954)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1793" data-name="Сгруппировать 1793" transform="translate(447.944 -1042.957)" clip-path="url(#clip-path-234)">
        <g id="Сгруппировать_1792" data-name="Сгруппировать 1792" transform="translate(-827.378 -338.74)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1791" data-name="Сгруппировать 1791" transform="translate(827.287 338.455)" style="isolation: isolate">
            <g id="Сгруппировать_1790" data-name="Сгруппировать 1790" transform="translate(0)" clip-path="url(#clip-path-236)">
              <g id="Сгруппировать_1789" data-name="Сгруппировать 1789" transform="translate(0.091 0.285)" clip-path="url(#clip-path-234)">
                <g id="Сгруппировать_1788" data-name="Сгруппировать 1788" transform="translate(-0.091 -0.285)" clip-path="url(#clip-path-238)">
                  <g id="Сгруппировать_1786" data-name="Сгруппировать 1786" transform="translate(-447.662 -59.371)" opacity="0">
                    <g id="Сгруппировать_1785" data-name="Сгруппировать 1785" transform="translate(0 0)">
                      <path id="Контур_1997" data-name="Контур 1997" d="M-1033.21-137.657h602.373V262.769H-1033.21Z" transform="translate(1033.21 137.657)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1787" data-name="Сгруппировать 1787" transform="translate(-447.662 -59.371)">
                    <path id="Контур_1998" data-name="Контур 1998" d="M-1033.21-137.657h602.373V262.769H-1033.21Z" transform="translate(1033.21 137.657)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1802" data-name="Сгруппировать 1802" transform="translate(447.944 -1031.132)" clip-path="url(#clip-path-239)">
        <g id="Сгруппировать_1801" data-name="Сгруппировать 1801" transform="translate(-827.378 -350.564)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1800" data-name="Сгруппировать 1800" transform="translate(827.287 350.156)" style="isolation: isolate">
            <g id="Сгруппировать_1799" data-name="Сгруппировать 1799" transform="translate(0)" clip-path="url(#clip-path-226)">
              <g id="Сгруппировать_1798" data-name="Сгруппировать 1798" transform="translate(0.091 0.408)" clip-path="url(#clip-path-239)">
                <g id="Сгруппировать_1797" data-name="Сгруппировать 1797" transform="translate(-0.091 -0.408)" clip-path="url(#clip-path-243)">
                  <g id="Сгруппировать_1795" data-name="Сгруппировать 1795" transform="translate(-447.662 -71.071)" opacity="0">
                    <g id="Сгруппировать_1794" data-name="Сгруппировать 1794" transform="translate(0 0)">
                      <path id="Контур_2003" data-name="Контур 2003" d="M-1033.21-164.942h602.373V235.484H-1033.21Z" transform="translate(1033.21 164.942)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1796" data-name="Сгруппировать 1796" transform="translate(-447.662 -71.071)">
                    <path id="Контур_2004" data-name="Контур 2004" d="M-1033.21-164.942h602.373V235.484H-1033.21Z" transform="translate(1033.21 164.942)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1811" data-name="Сгруппировать 1811" transform="translate(347.27 -974.039)" clip-path="url(#clip-path-244)">
        <g id="Сгруппировать_1810" data-name="Сгруппировать 1810" transform="translate(-726.704 -407.658)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1809" data-name="Сгруппировать 1809" transform="translate(726.314 407.36)" style="isolation: isolate">
            <g id="Сгруппировать_1808" data-name="Сгруппировать 1808" transform="translate(0 0)" clip-path="url(#clip-path-246)">
              <g id="Сгруппировать_1807" data-name="Сгруппировать 1807" transform="translate(0.39 0.298)" clip-path="url(#clip-path-244)">
                <g id="Сгруппировать_1806" data-name="Сгруппировать 1806" transform="translate(-0.39 -0.298)" clip-path="url(#clip-path-248)">
                  <g id="Сгруппировать_1804" data-name="Сгруппировать 1804" transform="translate(-346.689 -128.275)" opacity="0">
                    <g id="Сгруппировать_1803" data-name="Сгруппировать 1803">
                      <path id="Контур_2009" data-name="Контур 2009" d="M-800.9-296.688h602.372V103.738H-800.9Z" transform="translate(800.9 296.688)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1805" data-name="Сгруппировать 1805" transform="translate(-346.689 -128.275)">
                    <path id="Контур_2010" data-name="Контур 2010" d="M-800.9-296.688h602.372V103.738H-800.9Z" transform="translate(800.9 296.688)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1820" data-name="Сгруппировать 1820" transform="translate(402.996 -965.19)" clip-path="url(#clip-path-249)">
        <g id="Сгруппировать_1819" data-name="Сгруппировать 1819" transform="translate(-782.43 -416.506)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1818" data-name="Сгруппировать 1818" transform="translate(782.217 416.46)" style="isolation: isolate">
            <g id="Сгруппировать_1817" data-name="Сгруппировать 1817" transform="translate(0 0)" clip-path="url(#clip-path-251)">
              <g id="Сгруппировать_1816" data-name="Сгруппировать 1816" transform="translate(0.212 0.046)" clip-path="url(#clip-path-249)">
                <g id="Сгруппировать_1815" data-name="Сгруппировать 1815" transform="translate(-0.212 -0.046)" clip-path="url(#clip-path-253)">
                  <g id="Сгруппировать_1813" data-name="Сгруппировать 1813" transform="translate(-402.593 -137.376)" opacity="0">
                    <g id="Сгруппировать_1812" data-name="Сгруппировать 1812" transform="translate(0 0)">
                      <path id="Контур_2015" data-name="Контур 2015" d="M-929.49-317.106h602.372V83.32H-929.49Z" transform="translate(929.49 317.106)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1814" data-name="Сгруппировать 1814" transform="translate(-402.593 -137.376)">
                    <path id="Контур_2016" data-name="Контур 2016" d="M-929.49-317.106h602.372V83.32H-929.49Z" transform="translate(929.49 317.106)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1829" data-name="Сгруппировать 1829" transform="translate(407.958 -965.533)" clip-path="url(#clip-path-254)">
        <g id="Сгруппировать_1828" data-name="Сгруппировать 1828" transform="translate(-787.392 -416.164)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1827" data-name="Сгруппировать 1827" transform="translate(786.984 416.027)" style="isolation: isolate">
            <g id="Сгруппировать_1826" data-name="Сгруппировать 1826" transform="translate(0 0)" clip-path="url(#clip-path-256)">
              <g id="Сгруппировать_1825" data-name="Сгруппировать 1825" transform="translate(0.407 0.137)" clip-path="url(#clip-path-254)">
                <g id="Сгруппировать_1824" data-name="Сгруппировать 1824" transform="translate(-0.407 -0.137)" clip-path="url(#clip-path-258)">
                  <g id="Сгруппировать_1822" data-name="Сгруппировать 1822" transform="translate(-407.36 -136.942)" opacity="0">
                    <g id="Сгруппировать_1821" data-name="Сгруппировать 1821">
                      <path id="Контур_2021" data-name="Контур 2021" d="M-940.94-316.315h602.373V84.111H-940.94Z" transform="translate(940.94 316.315)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1823" data-name="Сгруппировать 1823" transform="translate(-407.36 -136.942)">
                    <path id="Контур_2022" data-name="Контур 2022" d="M-940.94-316.315h602.373V84.111H-940.94Z" transform="translate(940.94 316.315)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1838" data-name="Сгруппировать 1838" transform="translate(386.385 -982.857)" clip-path="url(#clip-path-259)">
        <g id="Сгруппировать_1837" data-name="Сгруппировать 1837" transform="translate(-765.819 -398.839)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1836" data-name="Сгруппировать 1836" transform="translate(765.75 398.693)" style="isolation: isolate">
            <g id="Сгруппировать_1835" data-name="Сгруппировать 1835" clip-path="url(#clip-path-261)">
              <g id="Сгруппировать_1834" data-name="Сгруппировать 1834" transform="translate(0.069 0.147)" clip-path="url(#clip-path-259)">
                <g id="Сгруппировать_1833" data-name="Сгруппировать 1833" transform="translate(-0.069 -0.147)" clip-path="url(#clip-path-263)">
                  <g id="Сгруппировать_1831" data-name="Сгруппировать 1831" transform="translate(-386.125 -119.608)" opacity="0">
                    <g id="Сгруппировать_1830" data-name="Сгруппировать 1830">
                      <path id="Контур_2027" data-name="Контур 2027" d="M-891.16-276.339h602.373V124.087H-891.16Z" transform="translate(891.16 276.339)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1832" data-name="Сгруппировать 1832" transform="translate(-386.125 -119.608)">
                    <path id="Контур_2028" data-name="Контур 2028" d="M-891.16-276.339h602.373V124.087H-891.16Z" transform="translate(891.16 276.339)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1847" data-name="Сгруппировать 1847" transform="translate(386.039 -982.835)" clip-path="url(#clip-path-264)">
        <g id="Сгруппировать_1846" data-name="Сгруппировать 1846" transform="translate(-765.472 -398.862)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1845" data-name="Сгруппировать 1845" transform="translate(765.316 398.693)" style="isolation: isolate">
            <g id="Сгруппировать_1844" data-name="Сгруппировать 1844" transform="translate(0)" clip-path="url(#clip-path-266)">
              <g id="Сгруппировать_1843" data-name="Сгруппировать 1843" transform="translate(0.156 0.169)" clip-path="url(#clip-path-264)">
                <g id="Сгруппировать_1842" data-name="Сгруппировать 1842" transform="translate(-0.156 -0.169)" clip-path="url(#clip-path-268)">
                  <g id="Сгруппировать_1840" data-name="Сгруппировать 1840" transform="translate(-385.692 -119.608)" opacity="0">
                    <g id="Сгруппировать_1839" data-name="Сгруппировать 1839">
                      <path id="Контур_2033" data-name="Контур 2033" d="M-890.36-276.39h602.372V124.036H-890.36Z" transform="translate(890.36 276.39)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1841" data-name="Сгруппировать 1841" transform="translate(-385.692 -119.608)">
                    <path id="Контур_2034" data-name="Контур 2034" d="M-890.36-276.39h602.372V124.036H-890.36Z" transform="translate(890.36 276.39)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1856" data-name="Сгруппировать 1856" transform="translate(355.838 -982.857)" clip-path="url(#clip-path-269)">
        <g id="Сгруппировать_1855" data-name="Сгруппировать 1855" transform="translate(-735.271 -398.839)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1854" data-name="Сгруппировать 1854" transform="translate(734.981 398.693)" style="isolation: isolate">
            <g id="Сгруппировать_1853" data-name="Сгруппировать 1853" clip-path="url(#clip-path-261)">
              <g id="Сгруппировать_1852" data-name="Сгруппировать 1852" transform="translate(0.29 0.147)" clip-path="url(#clip-path-269)">
                <g id="Сгруппировать_1851" data-name="Сгруппировать 1851" transform="translate(-0.29 -0.147)" clip-path="url(#clip-path-273)">
                  <g id="Сгруппировать_1849" data-name="Сгруппировать 1849" transform="translate(-355.356 -119.608)" opacity="0">
                    <g id="Сгруппировать_1848" data-name="Сгруппировать 1848">
                      <path id="Контур_2039" data-name="Контур 2039" d="M-820.67-276.339H-218.3V124.087H-820.67Z" transform="translate(820.67 276.339)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1850" data-name="Сгруппировать 1850" transform="translate(-355.356 -119.608)">
                    <path id="Контур_2040" data-name="Контур 2040" d="M-820.67-276.339H-218.3V124.087H-820.67Z" transform="translate(820.67 276.339)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1865" data-name="Сгруппировать 1865" transform="translate(355.495 -982.835)" clip-path="url(#clip-path-274)">
        <g id="Сгруппировать_1864" data-name="Сгруппировать 1864" transform="translate(-734.929 -398.862)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1863" data-name="Сгруппировать 1863" transform="translate(734.548 398.693)" style="isolation: isolate">
            <g id="Сгруппировать_1862" data-name="Сгруппировать 1862" transform="translate(0)" clip-path="url(#clip-path-276)">
              <g id="Сгруппировать_1861" data-name="Сгруппировать 1861" transform="translate(0.381 0.169)" clip-path="url(#clip-path-274)">
                <g id="Сгруппировать_1860" data-name="Сгруппировать 1860" transform="translate(-0.381 -0.169)" clip-path="url(#clip-path-278)">
                  <g id="Сгруппировать_1858" data-name="Сгруппировать 1858" transform="translate(-354.923 -119.608)" opacity="0">
                    <g id="Сгруппировать_1857" data-name="Сгруппировать 1857">
                      <path id="Контур_2045" data-name="Контур 2045" d="M-819.88-276.39h602.372V124.036H-819.88Z" transform="translate(819.88 276.39)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1859" data-name="Сгруппировать 1859" transform="translate(-354.923 -119.608)">
                    <path id="Контур_2046" data-name="Контур 2046" d="M-819.88-276.39h602.372V124.036H-819.88Z" transform="translate(819.88 276.39)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1874" data-name="Сгруппировать 1874" transform="translate(371.109 -983.156)" clip-path="url(#clip-path-279)">
        <g id="Сгруппировать_1873" data-name="Сгруппировать 1873" transform="translate(-750.543 -398.54)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1872" data-name="Сгруппировать 1872" transform="translate(750.149 398.259)" style="isolation: isolate">
            <g id="Сгруппировать_1871" data-name="Сгруппировать 1871" transform="translate(0)" clip-path="url(#clip-path-281)">
              <g id="Сгруппировать_1870" data-name="Сгруппировать 1870" transform="translate(0.394 0.281)" clip-path="url(#clip-path-279)">
                <g id="Сгруппировать_1869" data-name="Сгруппировать 1869" transform="translate(-0.394 -0.281)" clip-path="url(#clip-path-283)">
                  <g id="Сгруппировать_1867" data-name="Сгруппировать 1867" transform="translate(-370.524 -119.174)" opacity="0">
                    <g id="Сгруппировать_1866" data-name="Сгруппировать 1866">
                      <path id="Контур_2051" data-name="Контур 2051" d="M-855.91-275.649h602.373V124.777H-855.91Z" transform="translate(855.91 275.649)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1868" data-name="Сгруппировать 1868" transform="translate(-370.524 -119.174)">
                    <path id="Контур_2052" data-name="Контур 2052" d="M-855.91-275.649h602.373V124.777H-855.91Z" transform="translate(855.91 275.649)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1883" data-name="Сгруппировать 1883" transform="translate(370.767 -983.135)" clip-path="url(#clip-path-284)">
        <g id="Сгруппировать_1882" data-name="Сгруппировать 1882" transform="translate(-750.201 -398.562)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1881" data-name="Сгруппировать 1881" transform="translate(750.149 398.259)" style="isolation: isolate">
            <g id="Сгруппировать_1880" data-name="Сгруппировать 1880" transform="translate(0)" clip-path="url(#clip-path-286)">
              <g id="Сгруппировать_1879" data-name="Сгруппировать 1879" transform="translate(0.052 0.303)" clip-path="url(#clip-path-284)">
                <g id="Сгруппировать_1878" data-name="Сгруппировать 1878" transform="translate(-0.052 -0.303)" clip-path="url(#clip-path-288)">
                  <g id="Сгруппировать_1876" data-name="Сгруппировать 1876" transform="translate(-370.524 -119.174)" opacity="0">
                    <g id="Сгруппировать_1875" data-name="Сгруппировать 1875" transform="translate(0 0)">
                      <path id="Контур_2057" data-name="Контур 2057" d="M-855.12-275.7h602.372V124.727H-855.12Z" transform="translate(855.12 275.699)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1877" data-name="Сгруппировать 1877" transform="translate(-370.524 -119.174)">
                    <path id="Контур_2058" data-name="Контур 2058" d="M-855.12-275.7h602.372V124.727H-855.12Z" transform="translate(855.12 275.699)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1892" data-name="Сгруппировать 1892" transform="translate(356.873 -944.699)" clip-path="url(#clip-path-289)">
        <g id="Сгруппировать_1891" data-name="Сгруппировать 1891" transform="translate(-736.307 -436.997)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1890" data-name="Сгруппировать 1890" transform="translate(736.281 436.828)" style="isolation: isolate">
            <g id="Сгруппировать_1889" data-name="Сгруппировать 1889" transform="translate(0)" clip-path="url(#clip-path-261)">
              <g id="Сгруппировать_1888" data-name="Сгруппировать 1888" transform="translate(0.026 0.169)" clip-path="url(#clip-path-289)">
                <g id="Сгруппировать_1887" data-name="Сгруппировать 1887" transform="translate(-0.026 -0.169)" clip-path="url(#clip-path-293)">
                  <g id="Сгруппировать_1885" data-name="Сгруппировать 1885" transform="translate(-356.657 -157.744)" opacity="0">
                    <g id="Сгруппировать_1884" data-name="Сгруппировать 1884">
                      <path id="Контур_2063" data-name="Контур 2063" d="M-823.06-364.39h602.373V36.036H-823.06Z" transform="translate(823.06 364.39)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1886" data-name="Сгруппировать 1886" transform="translate(-356.657 -157.744)">
                    <path id="Контур_2064" data-name="Контур 2064" d="M-823.06-364.39h602.373V36.036H-823.06Z" transform="translate(823.06 364.39)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1901" data-name="Сгруппировать 1901" transform="translate(356.609 -939.737)" clip-path="url(#clip-path-294)">
        <g id="Сгруппировать_1900" data-name="Сгруппировать 1900" transform="translate(-736.043 -441.959)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1899" data-name="Сгруппировать 1899" transform="translate(735.848 441.595)" style="isolation: isolate">
            <g id="Сгруппировать_1898" data-name="Сгруппировать 1898" clip-path="url(#clip-path-286)">
              <g id="Сгруппировать_1897" data-name="Сгруппировать 1897" transform="translate(0.195 0.364)" clip-path="url(#clip-path-294)">
                <g id="Сгруппировать_1896" data-name="Сгруппировать 1896" transform="translate(-0.195 -0.364)" clip-path="url(#clip-path-298)">
                  <g id="Сгруппировать_1894" data-name="Сгруппировать 1894" transform="translate(-356.223 -162.511)" opacity="0">
                    <g id="Сгруппировать_1893" data-name="Сгруппировать 1893" transform="translate(0 0)">
                      <path id="Контур_2069" data-name="Контур 2069" d="M-822.45-375.84h602.372V24.586H-822.45Z" transform="translate(822.45 375.84)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1895" data-name="Сгруппировать 1895" transform="translate(-356.223 -162.511)">
                    <path id="Контур_2070" data-name="Контур 2070" d="M-822.45-375.84h602.372V24.586H-822.45Z" transform="translate(822.45 375.84)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1910" data-name="Сгруппировать 1910" transform="translate(387.417 -944.699)" clip-path="url(#clip-path-299)">
        <g id="Сгруппировать_1909" data-name="Сгруппировать 1909" transform="translate(-766.85 -436.997)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1908" data-name="Сгруппировать 1908" transform="translate(766.616 436.828)" style="isolation: isolate">
            <g id="Сгруппировать_1907" data-name="Сгруппировать 1907" clip-path="url(#clip-path-261)">
              <g id="Сгруппировать_1906" data-name="Сгруппировать 1906" transform="translate(0.234 0.169)" clip-path="url(#clip-path-299)">
                <g id="Сгруппировать_1905" data-name="Сгруппировать 1905" transform="translate(-0.234 -0.169)" clip-path="url(#clip-path-303)">
                  <g id="Сгруппировать_1903" data-name="Сгруппировать 1903" transform="translate(-386.992 -157.744)" opacity="0">
                    <g id="Сгруппировать_1902" data-name="Сгруппировать 1902">
                      <path id="Контур_2075" data-name="Контур 2075" d="M-893.54-364.39h602.373V36.036H-893.54Z" transform="translate(893.54 364.39)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1904" data-name="Сгруппировать 1904" transform="translate(-386.992 -157.744)">
                    <path id="Контур_2076" data-name="Контур 2076" d="M-893.54-364.39h602.373V36.036H-893.54Z" transform="translate(893.54 364.39)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1919" data-name="Сгруппировать 1919" transform="translate(387.152 -939.737)" clip-path="url(#clip-path-304)">
        <g id="Сгруппировать_1918" data-name="Сгруппировать 1918" transform="translate(-766.586 -441.959)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1917" data-name="Сгруппировать 1917" transform="translate(766.183 441.595)" style="isolation: isolate">
            <g id="Сгруппировать_1916" data-name="Сгруппировать 1916" transform="translate(0)" clip-path="url(#clip-path-306)">
              <g id="Сгруппировать_1915" data-name="Сгруппировать 1915" transform="translate(0.403 0.364)" clip-path="url(#clip-path-304)">
                <g id="Сгруппировать_1914" data-name="Сгруппировать 1914" transform="translate(-0.403 -0.364)" clip-path="url(#clip-path-308)">
                  <g id="Сгруппировать_1912" data-name="Сгруппировать 1912" transform="translate(-386.558 -162.511)" opacity="0">
                    <g id="Сгруппировать_1911" data-name="Сгруппировать 1911" transform="translate(0 0)">
                      <path id="Контур_2081" data-name="Контур 2081" d="M-892.93-375.84h602.373V24.586H-892.93Z" transform="translate(892.93 375.84)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1913" data-name="Сгруппировать 1913" transform="translate(-386.558 -162.511)">
                    <path id="Контур_2082" data-name="Контур 2082" d="M-892.93-375.84h602.373V24.586H-892.93Z" transform="translate(892.93 375.84)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1928" data-name="Сгруппировать 1928" transform="translate(372.145 -944.4)" clip-path="url(#clip-path-309)">
        <g id="Сгруппировать_1927" data-name="Сгруппировать 1927" transform="translate(-751.579 -437.296)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1926" data-name="Сгруппировать 1926" transform="translate(751.449 437.262)" style="isolation: isolate">
            <g id="Сгруппировать_1925" data-name="Сгруппировать 1925" transform="translate(0)" clip-path="url(#clip-path-261)">
              <g id="Сгруппировать_1924" data-name="Сгруппировать 1924" transform="translate(0.13 0.035)" clip-path="url(#clip-path-309)">
                <g id="Сгруппировать_1923" data-name="Сгруппировать 1923" transform="translate(-0.13 -0.035)" clip-path="url(#clip-path-313)">
                  <g id="Сгруппировать_1921" data-name="Сгруппировать 1921" transform="translate(-371.824 -158.177)" opacity="0">
                    <g id="Сгруппировать_1920" data-name="Сгруппировать 1920" transform="translate(0 0)">
                      <path id="Контур_2087" data-name="Контур 2087" d="M-858.3-365.08h602.372V35.346H-858.3Z" transform="translate(858.3 365.08)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1922" data-name="Сгруппировать 1922" transform="translate(-371.824 -158.177)">
                    <path id="Контур_2088" data-name="Контур 2088" d="M-858.3-365.08h602.372V35.346H-858.3Z" transform="translate(858.3 365.08)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1937" data-name="Сгруппировать 1937" transform="translate(371.881 -939.438)" clip-path="url(#clip-path-314)">
        <g id="Сгруппировать_1936" data-name="Сгруппировать 1936" transform="translate(-751.314 -442.258)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1935" data-name="Сгруппировать 1935" transform="translate(751.015 442.029)" style="isolation: isolate">
            <g id="Сгруппировать_1934" data-name="Сгруппировать 1934" transform="translate(0)" clip-path="url(#clip-path-306)">
              <g id="Сгруппировать_1933" data-name="Сгруппировать 1933" transform="translate(0.299 0.23)" clip-path="url(#clip-path-314)">
                <g id="Сгруппировать_1932" data-name="Сгруппировать 1932" transform="translate(-0.299 -0.23)" clip-path="url(#clip-path-318)">
                  <g id="Сгруппировать_1930" data-name="Сгруппировать 1930" transform="translate(-371.391 -162.944)" opacity="0">
                    <g id="Сгруппировать_1929" data-name="Сгруппировать 1929" transform="translate(0 0)">
                      <path id="Контур_2093" data-name="Контур 2093" d="M-857.69-376.53h602.372V23.9H-857.69Z" transform="translate(857.69 376.53)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1931" data-name="Сгруппировать 1931" transform="translate(-371.391 -162.944)">
                    <path id="Контур_2094" data-name="Контур 2094" d="M-857.69-376.53h602.372V23.9H-857.69Z" transform="translate(857.69 376.53)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1946" data-name="Сгруппировать 1946" transform="translate(339.626 -965.15)" clip-path="url(#clip-path-319)">
        <g id="Сгруппировать_1945" data-name="Сгруппировать 1945" transform="translate(-719.059 -416.546)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1944" data-name="Сгруппировать 1944" transform="translate(718.947 416.46)" style="isolation: isolate">
            <g id="Сгруппировать_1943" data-name="Сгруппировать 1943" transform="translate(0 0)" clip-path="url(#clip-path-251)">
              <g id="Сгруппировать_1942" data-name="Сгруппировать 1942" transform="translate(0.113 0.086)" clip-path="url(#clip-path-319)">
                <g id="Сгруппировать_1941" data-name="Сгруппировать 1941" transform="translate(-0.113 -0.086)" clip-path="url(#clip-path-323)">
                  <g id="Сгруппировать_1939" data-name="Сгруппировать 1939" transform="translate(-339.322 -137.376)" opacity="0">
                    <g id="Сгруппировать_1938" data-name="Сгруппировать 1938">
                      <path id="Контур_2099" data-name="Контур 2099" d="M-783.26-317.2h602.372V83.228H-783.26Z" transform="translate(783.26 317.198)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1940" data-name="Сгруппировать 1940" transform="translate(-339.322 -137.376)">
                    <path id="Контур_2100" data-name="Контур 2100" d="M-783.26-317.2h602.372V83.228H-783.26Z" transform="translate(783.26 317.198)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1955" data-name="Сгруппировать 1955" transform="translate(339.647 -965.494)" clip-path="url(#clip-path-324)">
        <g id="Сгруппировать_1954" data-name="Сгруппировать 1954" transform="translate(-719.081 -416.203)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1953" data-name="Сгруппировать 1953" transform="translate(718.947 416.027)" style="isolation: isolate">
            <g id="Сгруппировать_1952" data-name="Сгруппировать 1952" transform="translate(0 0)" clip-path="url(#clip-path-326)">
              <g id="Сгруппировать_1951" data-name="Сгруппировать 1951" transform="translate(0.134 0.176)" clip-path="url(#clip-path-324)">
                <g id="Сгруппировать_1950" data-name="Сгруппировать 1950" transform="translate(-0.134 -0.176)" clip-path="url(#clip-path-328)">
                  <g id="Сгруппировать_1948" data-name="Сгруппировать 1948" transform="translate(-339.322 -136.942)" opacity="0">
                    <g id="Сгруппировать_1947" data-name="Сгруппировать 1947">
                      <path id="Контур_2105" data-name="Контур 2105" d="M-783.31-316.406h602.373V84.02H-783.31Z" transform="translate(783.31 316.406)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1949" data-name="Сгруппировать 1949" transform="translate(-339.322 -136.942)">
                    <path id="Контур_2106" data-name="Контур 2106" d="M-783.31-316.406h602.373V84.02H-783.31Z" transform="translate(783.31 316.406)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1964" data-name="Сгруппировать 1964" transform="translate(491.211 -1070.159)" clip-path="url(#clip-path-329)">
        <g id="Сгруппировать_1963" data-name="Сгруппировать 1963" transform="translate(-870.645 -311.538)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1962" data-name="Сгруппировать 1962" transform="translate(870.623 311.154)" style="isolation: isolate">
            <g id="Сгруппировать_1961" data-name="Сгруппировать 1961" clip-path="url(#clip-path-331)">
              <g id="Сгруппировать_1960" data-name="Сгруппировать 1960" transform="translate(0.022 0.384)" clip-path="url(#clip-path-329)">
                <g id="Сгруппировать_1959" data-name="Сгруппировать 1959" transform="translate(-0.022 -0.384)" clip-path="url(#clip-path-333)">
                  <g id="Сгруппировать_1957" data-name="Сгруппировать 1957" transform="translate(-490.999 -32.069)" opacity="0">
                    <g id="Сгруппировать_1956" data-name="Сгруппировать 1956" transform="translate(0 0)">
                      <path id="Контур_2111" data-name="Контур 2111" d="M-1133.05-74.887h602.372V325.539H-1133.05Z" transform="translate(1133.05 74.887)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1958" data-name="Сгруппировать 1958" transform="translate(-490.999 -32.069)">
                    <path id="Контур_2112" data-name="Контур 2112" d="M-1133.05-74.887h602.372V325.539H-1133.05Z" transform="translate(1133.05 74.887)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1973" data-name="Сгруппировать 1973" transform="translate(488.212 -1051.537)" clip-path="url(#clip-path-334)">
        <g id="Сгруппировать_1972" data-name="Сгруппировать 1972" transform="translate(-867.646 -330.16)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1971" data-name="Сгруппировать 1971" transform="translate(867.59 329.788)" style="isolation: isolate">
            <g id="Сгруппировать_1970" data-name="Сгруппировать 1970" clip-path="url(#clip-path-336)">
              <g id="Сгруппировать_1969" data-name="Сгруппировать 1969" transform="translate(0.056 0.372)" clip-path="url(#clip-path-334)">
                <g id="Сгруппировать_1968" data-name="Сгруппировать 1968" transform="translate(-0.056 -0.372)" clip-path="url(#clip-path-338)">
                  <g id="Сгруппировать_1966" data-name="Сгруппировать 1966" transform="translate(-487.965 -50.703)" opacity="0">
                    <g id="Сгруппировать_1965" data-name="Сгруппировать 1965" transform="translate(0 0)">
                      <path id="Контур_2117" data-name="Контур 2117" d="M-1126.13-117.858h602.372V282.568H-1126.13Z" transform="translate(1126.13 117.858)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1967" data-name="Сгруппировать 1967" transform="translate(-487.965 -50.703)">
                    <path id="Контур_2118" data-name="Контур 2118" d="M-1126.13-117.858h602.372V282.568H-1126.13Z" transform="translate(1126.13 117.858)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1982" data-name="Сгруппировать 1982" transform="translate(488.628 -1049.995)" clip-path="url(#clip-path-339)">
        <g id="Сгруппировать_1981" data-name="Сгруппировать 1981" transform="translate(-868.062 -331.701)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1980" data-name="Сгруппировать 1980" transform="translate(868.023 331.522)" style="isolation: isolate">
            <g id="Сгруппировать_1979" data-name="Сгруппировать 1979" transform="translate(0)" clip-path="url(#clip-path-341)">
              <g id="Сгруппировать_1978" data-name="Сгруппировать 1978" transform="translate(0.039 0.18)" clip-path="url(#clip-path-339)">
                <g id="Сгруппировать_1977" data-name="Сгруппировать 1977" transform="translate(-0.039 -0.18)" clip-path="url(#clip-path-343)">
                  <g id="Сгруппировать_1975" data-name="Сгруппировать 1975" transform="translate(-488.398 -52.437)" opacity="0">
                    <g id="Сгруппировать_1974" data-name="Сгруппировать 1974" transform="translate(0 0)">
                      <path id="Контур_2123" data-name="Контур 2123" d="M-1127.09-121.415h602.373V279.011H-1127.09Z" transform="translate(1127.09 121.415)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1976" data-name="Сгруппировать 1976" transform="translate(-488.398 -52.437)">
                    <path id="Контур_2124" data-name="Контур 2124" d="M-1127.09-121.415h602.373V279.011H-1127.09Z" transform="translate(1127.09 121.415)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_1991" data-name="Сгруппировать 1991" transform="translate(520.688 -1048.681)" clip-path="url(#clip-path-344)">
        <g id="Сгруппировать_1990" data-name="Сгруппировать 1990" transform="translate(-900.122 -333.016)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1989" data-name="Сгруппировать 1989" transform="translate(900.092 332.822)" style="isolation: isolate">
            <g id="Сгруппировать_1988" data-name="Сгруппировать 1988" clip-path="url(#clip-path-346)">
              <g id="Сгруппировать_1987" data-name="Сгруппировать 1987" transform="translate(0.03 0.194)" clip-path="url(#clip-path-344)">
                <g id="Сгруппировать_1986" data-name="Сгруппировать 1986" transform="translate(-0.03 -0.194)" clip-path="url(#clip-path-348)">
                  <g id="Сгруппировать_1984" data-name="Сгруппировать 1984" transform="translate(-520.467 -53.737)" opacity="0">
                    <g id="Сгруппировать_1983" data-name="Сгруппировать 1983">
                      <path id="Контур_2129" data-name="Контур 2129" d="M-1201.07-124.448H-598.7V275.978H-1201.07Z" transform="translate(1201.07 124.448)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1985" data-name="Сгруппировать 1985" transform="translate(-520.467 -53.737)">
                    <path id="Контур_2130" data-name="Контур 2130" d="M-1201.07-124.448H-598.7V275.978H-1201.07Z" transform="translate(1201.07 124.448)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2000" data-name="Сгруппировать 2000" transform="translate(513.16 -1047.024)" clip-path="url(#clip-path-349)">
        <g id="Сгруппировать_1999" data-name="Сгруппировать 1999" transform="translate(-892.594 -334.672)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_1998" data-name="Сгруппировать 1998" transform="translate(892.291 334.555)" style="isolation: isolate">
            <g id="Сгруппировать_1997" data-name="Сгруппировать 1997" transform="translate(0)" clip-path="url(#clip-path-351)">
              <g id="Сгруппировать_1996" data-name="Сгруппировать 1996" transform="translate(0.303 0.117)" clip-path="url(#clip-path-349)">
                <g id="Сгруппировать_1995" data-name="Сгруппировать 1995" transform="translate(-0.303 -0.117)" clip-path="url(#clip-path-353)">
                  <g id="Сгруппировать_1993" data-name="Сгруппировать 1993" transform="translate(-512.667 -55.47)" opacity="0">
                    <g id="Сгруппировать_1992" data-name="Сгруппировать 1992">
                      <path id="Контур_2135" data-name="Контур 2135" d="M-1183.7-128.271h602.372V272.155H-1183.7Z" transform="translate(1183.698 128.271)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_1994" data-name="Сгруппировать 1994" transform="translate(-512.667 -55.47)">
                    <path id="Контур_2136" data-name="Контур 2136" d="M-1183.7-128.271h602.372V272.155H-1183.7Z" transform="translate(1183.698 128.271)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2009" data-name="Сгруппировать 2009" transform="translate(514.271 -1045.872)" clip-path="url(#clip-path-354)">
        <g id="Сгруппировать_2008" data-name="Сгруппировать 2008" transform="translate(-893.705 -335.825)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2007" data-name="Сгруппировать 2007" transform="translate(893.591 335.422)" style="isolation: isolate">
            <g id="Сгруппировать_2006" data-name="Сгруппировать 2006" clip-path="url(#clip-path-356)">
              <g id="Сгруппировать_2005" data-name="Сгруппировать 2005" transform="translate(0.113 0.403)" clip-path="url(#clip-path-354)">
                <g id="Сгруппировать_2004" data-name="Сгруппировать 2004" transform="translate(-0.113 -0.403)" clip-path="url(#clip-path-358)">
                  <g id="Сгруппировать_2002" data-name="Сгруппировать 2002" transform="translate(-513.967 -56.337)" opacity="0">
                    <g id="Сгруппировать_2001" data-name="Сгруппировать 2001">
                      <path id="Контур_2141" data-name="Контур 2141" d="M-1186.261-130.93h602.373V269.5h-602.373Z" transform="translate(1186.261 130.93)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2003" data-name="Сгруппировать 2003" transform="translate(-513.967 -56.337)">
                    <path id="Контур_2142" data-name="Контур 2142" d="M-1186.261-130.93h602.373V269.5h-602.373Z" transform="translate(1186.261 130.93)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2018" data-name="Сгруппировать 2018" transform="translate(521.759 -1044.494)" clip-path="url(#clip-path-359)">
        <g id="Сгруппировать_2017" data-name="Сгруппировать 2017" transform="translate(-901.193 -337.202)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2016" data-name="Сгруппировать 2016" transform="translate(900.959 337.155)" style="isolation: isolate">
            <g id="Сгруппировать_2015" data-name="Сгруппировать 2015" transform="translate(0)" clip-path="url(#clip-path-361)">
              <g id="Сгруппировать_2014" data-name="Сгруппировать 2014" transform="translate(0.234 0.047)" clip-path="url(#clip-path-359)">
                <g id="Сгруппировать_2013" data-name="Сгруппировать 2013" transform="translate(-0.234 -0.047)" clip-path="url(#clip-path-363)">
                  <g id="Сгруппировать_2011" data-name="Сгруппировать 2011" transform="translate(-521.334 -58.07)" opacity="0">
                    <g id="Сгруппировать_2010" data-name="Сгруппировать 2010" transform="translate(0 0)">
                      <path id="Контур_2147" data-name="Контур 2147" d="M-1203.54-134.109h602.373V266.317H-1203.54Z" transform="translate(1203.54 134.109)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2012" data-name="Сгруппировать 2012" transform="translate(-521.334 -58.07)">
                    <path id="Контур_2148" data-name="Контур 2148" d="M-1203.54-134.109h602.373V266.317H-1203.54Z" transform="translate(1203.54 134.109)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2027" data-name="Сгруппировать 2027" transform="translate(484.676 -1029.766)" clip-path="url(#clip-path-364)">
        <g id="Сгруппировать_2026" data-name="Сгруппировать 2026" transform="translate(-864.11 -351.931)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2025" data-name="Сгруппировать 2025" transform="translate(863.689 351.89)" style="isolation: isolate">
            <g id="Сгруппировать_2024" data-name="Сгруппировать 2024" transform="translate(0)" clip-path="url(#clip-path-366)">
              <g id="Сгруппировать_2023" data-name="Сгруппировать 2023" transform="translate(0.42 0.041)" clip-path="url(#clip-path-364)">
                <g id="Сгруппировать_2022" data-name="Сгруппировать 2022" transform="translate(-0.42 -0.041)" clip-path="url(#clip-path-368)">
                  <g id="Сгруппировать_2020" data-name="Сгруппировать 2020" transform="translate(-484.065 -72.805)" opacity="0">
                    <g id="Сгруппировать_2019" data-name="Сгруппировать 2019" transform="translate(0 0)">
                      <path id="Контур_2153" data-name="Контур 2153" d="M-1117.97-168.1H-515.6V232.331H-1117.97Z" transform="translate(1117.97 168.095)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2021" data-name="Сгруппировать 2021" transform="translate(-484.065 -72.805)">
                    <path id="Контур_2154" data-name="Контур 2154" d="M-1117.97-168.1H-515.6V232.331H-1117.97Z" transform="translate(1117.97 168.095)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2036" data-name="Сгруппировать 2036" transform="translate(509.811 -1029.781)" clip-path="url(#clip-path-369)">
        <g id="Сгруппировать_2035" data-name="Сгруппировать 2035" transform="translate(-889.245 -351.916)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2034" data-name="Сгруппировать 2034" transform="translate(888.824 351.89)" style="isolation: isolate">
            <g id="Сгруппировать_2033" data-name="Сгруппировать 2033" transform="translate(0)" clip-path="url(#clip-path-371)">
              <g id="Сгруппировать_2032" data-name="Сгруппировать 2032" transform="translate(0.42 0.026)" clip-path="url(#clip-path-369)">
                <g id="Сгруппировать_2031" data-name="Сгруппировать 2031" transform="translate(-0.42 -0.026)" clip-path="url(#clip-path-373)">
                  <g id="Сгруппировать_2029" data-name="Сгруппировать 2029" transform="translate(-509.2 -72.805)" opacity="0">
                    <g id="Сгруппировать_2028" data-name="Сгруппировать 2028">
                      <path id="Контур_2159" data-name="Контур 2159" d="M-1175.97-168.06H-573.6V232.366H-1175.97Z" transform="translate(1175.97 168.06)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2030" data-name="Сгруппировать 2030" transform="translate(-509.2 -72.805)">
                    <path id="Контур_2160" data-name="Контур 2160" d="M-1175.97-168.06H-573.6V232.366H-1175.97Z" transform="translate(1175.97 168.06)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2045" data-name="Сгруппировать 2045" transform="translate(505.577 -1033.623)" clip-path="url(#clip-path-374)">
        <g id="Сгруппировать_2044" data-name="Сгруппировать 2044" transform="translate(-885.011 -348.074)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2043" data-name="Сгруппировать 2043" transform="translate(884.924 347.556)" style="isolation: isolate">
            <g id="Сгруппировать_2042" data-name="Сгруппировать 2042" transform="translate(0)" clip-path="url(#clip-path-376)">
              <g id="Сгруппировать_2041" data-name="Сгруппировать 2041" transform="translate(0.087 0.518)" clip-path="url(#clip-path-374)">
                <g id="Сгруппировать_2040" data-name="Сгруппировать 2040" transform="translate(-0.087 -0.518)" clip-path="url(#clip-path-378)">
                  <g id="Сгруппировать_2038" data-name="Сгруппировать 2038" transform="translate(-505.299 -68.471)" opacity="0">
                    <g id="Сгруппировать_2037" data-name="Сгруппировать 2037" transform="translate(0 0)">
                      <path id="Контур_2165" data-name="Контур 2165" d="M-1166.2-159.2h602.372V241.23H-1166.2Z" transform="translate(1166.2 159.196)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2039" data-name="Сгруппировать 2039" transform="translate(-505.299 -68.471)">
                    <path id="Контур_2166" data-name="Контур 2166" d="M-1166.2-159.2h602.372V241.23H-1166.2Z" transform="translate(1166.2 159.196)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2054" data-name="Сгруппировать 2054" transform="translate(330.434 -1060.605)" clip-path="url(#clip-path-379)">
        <g id="Сгруппировать_2053" data-name="Сгруппировать 2053" transform="translate(-709.868 -321.091)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2052" data-name="Сгруппировать 2052" transform="translate(709.846 320.687)" style="isolation: isolate">
            <g id="Сгруппировать_2051" data-name="Сгруппировать 2051" transform="translate(0)" clip-path="url(#clip-path-381)">
              <g id="Сгруппировать_2050" data-name="Сгруппировать 2050" transform="translate(0.022 0.404)" clip-path="url(#clip-path-379)">
                <g id="Сгруппировать_2049" data-name="Сгруппировать 2049" transform="translate(-0.022 -0.404)" clip-path="url(#clip-path-383)">
                  <g id="Сгруппировать_2047" data-name="Сгруппировать 2047" transform="translate(-330.221 -41.603)" opacity="0">
                    <g id="Сгруппировать_2046" data-name="Сгруппировать 2046" transform="translate(0 0)">
                      <path id="Контур_2171" data-name="Контур 2171" d="M-762.05-96.932h602.373V303.494H-762.05Z" transform="translate(762.05 96.932)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2048" data-name="Сгруппировать 2048" transform="translate(-330.221 -41.603)">
                    <path id="Контур_2172" data-name="Контур 2172" d="M-762.05-96.932h602.373V303.494H-762.05Z" transform="translate(762.05 96.932)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2063" data-name="Сгруппировать 2063" transform="translate(346.377 -1060.605)" clip-path="url(#clip-path-384)">
        <g id="Сгруппировать_2062" data-name="Сгруппировать 2062" transform="translate(-725.811 -321.091)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2061" data-name="Сгруппировать 2061" transform="translate(725.447 320.687)" style="isolation: isolate">
            <g id="Сгруппировать_2060" data-name="Сгруппировать 2060" clip-path="url(#clip-path-386)">
              <g id="Сгруппировать_2059" data-name="Сгруппировать 2059" transform="translate(0.364 0.404)" clip-path="url(#clip-path-384)">
                <g id="Сгруппировать_2058" data-name="Сгруппировать 2058" transform="translate(-0.364 -0.404)" clip-path="url(#clip-path-388)">
                  <g id="Сгруппировать_2056" data-name="Сгруппировать 2056" transform="translate(-345.822 -41.603)" opacity="0">
                    <g id="Сгруппировать_2055" data-name="Сгруппировать 2055" transform="translate(0 0)">
                      <path id="Контур_2177" data-name="Контур 2177" d="M-798.84-96.932h602.373V303.494H-798.84Z" transform="translate(798.84 96.932)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2057" data-name="Сгруппировать 2057" transform="translate(-345.822 -41.603)">
                    <path id="Контур_2178" data-name="Контур 2178" d="M-798.84-96.932h602.373V303.494H-798.84Z" transform="translate(798.84 96.932)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2072" data-name="Сгруппировать 2072" transform="translate(331.782 -1059.239)" clip-path="url(#clip-path-389)">
        <g id="Сгруппировать_2071" data-name="Сгруппировать 2071" transform="translate(-711.216 -322.457)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2070" data-name="Сгруппировать 2070" transform="translate(711.146 322.421)" style="isolation: isolate">
            <g id="Сгруппировать_2069" data-name="Сгруппировать 2069" clip-path="url(#clip-path-391)">
              <g id="Сгруппировать_2068" data-name="Сгруппировать 2068" transform="translate(0.069 0.036)" clip-path="url(#clip-path-389)">
                <g id="Сгруппировать_2067" data-name="Сгруппировать 2067" transform="translate(-0.069 -0.036)" clip-path="url(#clip-path-393)">
                  <g id="Сгруппировать_2065" data-name="Сгруппировать 2065" transform="translate(-331.522 -43.336)" opacity="0">
                    <g id="Сгруппировать_2064" data-name="Сгруппировать 2064" transform="translate(0 0)">
                      <path id="Контур_2183" data-name="Контур 2183" d="M-765.16-100.084h602.373V300.342H-765.16Z" transform="translate(765.16 100.084)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2066" data-name="Сгруппировать 2066" transform="translate(-331.522 -43.336)">
                    <path id="Контур_2184" data-name="Контур 2184" d="M-765.16-100.084h602.373V300.342H-765.16Z" transform="translate(765.16 100.084)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2081" data-name="Сгруппировать 2081" transform="translate(331.782 -1043.934)" clip-path="url(#clip-path-394)">
        <g id="Сгруппировать_2080" data-name="Сгруппировать 2080" transform="translate(-711.216 -337.762)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2079" data-name="Сгруппировать 2079" transform="translate(711.146 337.589)" style="isolation: isolate">
            <g id="Сгруппировать_2078" data-name="Сгруппировать 2078" clip-path="url(#clip-path-391)">
              <g id="Сгруппировать_2077" data-name="Сгруппировать 2077" transform="translate(0.069 0.174)" clip-path="url(#clip-path-394)">
                <g id="Сгруппировать_2076" data-name="Сгруппировать 2076" transform="translate(-0.069 -0.174)" clip-path="url(#clip-path-398)">
                  <g id="Сгруппировать_2074" data-name="Сгруппировать 2074" transform="translate(-331.522 -58.504)" opacity="0">
                    <g id="Сгруппировать_2073" data-name="Сгруппировать 2073">
                      <path id="Контур_2189" data-name="Контур 2189" d="M-765.16-135.4h602.373V265.025H-765.16Z" transform="translate(765.16 135.401)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2075" data-name="Сгруппировать 2075" transform="translate(-331.522 -58.504)">
                    <path id="Контур_2190" data-name="Контур 2190" d="M-765.16-135.4h602.373V265.025H-765.16Z" transform="translate(765.16 135.401)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2090" data-name="Сгруппировать 2090" transform="translate(327.734 -1061.861)" clip-path="url(#clip-path-399)">
        <g id="Сгруппировать_2089" data-name="Сгруппировать 2089" transform="translate(-707.168 -319.835)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2088" data-name="Сгруппировать 2088" transform="translate(706.813 319.821)" style="isolation: isolate">
            <g id="Сгруппировать_2087" data-name="Сгруппировать 2087" clip-path="url(#clip-path-401)">
              <g id="Сгруппировать_2086" data-name="Сгруппировать 2086" transform="translate(0.355 0.014)" clip-path="url(#clip-path-399)">
                <g id="Сгруппировать_2085" data-name="Сгруппировать 2085" transform="translate(-0.355 -0.014)" clip-path="url(#clip-path-403)">
                  <g id="Сгруппировать_2083" data-name="Сгруппировать 2083" transform="translate(-327.188 -40.736)" opacity="0">
                    <g id="Сгруппировать_2082" data-name="Сгруппировать 2082" transform="translate(0 0)">
                      <path id="Контур_2195" data-name="Контур 2195" d="M-755.82-94.033h602.372V306.393H-755.82Z" transform="translate(755.82 94.033)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2084" data-name="Сгруппировать 2084" transform="translate(-327.188 -40.736)">
                    <path id="Контур_2196" data-name="Контур 2196" d="M-755.82-94.033h602.372V306.393H-755.82Z" transform="translate(755.82 94.033)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2099" data-name="Сгруппировать 2099" transform="translate(382.541 -1078.123)" clip-path="url(#clip-path-404)">
        <g id="Сгруппировать_2098" data-name="Сгруппировать 2098" transform="translate(-761.975 -303.574)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2097" data-name="Сгруппировать 2097" transform="translate(761.85 303.353)" style="isolation: isolate">
            <g id="Сгруппировать_2096" data-name="Сгруппировать 2096" transform="translate(0)" clip-path="url(#clip-path-406)">
              <g id="Сгруппировать_2095" data-name="Сгруппировать 2095" transform="translate(0.126 0.221)" clip-path="url(#clip-path-404)">
                <g id="Сгруппировать_2094" data-name="Сгруппировать 2094" transform="translate(-0.126 -0.221)" clip-path="url(#clip-path-408)">
                  <g id="Сгруппировать_2092" data-name="Сгруппировать 2092" transform="translate(-382.225 -24.268)" opacity="0">
                    <g id="Сгруппировать_2091" data-name="Сгруппировать 2091" transform="translate(0 0)">
                      <path id="Контур_2201" data-name="Контур 2201" d="M-882.29-56.51h602.373V343.916H-882.29Z" transform="translate(882.29 56.51)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2093" data-name="Сгруппировать 2093" transform="translate(-382.225 -24.268)">
                    <path id="Контур_2202" data-name="Контур 2202" d="M-882.29-56.51h602.373V343.916H-882.29Z" transform="translate(882.29 56.51)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2108" data-name="Сгруппировать 2108" transform="translate(406.259 -934.329)" clip-path="url(#clip-path-409)">
        <g id="Сгруппировать_2107" data-name="Сгруппировать 2107" transform="translate(-785.693 -447.368)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2106" data-name="Сгруппировать 2106" transform="translate(785.684 447.229)" style="isolation: isolate">
            <g id="Сгруппировать_2105" data-name="Сгруппировать 2105" transform="translate(0 0)" clip-path="url(#clip-path-406)">
              <g id="Сгруппировать_2104" data-name="Сгруппировать 2104" transform="translate(0.009 0.139)" clip-path="url(#clip-path-409)">
                <g id="Сгруппировать_2103" data-name="Сгруппировать 2103" transform="translate(-0.009 -0.139)" clip-path="url(#clip-path-413)">
                  <g id="Сгруппировать_2101" data-name="Сгруппировать 2101" transform="translate(-406.06 -168.144)" opacity="0">
                    <g id="Сгруппировать_2100" data-name="Сгруппировать 2100">
                      <path id="Контур_2207" data-name="Контур 2207" d="M-937.02-388.32h602.373V12.106H-937.02Z" transform="translate(937.02 388.32)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2102" data-name="Сгруппировать 2102" transform="translate(-406.06 -168.144)">
                    <path id="Контур_2208" data-name="Контур 2208" d="M-937.02-388.32h602.373V12.106H-937.02Z" transform="translate(937.02 388.32)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2117" data-name="Сгруппировать 2117" transform="translate(414.263 -1024.77)" clip-path="url(#clip-path-414)">
        <g id="Сгруппировать_2116" data-name="Сгруппировать 2116" transform="translate(-793.697 -356.927)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2115" data-name="Сгруппировать 2115" transform="translate(793.485 356.657)" style="isolation: isolate">
            <g id="Сгруппировать_2114" data-name="Сгруппировать 2114" clip-path="url(#clip-path-416)">
              <g id="Сгруппировать_2113" data-name="Сгруппировать 2113" transform="translate(0.212 0.27)" clip-path="url(#clip-path-414)">
                <g id="Сгруппировать_2112" data-name="Сгруппировать 2112" transform="translate(-0.212 -0.27)" clip-path="url(#clip-path-418)">
                  <g id="Сгруппировать_2110" data-name="Сгруппировать 2110" transform="translate(-413.86 -77.572)" opacity="0">
                    <g id="Сгруппировать_2109" data-name="Сгруппировать 2109" transform="translate(0 0)">
                      <path id="Контур_2213" data-name="Контур 2213" d="M-955.49-179.624h602.373V220.8H-955.49Z" transform="translate(955.49 179.624)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2111" data-name="Сгруппировать 2111" transform="translate(-413.86 -77.572)">
                    <path id="Контур_2214" data-name="Контур 2214" d="M-955.49-179.624h602.373V220.8H-955.49Z" transform="translate(955.49 179.624)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2126" data-name="Сгруппировать 2126" transform="translate(327.951 -1024.519)" clip-path="url(#clip-path-419)">
        <g id="Сгруппировать_2125" data-name="Сгруппировать 2125" transform="translate(-707.385 -357.178)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2124" data-name="Сгруппировать 2124" transform="translate(707.246 357.09)" style="isolation: isolate">
            <g id="Сгруппировать_2123" data-name="Сгруппировать 2123" transform="translate(0)" clip-path="url(#clip-path-421)">
              <g id="Сгруппировать_2122" data-name="Сгруппировать 2122" transform="translate(0.139 0.088)" clip-path="url(#clip-path-419)">
                <g id="Сгруппировать_2121" data-name="Сгруппировать 2121" transform="translate(-0.139 -0.088)" clip-path="url(#clip-path-423)">
                  <g id="Сгруппировать_2119" data-name="Сгруппировать 2119" transform="translate(-327.621 -78.005)" opacity="0">
                    <g id="Сгруппировать_2118" data-name="Сгруппировать 2118">
                      <path id="Контур_2219" data-name="Контур 2219" d="M-756.32-180.2h602.373V220.223H-756.32Z" transform="translate(756.32 180.203)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2120" data-name="Сгруппировать 2120" transform="translate(-327.621 -78.005)">
                    <path id="Контур_2220" data-name="Контур 2220" d="M-756.32-180.2h602.373V220.223H-756.32Z" transform="translate(756.32 180.203)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2135" data-name="Сгруппировать 2135" transform="translate(327.951 -1074.239)" clip-path="url(#clip-path-424)">
        <g id="Сгруппировать_2134" data-name="Сгруппировать 2134" transform="translate(-707.385 -307.458)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2133" data-name="Сгруппировать 2133" transform="translate(707.246 307.253)" style="isolation: isolate">
            <g id="Сгруппировать_2132" data-name="Сгруппировать 2132" transform="translate(0)" clip-path="url(#clip-path-421)">
              <g id="Сгруппировать_2131" data-name="Сгруппировать 2131" transform="translate(0.139 0.205)" clip-path="url(#clip-path-424)">
                <g id="Сгруппировать_2130" data-name="Сгруппировать 2130" transform="translate(-0.139 -0.205)" clip-path="url(#clip-path-428)">
                  <g id="Сгруппировать_2128" data-name="Сгруппировать 2128" transform="translate(-327.621 -28.168)" opacity="0">
                    <g id="Сгруппировать_2127" data-name="Сгруппировать 2127" transform="translate(0 0)">
                      <path id="Контур_2225" data-name="Контур 2225" d="M-756.32-65.472h602.373V334.954H-756.32Z" transform="translate(756.32 65.472)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2129" data-name="Сгруппировать 2129" transform="translate(-327.621 -28.168)">
                    <path id="Контур_2226" data-name="Контур 2226" d="M-756.32-65.472h602.373V334.954H-756.32Z" transform="translate(756.32 65.472)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2144" data-name="Сгруппировать 2144" transform="translate(499.54 -1054.637)" clip-path="url(#clip-path-429)">
        <g id="Сгруппировать_2143" data-name="Сгруппировать 2143" transform="translate(-878.974 -327.059)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2142" data-name="Сгруппировать 2142" transform="translate(878.857 326.755)" style="isolation: isolate">
            <g id="Сгруппировать_2141" data-name="Сгруппировать 2141" transform="translate(0)" clip-path="url(#clip-path-431)">
              <g id="Сгруппировать_2140" data-name="Сгруппировать 2140" transform="translate(0.117 0.305)" clip-path="url(#clip-path-429)">
                <g id="Сгруппировать_2139" data-name="Сгруппировать 2139" transform="translate(-0.117 -0.305)" clip-path="url(#clip-path-433)">
                  <g id="Сгруппировать_2137" data-name="Сгруппировать 2137" transform="translate(-499.232 -47.67)" opacity="0">
                    <g id="Сгруппировать_2136" data-name="Сгруппировать 2136">
                      <path id="Контур_2231" data-name="Контур 2231" d="M-1152.27-110.7H-549.9V289.723H-1152.27Z" transform="translate(1152.27 110.703)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2138" data-name="Сгруппировать 2138" transform="translate(-499.232 -47.67)">
                    <path id="Контур_2232" data-name="Контур 2232" d="M-1152.27-110.7H-549.9V289.723H-1152.27Z" transform="translate(1152.27 110.703)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2153" data-name="Сгруппировать 2153" transform="translate(501.335 -1054.649)" clip-path="url(#clip-path-434)">
        <g id="Сгруппировать_2152" data-name="Сгруппировать 2152" transform="translate(-880.768 -327.048)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2151" data-name="Сгруппировать 2151" transform="translate(880.591 326.755)" style="isolation: isolate">
            <g id="Сгруппировать_2150" data-name="Сгруппировать 2150" clip-path="url(#clip-path-436)">
              <g id="Сгруппировать_2149" data-name="Сгруппировать 2149" transform="translate(0.178 0.293)" clip-path="url(#clip-path-434)">
                <g id="Сгруппировать_2148" data-name="Сгруппировать 2148" transform="translate(-0.178 -0.293)" clip-path="url(#clip-path-438)">
                  <g id="Сгруппировать_2146" data-name="Сгруппировать 2146" transform="translate(-500.966 -47.67)" opacity="0">
                    <g id="Сгруппировать_2145" data-name="Сгруппировать 2145" transform="translate(0 0)">
                      <path id="Контур_2237" data-name="Контур 2237" d="M-1156.41-110.676h602.373V289.75H-1156.41Z" transform="translate(1156.41 110.676)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2147" data-name="Сгруппировать 2147" transform="translate(-500.966 -47.67)">
                    <path id="Контур_2238" data-name="Контур 2238" d="M-1156.41-110.676h602.373V289.75H-1156.41Z" transform="translate(1156.41 110.676)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2162" data-name="Сгруппировать 2162" transform="translate(503.406 -1054.637)" clip-path="url(#clip-path-439)">
        <g id="Сгруппировать_2161" data-name="Сгруппировать 2161" transform="translate(-882.84 -327.059)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2160" data-name="Сгруппировать 2160" transform="translate(882.757 326.755)" style="isolation: isolate">
            <g id="Сгруппировать_2159" data-name="Сгруппировать 2159" transform="translate(0)" clip-path="url(#clip-path-441)">
              <g id="Сгруппировать_2158" data-name="Сгруппировать 2158" transform="translate(0.082 0.305)" clip-path="url(#clip-path-439)">
                <g id="Сгруппировать_2157" data-name="Сгруппировать 2157" transform="translate(-0.082 -0.305)" clip-path="url(#clip-path-443)">
                  <g id="Сгруппировать_2155" data-name="Сгруппировать 2155" transform="translate(-503.133 -47.67)" opacity="0">
                    <g id="Сгруппировать_2154" data-name="Сгруппировать 2154">
                      <path id="Контур_2243" data-name="Контур 2243" d="M-1161.19-110.7h602.373V289.723H-1161.19Z" transform="translate(1161.19 110.703)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2156" data-name="Сгруппировать 2156" transform="translate(-503.133 -47.67)">
                    <path id="Контур_2244" data-name="Контур 2244" d="M-1161.19-110.7h602.373V289.723H-1161.19Z" transform="translate(1161.19 110.703)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2171" data-name="Сгруппировать 2171" transform="translate(505.352 -1054.637)" clip-path="url(#clip-path-444)">
        <g id="Сгруппировать_2170" data-name="Сгруппировать 2170" transform="translate(-884.786 -327.059)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2169" data-name="Сгруппировать 2169" transform="translate(884.491 326.755)" style="isolation: isolate">
            <g id="Сгруппировать_2168" data-name="Сгруппировать 2168" transform="translate(0)" clip-path="url(#clip-path-431)">
              <g id="Сгруппировать_2167" data-name="Сгруппировать 2167" transform="translate(0.295 0.305)" clip-path="url(#clip-path-444)">
                <g id="Сгруппировать_2166" data-name="Сгруппировать 2166" transform="translate(-0.295 -0.305)" clip-path="url(#clip-path-448)">
                  <g id="Сгруппировать_2164" data-name="Сгруппировать 2164" transform="translate(-504.866 -47.67)" opacity="0">
                    <g id="Сгруппировать_2163" data-name="Сгруппировать 2163">
                      <path id="Контур_2249" data-name="Контур 2249" d="M-1165.68-110.7h602.372V289.723H-1165.68Z" transform="translate(1165.68 110.703)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2165" data-name="Сгруппировать 2165" transform="translate(-504.866 -47.67)">
                    <path id="Контур_2250" data-name="Контур 2250" d="M-1165.68-110.7h602.372V289.723H-1165.68Z" transform="translate(1165.68 110.703)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2180" data-name="Сгруппировать 2180" transform="translate(507.64 -1054.637)" clip-path="url(#clip-path-449)">
        <g id="Сгруппировать_2179" data-name="Сгруппировать 2179" transform="translate(-887.074 -327.059)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2178" data-name="Сгруппировать 2178" transform="translate(886.658 326.755)" style="isolation: isolate">
            <g id="Сгруппировать_2177" data-name="Сгруппировать 2177" transform="translate(0)" clip-path="url(#clip-path-436)">
              <g id="Сгруппировать_2176" data-name="Сгруппировать 2176" transform="translate(0.416 0.305)" clip-path="url(#clip-path-449)">
                <g id="Сгруппировать_2175" data-name="Сгруппировать 2175" transform="translate(-0.416 -0.305)" clip-path="url(#clip-path-453)">
                  <g id="Сгруппировать_2173" data-name="Сгруппировать 2173" transform="translate(-507.033 -47.67)" opacity="0">
                    <g id="Сгруппировать_2172" data-name="Сгруппировать 2172">
                      <path id="Контур_2255" data-name="Контур 2255" d="M-1170.96-110.7h602.372V289.723H-1170.96Z" transform="translate(1170.96 110.703)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2174" data-name="Сгруппировать 2174" transform="translate(-507.033 -47.67)">
                    <path id="Контур_2256" data-name="Контур 2256" d="M-1170.96-110.7h602.372V289.723H-1170.96Z" transform="translate(1170.96 110.703)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2189" data-name="Сгруппировать 2189" transform="translate(509.573 -1054.683)" clip-path="url(#clip-path-454)">
        <g id="Сгруппировать_2188" data-name="Сгруппировать 2188" transform="translate(-889.006 -327.014)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2187" data-name="Сгруппировать 2187" transform="translate(888.824 326.755)" style="isolation: isolate">
            <g id="Сгруппировать_2186" data-name="Сгруппировать 2186" clip-path="url(#clip-path-436)">
              <g id="Сгруппировать_2185" data-name="Сгруппировать 2185" transform="translate(0.182 0.26)" clip-path="url(#clip-path-454)">
                <g id="Сгруппировать_2184" data-name="Сгруппировать 2184" transform="translate(-0.182 -0.26)" clip-path="url(#clip-path-458)">
                  <g id="Сгруппировать_2182" data-name="Сгруппировать 2182" transform="translate(-509.2 -47.67)" opacity="0">
                    <g id="Сгруппировать_2181" data-name="Сгруппировать 2181">
                      <path id="Контур_2261" data-name="Контур 2261" d="M-1175.42-110.6h602.373V289.827H-1175.42Z" transform="translate(1175.42 110.599)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2183" data-name="Сгруппировать 2183" transform="translate(-509.2 -47.67)">
                    <path id="Контур_2262" data-name="Контур 2262" d="M-1175.42-110.6h602.373V289.827H-1175.42Z" transform="translate(1175.42 110.599)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2198" data-name="Сгруппировать 2198" transform="translate(511.978 -1054.683)" clip-path="url(#clip-path-459)">
        <g id="Сгруппировать_2197" data-name="Сгруппировать 2197" transform="translate(-891.412 -327.014)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2196" data-name="Сгруппировать 2196" transform="translate(890.991 326.755)" style="isolation: isolate">
            <g id="Сгруппировать_2195" data-name="Сгруппировать 2195" clip-path="url(#clip-path-436)">
              <g id="Сгруппировать_2194" data-name="Сгруппировать 2194" transform="translate(0.42 0.26)" clip-path="url(#clip-path-459)">
                <g id="Сгруппировать_2193" data-name="Сгруппировать 2193" transform="translate(-0.42 -0.26)" clip-path="url(#clip-path-463)">
                  <g id="Сгруппировать_2191" data-name="Сгруппировать 2191" transform="translate(-511.367 -47.67)" opacity="0">
                    <g id="Сгруппировать_2190" data-name="Сгруппировать 2190">
                      <path id="Контур_2267" data-name="Контур 2267" d="M-1180.97-110.6H-578.6V289.827H-1180.97Z" transform="translate(1180.97 110.599)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2192" data-name="Сгруппировать 2192" transform="translate(-511.367 -47.67)">
                    <path id="Контур_2268" data-name="Контур 2268" d="M-1180.97-110.6H-578.6V289.827H-1180.97Z" transform="translate(1180.97 110.599)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2207" data-name="Сгруппировать 2207" transform="translate(514.47 -1054.637)" clip-path="url(#clip-path-464)">
        <g id="Сгруппировать_2206" data-name="Сгруппировать 2206" transform="translate(-893.903 -327.059)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2205" data-name="Сгруппировать 2205" transform="translate(893.591 326.755)" style="isolation: isolate">
            <g id="Сгруппировать_2204" data-name="Сгруппировать 2204" clip-path="url(#clip-path-466)">
              <g id="Сгруппировать_2203" data-name="Сгруппировать 2203" transform="translate(0.312 0.305)" clip-path="url(#clip-path-464)">
                <g id="Сгруппировать_2202" data-name="Сгруппировать 2202" transform="translate(-0.312 -0.305)" clip-path="url(#clip-path-468)">
                  <g id="Сгруппировать_2200" data-name="Сгруппировать 2200" transform="translate(-513.967 -47.67)" opacity="0">
                    <g id="Сгруппировать_2199" data-name="Сгруппировать 2199">
                      <path id="Контур_2273" data-name="Контур 2273" d="M-1186.72-110.7h602.372V289.723H-1186.72Z" transform="translate(1186.72 110.703)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2201" data-name="Сгруппировать 2201" transform="translate(-513.967 -47.67)">
                    <path id="Контур_2274" data-name="Контур 2274" d="M-1186.72-110.7h602.372V289.723H-1186.72Z" transform="translate(1186.72 110.703)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2216" data-name="Сгруппировать 2216" transform="translate(428.967 -977.285)" clip-path="url(#clip-path-469)">
        <g id="Сгруппировать_2215" data-name="Сгруппировать 2215" transform="translate(-808.401 -404.411)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2214" data-name="Сгруппировать 2214" transform="translate(808.219 404.326)" style="isolation: isolate">
            <g id="Сгруппировать_2213" data-name="Сгруппировать 2213" transform="translate(0)" clip-path="url(#clip-path-471)">
              <g id="Сгруппировать_2212" data-name="Сгруппировать 2212" transform="translate(0.182 0.085)" clip-path="url(#clip-path-469)">
                <g id="Сгруппировать_2211" data-name="Сгруппировать 2211" transform="translate(-0.182 -0.085)" clip-path="url(#clip-path-473)">
                  <g id="Сгруппировать_2209" data-name="Сгруппировать 2209" transform="translate(-428.595 -125.241)" opacity="0">
                    <g id="Сгруппировать_2208" data-name="Сгруппировать 2208" transform="translate(0 0)">
                      <path id="Контур_2279" data-name="Контур 2279" d="M-989.42-289.2h602.373V111.23H-989.42Z" transform="translate(989.42 289.196)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2210" data-name="Сгруппировать 2210" transform="translate(-428.595 -125.241)">
                    <path id="Контур_2280" data-name="Контур 2280" d="M-989.42-289.2h602.373V111.23H-989.42Z" transform="translate(989.42 289.196)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2225" data-name="Сгруппировать 2225" transform="translate(431.043 -977.346)" clip-path="url(#clip-path-474)">
        <g id="Сгруппировать_2224" data-name="Сгруппировать 2224" transform="translate(-810.477 -404.35)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2223" data-name="Сгруппировать 2223" transform="translate(810.386 404.326)" style="isolation: isolate">
            <g id="Сгруппировать_2222" data-name="Сгруппировать 2222" clip-path="url(#clip-path-476)">
              <g id="Сгруппировать_2221" data-name="Сгруппировать 2221" transform="translate(0.091 0.024)" clip-path="url(#clip-path-474)">
                <g id="Сгруппировать_2220" data-name="Сгруппировать 2220" transform="translate(-0.091 -0.024)" clip-path="url(#clip-path-478)">
                  <g id="Сгруппировать_2218" data-name="Сгруппировать 2218" transform="translate(-430.761 -125.241)" opacity="0">
                    <g id="Сгруппировать_2217" data-name="Сгруппировать 2217">
                      <path id="Контур_2285" data-name="Контур 2285" d="M-994.21-289.055h602.373V111.371H-994.21Z" transform="translate(994.21 289.055)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2219" data-name="Сгруппировать 2219" transform="translate(-430.761 -125.241)">
                    <path id="Контур_2286" data-name="Контур 2286" d="M-994.21-289.055h602.373V111.371H-994.21Z" transform="translate(994.21 289.055)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2234" data-name="Сгруппировать 2234" transform="translate(434.393 -977.285)" clip-path="url(#clip-path-479)">
        <g id="Сгруппировать_2233" data-name="Сгруппировать 2233" transform="translate(-813.827 -404.411)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2232" data-name="Сгруппировать 2232" transform="translate(813.419 404.326)" style="isolation: isolate">
            <g id="Сгруппировать_2231" data-name="Сгруппировать 2231" transform="translate(0)" clip-path="url(#clip-path-476)">
              <g id="Сгруппировать_2230" data-name="Сгруппировать 2230" transform="translate(0.407 0.085)" clip-path="url(#clip-path-479)">
                <g id="Сгруппировать_2229" data-name="Сгруппировать 2229" transform="translate(-0.407 -0.085)" clip-path="url(#clip-path-483)">
                  <g id="Сгруппировать_2227" data-name="Сгруппировать 2227" transform="translate(-433.795 -125.241)" opacity="0">
                    <g id="Сгруппировать_2226" data-name="Сгруппировать 2226" transform="translate(0 0)">
                      <path id="Контур_2291" data-name="Контур 2291" d="M-1001.94-289.2h602.373V111.23H-1001.94Z" transform="translate(1001.94 289.196)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2228" data-name="Сгруппировать 2228" transform="translate(-433.795 -125.241)">
                    <path id="Контур_2292" data-name="Контур 2292" d="M-1001.94-289.2h602.373V111.23H-1001.94Z" transform="translate(1001.94 289.196)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2243" data-name="Сгруппировать 2243" transform="translate(437.479 -977.285)" clip-path="url(#clip-path-484)">
        <g id="Сгруппировать_2242" data-name="Сгруппировать 2242" transform="translate(-816.912 -404.411)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2241" data-name="Сгруппировать 2241" transform="translate(816.886 404.326)" style="isolation: isolate">
            <g id="Сгруппировать_2240" data-name="Сгруппировать 2240" transform="translate(0)" clip-path="url(#clip-path-476)">
              <g id="Сгруппировать_2239" data-name="Сгруппировать 2239" transform="translate(0.026 0.085)" clip-path="url(#clip-path-484)">
                <g id="Сгруппировать_2238" data-name="Сгруппировать 2238" transform="translate(-0.026 -0.085)" clip-path="url(#clip-path-488)">
                  <g id="Сгруппировать_2236" data-name="Сгруппировать 2236" transform="translate(-437.262 -125.241)" opacity="0">
                    <g id="Сгруппировать_2235" data-name="Сгруппировать 2235" transform="translate(0 0)">
                      <path id="Контур_2297" data-name="Контур 2297" d="M-1009.06-289.2h602.373V111.23H-1009.06Z" transform="translate(1009.06 289.196)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2237" data-name="Сгруппировать 2237" transform="translate(-437.262 -125.241)">
                    <path id="Контур_2298" data-name="Контур 2298" d="M-1009.06-289.2h602.373V111.23H-1009.06Z" transform="translate(1009.06 289.196)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2252" data-name="Сгруппировать 2252" transform="translate(440.777 -977.341)" clip-path="url(#clip-path-489)">
        <g id="Сгруппировать_2251" data-name="Сгруппировать 2251" transform="translate(-820.21 -404.355)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2250" data-name="Сгруппировать 2250" transform="translate(819.92 404.326)" style="isolation: isolate">
            <g id="Сгруппировать_2249" data-name="Сгруппировать 2249" clip-path="url(#clip-path-476)">
              <g id="Сгруппировать_2248" data-name="Сгруппировать 2248" transform="translate(0.29 0.029)" clip-path="url(#clip-path-489)">
                <g id="Сгруппировать_2247" data-name="Сгруппировать 2247" transform="translate(-0.29 -0.029)" clip-path="url(#clip-path-493)">
                  <g id="Сгруппировать_2245" data-name="Сгруппировать 2245" transform="translate(-440.295 -125.241)" opacity="0">
                    <g id="Сгруппировать_2244" data-name="Сгруппировать 2244" transform="translate(0 0)">
                      <path id="Контур_2303" data-name="Контур 2303" d="M-1016.67-289.067H-414.3V111.359H-1016.67Z" transform="translate(1016.67 289.067)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2246" data-name="Сгруппировать 2246" transform="translate(-440.295 -125.241)">
                    <path id="Контур_2304" data-name="Контур 2304" d="M-1016.67-289.067H-414.3V111.359H-1016.67Z" transform="translate(1016.67 289.067)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2261" data-name="Сгруппировать 2261" transform="translate(443.858 -977.285)" clip-path="url(#clip-path-494)">
        <g id="Сгруппировать_2260" data-name="Сгруппировать 2260" transform="translate(-823.292 -404.411)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2259" data-name="Сгруппировать 2259" transform="translate(822.954 404.326)" style="isolation: isolate">
            <g id="Сгруппировать_2258" data-name="Сгруппировать 2258" clip-path="url(#clip-path-471)">
              <g id="Сгруппировать_2257" data-name="Сгруппировать 2257" transform="translate(0.338 0.085)" clip-path="url(#clip-path-494)">
                <g id="Сгруппировать_2256" data-name="Сгруппировать 2256" transform="translate(-0.338 -0.085)" clip-path="url(#clip-path-498)">
                  <g id="Сгруппировать_2254" data-name="Сгруппировать 2254" transform="translate(-443.329 -125.241)" opacity="0">
                    <g id="Сгруппировать_2253" data-name="Сгруппировать 2253" transform="translate(0 0)">
                      <path id="Контур_2309" data-name="Контур 2309" d="M-1023.78-289.2h602.373V111.23H-1023.78Z" transform="translate(1023.78 289.196)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2255" data-name="Сгруппировать 2255" transform="translate(-443.329 -125.241)">
                    <path id="Контур_2310" data-name="Контур 2310" d="M-1023.78-289.2h602.373V111.23H-1023.78Z" transform="translate(1023.78 289.196)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2270" data-name="Сгруппировать 2270" transform="translate(367.989 -1005.046)" clip-path="url(#clip-path-499)">
        <g id="Сгруппировать_2269" data-name="Сгруппировать 2269" transform="translate(-747.423 -376.651)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2268" data-name="Сгруппировать 2268" transform="translate(747.115 376.591)" style="isolation: isolate">
            <g id="Сгруппировать_2267" data-name="Сгруппировать 2267" transform="translate(0)" clip-path="url(#clip-path-501)">
              <g id="Сгруппировать_2266" data-name="Сгруппировать 2266" transform="translate(0.308 0.06)" clip-path="url(#clip-path-499)">
                <g id="Сгруппировать_2265" data-name="Сгруппировать 2265" transform="translate(-0.308 -0.06)" clip-path="url(#clip-path-503)">
                  <g id="Сгруппировать_2263" data-name="Сгруппировать 2263" transform="translate(-367.491 -97.506)" opacity="0">
                    <g id="Сгруппировать_2262" data-name="Сгруппировать 2262">
                      <path id="Контур_2315" data-name="Контур 2315" d="M-848.71-225.138h602.372V175.288H-848.71Z" transform="translate(848.71 225.138)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2264" data-name="Сгруппировать 2264" transform="translate(-367.491 -97.506)">
                    <path id="Контур_2316" data-name="Контур 2316" d="M-848.71-225.138h602.372V175.288H-848.71Z" transform="translate(848.71 225.138)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2279" data-name="Сгруппировать 2279" transform="translate(372.18 -1005.062)" clip-path="url(#clip-path-504)">
        <g id="Сгруппировать_2278" data-name="Сгруппировать 2278" transform="translate(-751.613 -376.635)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2277" data-name="Сгруппировать 2277" transform="translate(751.449 376.591)" style="isolation: isolate">
            <g id="Сгруппировать_2276" data-name="Сгруппировать 2276" clip-path="url(#clip-path-506)">
              <g id="Сгруппировать_2275" data-name="Сгруппировать 2275" transform="translate(0.165 0.044)" clip-path="url(#clip-path-504)">
                <g id="Сгруппировать_2274" data-name="Сгруппировать 2274" transform="translate(-0.165 -0.044)" clip-path="url(#clip-path-508)">
                  <g id="Сгруппировать_2272" data-name="Сгруппировать 2272" transform="translate(-371.824 -97.506)" opacity="0">
                    <g id="Сгруппировать_2271" data-name="Сгруппировать 2271" transform="translate(0 0)">
                      <path id="Контур_2321" data-name="Контур 2321" d="M-858.38-225.1h602.373V175.325H-858.38Z" transform="translate(858.38 225.101)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2273" data-name="Сгруппировать 2273" transform="translate(-371.824 -97.506)">
                    <path id="Контур_2322" data-name="Контур 2322" d="M-858.38-225.1h602.373V175.325H-858.38Z" transform="translate(858.38 225.101)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2288" data-name="Сгруппировать 2288" transform="translate(375.439 -1005.046)" clip-path="url(#clip-path-509)">
        <g id="Сгруппировать_2287" data-name="Сгруппировать 2287" transform="translate(-754.872 -376.651)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2286" data-name="Сгруппировать 2286" transform="translate(754.482 376.591)" style="isolation: isolate">
            <g id="Сгруппировать_2285" data-name="Сгруппировать 2285" transform="translate(0)" clip-path="url(#clip-path-476)">
              <g id="Сгруппировать_2284" data-name="Сгруппировать 2284" transform="translate(0.39 0.06)" clip-path="url(#clip-path-509)">
                <g id="Сгруппировать_2283" data-name="Сгруппировать 2283" transform="translate(-0.39 -0.06)" clip-path="url(#clip-path-513)">
                  <g id="Сгруппировать_2281" data-name="Сгруппировать 2281" transform="translate(-374.858 -97.506)" opacity="0">
                    <g id="Сгруппировать_2280" data-name="Сгруппировать 2280">
                      <path id="Контур_2327" data-name="Контур 2327" d="M-865.9-225.138h602.372V175.288H-865.9Z" transform="translate(865.9 225.138)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2282" data-name="Сгруппировать 2282" transform="translate(-374.858 -97.506)">
                    <path id="Контур_2328" data-name="Контур 2328" d="M-865.9-225.138h602.372V175.288H-865.9Z" transform="translate(865.9 225.138)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2297" data-name="Сгруппировать 2297" transform="translate(378.169 -1005.046)" clip-path="url(#clip-path-514)">
        <g id="Сгруппировать_2296" data-name="Сгруппировать 2296" transform="translate(-757.603 -376.651)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2295" data-name="Сгруппировать 2295" transform="translate(757.516 376.591)" style="isolation: isolate">
            <g id="Сгруппировать_2294" data-name="Сгруппировать 2294" clip-path="url(#clip-path-516)">
              <g id="Сгруппировать_2293" data-name="Сгруппировать 2293" transform="translate(0.087 0.06)" clip-path="url(#clip-path-514)">
                <g id="Сгруппировать_2292" data-name="Сгруппировать 2292" transform="translate(-0.087 -0.06)" clip-path="url(#clip-path-518)">
                  <g id="Сгруппировать_2290" data-name="Сгруппировать 2290" transform="translate(-377.891 -97.506)" opacity="0">
                    <g id="Сгруппировать_2289" data-name="Сгруппировать 2289">
                      <path id="Контур_2333" data-name="Контур 2333" d="M-872.2-225.138h602.373V175.288H-872.2Z" transform="translate(872.2 225.138)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2291" data-name="Сгруппировать 2291" transform="translate(-377.891 -97.506)">
                    <path id="Контур_2334" data-name="Контур 2334" d="M-872.2-225.138h602.373V175.288H-872.2Z" transform="translate(872.2 225.138)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2306" data-name="Сгруппировать 2306" transform="translate(381.215 -1005.046)" clip-path="url(#clip-path-519)">
        <g id="Сгруппировать_2305" data-name="Сгруппировать 2305" transform="translate(-760.649 -376.651)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2304" data-name="Сгруппировать 2304" transform="translate(760.549 376.591)" style="isolation: isolate">
            <g id="Сгруппировать_2303" data-name="Сгруппировать 2303" clip-path="url(#clip-path-516)">
              <g id="Сгруппировать_2302" data-name="Сгруппировать 2302" transform="translate(0.1 0.06)" clip-path="url(#clip-path-519)">
                <g id="Сгруппировать_2301" data-name="Сгруппировать 2301" transform="translate(-0.1 -0.06)" clip-path="url(#clip-path-523)">
                  <g id="Сгруппировать_2299" data-name="Сгруппировать 2299" transform="translate(-380.925 -97.506)" opacity="0">
                    <g id="Сгруппировать_2298" data-name="Сгруппировать 2298">
                      <path id="Контур_2339" data-name="Контур 2339" d="M-879.23-225.138h602.372V175.288H-879.23Z" transform="translate(879.23 225.138)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2300" data-name="Сгруппировать 2300" transform="translate(-380.925 -97.506)">
                    <path id="Контур_2340" data-name="Контур 2340" d="M-879.23-225.138h602.372V175.288H-879.23Z" transform="translate(879.23 225.138)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2315" data-name="Сгруппировать 2315" transform="translate(383.824 -1005.107)" clip-path="url(#clip-path-524)">
        <g id="Сгруппировать_2314" data-name="Сгруппировать 2314" transform="translate(-763.258 -376.589)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2313" data-name="Сгруппировать 2313" transform="translate(763.15 376.158)" style="isolation: isolate">
            <g id="Сгруппировать_2312" data-name="Сгруппировать 2312" transform="translate(0)" clip-path="url(#clip-path-526)">
              <g id="Сгруппировать_2311" data-name="Сгруппировать 2311" transform="translate(0.108 0.432)" clip-path="url(#clip-path-524)">
                <g id="Сгруппировать_2310" data-name="Сгруппировать 2310" transform="translate(-0.108 -0.432)" clip-path="url(#clip-path-528)">
                  <g id="Сгруппировать_2308" data-name="Сгруппировать 2308" transform="translate(-383.525 -97.073)" opacity="0">
                    <g id="Сгруппировать_2307" data-name="Сгруппировать 2307" transform="translate(0 0)">
                      <path id="Контур_2345" data-name="Контур 2345" d="M-885.25-225h602.372V175.43H-885.25Z" transform="translate(885.25 224.996)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2309" data-name="Сгруппировать 2309" transform="translate(-383.525 -97.073)">
                    <path id="Контур_2346" data-name="Контур 2346" d="M-885.25-225h602.372V175.43H-885.25Z" transform="translate(885.25 224.996)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2324" data-name="Сгруппировать 2324" transform="translate(387.191 -1005.046)" clip-path="url(#clip-path-529)">
        <g id="Сгруппировать_2323" data-name="Сгруппировать 2323" transform="translate(-766.625 -376.651)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2322" data-name="Сгруппировать 2322" transform="translate(766.616 376.591)" style="isolation: isolate">
            <g id="Сгруппировать_2321" data-name="Сгруппировать 2321" clip-path="url(#clip-path-516)">
              <g id="Сгруппировать_2320" data-name="Сгруппировать 2320" transform="translate(0.009 0.06)" clip-path="url(#clip-path-529)">
                <g id="Сгруппировать_2319" data-name="Сгруппировать 2319" transform="translate(-0.009 -0.06)" clip-path="url(#clip-path-533)">
                  <g id="Сгруппировать_2317" data-name="Сгруппировать 2317" transform="translate(-386.992 -97.506)" opacity="0">
                    <g id="Сгруппировать_2316" data-name="Сгруппировать 2316">
                      <path id="Контур_2351" data-name="Контур 2351" d="M-893.02-225.138h602.372V175.288H-893.02Z" transform="translate(893.02 225.138)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2318" data-name="Сгруппировать 2318" transform="translate(-386.992 -97.506)">
                    <path id="Контур_2352" data-name="Контур 2352" d="M-893.02-225.138h602.372V175.288H-893.02Z" transform="translate(893.02 225.138)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2333" data-name="Сгруппировать 2333" transform="translate(389.961 -1005.046)" clip-path="url(#clip-path-534)">
        <g id="Сгруппировать_2332" data-name="Сгруппировать 2332" transform="translate(-769.394 -376.651)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2331" data-name="Сгруппировать 2331" transform="translate(769.217 376.591)" style="isolation: isolate">
            <g id="Сгруппировать_2330" data-name="Сгруппировать 2330" clip-path="url(#clip-path-471)">
              <g id="Сгруппировать_2329" data-name="Сгруппировать 2329" transform="translate(0.178 0.06)" clip-path="url(#clip-path-534)">
                <g id="Сгруппировать_2328" data-name="Сгруппировать 2328" transform="translate(-0.178 -0.06)" clip-path="url(#clip-path-538)">
                  <g id="Сгруппировать_2326" data-name="Сгруппировать 2326" transform="translate(-389.592 -97.506)" opacity="0">
                    <g id="Сгруппировать_2325" data-name="Сгруппировать 2325">
                      <path id="Контур_2357" data-name="Контур 2357" d="M-899.41-225.138h602.373V175.288H-899.41Z" transform="translate(899.41 225.138)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2327" data-name="Сгруппировать 2327" transform="translate(-389.592 -97.506)">
                    <path id="Контур_2358" data-name="Контур 2358" d="M-899.41-225.138h602.373V175.288H-899.41Z" transform="translate(899.41 225.138)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2342" data-name="Сгруппировать 2342" transform="translate(394.472 -1048.6)" clip-path="url(#clip-path-539)">
        <g id="Сгруппировать_2341" data-name="Сгруппировать 2341" transform="translate(-773.906 -333.096)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2340" data-name="Сгруппировать 2340" transform="translate(773.55 332.822)" style="isolation: isolate">
            <g id="Сгруппировать_2339" data-name="Сгруппировать 2339" clip-path="url(#clip-path-541)">
              <g id="Сгруппировать_2338" data-name="Сгруппировать 2338" transform="translate(0.355 0.275)" clip-path="url(#clip-path-539)">
                <g id="Сгруппировать_2337" data-name="Сгруппировать 2337" transform="translate(-0.355 -0.275)" clip-path="url(#clip-path-543)">
                  <g id="Сгруппировать_2335" data-name="Сгруппировать 2335" transform="translate(-393.926 -53.737)" opacity="0">
                    <g id="Сгруппировать_2334" data-name="Сгруппировать 2334" transform="translate(0 0)">
                      <path id="Контур_2363" data-name="Контур 2363" d="M-909.82-124.634h602.373V275.792H-909.82Z" transform="translate(909.82 124.634)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2336" data-name="Сгруппировать 2336" transform="translate(-393.926 -53.737)">
                    <path id="Контур_2364" data-name="Контур 2364" d="M-909.82-124.634h602.373V275.792H-909.82Z" transform="translate(909.82 124.634)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2351" data-name="Сгруппировать 2351" transform="translate(398.411 -1048.616)" clip-path="url(#clip-path-544)">
        <g id="Сгруппировать_2350" data-name="Сгруппировать 2350" transform="translate(-777.845 -333.08)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2349" data-name="Сгруппировать 2349" transform="translate(777.451 332.822)" style="isolation: isolate">
            <g id="Сгруппировать_2348" data-name="Сгруппировать 2348" transform="translate(0)" clip-path="url(#clip-path-506)">
              <g id="Сгруппировать_2347" data-name="Сгруппировать 2347" transform="translate(0.394 0.259)" clip-path="url(#clip-path-544)">
                <g id="Сгруппировать_2346" data-name="Сгруппировать 2346" transform="translate(-0.394 -0.259)" clip-path="url(#clip-path-548)">
                  <g id="Сгруппировать_2344" data-name="Сгруппировать 2344" transform="translate(-397.826 -53.737)" opacity="0">
                    <g id="Сгруппировать_2343" data-name="Сгруппировать 2343" transform="translate(0 0)">
                      <path id="Контур_2369" data-name="Контур 2369" d="M-918.91-124.6h602.372V275.829H-918.91Z" transform="translate(918.91 124.597)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2345" data-name="Сгруппировать 2345" transform="translate(-397.826 -53.737)">
                    <path id="Контур_2370" data-name="Контур 2370" d="M-918.91-124.6h602.372V275.829H-918.91Z" transform="translate(918.91 124.597)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2360" data-name="Сгруппировать 2360" transform="translate(401.505 -1048.656)" clip-path="url(#clip-path-549)">
        <g id="Сгруппировать_2359" data-name="Сгруппировать 2359" transform="translate(-780.939 -333.04)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2358" data-name="Сгруппировать 2358" transform="translate(780.917 332.822)" style="isolation: isolate">
            <g id="Сгруппировать_2357" data-name="Сгруппировать 2357" transform="translate(0)" clip-path="url(#clip-path-471)">
              <g id="Сгруппировать_2356" data-name="Сгруппировать 2356" transform="translate(0.022 0.219)" clip-path="url(#clip-path-549)">
                <g id="Сгруппировать_2355" data-name="Сгруппировать 2355" transform="translate(-0.022 -0.219)" clip-path="url(#clip-path-553)">
                  <g id="Сгруппировать_2353" data-name="Сгруппировать 2353" transform="translate(-401.293 -53.737)" opacity="0">
                    <g id="Сгруппировать_2352" data-name="Сгруппировать 2352" transform="translate(0 0)">
                      <path id="Контур_2375" data-name="Контур 2375" d="M-926.05-124.5h602.373V275.921H-926.05Z" transform="translate(926.05 124.505)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2354" data-name="Сгруппировать 2354" transform="translate(-401.293 -53.737)">
                    <path id="Контур_2376" data-name="Контур 2376" d="M-926.05-124.5h602.373V275.921H-926.05Z" transform="translate(926.05 124.505)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2369" data-name="Сгруппировать 2369" transform="translate(403.65 -1048.6)" clip-path="url(#clip-path-554)">
        <g id="Сгруппировать_2368" data-name="Сгруппировать 2368" transform="translate(-783.084 -333.096)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2367" data-name="Сгруппировать 2367" transform="translate(783.084 332.822)" style="isolation: isolate">
            <g id="Сгруппировать_2366" data-name="Сгруппировать 2366" clip-path="url(#clip-path-516)">
              <g id="Сгруппировать_2365" data-name="Сгруппировать 2365" transform="translate(0 0.275)" clip-path="url(#clip-path-554)">
                <g id="Сгруппировать_2364" data-name="Сгруппировать 2364" transform="translate(0 -0.275)" clip-path="url(#clip-path-558)">
                  <g id="Сгруппировать_2362" data-name="Сгруппировать 2362" transform="translate(-403.46 -53.737)" opacity="0">
                    <g id="Сгруппировать_2361" data-name="Сгруппировать 2361" transform="translate(0 0)">
                      <path id="Контур_2381" data-name="Контур 2381" d="M-931-124.634h602.372V275.792H-931Z" transform="translate(931 124.634)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2363" data-name="Сгруппировать 2363" transform="translate(-403.46 -53.737)">
                    <path id="Контур_2382" data-name="Контур 2382" d="M-931-124.634h602.372V275.792H-931Z" transform="translate(931 124.634)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2378" data-name="Сгруппировать 2378" transform="translate(406.281 -1048.6)" clip-path="url(#clip-path-559)">
        <g id="Сгруппировать_2377" data-name="Сгруппировать 2377" transform="translate(-785.715 -333.096)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2376" data-name="Сгруппировать 2376" transform="translate(785.684 332.822)" style="isolation: isolate">
            <g id="Сгруппировать_2375" data-name="Сгруппировать 2375" clip-path="url(#clip-path-471)">
              <g id="Сгруппировать_2374" data-name="Сгруппировать 2374" transform="translate(0.03 0.275)" clip-path="url(#clip-path-559)">
                <g id="Сгруппировать_2373" data-name="Сгруппировать 2373" transform="translate(-0.03 -0.275)" clip-path="url(#clip-path-563)">
                  <g id="Сгруппировать_2371" data-name="Сгруппировать 2371" transform="translate(-406.06 -53.737)" opacity="0">
                    <g id="Сгруппировать_2370" data-name="Сгруппировать 2370" transform="translate(0 0)">
                      <path id="Контур_2387" data-name="Контур 2387" d="M-937.07-124.634H-334.7V275.792H-937.07Z" transform="translate(937.07 124.634)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2372" data-name="Сгруппировать 2372" transform="translate(-406.06 -53.737)">
                    <path id="Контур_2388" data-name="Контур 2388" d="M-937.07-124.634H-334.7V275.792H-937.07Z" transform="translate(937.07 124.634)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2387" data-name="Сгруппировать 2387" transform="translate(408.604 -1048.6)" clip-path="url(#clip-path-564)">
        <g id="Сгруппировать_2386" data-name="Сгруппировать 2386" transform="translate(-788.038 -333.096)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2385" data-name="Сгруппировать 2385" transform="translate(787.851 332.822)" style="isolation: isolate">
            <g id="Сгруппировать_2384" data-name="Сгруппировать 2384" transform="translate(0)" clip-path="url(#clip-path-516)">
              <g id="Сгруппировать_2383" data-name="Сгруппировать 2383" transform="translate(0.186 0.275)" clip-path="url(#clip-path-564)">
                <g id="Сгруппировать_2382" data-name="Сгруппировать 2382" transform="translate(-0.186 -0.275)" clip-path="url(#clip-path-568)">
                  <g id="Сгруппировать_2380" data-name="Сгруппировать 2380" transform="translate(-408.227 -53.737)" opacity="0">
                    <g id="Сгруппировать_2379" data-name="Сгруппировать 2379" transform="translate(0 0)">
                      <path id="Контур_2393" data-name="Контур 2393" d="M-942.43-124.634h602.373V275.792H-942.43Z" transform="translate(942.43 124.634)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2381" data-name="Сгруппировать 2381" transform="translate(-408.227 -53.737)">
                    <path id="Контур_2394" data-name="Контур 2394" d="M-942.43-124.634h602.373V275.792H-942.43Z" transform="translate(942.43 124.634)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2396" data-name="Сгруппировать 2396" transform="translate(393.124 -1042.051)" clip-path="url(#clip-path-569)">
        <g id="Сгруппировать_2395" data-name="Сгруппировать 2395" transform="translate(-772.558 -339.646)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2394" data-name="Сгруппировать 2394" transform="translate(772.25 339.322)" style="isolation: isolate">
            <g id="Сгруппировать_2393" data-name="Сгруппировать 2393" clip-path="url(#clip-path-516)">
              <g id="Сгруппировать_2392" data-name="Сгруппировать 2392" transform="translate(0.308 0.324)" clip-path="url(#clip-path-569)">
                <g id="Сгруппировать_2391" data-name="Сгруппировать 2391" transform="translate(-0.308 -0.324)" clip-path="url(#clip-path-573)">
                  <g id="Сгруппировать_2389" data-name="Сгруппировать 2389" transform="translate(-392.625 -60.237)" opacity="0">
                    <g id="Сгруппировать_2388" data-name="Сгруппировать 2388" transform="translate(0 0)">
                      <path id="Контур_2399" data-name="Контур 2399" d="M-906.71-139.747h602.373V260.679H-906.71Z" transform="translate(906.71 139.747)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2390" data-name="Сгруппировать 2390" transform="translate(-392.625 -60.237)">
                    <path id="Контур_2400" data-name="Контур 2400" d="M-906.71-139.747h602.373V260.679H-906.71Z" transform="translate(906.71 139.747)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2405" data-name="Сгруппировать 2405" transform="translate(395.889 -1042.051)" clip-path="url(#clip-path-574)">
        <g id="Сгруппировать_2404" data-name="Сгруппировать 2404" transform="translate(-775.323 -339.646)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2403" data-name="Сгруппировать 2403" transform="translate(775.284 339.322)" style="isolation: isolate">
            <g id="Сгруппировать_2402" data-name="Сгруппировать 2402" clip-path="url(#clip-path-471)">
              <g id="Сгруппировать_2401" data-name="Сгруппировать 2401" transform="translate(0.039 0.324)" clip-path="url(#clip-path-574)">
                <g id="Сгруппировать_2400" data-name="Сгруппировать 2400" transform="translate(-0.039 -0.324)" clip-path="url(#clip-path-578)">
                  <g id="Сгруппировать_2398" data-name="Сгруппировать 2398" transform="translate(-395.659 -60.237)" opacity="0">
                    <g id="Сгруппировать_2397" data-name="Сгруппировать 2397" transform="translate(0 0)">
                      <path id="Контур_2405" data-name="Контур 2405" d="M-913.09-139.747h602.373V260.679H-913.09Z" transform="translate(913.09 139.747)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2399" data-name="Сгруппировать 2399" transform="translate(-395.659 -60.237)">
                    <path id="Контур_2406" data-name="Контур 2406" d="M-913.09-139.747h602.373V260.679H-913.09Z" transform="translate(913.09 139.747)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2414" data-name="Сгруппировать 2414" transform="translate(398.216 -1042.051)" clip-path="url(#clip-path-579)">
        <g id="Сгруппировать_2413" data-name="Сгруппировать 2413" transform="translate(-777.65 -339.646)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2412" data-name="Сгруппировать 2412" transform="translate(777.451 339.322)" style="isolation: isolate">
            <g id="Сгруппировать_2411" data-name="Сгруппировать 2411" transform="translate(0)" clip-path="url(#clip-path-476)">
              <g id="Сгруппировать_2410" data-name="Сгруппировать 2410" transform="translate(0.199 0.324)" clip-path="url(#clip-path-579)">
                <g id="Сгруппировать_2409" data-name="Сгруппировать 2409" transform="translate(-0.199 -0.324)" clip-path="url(#clip-path-583)">
                  <g id="Сгруппировать_2407" data-name="Сгруппировать 2407" transform="translate(-397.826 -60.237)" opacity="0">
                    <g id="Сгруппировать_2406" data-name="Сгруппировать 2406" transform="translate(0 0)">
                      <path id="Контур_2411" data-name="Контур 2411" d="M-918.46-139.747h602.373V260.679H-918.46Z" transform="translate(918.46 139.747)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2408" data-name="Сгруппировать 2408" transform="translate(-397.826 -60.237)">
                    <path id="Контур_2412" data-name="Контур 2412" d="M-918.46-139.747h602.373V260.679H-918.46Z" transform="translate(918.46 139.747)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2423" data-name="Сгруппировать 2423" transform="translate(401.263 -1042.051)" clip-path="url(#clip-path-584)">
        <g id="Сгруппировать_2422" data-name="Сгруппировать 2422" transform="translate(-780.696 -339.646)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2421" data-name="Сгруппировать 2421" transform="translate(780.484 339.322)" style="isolation: isolate">
            <g id="Сгруппировать_2420" data-name="Сгруппировать 2420" transform="translate(0)" clip-path="url(#clip-path-516)">
              <g id="Сгруппировать_2419" data-name="Сгруппировать 2419" transform="translate(0.212 0.324)" clip-path="url(#clip-path-584)">
                <g id="Сгруппировать_2418" data-name="Сгруппировать 2418" transform="translate(-0.212 -0.324)" clip-path="url(#clip-path-588)">
                  <g id="Сгруппировать_2416" data-name="Сгруппировать 2416" transform="translate(-400.859 -60.237)" opacity="0">
                    <g id="Сгруппировать_2415" data-name="Сгруппировать 2415" transform="translate(0 0)">
                      <path id="Контур_2417" data-name="Контур 2417" d="M-925.49-139.747h602.372V260.679H-925.49Z" transform="translate(925.49 139.747)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2417" data-name="Сгруппировать 2417" transform="translate(-400.859 -60.237)">
                    <path id="Контур_2418" data-name="Контур 2418" d="M-925.49-139.747h602.372V260.679H-925.49Z" transform="translate(925.49 139.747)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2432" data-name="Сгруппировать 2432" transform="translate(403.872 -1042.113)" clip-path="url(#clip-path-589)">
        <g id="Сгруппировать_2431" data-name="Сгруппировать 2431" transform="translate(-783.305 -339.584)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2430" data-name="Сгруппировать 2430" transform="translate(783.084 339.322)" style="isolation: isolate">
            <g id="Сгруппировать_2429" data-name="Сгруппировать 2429" clip-path="url(#clip-path-476)">
              <g id="Сгруппировать_2428" data-name="Сгруппировать 2428" transform="translate(0.221 0.262)" clip-path="url(#clip-path-589)">
                <g id="Сгруппировать_2427" data-name="Сгруппировать 2427" transform="translate(-0.221 -0.262)" clip-path="url(#clip-path-593)">
                  <g id="Сгруппировать_2425" data-name="Сгруппировать 2425" transform="translate(-403.46 -60.237)" opacity="0">
                    <g id="Сгруппировать_2424" data-name="Сгруппировать 2424" transform="translate(0 0)">
                      <path id="Контур_2423" data-name="Контур 2423" d="M-931.51-139.605h602.372V260.821H-931.51Z" transform="translate(931.51 139.605)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2426" data-name="Сгруппировать 2426" transform="translate(-403.46 -60.237)">
                    <path id="Контур_2424" data-name="Контур 2424" d="M-931.51-139.605h602.372V260.821H-931.51Z" transform="translate(931.51 139.605)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2441" data-name="Сгруппировать 2441" transform="translate(407.117 -1042.113)" clip-path="url(#clip-path-594)">
        <g id="Сгруппировать_2440" data-name="Сгруппировать 2440" transform="translate(-786.551 -339.584)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2439" data-name="Сгруппировать 2439" transform="translate(786.551 339.322)" style="isolation: isolate">
            <g id="Сгруппировать_2438" data-name="Сгруппировать 2438" clip-path="url(#clip-path-476)">
              <g id="Сгруппировать_2437" data-name="Сгруппировать 2437" transform="translate(0 0.262)" clip-path="url(#clip-path-594)">
                <g id="Сгруппировать_2436" data-name="Сгруппировать 2436" transform="translate(0 -0.262)" clip-path="url(#clip-path-598)">
                  <g id="Сгруппировать_2434" data-name="Сгруппировать 2434" transform="translate(-406.926 -60.237)" opacity="0">
                    <g id="Сгруппировать_2433" data-name="Сгруппировать 2433" transform="translate(0 0)">
                      <path id="Контур_2429" data-name="Контур 2429" d="M-939-139.605h602.372V260.821H-939Z" transform="translate(939 139.605)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2435" data-name="Сгруппировать 2435" transform="translate(-406.926 -60.237)">
                    <path id="Контур_2430" data-name="Контур 2430" d="M-939-139.605h602.372V260.821H-939Z" transform="translate(939 139.605)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2450" data-name="Сгруппировать 2450" transform="translate(410.489 -1042.051)" clip-path="url(#clip-path-599)">
        <g id="Сгруппировать_2449" data-name="Сгруппировать 2449" transform="translate(-789.923 -339.646)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2448" data-name="Сгруппировать 2448" transform="translate(789.585 339.322)" style="isolation: isolate">
            <g id="Сгруппировать_2447" data-name="Сгруппировать 2447" clip-path="url(#clip-path-541)">
              <g id="Сгруппировать_2446" data-name="Сгруппировать 2446" transform="translate(0.338 0.324)" clip-path="url(#clip-path-599)">
                <g id="Сгруппировать_2445" data-name="Сгруппировать 2445" transform="translate(-0.338 -0.324)" clip-path="url(#clip-path-603)">
                  <g id="Сгруппировать_2443" data-name="Сгруппировать 2443" transform="translate(-409.96 -60.237)" opacity="0">
                    <g id="Сгруппировать_2442" data-name="Сгруппировать 2442" transform="translate(0 0)">
                      <path id="Контур_2435" data-name="Контур 2435" d="M-946.78-139.747h602.373V260.679H-946.78Z" transform="translate(946.78 139.747)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2444" data-name="Сгруппировать 2444" transform="translate(-409.96 -60.237)">
                    <path id="Контур_2436" data-name="Контур 2436" d="M-946.78-139.747h602.373V260.679H-946.78Z" transform="translate(946.78 139.747)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2459" data-name="Сгруппировать 2459" transform="translate(461.314 -994.837)" clip-path="url(#clip-path-604)">
        <g id="Сгруппировать_2458" data-name="Сгруппировать 2458" transform="translate(-840.747 -386.859)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2457" data-name="Сгруппировать 2457" transform="translate(840.721 386.558)" style="isolation: isolate">
            <g id="Сгруппировать_2456" data-name="Сгруппировать 2456" clip-path="url(#clip-path-606)">
              <g id="Сгруппировать_2455" data-name="Сгруппировать 2455" transform="translate(0.026 0.301)" clip-path="url(#clip-path-604)">
                <g id="Сгруппировать_2454" data-name="Сгруппировать 2454" transform="translate(-0.026 -0.301)" clip-path="url(#clip-path-608)">
                  <g id="Сгруппировать_2452" data-name="Сгруппировать 2452" transform="translate(-461.097 -107.474)" opacity="0">
                    <g id="Сгруппировать_2451" data-name="Сгруппировать 2451" transform="translate(0 0)">
                      <path id="Контур_2441" data-name="Контур 2441" d="M-1064.06-248.694h602.372V151.732H-1064.06Z" transform="translate(1064.06 248.694)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2453" data-name="Сгруппировать 2453" transform="translate(-461.097 -107.474)">
                    <path id="Контур_2442" data-name="Контур 2442" d="M-1064.06-248.694h602.372V151.732H-1064.06Z" transform="translate(1064.06 248.694)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2468" data-name="Сгруппировать 2468" transform="translate(459.507 -983.568)" clip-path="url(#clip-path-609)">
        <g id="Сгруппировать_2467" data-name="Сгруппировать 2467" transform="translate(-838.94 -398.129)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2466" data-name="Сгруппировать 2466" transform="translate(838.554 397.826)" style="isolation: isolate">
            <g id="Сгруппировать_2465" data-name="Сгруппировать 2465" transform="translate(0 0)" clip-path="url(#clip-path-611)">
              <g id="Сгруппировать_2464" data-name="Сгруппировать 2464" transform="translate(0.386 0.303)" clip-path="url(#clip-path-609)">
                <g id="Сгруппировать_2463" data-name="Сгруппировать 2463" transform="translate(-0.386 -0.303)" clip-path="url(#clip-path-613)">
                  <g id="Сгруппировать_2461" data-name="Сгруппировать 2461" transform="translate(-458.93 -118.741)" opacity="0">
                    <g id="Сгруппировать_2460" data-name="Сгруппировать 2460" transform="translate(0 0)">
                      <path id="Контур_2447" data-name="Контур 2447" d="M-1059.89-274.7h602.372V125.727H-1059.89Z" transform="translate(1059.89 274.699)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2462" data-name="Сгруппировать 2462" transform="translate(-458.93 -118.741)">
                    <path id="Контур_2448" data-name="Контур 2448" d="M-1059.89-274.7h602.372V125.727H-1059.89Z" transform="translate(1059.89 274.699)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2477" data-name="Сгруппировать 2477" transform="translate(507.701 -999.257)" clip-path="url(#clip-path-614)">
        <g id="Сгруппировать_2476" data-name="Сгруппировать 2476" transform="translate(-887.134 -382.439)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2475" data-name="Сгруппировать 2475" transform="translate(887.091 382.225)" style="isolation: isolate">
            <g id="Сгруппировать_2474" data-name="Сгруппировать 2474" transform="translate(0)" clip-path="url(#clip-path-616)">
              <g id="Сгруппировать_2473" data-name="Сгруппировать 2473" transform="translate(0.043 0.215)" clip-path="url(#clip-path-614)">
                <g id="Сгруппировать_2472" data-name="Сгруппировать 2472" transform="translate(-0.043 -0.215)" clip-path="url(#clip-path-618)">
                  <g id="Сгруппировать_2470" data-name="Сгруппировать 2470" transform="translate(-507.466 -103.14)" opacity="0">
                    <g id="Сгруппировать_2469" data-name="Сгруппировать 2469">
                      <path id="Контур_2453" data-name="Контур 2453" d="M-1171.1-238.495h602.373V161.931H-1171.1Z" transform="translate(1171.1 238.495)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2471" data-name="Сгруппировать 2471" transform="translate(-507.466 -103.14)">
                    <path id="Контур_2454" data-name="Контур 2454" d="M-1171.1-238.495h602.373V161.931H-1171.1Z" transform="translate(1171.1 238.495)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2486" data-name="Сгруппировать 2486" transform="translate(507.701 -982.616)" clip-path="url(#clip-path-619)">
        <g id="Сгруппировать_2485" data-name="Сгруппировать 2485" transform="translate(-887.134 -399.081)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2484" data-name="Сгруппировать 2484" transform="translate(887.091 398.693)" style="isolation: isolate">
            <g id="Сгруппировать_2483" data-name="Сгруппировать 2483" transform="translate(0)" clip-path="url(#clip-path-616)">
              <g id="Сгруппировать_2482" data-name="Сгруппировать 2482" transform="translate(0.043 0.388)" clip-path="url(#clip-path-619)">
                <g id="Сгруппировать_2481" data-name="Сгруппировать 2481" transform="translate(-0.043 -0.388)" clip-path="url(#clip-path-623)">
                  <g id="Сгруппировать_2479" data-name="Сгруппировать 2479" transform="translate(-507.466 -119.608)" opacity="0">
                    <g id="Сгруппировать_2478" data-name="Сгруппировать 2478">
                      <path id="Контур_2459" data-name="Контур 2459" d="M-1171.1-276.9h602.373V123.53H-1171.1Z" transform="translate(1171.1 276.896)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2480" data-name="Сгруппировать 2480" transform="translate(-507.466 -119.608)">
                    <path id="Контур_2460" data-name="Контур 2460" d="M-1171.1-276.9h602.373V123.53H-1171.1Z" transform="translate(1171.1 276.896)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2495" data-name="Сгруппировать 2495" transform="translate(456.733 -949.743)" clip-path="url(#clip-path-624)">
        <g id="Сгруппировать_2494" data-name="Сгруппировать 2494" transform="translate(-836.167 -431.953)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2493" data-name="Сгруппировать 2493" transform="translate(835.954 431.628)" style="isolation: isolate">
            <g id="Сгруппировать_2492" data-name="Сгруппировать 2492" clip-path="url(#clip-path-626)">
              <g id="Сгруппировать_2491" data-name="Сгруппировать 2491" transform="translate(0.212 0.325)" clip-path="url(#clip-path-624)">
                <g id="Сгруппировать_2490" data-name="Сгруппировать 2490" transform="translate(-0.212 -0.325)" clip-path="url(#clip-path-628)">
                  <g id="Сгруппировать_2488" data-name="Сгруппировать 2488" transform="translate(-456.33 -152.543)" opacity="0">
                    <g id="Сгруппировать_2487" data-name="Сгруппировать 2487" transform="translate(0 0)">
                      <path id="Контур_2465" data-name="Контур 2465" d="M-1053.49-352.751h602.372V47.675H-1053.49Z" transform="translate(1053.49 352.751)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2489" data-name="Сгруппировать 2489" transform="translate(-456.33 -152.543)">
                    <path id="Контур_2466" data-name="Контур 2466" d="M-1053.49-352.751h602.372V47.675H-1053.49Z" transform="translate(1053.49 352.751)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2504" data-name="Сгруппировать 2504" transform="translate(474.986 -949.743)" clip-path="url(#clip-path-629)">
        <g id="Сгруппировать_2503" data-name="Сгруппировать 2503" transform="translate(-854.42 -431.953)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2502" data-name="Сгруппировать 2502" transform="translate(854.155 431.628)" style="isolation: isolate">
            <g id="Сгруппировать_2501" data-name="Сгруппировать 2501" transform="translate(0)" clip-path="url(#clip-path-626)">
              <g id="Сгруппировать_2500" data-name="Сгруппировать 2500" transform="translate(0.264 0.325)" clip-path="url(#clip-path-629)">
                <g id="Сгруппировать_2499" data-name="Сгруппировать 2499" transform="translate(-0.264 -0.325)" clip-path="url(#clip-path-633)">
                  <g id="Сгруппировать_2497" data-name="Сгруппировать 2497" transform="translate(-474.531 -152.543)" opacity="0">
                    <g id="Сгруппировать_2496" data-name="Сгруппировать 2496" transform="translate(0 0)">
                      <path id="Контур_2471" data-name="Контур 2471" d="M-1095.61-352.751h602.372V47.675H-1095.61Z" transform="translate(1095.61 352.751)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2498" data-name="Сгруппировать 2498" transform="translate(-474.531 -152.543)">
                    <path id="Контур_2472" data-name="Контур 2472" d="M-1095.61-352.751h602.372V47.675H-1095.61Z" transform="translate(1095.61 352.751)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2513" data-name="Сгруппировать 2513" transform="translate(493.265 -966.144)" clip-path="url(#clip-path-634)">
        <g id="Сгруппировать_2512" data-name="Сгруппировать 2512" transform="translate(-872.699 -415.553)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2511" data-name="Сгруппировать 2511" transform="translate(872.357 415.16)" style="isolation: isolate">
            <g id="Сгруппировать_2510" data-name="Сгруппировать 2510" transform="translate(0 0)" clip-path="url(#clip-path-636)">
              <g id="Сгруппировать_2509" data-name="Сгруппировать 2509" transform="translate(0.342 0.393)" clip-path="url(#clip-path-634)">
                <g id="Сгруппировать_2508" data-name="Сгруппировать 2508" transform="translate(-0.342 -0.393)" clip-path="url(#clip-path-638)">
                  <g id="Сгруппировать_2506" data-name="Сгруппировать 2506" transform="translate(-492.732 -136.076)" opacity="0">
                    <g id="Сгруппировать_2505" data-name="Сгруппировать 2505">
                      <path id="Контур_2477" data-name="Контур 2477" d="M-1137.79-314.906h602.372V85.52H-1137.79Z" transform="translate(1137.79 314.906)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2507" data-name="Сгруппировать 2507" transform="translate(-492.732 -136.076)">
                    <path id="Контур_2478" data-name="Контур 2478" d="M-1137.79-314.906h602.372V85.52H-1137.79Z" transform="translate(1137.79 314.906)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2522" data-name="Сгруппировать 2522" transform="translate(516.767 -999.257)" clip-path="url(#clip-path-639)">
        <g id="Сгруппировать_2521" data-name="Сгруппировать 2521" transform="translate(-896.2 -382.439)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2520" data-name="Сгруппировать 2520" transform="translate(896.192 382.225)" style="isolation: isolate">
            <g id="Сгруппировать_2519" data-name="Сгруппировать 2519" clip-path="url(#clip-path-641)">
              <g id="Сгруппировать_2518" data-name="Сгруппировать 2518" transform="translate(0.009 0.215)" clip-path="url(#clip-path-639)">
                <g id="Сгруппировать_2517" data-name="Сгруппировать 2517" transform="translate(-0.009 -0.215)" clip-path="url(#clip-path-643)">
                  <g id="Сгруппировать_2515" data-name="Сгруппировать 2515" transform="translate(-516.567 -103.14)" opacity="0">
                    <g id="Сгруппировать_2514" data-name="Сгруппировать 2514">
                      <path id="Контур_2483" data-name="Контур 2483" d="M-1192.02-238.495h602.372V161.931H-1192.02Z" transform="translate(1192.02 238.495)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2516" data-name="Сгруппировать 2516" transform="translate(-516.567 -103.14)">
                    <path id="Контур_2484" data-name="Контур 2484" d="M-1192.02-238.495h602.372V161.931H-1192.02Z" transform="translate(1192.02 238.495)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2531" data-name="Сгруппировать 2531" transform="translate(516.767 -982.616)" clip-path="url(#clip-path-644)">
        <g id="Сгруппировать_2530" data-name="Сгруппировать 2530" transform="translate(-896.2 -399.081)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2529" data-name="Сгруппировать 2529" transform="translate(896.192 398.693)" style="isolation: isolate">
            <g id="Сгруппировать_2528" data-name="Сгруппировать 2528" clip-path="url(#clip-path-641)">
              <g id="Сгруппировать_2527" data-name="Сгруппировать 2527" transform="translate(0.009 0.388)" clip-path="url(#clip-path-644)">
                <g id="Сгруппировать_2526" data-name="Сгруппировать 2526" transform="translate(-0.009 -0.388)" clip-path="url(#clip-path-648)">
                  <g id="Сгруппировать_2524" data-name="Сгруппировать 2524" transform="translate(-516.567 -119.608)" opacity="0">
                    <g id="Сгруппировать_2523" data-name="Сгруппировать 2523">
                      <path id="Контур_2489" data-name="Контур 2489" d="M-1192.02-276.9h602.372V123.53H-1192.02Z" transform="translate(1192.02 276.896)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2525" data-name="Сгруппировать 2525" transform="translate(-516.567 -119.608)">
                    <path id="Контур_2490" data-name="Контур 2490" d="M-1192.02-276.9h602.372V123.53H-1192.02Z" transform="translate(1192.02 276.896)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2540" data-name="Сгруппировать 2540" transform="translate(516.767 -966.144)" clip-path="url(#clip-path-649)">
        <g id="Сгруппировать_2539" data-name="Сгруппировать 2539" transform="translate(-896.2 -415.553)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2538" data-name="Сгруппировать 2538" transform="translate(896.192 415.16)" style="isolation: isolate">
            <g id="Сгруппировать_2537" data-name="Сгруппировать 2537" transform="translate(0 0)" clip-path="url(#clip-path-651)">
              <g id="Сгруппировать_2536" data-name="Сгруппировать 2536" transform="translate(0.009 0.393)" clip-path="url(#clip-path-649)">
                <g id="Сгруппировать_2535" data-name="Сгруппировать 2535" transform="translate(-0.009 -0.393)" clip-path="url(#clip-path-653)">
                  <g id="Сгруппировать_2533" data-name="Сгруппировать 2533" transform="translate(-516.567 -136.076)" opacity="0">
                    <g id="Сгруппировать_2532" data-name="Сгруппировать 2532">
                      <path id="Контур_2495" data-name="Контур 2495" d="M-1192.02-314.906h602.372V85.52H-1192.02Z" transform="translate(1192.02 314.906)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2534" data-name="Сгруппировать 2534" transform="translate(-516.567 -136.076)">
                    <path id="Контур_2496" data-name="Контур 2496" d="M-1192.02-314.906h602.372V85.52H-1192.02Z" transform="translate(1192.02 314.906)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2549" data-name="Сгруппировать 2549" transform="translate(456.733 -941.393)" clip-path="url(#clip-path-654)">
        <g id="Сгруппировать_2548" data-name="Сгруппировать 2548" transform="translate(-836.167 -440.304)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2547" data-name="Сгруппировать 2547" transform="translate(835.954 440.295)" style="isolation: isolate">
            <g id="Сгруппировать_2546" data-name="Сгруппировать 2546" transform="translate(0 0)" clip-path="url(#clip-path-656)">
              <g id="Сгруппировать_2545" data-name="Сгруппировать 2545" transform="translate(0.212 0.009)" clip-path="url(#clip-path-654)">
                <g id="Сгруппировать_2544" data-name="Сгруппировать 2544" transform="translate(-0.212 -0.009)" clip-path="url(#clip-path-658)">
                  <g id="Сгруппировать_2542" data-name="Сгруппировать 2542" transform="translate(-456.33 -161.21)" opacity="0">
                    <g id="Сгруппировать_2541" data-name="Сгруппировать 2541" transform="translate(0 0)">
                      <path id="Контур_2501" data-name="Контур 2501" d="M-1053.49-372.02h602.372V28.406H-1053.49Z" transform="translate(1053.49 372.02)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2543" data-name="Сгруппировать 2543" transform="translate(-456.33 -161.21)">
                    <path id="Контур_2502" data-name="Контур 2502" d="M-1053.49-372.02h602.372V28.406H-1053.49Z" transform="translate(1053.49 372.02)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2558" data-name="Сгруппировать 2558" transform="translate(474.986 -941.393)" clip-path="url(#clip-path-659)">
        <g id="Сгруппировать_2557" data-name="Сгруппировать 2557" transform="translate(-854.42 -440.304)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2556" data-name="Сгруппировать 2556" transform="translate(854.155 440.295)" style="isolation: isolate">
            <g id="Сгруппировать_2555" data-name="Сгруппировать 2555" transform="translate(0 0)" clip-path="url(#clip-path-656)">
              <g id="Сгруппировать_2554" data-name="Сгруппировать 2554" transform="translate(0.264 0.009)" clip-path="url(#clip-path-659)">
                <g id="Сгруппировать_2553" data-name="Сгруппировать 2553" transform="translate(-0.264 -0.009)" clip-path="url(#clip-path-663)">
                  <g id="Сгруппировать_2551" data-name="Сгруппировать 2551" transform="translate(-474.531 -161.21)" opacity="0">
                    <g id="Сгруппировать_2550" data-name="Сгруппировать 2550" transform="translate(0 0)">
                      <path id="Контур_2507" data-name="Контур 2507" d="M-1095.61-372.02h602.372V28.406H-1095.61Z" transform="translate(1095.61 372.02)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2552" data-name="Сгруппировать 2552" transform="translate(-474.531 -161.21)">
                    <path id="Контур_2508" data-name="Контур 2508" d="M-1095.61-372.02h602.372V28.406H-1095.61Z" transform="translate(1095.61 372.02)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2567" data-name="Сгруппировать 2567" transform="translate(493.265 -941.393)" clip-path="url(#clip-path-664)">
        <g id="Сгруппировать_2566" data-name="Сгруппировать 2566" transform="translate(-872.699 -440.304)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2565" data-name="Сгруппировать 2565" transform="translate(872.357 440.295)" style="isolation: isolate">
            <g id="Сгруппировать_2564" data-name="Сгруппировать 2564" transform="translate(0 0)" clip-path="url(#clip-path-666)">
              <g id="Сгруппировать_2563" data-name="Сгруппировать 2563" transform="translate(0.342 0.009)" clip-path="url(#clip-path-664)">
                <g id="Сгруппировать_2562" data-name="Сгруппировать 2562" transform="translate(-0.342 -0.009)" clip-path="url(#clip-path-668)">
                  <g id="Сгруппировать_2560" data-name="Сгруппировать 2560" transform="translate(-492.732 -161.21)" opacity="0">
                    <g id="Сгруппировать_2559" data-name="Сгруппировать 2559" transform="translate(0 0)">
                      <path id="Контур_2513" data-name="Контур 2513" d="M-1137.79-372.02h602.372V28.406H-1137.79Z" transform="translate(1137.79 372.02)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2561" data-name="Сгруппировать 2561" transform="translate(-492.732 -161.21)">
                    <path id="Контур_2514" data-name="Контур 2514" d="M-1137.79-372.02h602.372V28.406H-1137.79Z" transform="translate(1137.79 372.02)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2576" data-name="Сгруппировать 2576" transform="translate(324.276 -980.385)" clip-path="url(#clip-path-669)">
        <g id="Сгруппировать_2575" data-name="Сгруппировать 2575" transform="translate(-703.71 -401.311)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2574" data-name="Сгруппировать 2574" transform="translate(703.346 401.293)" style="isolation: isolate">
            <g id="Сгруппировать_2573" data-name="Сгруппировать 2573" transform="translate(0 0)" clip-path="url(#clip-path-671)">
              <g id="Сгруппировать_2572" data-name="Сгруппировать 2572" transform="translate(0.364 0.019)" clip-path="url(#clip-path-669)">
                <g id="Сгруппировать_2571" data-name="Сгруппировать 2571" transform="translate(-0.364 -0.019)" clip-path="url(#clip-path-673)">
                  <g id="Сгруппировать_2569" data-name="Сгруппировать 2569" transform="translate(-323.721 -122.208)" opacity="0">
                    <g id="Сгруппировать_2568" data-name="Сгруппировать 2568" transform="translate(0 0)">
                      <path id="Контур_2519" data-name="Контур 2519" d="M-747.84-282.043h602.372V118.383H-747.84Z" transform="translate(747.84 282.043)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2570" data-name="Сгруппировать 2570" transform="translate(-323.721 -122.208)">
                    <path id="Контур_2520" data-name="Контур 2520" d="M-747.84-282.043h602.372V118.383H-747.84Z" transform="translate(747.84 282.043)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2585" data-name="Сгруппировать 2585" transform="translate(328.705 -1010.318)" clip-path="url(#clip-path-674)">
        <g id="Сгруппировать_2584" data-name="Сгруппировать 2584" transform="translate(-708.139 -371.379)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2583" data-name="Сгруппировать 2583" transform="translate(708.113 370.957)" style="isolation: isolate">
            <g id="Сгруппировать_2582" data-name="Сгруппировать 2582" transform="translate(0)" clip-path="url(#clip-path-676)">
              <g id="Сгруппировать_2581" data-name="Сгруппировать 2581" transform="translate(0.026 0.422)" clip-path="url(#clip-path-674)">
                <g id="Сгруппировать_2580" data-name="Сгруппировать 2580" transform="translate(-0.026 -0.422)" clip-path="url(#clip-path-678)">
                  <g id="Сгруппировать_2578" data-name="Сгруппировать 2578" transform="translate(-328.488 -91.873)" opacity="0">
                    <g id="Сгруппировать_2577" data-name="Сгруппировать 2577" transform="translate(0 0)">
                      <path id="Контур_2525" data-name="Контур 2525" d="M-758.06-212.973h602.373V187.453H-758.06Z" transform="translate(758.06 212.973)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2579" data-name="Сгруппировать 2579" transform="translate(-328.488 -91.873)">
                    <path id="Контур_2526" data-name="Контур 2526" d="M-758.06-212.973h602.373V187.453H-758.06Z" transform="translate(758.06 212.973)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2594" data-name="Сгруппировать 2594" transform="translate(398.082 -1010.65)" clip-path="url(#clip-path-679)">
        <g id="Сгруппировать_2593" data-name="Сгруппировать 2593" transform="translate(-777.516 -371.047)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2592" data-name="Сгруппировать 2592" transform="translate(777.451 370.957)" style="isolation: isolate">
            <g id="Сгруппировать_2591" data-name="Сгруппировать 2591" transform="translate(0)" clip-path="url(#clip-path-681)">
              <g id="Сгруппировать_2590" data-name="Сгруппировать 2590" transform="translate(0.065 0.089)" clip-path="url(#clip-path-679)">
                <g id="Сгруппировать_2589" data-name="Сгруппировать 2589" transform="translate(-0.065 -0.089)" clip-path="url(#clip-path-683)">
                  <g id="Сгруппировать_2587" data-name="Сгруппировать 2587" transform="translate(-397.826 -91.873)" opacity="0">
                    <g id="Сгруппировать_2586" data-name="Сгруппировать 2586">
                      <path id="Контур_2531" data-name="Контур 2531" d="M-918.15-212.206h602.373V188.22H-918.15Z" transform="translate(918.15 212.206)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2588" data-name="Сгруппировать 2588" transform="translate(-397.826 -91.873)">
                    <path id="Контур_2532" data-name="Контур 2532" d="M-918.15-212.206h602.373V188.22H-918.15Z" transform="translate(918.15 212.206)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2603" data-name="Сгруппировать 2603" transform="translate(428.625 -1010.65)" clip-path="url(#clip-path-684)">
        <g id="Сгруппировать_2602" data-name="Сгруппировать 2602" transform="translate(-808.059 -371.047)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2601" data-name="Сгруппировать 2601" transform="translate(807.786 370.957)" style="isolation: isolate">
            <g id="Сгруппировать_2600" data-name="Сгруппировать 2600" transform="translate(0)" clip-path="url(#clip-path-686)">
              <g id="Сгруппировать_2599" data-name="Сгруппировать 2599" transform="translate(0.273 0.089)" clip-path="url(#clip-path-684)">
                <g id="Сгруппировать_2598" data-name="Сгруппировать 2598" transform="translate(-0.273 -0.089)" clip-path="url(#clip-path-688)">
                  <g id="Сгруппировать_2596" data-name="Сгруппировать 2596" transform="translate(-428.161 -91.873)" opacity="0">
                    <g id="Сгруппировать_2595" data-name="Сгруппировать 2595">
                      <path id="Контур_2537" data-name="Контур 2537" d="M-988.63-212.206h602.372V188.22H-988.63Z" transform="translate(988.63 212.206)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2597" data-name="Сгруппировать 2597" transform="translate(-428.161 -91.873)">
                    <path id="Контур_2538" data-name="Контур 2538" d="M-988.63-212.206h602.372V188.22H-988.63Z" transform="translate(988.63 212.206)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2608" data-name="Сгруппировать 2608" transform="translate(363.981 -1009.894)" clip-path="url(#clip-path-689)">
        <g id="Сгруппировать_2607" data-name="Сгруппировать 2607" transform="translate(-743.414 -371.802)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2605" data-name="Сгруппировать 2605" transform="translate(379.625 279.085)" opacity="0">
            <g id="Сгруппировать_2604" data-name="Сгруппировать 2604" transform="translate(0 0)">
              <path id="Контур_2543" data-name="Контур 2543" d="M-839.46-213.95h602.372V186.476H-839.46Z" transform="translate(839.46 213.95)" fill="#c4d3e0"/>
            </g>
          </g>
          <g id="Сгруппировать_2606" data-name="Сгруппировать 2606" transform="translate(379.625 279.085)">
            <path id="Контур_2544" data-name="Контур 2544" d="M-839.46-213.95h602.372V186.476H-839.46Z" transform="translate(839.46 213.95)" fill="#c4d3e0"/>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2617" data-name="Сгруппировать 2617" transform="translate(321.58 -909.606)" clip-path="url(#clip-path-691)">
        <g id="Сгруппировать_2616" data-name="Сгруппировать 2616" transform="translate(-701.014 -472.091)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2615" data-name="Сгруппировать 2615" transform="translate(700.746 471.931)" style="isolation: isolate">
            <g id="Сгруппировать_2614" data-name="Сгруппировать 2614" transform="translate(0 0)" clip-path="url(#clip-path-693)">
              <g id="Сгруппировать_2613" data-name="Сгруппировать 2613" transform="translate(0.269 0.16)" clip-path="url(#clip-path-691)">
                <g id="Сгруппировать_2612" data-name="Сгруппировать 2612" transform="translate(-0.269 -0.16)" clip-path="url(#clip-path-695)">
                  <g id="Сгруппировать_2610" data-name="Сгруппировать 2610" transform="translate(-321.121 -192.846)" opacity="0">
                    <g id="Сгруппировать_2609" data-name="Сгруппировать 2609">
                      <path id="Контур_2547" data-name="Контур 2547" d="M-741.62-445.37h602.372V-44.944H-741.62Z" transform="translate(741.62 445.37)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2611" data-name="Сгруппировать 2611" transform="translate(-321.121 -192.846)">
                    <path id="Контур_2548" data-name="Контур 2548" d="M-741.62-445.37h602.372V-44.944H-741.62Z" transform="translate(741.62 445.37)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2626" data-name="Сгруппировать 2626" transform="translate(325.13 -907.33)" clip-path="url(#clip-path-696)">
        <g id="Сгруппировать_2625" data-name="Сгруппировать 2625" transform="translate(-704.563 -474.366)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2624" data-name="Сгруппировать 2624" transform="translate(704.212 474.097)" style="isolation: isolate">
            <g id="Сгруппировать_2623" data-name="Сгруппировать 2623" clip-path="url(#clip-path-199)">
              <g id="Сгруппировать_2622" data-name="Сгруппировать 2622" transform="translate(0.351 0.269)" clip-path="url(#clip-path-696)">
                <g id="Сгруппировать_2621" data-name="Сгруппировать 2621" transform="translate(-0.351 -0.269)" clip-path="url(#clip-path-700)">
                  <g id="Сгруппировать_2619" data-name="Сгруппировать 2619" transform="translate(-324.588 -195.013)" opacity="0">
                    <g id="Сгруппировать_2618" data-name="Сгруппировать 2618">
                      <path id="Контур_2553" data-name="Контур 2553" d="M-749.81-450.62h602.373V-50.194H-749.81Z" transform="translate(749.81 450.62)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2620" data-name="Сгруппировать 2620" transform="translate(-324.588 -195.013)">
                    <path id="Контур_2554" data-name="Контур 2554" d="M-749.81-450.62h602.373V-50.194H-749.81Z" transform="translate(749.81 450.62)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2635" data-name="Сгруппировать 2635" transform="translate(407.126 -906.52)" clip-path="url(#clip-path-701)">
        <g id="Сгруппировать_2634" data-name="Сгруппировать 2634" transform="translate(-786.56 -475.177)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2633" data-name="Сгруппировать 2633" transform="translate(786.551 474.964)" style="isolation: isolate">
            <g id="Сгруппировать_2632" data-name="Сгруппировать 2632" clip-path="url(#clip-path-703)">
              <g id="Сгруппировать_2631" data-name="Сгруппировать 2631" transform="translate(0.009 0.212)" clip-path="url(#clip-path-701)">
                <g id="Сгруппировать_2630" data-name="Сгруппировать 2630" transform="translate(-0.009 -0.212)" clip-path="url(#clip-path-705)">
                  <g id="Сгруппировать_2628" data-name="Сгруппировать 2628" transform="translate(-406.926 -195.879)" opacity="0">
                    <g id="Сгруппировать_2627" data-name="Сгруппировать 2627">
                      <path id="Контур_2559" data-name="Контур 2559" d="M-939.02-452.49h602.373V-52.064H-939.02Z" transform="translate(939.02 452.49)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2629" data-name="Сгруппировать 2629" transform="translate(-406.926 -195.879)">
                    <path id="Контур_2560" data-name="Контур 2560" d="M-939.02-452.49h602.373V-52.064H-939.02Z" transform="translate(939.02 452.49)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2644" data-name="Сгруппировать 2644" transform="translate(326.451 -852.658)" clip-path="url(#clip-path-706)">
        <g id="Сгруппировать_2643" data-name="Сгруппировать 2643" transform="translate(-705.885 -529.039)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2642" data-name="Сгруппировать 2642" transform="translate(705.513 528.701)" style="isolation: isolate">
            <g id="Сгруппировать_2641" data-name="Сгруппировать 2641" clip-path="url(#clip-path-708)">
              <g id="Сгруппировать_2640" data-name="Сгруппировать 2640" transform="translate(0.373 0.338)" clip-path="url(#clip-path-706)">
                <g id="Сгруппировать_2639" data-name="Сгруппировать 2639" transform="translate(-0.373 -0.338)" clip-path="url(#clip-path-710)">
                  <g id="Сгруппировать_2637" data-name="Сгруппировать 2637" transform="translate(-325.888 -249.616)" opacity="0">
                    <g id="Сгруппировать_2636" data-name="Сгруппировать 2636">
                      <path id="Контур_2565" data-name="Контур 2565" d="M-752.86-576.78h602.372v400.426H-752.86Z" transform="translate(752.86 576.78)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2638" data-name="Сгруппировать 2638" transform="translate(-325.888 -249.616)">
                    <path id="Контур_2566" data-name="Контур 2566" d="M-752.86-576.78h602.372v400.426H-752.86Z" transform="translate(752.86 576.78)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2653" data-name="Сгруппировать 2653" transform="translate(435.061 -902.858)" clip-path="url(#clip-path-711)">
        <g id="Сгруппировать_2652" data-name="Сгруппировать 2652" transform="translate(-814.494 -478.838)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2651" data-name="Сгруппировать 2651" transform="translate(814.286 478.431)" style="isolation: isolate">
            <g id="Сгруппировать_2650" data-name="Сгруппировать 2650" clip-path="url(#clip-path-713)">
              <g id="Сгруппировать_2649" data-name="Сгруппировать 2649" transform="translate(0.208 0.407)" clip-path="url(#clip-path-711)">
                <g id="Сгруппировать_2648" data-name="Сгруппировать 2648" transform="translate(-0.208 -0.407)" clip-path="url(#clip-path-715)">
                  <g id="Сгруппировать_2646" data-name="Сгруппировать 2646" transform="translate(-434.662 -199.346)" opacity="0">
                    <g id="Сгруппировать_2645" data-name="Сгруппировать 2645">
                      <path id="Контур_2571" data-name="Контур 2571" d="M-1003.48-460.94h602.373V-60.514H-1003.48Z" transform="translate(1003.48 460.94)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2647" data-name="Сгруппировать 2647" transform="translate(-434.662 -199.346)">
                    <path id="Контур_2572" data-name="Контур 2572" d="M-1003.48-460.94h602.373V-60.514H-1003.48Z" transform="translate(1003.48 460.94)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2662" data-name="Сгруппировать 2662" transform="translate(435.061 -897.835)" clip-path="url(#clip-path-716)">
        <g id="Сгруппировать_2661" data-name="Сгруппировать 2661" transform="translate(-814.494 -483.861)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2660" data-name="Сгруппировать 2660" transform="translate(814.286 483.631)" style="isolation: isolate">
            <g id="Сгруппировать_2659" data-name="Сгруппировать 2659" transform="translate(0 0)" clip-path="url(#clip-path-718)">
              <g id="Сгруппировать_2658" data-name="Сгруппировать 2658" transform="translate(0.208 0.23)" clip-path="url(#clip-path-716)">
                <g id="Сгруппировать_2657" data-name="Сгруппировать 2657" transform="translate(-0.208 -0.23)" clip-path="url(#clip-path-720)">
                  <g id="Сгруппировать_2655" data-name="Сгруппировать 2655" transform="translate(-434.662 -204.547)" opacity="0">
                    <g id="Сгруппировать_2654" data-name="Сгруппировать 2654" transform="translate(0 0)">
                      <path id="Контур_2577" data-name="Контур 2577" d="M-1003.48-472.53h602.373V-72.1H-1003.48Z" transform="translate(1003.48 472.53)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2656" data-name="Сгруппировать 2656" transform="translate(-434.662 -204.547)">
                    <path id="Контур_2578" data-name="Контур 2578" d="M-1003.48-472.53h602.373V-72.1H-1003.48Z" transform="translate(1003.48 472.53)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2671" data-name="Сгруппировать 2671" transform="translate(435.061 -885.667)" clip-path="url(#clip-path-721)">
        <g id="Сгруппировать_2670" data-name="Сгруппировать 2670" transform="translate(-814.494 -496.03)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2669" data-name="Сгруппировать 2669" transform="translate(814.286 495.766)" style="isolation: isolate">
            <g id="Сгруппировать_2668" data-name="Сгруппировать 2668" transform="translate(0 0)" clip-path="url(#clip-path-718)">
              <g id="Сгруппировать_2667" data-name="Сгруппировать 2667" transform="translate(0.208 0.264)" clip-path="url(#clip-path-721)">
                <g id="Сгруппировать_2666" data-name="Сгруппировать 2666" transform="translate(-0.208 -0.264)" clip-path="url(#clip-path-725)">
                  <g id="Сгруппировать_2664" data-name="Сгруппировать 2664" transform="translate(-434.662 -216.681)" opacity="0">
                    <g id="Сгруппировать_2663" data-name="Сгруппировать 2663">
                      <path id="Контур_2583" data-name="Контур 2583" d="M-1003.48-500.61h602.373v400.426H-1003.48Z" transform="translate(1003.48 500.61)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2665" data-name="Сгруппировать 2665" transform="translate(-434.662 -216.681)">
                    <path id="Контур_2584" data-name="Контур 2584" d="M-1003.48-500.61h602.373v400.426H-1003.48Z" transform="translate(1003.48 500.61)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2680" data-name="Сгруппировать 2680" transform="translate(435.061 -874.529)" clip-path="url(#clip-path-726)">
        <g id="Сгруппировать_2679" data-name="Сгруппировать 2679" transform="translate(-814.494 -507.167)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2678" data-name="Сгруппировать 2678" transform="translate(814.286 507.033)" style="isolation: isolate">
            <g id="Сгруппировать_2677" data-name="Сгруппировать 2677" transform="translate(0 0)" clip-path="url(#clip-path-718)">
              <g id="Сгруппировать_2676" data-name="Сгруппировать 2676" transform="translate(0.208 0.134)" clip-path="url(#clip-path-726)">
                <g id="Сгруппировать_2675" data-name="Сгруппировать 2675" transform="translate(-0.208 -0.134)" clip-path="url(#clip-path-730)">
                  <g id="Сгруппировать_2673" data-name="Сгруппировать 2673" transform="translate(-434.662 -227.948)" opacity="0">
                    <g id="Сгруппировать_2672" data-name="Сгруппировать 2672">
                      <path id="Контур_2589" data-name="Контур 2589" d="M-1003.48-526.31h602.373v400.426H-1003.48Z" transform="translate(1003.48 526.31)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2674" data-name="Сгруппировать 2674" transform="translate(-434.662 -227.948)">
                    <path id="Контур_2590" data-name="Контур 2590" d="M-1003.48-526.31h602.373v400.426H-1003.48Z" transform="translate(1003.48 526.31)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2689" data-name="Сгруппировать 2689" transform="translate(435.061 -862.703)" clip-path="url(#clip-path-731)">
        <g id="Сгруппировать_2688" data-name="Сгруппировать 2688" transform="translate(-814.494 -518.994)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2687" data-name="Сгруппировать 2687" transform="translate(814.286 518.734)" style="isolation: isolate">
            <g id="Сгруппировать_2686" data-name="Сгруппировать 2686" transform="translate(0 0)" clip-path="url(#clip-path-718)">
              <g id="Сгруппировать_2685" data-name="Сгруппировать 2685" transform="translate(0.208 0.26)" clip-path="url(#clip-path-731)">
                <g id="Сгруппировать_2684" data-name="Сгруппировать 2684" transform="translate(-0.208 -0.26)" clip-path="url(#clip-path-735)">
                  <g id="Сгруппировать_2682" data-name="Сгруппировать 2682" transform="translate(-434.662 -239.649)" opacity="0">
                    <g id="Сгруппировать_2681" data-name="Сгруппировать 2681">
                      <path id="Контур_2595" data-name="Контур 2595" d="M-1003.48-553.6h602.373v400.426H-1003.48Z" transform="translate(1003.48 553.6)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2683" data-name="Сгруппировать 2683" transform="translate(-434.662 -239.649)">
                    <path id="Контур_2596" data-name="Контур 2596" d="M-1003.48-553.6h602.373v400.426H-1003.48Z" transform="translate(1003.48 553.6)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2698" data-name="Сгруппировать 2698" transform="translate(461.361 -882.191)" clip-path="url(#clip-path-736)">
        <g id="Сгруппировать_2697" data-name="Сгруппировать 2697" transform="translate(-840.795 -499.505)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2696" data-name="Сгруппировать 2696" transform="translate(840.721 499.232)" style="isolation: isolate">
            <g id="Сгруппировать_2695" data-name="Сгруппировать 2695" transform="translate(0)" clip-path="url(#clip-path-738)">
              <g id="Сгруппировать_2694" data-name="Сгруппировать 2694" transform="translate(0.074 0.273)" clip-path="url(#clip-path-736)">
                <g id="Сгруппировать_2693" data-name="Сгруппировать 2693" transform="translate(-0.074 -0.273)" clip-path="url(#clip-path-740)">
                  <g id="Сгруппировать_2691" data-name="Сгруппировать 2691" transform="translate(-461.097 -220.148)" opacity="0">
                    <g id="Сгруппировать_2690" data-name="Сгруппировать 2690">
                      <path id="Контур_2601" data-name="Контур 2601" d="M-1064.17-508.63H-461.8V-108.2H-1064.17Z" transform="translate(1064.17 508.63)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2692" data-name="Сгруппировать 2692" transform="translate(-461.097 -220.148)">
                    <path id="Контур_2602" data-name="Контур 2602" d="M-1064.17-508.63H-461.8V-108.2H-1064.17Z" transform="translate(1064.17 508.63)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2707" data-name="Сгруппировать 2707" transform="translate(459.77 -884.034)" clip-path="url(#clip-path-741)">
        <g id="Сгруппировать_2706" data-name="Сгруппировать 2706" transform="translate(-839.204 -497.662)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2705" data-name="Сгруппировать 2705" transform="translate(838.988 497.499)" style="isolation: isolate">
            <g id="Сгруппировать_2704" data-name="Сгруппировать 2704" transform="translate(0)" clip-path="url(#clip-path-501)">
              <g id="Сгруппировать_2703" data-name="Сгруппировать 2703" transform="translate(0.216 0.163)" clip-path="url(#clip-path-741)">
                <g id="Сгруппировать_2702" data-name="Сгруппировать 2702" transform="translate(-0.216 -0.163)" clip-path="url(#clip-path-745)">
                  <g id="Сгруппировать_2700" data-name="Сгруппировать 2700" transform="translate(-459.363 -218.414)" opacity="0">
                    <g id="Сгруппировать_2699" data-name="Сгруппировать 2699">
                      <path id="Контур_2607" data-name="Контур 2607" d="M-1060.5-504.377h602.373v400.426H-1060.5Z" transform="translate(1060.499 504.377)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2701" data-name="Сгруппировать 2701" transform="translate(-459.363 -218.414)">
                    <path id="Контур_2608" data-name="Контур 2608" d="M-1060.5-504.377h602.373v400.426H-1060.5Z" transform="translate(1060.499 504.377)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2716" data-name="Сгруппировать 2716" transform="translate(337.65 -830.179)" clip-path="url(#clip-path-746)">
        <g id="Сгруппировать_2715" data-name="Сгруппировать 2715" transform="translate(-717.083 -551.518)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2714" data-name="Сгруппировать 2714" transform="translate(716.78 551.236)" style="isolation: isolate">
            <g id="Сгруппировать_2713" data-name="Сгруппировать 2713" transform="translate(0 0)" clip-path="url(#clip-path-261)">
              <g id="Сгруппировать_2712" data-name="Сгруппировать 2712" transform="translate(0.303 0.282)" clip-path="url(#clip-path-746)">
                <g id="Сгруппировать_2711" data-name="Сгруппировать 2711" transform="translate(-0.303 -0.282)" clip-path="url(#clip-path-750)">
                  <g id="Сгруппировать_2709" data-name="Сгруппировать 2709" transform="translate(-337.155 -272.151)" opacity="0">
                    <g id="Сгруппировать_2708" data-name="Сгруппировать 2708" transform="translate(0 0)">
                      <path id="Контур_2613" data-name="Контур 2613" d="M-778.7-628.65h602.372v400.426H-778.7Z" transform="translate(778.7 628.65)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2710" data-name="Сгруппировать 2710" transform="translate(-337.155 -272.151)">
                    <path id="Контур_2614" data-name="Контур 2614" d="M-778.7-628.65h602.372v400.426H-778.7Z" transform="translate(778.7 628.65)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2725" data-name="Сгруппировать 2725" transform="translate(337.307 -825.213)" clip-path="url(#clip-path-751)">
        <g id="Сгруппировать_2724" data-name="Сгруппировать 2724" transform="translate(-716.741 -556.484)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2723" data-name="Сгруппировать 2723" transform="translate(716.347 556.436)" style="isolation: isolate">
            <g id="Сгруппировать_2722" data-name="Сгруппировать 2722" transform="translate(0 0)" clip-path="url(#clip-path-276)">
              <g id="Сгруппировать_2721" data-name="Сгруппировать 2721" transform="translate(0.394 0.048)" clip-path="url(#clip-path-751)">
                <g id="Сгруппировать_2720" data-name="Сгруппировать 2720" transform="translate(-0.394 -0.048)" clip-path="url(#clip-path-755)">
                  <g id="Сгруппировать_2718" data-name="Сгруппировать 2718" transform="translate(-336.722 -277.351)" opacity="0">
                    <g id="Сгруппировать_2717" data-name="Сгруппировать 2717">
                      <path id="Контур_2619" data-name="Контур 2619" d="M-777.91-640.11h602.372v400.426H-777.91Z" transform="translate(777.91 640.11)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2719" data-name="Сгруппировать 2719" transform="translate(-336.722 -277.351)">
                    <path id="Контур_2620" data-name="Контур 2620" d="M-777.91-640.11h602.372v400.426H-777.91Z" transform="translate(777.91 640.11)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2734" data-name="Сгруппировать 2734" transform="translate(480.884 -818.344)" clip-path="url(#clip-path-756)">
        <g id="Сгруппировать_2733" data-name="Сгруппировать 2733" transform="translate(-860.318 -563.353)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2732" data-name="Сгруппировать 2732" transform="translate(860.223 562.937)" style="isolation: isolate">
            <g id="Сгруппировать_2731" data-name="Сгруппировать 2731" transform="translate(0 0)" clip-path="url(#clip-path-758)">
              <g id="Сгруппировать_2730" data-name="Сгруппировать 2730" transform="translate(0.095 0.416)" clip-path="url(#clip-path-756)">
                <g id="Сгруппировать_2729" data-name="Сгруппировать 2729" transform="translate(-0.095 -0.416)" clip-path="url(#clip-path-760)">
                  <g id="Сгруппировать_2727" data-name="Сгруппировать 2727" transform="translate(-480.598 -283.852)" opacity="0">
                    <g id="Сгруппировать_2726" data-name="Сгруппировать 2726" transform="translate(0 0)">
                      <path id="Контур_2625" data-name="Контур 2625" d="M-1109.22-655.96h602.372v400.426H-1109.22Z" transform="translate(1109.22 655.96)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2728" data-name="Сгруппировать 2728" transform="translate(-480.598 -283.852)">
                    <path id="Контур_2626" data-name="Контур 2626" d="M-1109.22-655.96h602.372v400.426H-1109.22Z" transform="translate(1109.22 655.96)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2743" data-name="Сгруппировать 2743" transform="translate(491.228 -827.102)" clip-path="url(#clip-path-761)">
        <g id="Сгруппировать_2742" data-name="Сгруппировать 2742" transform="translate(-870.662 -554.594)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2741" data-name="Сгруппировать 2741" transform="translate(870.623 554.269)" style="isolation: isolate">
            <g id="Сгруппировать_2740" data-name="Сгруппировать 2740" clip-path="url(#clip-path-261)">
              <g id="Сгруппировать_2739" data-name="Сгруппировать 2739" transform="translate(0.039 0.325)" clip-path="url(#clip-path-761)">
                <g id="Сгруппировать_2738" data-name="Сгруппировать 2738" transform="translate(-0.039 -0.325)" clip-path="url(#clip-path-765)">
                  <g id="Сгруппировать_2736" data-name="Сгруппировать 2736" transform="translate(-490.999 -275.185)" opacity="0">
                    <g id="Сгруппировать_2735" data-name="Сгруппировать 2735">
                      <path id="Контур_2631" data-name="Контур 2631" d="M-1133.09-635.75h602.372v400.426H-1133.09Z" transform="translate(1133.09 635.75)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2737" data-name="Сгруппировать 2737" transform="translate(-490.999 -275.185)">
                    <path id="Контур_2632" data-name="Контур 2632" d="M-1133.09-635.75h602.372v400.426H-1133.09Z" transform="translate(1133.09 635.75)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2752" data-name="Сгруппировать 2752" transform="translate(490.886 -827.081)" clip-path="url(#clip-path-766)">
        <g id="Сгруппировать_2751" data-name="Сгруппировать 2751" transform="translate(-870.32 -554.616)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2750" data-name="Сгруппировать 2750" transform="translate(870.19 554.269)" style="isolation: isolate">
            <g id="Сгруппировать_2749" data-name="Сгруппировать 2749" clip-path="url(#clip-path-286)">
              <g id="Сгруппировать_2748" data-name="Сгруппировать 2748" transform="translate(0.13 0.347)" clip-path="url(#clip-path-766)">
                <g id="Сгруппировать_2747" data-name="Сгруппировать 2747" transform="translate(-0.13 -0.347)" clip-path="url(#clip-path-770)">
                  <g id="Сгруппировать_2745" data-name="Сгруппировать 2745" transform="translate(-490.565 -275.185)" opacity="0">
                    <g id="Сгруппировать_2744" data-name="Сгруппировать 2744">
                      <path id="Контур_2637" data-name="Контур 2637" d="M-1132.3-635.8h602.373v400.426H-1132.3Z" transform="translate(1132.3 635.8)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2746" data-name="Сгруппировать 2746" transform="translate(-490.565 -275.185)">
                    <path id="Контур_2638" data-name="Контур 2638" d="M-1132.3-635.8h602.373v400.426H-1132.3Z" transform="translate(1132.3 635.8)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2761" data-name="Сгруппировать 2761" transform="translate(472.065 -795.601)" clip-path="url(#clip-path-771)">
        <g id="Сгруппировать_2760" data-name="Сгруппировать 2760" transform="translate(-851.499 -586.095)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2759" data-name="Сгруппировать 2759" transform="translate(851.122 585.905)" style="isolation: isolate">
            <g id="Сгруппировать_2758" data-name="Сгруппировать 2758" transform="translate(0 0)" clip-path="url(#clip-path-251)">
              <g id="Сгруппировать_2757" data-name="Сгруппировать 2757" transform="translate(0.377 0.191)" clip-path="url(#clip-path-771)">
                <g id="Сгруппировать_2756" data-name="Сгруппировать 2756" transform="translate(-0.377 -0.191)" clip-path="url(#clip-path-775)">
                  <g id="Сгруппировать_2754" data-name="Сгруппировать 2754" transform="translate(-471.497 -306.82)" opacity="0">
                    <g id="Сгруппировать_2753" data-name="Сгруппировать 2753">
                      <path id="Контур_2643" data-name="Контур 2643" d="M-1088.87-708.44H-486.5v400.426H-1088.87Z" transform="translate(1088.87 708.44)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2755" data-name="Сгруппировать 2755" transform="translate(-471.497 -306.82)">
                    <path id="Контур_2644" data-name="Контур 2644" d="M-1088.87-708.44H-486.5v400.426H-1088.87Z" transform="translate(1088.87 708.44)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2770" data-name="Сгруппировать 2770" transform="translate(472.087 -795.865)" clip-path="url(#clip-path-776)">
        <g id="Сгруппировать_2769" data-name="Сгруппировать 2769" transform="translate(-851.521 -585.831)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2768" data-name="Сгруппировать 2768" transform="translate(851.122 585.471)" style="isolation: isolate">
            <g id="Сгруппировать_2767" data-name="Сгруппировать 2767" transform="translate(0 0)" clip-path="url(#clip-path-778)">
              <g id="Сгруппировать_2766" data-name="Сгруппировать 2766" transform="translate(0.399 0.36)" clip-path="url(#clip-path-776)">
                <g id="Сгруппировать_2765" data-name="Сгруппировать 2765" transform="translate(-0.399 -0.36)" clip-path="url(#clip-path-780)">
                  <g id="Сгруппировать_2763" data-name="Сгруппировать 2763" transform="translate(-471.497 -306.387)" opacity="0">
                    <g id="Сгруппировать_2762" data-name="Сгруппировать 2762" transform="translate(0 0)">
                      <path id="Контур_2649" data-name="Контур 2649" d="M-1088.92-707.83h602.373V-307.4H-1088.92Z" transform="translate(1088.92 707.83)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2764" data-name="Сгруппировать 2764" transform="translate(-471.497 -306.387)">
                    <path id="Контур_2650" data-name="Контур 2650" d="M-1088.92-707.83h602.373V-307.4H-1088.92Z" transform="translate(1088.92 707.83)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2779" data-name="Сгруппировать 2779" transform="translate(471.766 -810.873)" clip-path="url(#clip-path-781)">
        <g id="Сгруппировать_2778" data-name="Сгруппировать 2778" transform="translate(-851.2 -570.824)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2777" data-name="Сгруппировать 2777" transform="translate(851.122 570.737)" style="isolation: isolate">
            <g id="Сгруппировать_2776" data-name="Сгруппировать 2776" transform="translate(0 0)" clip-path="url(#clip-path-251)">
              <g id="Сгруппировать_2775" data-name="Сгруппировать 2775" transform="translate(0.078 0.087)" clip-path="url(#clip-path-781)">
                <g id="Сгруппировать_2774" data-name="Сгруппировать 2774" transform="translate(-0.078 -0.087)" clip-path="url(#clip-path-785)">
                  <g id="Сгруппировать_2772" data-name="Сгруппировать 2772" transform="translate(-471.497 -291.652)" opacity="0">
                    <g id="Сгруппировать_2771" data-name="Сгруппировать 2771">
                      <path id="Контур_2655" data-name="Контур 2655" d="M-1088.18-673.2h602.373v400.426H-1088.18Z" transform="translate(1088.18 673.2)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2773" data-name="Сгруппировать 2773" transform="translate(-471.497 -291.652)">
                    <path id="Контур_2656" data-name="Контур 2656" d="M-1088.18-673.2h602.373v400.426H-1088.18Z" transform="translate(1088.18 673.2)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2788" data-name="Сгруппировать 2788" transform="translate(471.788 -811.137)" clip-path="url(#clip-path-786)">
        <g id="Сгруппировать_2787" data-name="Сгруппировать 2787" transform="translate(-851.222 -570.559)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2786" data-name="Сгруппировать 2786" transform="translate(851.122 570.304)" style="isolation: isolate">
            <g id="Сгруппировать_2785" data-name="Сгруппировать 2785" transform="translate(0 0)" clip-path="url(#clip-path-788)">
              <g id="Сгруппировать_2784" data-name="Сгруппировать 2784" transform="translate(0.1 0.256)" clip-path="url(#clip-path-786)">
                <g id="Сгруппировать_2783" data-name="Сгруппировать 2783" transform="translate(-0.1 -0.256)" clip-path="url(#clip-path-790)">
                  <g id="Сгруппировать_2781" data-name="Сгруппировать 2781" transform="translate(-471.497 -291.219)" opacity="0">
                    <g id="Сгруппировать_2780" data-name="Сгруппировать 2780" transform="translate(0 0)">
                      <path id="Контур_2661" data-name="Контур 2661" d="M-1088.23-672.59h602.373v400.426H-1088.23Z" transform="translate(1088.23 672.59)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2782" data-name="Сгруппировать 2782" transform="translate(-471.497 -291.219)">
                    <path id="Контур_2662" data-name="Контур 2662" d="M-1088.23-672.59h602.373v400.426H-1088.23Z" transform="translate(1088.23 672.59)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2797" data-name="Сгруппировать 2797" transform="translate(510.223 -796.637)" clip-path="url(#clip-path-791)">
        <g id="Сгруппировать_2796" data-name="Сгруппировать 2796" transform="translate(-889.656 -585.06)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2795" data-name="Сгруппировать 2795" transform="translate(889.258 585.038)" style="isolation: isolate">
            <g id="Сгруппировать_2794" data-name="Сгруппировать 2794" clip-path="url(#clip-path-251)">
              <g id="Сгруппировать_2793" data-name="Сгруппировать 2793" transform="translate(0.399 0.022)" clip-path="url(#clip-path-791)">
                <g id="Сгруппировать_2792" data-name="Сгруппировать 2792" transform="translate(-0.399 -0.022)" clip-path="url(#clip-path-795)">
                  <g id="Сгруппировать_2790" data-name="Сгруппировать 2790" transform="translate(-509.633 -305.953)" opacity="0">
                    <g id="Сгруппировать_2789" data-name="Сгруппировать 2789">
                      <path id="Контур_2667" data-name="Контур 2667" d="M-1176.92-706.05h602.373v400.426H-1176.92Z" transform="translate(1176.92 706.05)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2791" data-name="Сгруппировать 2791" transform="translate(-509.633 -305.953)">
                    <path id="Контур_2668" data-name="Контур 2668" d="M-1176.92-706.05h602.373v400.426H-1176.92Z" transform="translate(1176.92 706.05)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2806" data-name="Сгруппировать 2806" transform="translate(515.185 -796.979)" clip-path="url(#clip-path-796)">
        <g id="Сгруппировать_2805" data-name="Сгруппировать 2805" transform="translate(-894.618 -584.717)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2804" data-name="Сгруппировать 2804" transform="translate(894.458 584.605)" style="isolation: isolate">
            <g id="Сгруппировать_2803" data-name="Сгруппировать 2803" clip-path="url(#clip-path-326)">
              <g id="Сгруппировать_2802" data-name="Сгруппировать 2802" transform="translate(0.16 0.113)" clip-path="url(#clip-path-796)">
                <g id="Сгруппировать_2801" data-name="Сгруппировать 2801" transform="translate(-0.16 -0.113)" clip-path="url(#clip-path-800)">
                  <g id="Сгруппировать_2799" data-name="Сгруппировать 2799" transform="translate(-514.833 -305.52)" opacity="0">
                    <g id="Сгруппировать_2798" data-name="Сгруппировать 2798">
                      <path id="Контур_2673" data-name="Контур 2673" d="M-1188.37-705.26H-586v400.426H-1188.37Z" transform="translate(1188.37 705.26)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2800" data-name="Сгруппировать 2800" transform="translate(-514.833 -305.52)">
                    <path id="Контур_2674" data-name="Контур 2674" d="M-1188.37-705.26H-586v400.426H-1188.37Z" transform="translate(1188.37 705.26)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2815" data-name="Сгруппировать 2815" transform="translate(510.522 -811.909)" clip-path="url(#clip-path-801)">
        <g id="Сгруппировать_2814" data-name="Сгруппировать 2814" transform="translate(-889.956 -569.788)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2813" data-name="Сгруппировать 2813" transform="translate(889.691 569.437)" style="isolation: isolate">
            <g id="Сгруппировать_2812" data-name="Сгруппировать 2812" transform="translate(0 0)" clip-path="url(#clip-path-251)">
              <g id="Сгруппировать_2811" data-name="Сгруппировать 2811" transform="translate(0.264 0.351)" clip-path="url(#clip-path-801)">
                <g id="Сгруппировать_2810" data-name="Сгруппировать 2810" transform="translate(-0.264 -0.351)" clip-path="url(#clip-path-805)">
                  <g id="Сгруппировать_2808" data-name="Сгруппировать 2808" transform="translate(-510.066 -290.352)" opacity="0">
                    <g id="Сгруппировать_2807" data-name="Сгруппировать 2807">
                      <path id="Контур_2679" data-name="Контур 2679" d="M-1177.61-670.81h602.373v400.426H-1177.61Z" transform="translate(1177.61 670.81)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2809" data-name="Сгруппировать 2809" transform="translate(-510.066 -290.352)">
                    <path id="Контур_2680" data-name="Контур 2680" d="M-1177.61-670.81h602.373v400.426H-1177.61Z" transform="translate(1177.61 670.81)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2824" data-name="Сгруппировать 2824" transform="translate(515.484 -812.251)" clip-path="url(#clip-path-806)">
        <g id="Сгруппировать_2823" data-name="Сгруппировать 2823" transform="translate(-894.917 -569.446)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2822" data-name="Сгруппировать 2822" transform="translate(894.891 569.437)" style="isolation: isolate">
            <g id="Сгруппировать_2821" data-name="Сгруппировать 2821" transform="translate(0 0)" clip-path="url(#clip-path-326)">
              <g id="Сгруппировать_2820" data-name="Сгруппировать 2820" transform="translate(0.026 0.009)" clip-path="url(#clip-path-806)">
                <g id="Сгруппировать_2819" data-name="Сгруппировать 2819" transform="translate(-0.026 -0.009)" clip-path="url(#clip-path-810)">
                  <g id="Сгруппировать_2817" data-name="Сгруппировать 2817" transform="translate(-515.267 -290.352)" opacity="0">
                    <g id="Сгруппировать_2816" data-name="Сгруппировать 2816" transform="translate(0 0)">
                      <path id="Контур_2685" data-name="Контур 2685" d="M-1189.06-670.02h602.372v400.426H-1189.06Z" transform="translate(1189.06 670.02)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2818" data-name="Сгруппировать 2818" transform="translate(-515.267 -290.352)">
                    <path id="Контур_2686" data-name="Контур 2686" d="M-1189.06-670.02h602.372v400.426H-1189.06Z" transform="translate(1189.06 670.02)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2833" data-name="Сгруппировать 2833" transform="translate(489.772 -779.784)" clip-path="url(#clip-path-811)">
        <g id="Сгруппировать_2832" data-name="Сгруппировать 2832" transform="translate(-869.206 -601.913)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2831" data-name="Сгруппировать 2831" transform="translate(868.89 601.506)" style="isolation: isolate">
            <g id="Сгруппировать_2830" data-name="Сгруппировать 2830" transform="translate(0 0)" clip-path="url(#clip-path-261)">
              <g id="Сгруппировать_2829" data-name="Сгруппировать 2829" transform="translate(0.316 0.407)" clip-path="url(#clip-path-811)">
                <g id="Сгруппировать_2828" data-name="Сгруппировать 2828" transform="translate(-0.316 -0.407)" clip-path="url(#clip-path-815)">
                  <g id="Сгруппировать_2826" data-name="Сгруппировать 2826" transform="translate(-489.265 -322.421)" opacity="0">
                    <g id="Сгруппировать_2825" data-name="Сгруппировать 2825">
                      <path id="Контур_2691" data-name="Контур 2691" d="M-1129.73-744.94h602.372v400.426H-1129.73Z" transform="translate(1129.73 744.94)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2827" data-name="Сгруппировать 2827" transform="translate(-489.265 -322.421)">
                    <path id="Контур_2692" data-name="Контур 2692" d="M-1129.73-744.94h602.372v400.426H-1129.73Z" transform="translate(1129.73 744.94)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2842" data-name="Сгруппировать 2842" transform="translate(489.43 -774.817)" clip-path="url(#clip-path-816)">
        <g id="Сгруппировать_2841" data-name="Сгруппировать 2841" transform="translate(-868.864 -606.879)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2840" data-name="Сгруппировать 2840" transform="translate(868.456 606.706)" style="isolation: isolate">
            <g id="Сгруппировать_2839" data-name="Сгруппировать 2839" transform="translate(0 0)" clip-path="url(#clip-path-276)">
              <g id="Сгруппировать_2838" data-name="Сгруппировать 2838" transform="translate(0.407 0.173)" clip-path="url(#clip-path-816)">
                <g id="Сгруппировать_2837" data-name="Сгруппировать 2837" transform="translate(-0.407 -0.173)" clip-path="url(#clip-path-820)">
                  <g id="Сгруппировать_2835" data-name="Сгруппировать 2835" transform="translate(-488.832 -327.621)" opacity="0">
                    <g id="Сгруппировать_2834" data-name="Сгруппировать 2834" transform="translate(0 0)">
                      <path id="Контур_2697" data-name="Контур 2697" d="M-1128.94-756.4h602.372v400.426H-1128.94Z" transform="translate(1128.94 756.4)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2836" data-name="Сгруппировать 2836" transform="translate(-488.832 -327.621)">
                    <path id="Контур_2698" data-name="Контур 2698" d="M-1128.94-756.4h602.372v400.426H-1128.94Z" transform="translate(1128.94 756.4)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2851" data-name="Сгруппировать 2851" transform="translate(354.919 -909.939)" clip-path="url(#clip-path-821)">
        <g id="Сгруппировать_2850" data-name="Сгруппировать 2850" transform="translate(-734.353 -471.757)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2849" data-name="Сгруппировать 2849" transform="translate(734.114 471.497)" style="isolation: isolate">
            <g id="Сгруппировать_2848" data-name="Сгруппировать 2848" transform="translate(0 0)" clip-path="url(#clip-path-406)">
              <g id="Сгруппировать_2847" data-name="Сгруппировать 2847" transform="translate(0.238 0.26)" clip-path="url(#clip-path-821)">
                <g id="Сгруппировать_2846" data-name="Сгруппировать 2846" transform="translate(-0.238 -0.26)" clip-path="url(#clip-path-825)">
                  <g id="Сгруппировать_2844" data-name="Сгруппировать 2844" transform="translate(-354.49 -192.413)" opacity="0">
                    <g id="Сгруппировать_2843" data-name="Сгруппировать 2843" transform="translate(0 0)">
                      <path id="Контур_2703" data-name="Контур 2703" d="M-818.55-444.6h602.372V-44.174H-818.55Z" transform="translate(818.55 444.6)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2845" data-name="Сгруппировать 2845" transform="translate(-354.49 -192.413)">
                    <path id="Контур_2704" data-name="Контур 2704" d="M-818.55-444.6h602.372V-44.174H-818.55Z" transform="translate(818.55 444.6)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2860" data-name="Сгруппировать 2860" transform="translate(433.86 -760.286)" clip-path="url(#clip-path-826)">
        <g id="Сгруппировать_2859" data-name="Сгруппировать 2859" transform="translate(-813.294 -621.41)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2858" data-name="Сгруппировать 2858" transform="translate(812.986 621.007)" style="isolation: isolate">
            <g id="Сгруппировать_2857" data-name="Сгруппировать 2857" clip-path="url(#clip-path-828)">
              <g id="Сгруппировать_2856" data-name="Сгруппировать 2856" transform="translate(0.308 0.403)" clip-path="url(#clip-path-826)">
                <g id="Сгруппировать_2855" data-name="Сгруппировать 2855" transform="translate(-0.308 -0.403)" clip-path="url(#clip-path-830)">
                  <g id="Сгруппировать_2853" data-name="Сгруппировать 2853" transform="translate(-433.362 -341.922)" opacity="0">
                    <g id="Сгруппировать_2852" data-name="Сгруппировать 2852">
                      <path id="Контур_2709" data-name="Контур 2709" d="M-1000.71-789.93h602.372V-389.5H-1000.71Z" transform="translate(1000.71 789.93)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2854" data-name="Сгруппировать 2854" transform="translate(-433.362 -341.922)">
                    <path id="Контур_2710" data-name="Контур 2710" d="M-1000.71-789.93h602.372V-389.5H-1000.71Z" transform="translate(1000.71 789.93)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2869" data-name="Сгруппировать 2869" transform="translate(528.71 -819.644)" clip-path="url(#clip-path-831)">
        <g id="Сгруппировать_2868" data-name="Сгруппировать 2868" transform="translate(-908.144 -562.052)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2867" data-name="Сгруппировать 2867" transform="translate(907.892 561.636)" style="isolation: isolate">
            <g id="Сгруппировать_2866" data-name="Сгруппировать 2866" transform="translate(0 0)" clip-path="url(#clip-path-833)">
              <g id="Сгруппировать_2865" data-name="Сгруппировать 2865" transform="translate(0.251 0.416)" clip-path="url(#clip-path-831)">
                <g id="Сгруппировать_2864" data-name="Сгруппировать 2864" transform="translate(-0.251 -0.416)" clip-path="url(#clip-path-835)">
                  <g id="Сгруппировать_2862" data-name="Сгруппировать 2862" transform="translate(-528.268 -282.552)" opacity="0">
                    <g id="Сгруппировать_2861" data-name="Сгруппировать 2861" transform="translate(0 0)">
                      <path id="Контур_2715" data-name="Контур 2715" d="M-1219.58-652.96h602.373v400.426H-1219.58Z" transform="translate(1219.58 652.96)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2863" data-name="Сгруппировать 2863" transform="translate(-528.268 -282.552)">
                    <path id="Контур_2716" data-name="Контур 2716" d="M-1219.58-652.96h602.373v400.426H-1219.58Z" transform="translate(1219.58 652.96)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2878" data-name="Сгруппировать 2878" transform="translate(327.067 -848.887)" clip-path="url(#clip-path-836)">
        <g id="Сгруппировать_2877" data-name="Сгруппировать 2877" transform="translate(-706.501 -532.809)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2876" data-name="Сгруппировать 2876" transform="translate(706.379 532.601)" style="isolation: isolate">
            <g id="Сгруппировать_2875" data-name="Сгруппировать 2875" transform="translate(0 0)" clip-path="url(#clip-path-838)">
              <g id="Сгруппировать_2874" data-name="Сгруппировать 2874" transform="translate(0.121 0.208)" clip-path="url(#clip-path-836)">
                <g id="Сгруппировать_2873" data-name="Сгруппировать 2873" transform="translate(-0.121 -0.208)" clip-path="url(#clip-path-840)">
                  <g id="Сгруппировать_2871" data-name="Сгруппировать 2871" transform="translate(-326.755 -253.516)" opacity="0">
                    <g id="Сгруппировать_2870" data-name="Сгруппировать 2870">
                      <path id="Контур_2721" data-name="Контур 2721" d="M-754.28-585.48h602.373v400.426H-754.28Z" transform="translate(754.28 585.48)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2872" data-name="Сгруппировать 2872" transform="translate(-326.755 -253.516)">
                    <path id="Контур_2722" data-name="Контур 2722" d="M-754.28-585.48h602.373v400.426H-754.28Z" transform="translate(754.28 585.48)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2887" data-name="Сгруппировать 2887" transform="translate(391.369 -869.342)" clip-path="url(#clip-path-841)">
        <g id="Сгруппировать_2886" data-name="Сгруппировать 2886" transform="translate(-770.803 -512.355)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2885" data-name="Сгруппировать 2885" transform="translate(770.517 512.233)" style="isolation: isolate">
            <g id="Сгруппировать_2884" data-name="Сгруппировать 2884" transform="translate(0 0)" clip-path="url(#clip-path-843)">
              <g id="Сгруппировать_2883" data-name="Сгруппировать 2883" transform="translate(0.286 0.121)" clip-path="url(#clip-path-841)">
                <g id="Сгруппировать_2882" data-name="Сгруппировать 2882" transform="translate(-0.286 -0.121)" clip-path="url(#clip-path-845)">
                  <g id="Сгруппировать_2880" data-name="Сгруппировать 2880" transform="translate(-390.892 -233.148)" opacity="0">
                    <g id="Сгруппировать_2879" data-name="Сгруппировать 2879">
                      <path id="Контур_2727" data-name="Контур 2727" d="M-902.66-538.28h602.373v400.426H-902.66Z" transform="translate(902.66 538.28)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2881" data-name="Сгруппировать 2881" transform="translate(-390.892 -233.148)">
                    <path id="Контур_2728" data-name="Контур 2728" d="M-902.66-538.28h602.373v400.426H-902.66Z" transform="translate(902.66 538.28)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2892" data-name="Сгруппировать 2892" transform="translate(472.672 -906.52)" clip-path="url(#clip-path-846)">
        <g id="Сгруппировать_2891" data-name="Сгруппировать 2891" transform="translate(-852.106 -475.177)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2889" data-name="Сгруппировать 2889" transform="translate(379.625 279.085)" opacity="0">
            <g id="Сгруппировать_2888" data-name="Сгруппировать 2888">
              <path id="Контур_2733" data-name="Контур 2733" d="M-1090.27-452.49H-487.9V-52.064H-1090.27Z" transform="translate(1090.27 452.49)" fill="#c4d3e0"/>
            </g>
          </g>
          <g id="Сгруппировать_2890" data-name="Сгруппировать 2890" transform="translate(379.625 279.085)">
            <path id="Контур_2734" data-name="Контур 2734" d="M-1090.27-452.49H-487.9V-52.064H-1090.27Z" transform="translate(1090.27 452.49)" fill="#c4d3e0"/>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2901" data-name="Сгруппировать 2901" transform="translate(476.208 -884.25)" clip-path="url(#clip-path-848)">
        <g id="Сгруппировать_2900" data-name="Сгруппировать 2900" transform="translate(-855.642 -497.447)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2899" data-name="Сгруппировать 2899" transform="translate(855.456 497.066)" style="isolation: isolate">
            <g id="Сгруппировать_2898" data-name="Сгруппировать 2898" clip-path="url(#clip-path-336)">
              <g id="Сгруппировать_2897" data-name="Сгруппировать 2897" transform="translate(0.186 0.381)" clip-path="url(#clip-path-848)">
                <g id="Сгруппировать_2896" data-name="Сгруппировать 2896" transform="translate(-0.186 -0.381)" clip-path="url(#clip-path-852)">
                  <g id="Сгруппировать_2894" data-name="Сгруппировать 2894" transform="translate(-475.831 -217.981)" opacity="0">
                    <g id="Сгруппировать_2893" data-name="Сгруппировать 2893">
                      <path id="Контур_2737" data-name="Контур 2737" d="M-1098.43-503.88h602.372v400.426H-1098.43Z" transform="translate(1098.43 503.88)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2895" data-name="Сгруппировать 2895" transform="translate(-475.831 -217.981)">
                    <path id="Контур_2738" data-name="Контур 2738" d="M-1098.43-503.88h602.372v400.426H-1098.43Z" transform="translate(1098.43 503.88)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2910" data-name="Сгруппировать 2910" transform="translate(476.624 -882.707)" clip-path="url(#clip-path-853)">
        <g id="Сгруппировать_2909" data-name="Сгруппировать 2909" transform="translate(-856.058 -498.99)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2908" data-name="Сгруппировать 2908" transform="translate(855.889 498.799)" style="isolation: isolate">
            <g id="Сгруппировать_2907" data-name="Сгруппировать 2907" transform="translate(0)" clip-path="url(#clip-path-855)">
              <g id="Сгруппировать_2906" data-name="Сгруппировать 2906" transform="translate(0.169 0.191)" clip-path="url(#clip-path-853)">
                <g id="Сгруппировать_2905" data-name="Сгруппировать 2905" transform="translate(-0.169 -0.191)" clip-path="url(#clip-path-857)">
                  <g id="Сгруппировать_2903" data-name="Сгруппировать 2903" transform="translate(-476.264 -219.714)" opacity="0">
                    <g id="Сгруппировать_2902" data-name="Сгруппировать 2902">
                      <path id="Контур_2743" data-name="Контур 2743" d="M-1099.39-507.44h602.373v400.426H-1099.39Z" transform="translate(1099.39 507.44)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2904" data-name="Сгруппировать 2904" transform="translate(-476.264 -219.714)">
                    <path id="Контур_2744" data-name="Контур 2744" d="M-1099.39-507.44h602.373v400.426H-1099.39Z" transform="translate(1099.39 507.44)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2919" data-name="Сгруппировать 2919" transform="translate(479.073 -906.316)" clip-path="url(#clip-path-858)">
        <g id="Сгруппировать_2918" data-name="Сгруппировать 2918" transform="translate(-858.506 -475.38)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2917" data-name="Сгруппировать 2917" transform="translate(858.489 474.964)" style="isolation: isolate">
            <g id="Сгруппировать_2916" data-name="Сгруппировать 2916" clip-path="url(#clip-path-860)">
              <g id="Сгруппировать_2915" data-name="Сгруппировать 2915" transform="translate(0.017 0.416)" clip-path="url(#clip-path-858)">
                <g id="Сгруппировать_2914" data-name="Сгруппировать 2914" transform="translate(-0.017 -0.416)" clip-path="url(#clip-path-862)">
                  <g id="Сгруппировать_2912" data-name="Сгруппировать 2912" transform="translate(-478.864 -195.879)" opacity="0">
                    <g id="Сгруппировать_2911" data-name="Сгруппировать 2911" transform="translate(0 0)">
                      <path id="Контур_2749" data-name="Контур 2749" d="M-1105.04-452.96h602.372V-52.534H-1105.04Z" transform="translate(1105.04 452.96)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2913" data-name="Сгруппировать 2913" transform="translate(-478.864 -195.879)">
                    <path id="Контур_2750" data-name="Контур 2750" d="M-1105.04-452.96h602.372V-52.534H-1105.04Z" transform="translate(1105.04 452.96)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2928" data-name="Сгруппировать 2928" transform="translate(480.728 -902.889)" clip-path="url(#clip-path-863)">
        <g id="Сгруппировать_2927" data-name="Сгруппировать 2927" transform="translate(-860.162 -478.808)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2926" data-name="Сгруппировать 2926" transform="translate(859.789 478.431)" style="isolation: isolate">
            <g id="Сгруппировать_2925" data-name="Сгруппировать 2925" clip-path="url(#clip-path-865)">
              <g id="Сгруппировать_2924" data-name="Сгруппировать 2924" transform="translate(0.373 0.377)" clip-path="url(#clip-path-863)">
                <g id="Сгруппировать_2923" data-name="Сгруппировать 2923" transform="translate(-0.373 -0.377)" clip-path="url(#clip-path-867)">
                  <g id="Сгруппировать_2921" data-name="Сгруппировать 2921" transform="translate(-480.165 -199.346)" opacity="0">
                    <g id="Сгруппировать_2920" data-name="Сгруппировать 2920">
                      <path id="Контур_2755" data-name="Контур 2755" d="M-1108.86-460.87h602.373V-60.444H-1108.86Z" transform="translate(1108.86 460.87)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2922" data-name="Сгруппировать 2922" transform="translate(-480.165 -199.346)">
                    <path id="Контур_2756" data-name="Контур 2756" d="M-1108.86-460.87h602.373V-60.444H-1108.86Z" transform="translate(1108.86 460.87)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2937" data-name="Сгруппировать 2937" transform="translate(481.881 -902.672)" clip-path="url(#clip-path-868)">
        <g id="Сгруппировать_2936" data-name="Сгруппировать 2936" transform="translate(-861.315 -479.025)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2935" data-name="Сгруппировать 2935" transform="translate(861.089 478.864)" style="isolation: isolate">
            <g id="Сгруппировать_2934" data-name="Сгруппировать 2934" clip-path="url(#clip-path-870)">
              <g id="Сгруппировать_2933" data-name="Сгруппировать 2933" transform="translate(0.225 0.16)" clip-path="url(#clip-path-868)">
                <g id="Сгруппировать_2932" data-name="Сгруппировать 2932" transform="translate(-0.225 -0.16)" clip-path="url(#clip-path-872)">
                  <g id="Сгруппировать_2930" data-name="Сгруппировать 2930" transform="translate(-481.465 -199.78)" opacity="0">
                    <g id="Сгруппировать_2929" data-name="Сгруппировать 2929">
                      <path id="Контур_2761" data-name="Контур 2761" d="M-1111.52-461.37h602.372V-60.944H-1111.52Z" transform="translate(1111.52 461.37)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2931" data-name="Сгруппировать 2931" transform="translate(-481.465 -199.78)">
                    <path id="Контур_2762" data-name="Контур 2762" d="M-1111.52-461.37h602.372V-60.944H-1111.52Z" transform="translate(1111.52 461.37)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2946" data-name="Сгруппировать 2946" transform="translate(483.259 -904.93)" clip-path="url(#clip-path-873)">
        <g id="Сгруппировать_2945" data-name="Сгруппировать 2945" transform="translate(-862.693 -476.767)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2944" data-name="Сгруппировать 2944" transform="translate(862.389 476.698)" style="isolation: isolate">
            <g id="Сгруппировать_2943" data-name="Сгруппировать 2943" transform="translate(0 0)" clip-path="url(#clip-path-361)">
              <g id="Сгруппировать_2942" data-name="Сгруппировать 2942" transform="translate(0.303 0.069)" clip-path="url(#clip-path-873)">
                <g id="Сгруппировать_2941" data-name="Сгруппировать 2941" transform="translate(-0.303 -0.069)" clip-path="url(#clip-path-877)">
                  <g id="Сгруппировать_2939" data-name="Сгруппировать 2939" transform="translate(-482.765 -197.613)" opacity="0">
                    <g id="Сгруппировать_2938" data-name="Сгруппировать 2938" transform="translate(0 0)">
                      <path id="Контур_2767" data-name="Контур 2767" d="M-1114.7-456.16h602.372V-55.734H-1114.7Z" transform="translate(1114.7 456.16)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2940" data-name="Сгруппировать 2940" transform="translate(-482.765 -197.613)">
                    <path id="Контур_2768" data-name="Контур 2768" d="M-1114.7-456.16h602.372V-55.734H-1114.7Z" transform="translate(1114.7 456.16)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2955" data-name="Сгруппировать 2955" transform="translate(472.672 -862.478)" clip-path="url(#clip-path-878)">
        <g id="Сгруппировать_2954" data-name="Сгруппировать 2954" transform="translate(-852.106 -519.219)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2953" data-name="Сгруппировать 2953" transform="translate(851.989 519.167)" style="isolation: isolate">
            <g id="Сгруппировать_2952" data-name="Сгруппировать 2952" transform="translate(0 0)" clip-path="url(#clip-path-880)">
              <g id="Сгруппировать_2951" data-name="Сгруппировать 2951" transform="translate(0.117 0.052)" clip-path="url(#clip-path-878)">
                <g id="Сгруппировать_2950" data-name="Сгруппировать 2950" transform="translate(-0.117 -0.052)" clip-path="url(#clip-path-882)">
                  <g id="Сгруппировать_2948" data-name="Сгруппировать 2948" transform="translate(-472.364 -240.082)" opacity="0">
                    <g id="Сгруппировать_2947" data-name="Сгруппировать 2947">
                      <path id="Контур_2773" data-name="Контур 2773" d="M-1090.27-554.12H-487.9v400.426H-1090.27Z" transform="translate(1090.27 554.12)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2949" data-name="Сгруппировать 2949" transform="translate(-472.364 -240.082)">
                    <path id="Контур_2774" data-name="Контур 2774" d="M-1090.27-554.12H-487.9v400.426H-1090.27Z" transform="translate(1090.27 554.12)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2964" data-name="Сгруппировать 2964" transform="translate(502.895 -862.43)" clip-path="url(#clip-path-883)">
        <g id="Сгруппировать_2963" data-name="Сгруппировать 2963" transform="translate(-882.328 -519.267)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2962" data-name="Сгруппировать 2962" transform="translate(882.324 519.167)" style="isolation: isolate">
            <g id="Сгруппировать_2961" data-name="Сгруппировать 2961" transform="translate(0 0)" clip-path="url(#clip-path-885)">
              <g id="Сгруппировать_2960" data-name="Сгруппировать 2960" transform="translate(0.004 0.1)" clip-path="url(#clip-path-883)">
                <g id="Сгруппировать_2959" data-name="Сгруппировать 2959" transform="translate(-0.004 -0.1)" clip-path="url(#clip-path-887)">
                  <g id="Сгруппировать_2957" data-name="Сгруппировать 2957" transform="translate(-502.699 -240.082)" opacity="0">
                    <g id="Сгруппировать_2956" data-name="Сгруппировать 2956">
                      <path id="Контур_2779" data-name="Контур 2779" d="M-1160.01-554.23h602.372V-153.8H-1160.01Z" transform="translate(1160.01 554.23)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2958" data-name="Сгруппировать 2958" transform="translate(-502.699 -240.082)">
                    <path id="Контур_2780" data-name="Контур 2780" d="M-1160.01-554.23h602.372V-153.8H-1160.01Z" transform="translate(1160.01 554.23)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2973" data-name="Сгруппировать 2973" transform="translate(498.665 -866.272)" clip-path="url(#clip-path-888)">
        <g id="Сгруппировать_2972" data-name="Сгруппировать 2972" transform="translate(-878.099 -515.424)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2971" data-name="Сгруппировать 2971" transform="translate(877.99 515.267)" style="isolation: isolate">
            <g id="Сгруппировать_2970" data-name="Сгруппировать 2970" transform="translate(0)" clip-path="url(#clip-path-890)">
              <g id="Сгруппировать_2969" data-name="Сгруппировать 2969" transform="translate(0.108 0.157)" clip-path="url(#clip-path-888)">
                <g id="Сгруппировать_2968" data-name="Сгруппировать 2968" transform="translate(-0.108 -0.157)" clip-path="url(#clip-path-892)">
                  <g id="Сгруппировать_2966" data-name="Сгруппировать 2966" transform="translate(-498.366 -236.182)" opacity="0">
                    <g id="Сгруппировать_2965" data-name="Сгруппировать 2965">
                      <path id="Контур_2785" data-name="Контур 2785" d="M-1150.25-545.363h602.373v400.426H-1150.25Z" transform="translate(1150.25 545.363)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2967" data-name="Сгруппировать 2967" transform="translate(-498.366 -236.182)">
                    <path id="Контур_2786" data-name="Контур 2786" d="M-1150.25-545.363h602.373v400.426H-1150.25Z" transform="translate(1150.25 545.363)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2982" data-name="Сгруппировать 2982" transform="translate(487.723 -887.21)" clip-path="url(#clip-path-893)">
        <g id="Сгруппировать_2981" data-name="Сгруппировать 2981" transform="translate(-867.156 -494.487)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2980" data-name="Сгруппировать 2980" transform="translate(867.156 494.465)" style="isolation: isolate">
            <g id="Сгруппировать_2979" data-name="Сгруппировать 2979" transform="translate(0 0)" clip-path="url(#clip-path-895)">
              <g id="Сгруппировать_2978" data-name="Сгруппировать 2978" transform="translate(0 0.022)" clip-path="url(#clip-path-893)">
                <g id="Сгруппировать_2977" data-name="Сгруппировать 2977" transform="translate(0 -0.022)" clip-path="url(#clip-path-897)">
                  <g id="Сгруппировать_2975" data-name="Сгруппировать 2975" transform="translate(-487.532 -215.381)" opacity="0">
                    <g id="Сгруппировать_2974" data-name="Сгруппировать 2974">
                      <path id="Контур_2791" data-name="Контур 2791" d="M-1125-497.05h602.372V-96.624H-1125Z" transform="translate(1125 497.05)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2976" data-name="Сгруппировать 2976" transform="translate(-487.532 -215.381)">
                    <path id="Контур_2792" data-name="Контур 2792" d="M-1125-497.05h602.372V-96.624H-1125Z" transform="translate(1125 497.05)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_2991" data-name="Сгруппировать 2991" transform="translate(489.707 -887.192)" clip-path="url(#clip-path-898)">
        <g id="Сгруппировать_2990" data-name="Сгруппировать 2990" transform="translate(-869.141 -494.504)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2989" data-name="Сгруппировать 2989" transform="translate(868.89 494.465)" style="isolation: isolate">
            <g id="Сгруппировать_2988" data-name="Сгруппировать 2988" transform="translate(0 0)" clip-path="url(#clip-path-900)">
              <g id="Сгруппировать_2987" data-name="Сгруппировать 2987" transform="translate(0.251 0.039)" clip-path="url(#clip-path-898)">
                <g id="Сгруппировать_2986" data-name="Сгруппировать 2986" transform="translate(-0.251 -0.039)" clip-path="url(#clip-path-902)">
                  <g id="Сгруппировать_2984" data-name="Сгруппировать 2984" transform="translate(-489.265 -215.381)" opacity="0">
                    <g id="Сгруппировать_2983" data-name="Сгруппировать 2983" transform="translate(0 0)">
                      <path id="Контур_2797" data-name="Контур 2797" d="M-1129.58-497.09h602.373V-96.664H-1129.58Z" transform="translate(1129.58 497.09)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2985" data-name="Сгруппировать 2985" transform="translate(-489.265 -215.381)">
                    <path id="Контур_2798" data-name="Контур 2798" d="M-1129.58-497.09h602.373V-96.664H-1129.58Z" transform="translate(1129.58 497.09)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3000" data-name="Сгруппировать 3000" transform="translate(491.762 -887.192)" clip-path="url(#clip-path-903)">
        <g id="Сгруппировать_2999" data-name="Сгруппировать 2999" transform="translate(-871.195 -494.504)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_2998" data-name="Сгруппировать 2998" transform="translate(871.057 494.465)" style="isolation: isolate">
            <g id="Сгруппировать_2997" data-name="Сгруппировать 2997" transform="translate(0 0)" clip-path="url(#clip-path-905)">
              <g id="Сгруппировать_2996" data-name="Сгруппировать 2996" transform="translate(0.139 0.039)" clip-path="url(#clip-path-903)">
                <g id="Сгруппировать_2995" data-name="Сгруппировать 2995" transform="translate(-0.139 -0.039)" clip-path="url(#clip-path-907)">
                  <g id="Сгруппировать_2993" data-name="Сгруппировать 2993" transform="translate(-491.432 -215.381)" opacity="0">
                    <g id="Сгруппировать_2992" data-name="Сгруппировать 2992" transform="translate(0 0)">
                      <path id="Контур_2803" data-name="Контур 2803" d="M-1134.32-497.09h602.373V-96.664H-1134.32Z" transform="translate(1134.32 497.09)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_2994" data-name="Сгруппировать 2994" transform="translate(-491.432 -215.381)">
                    <path id="Контур_2804" data-name="Контур 2804" d="M-1134.32-497.09h602.373V-96.664H-1134.32Z" transform="translate(1134.32 497.09)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3009" data-name="Сгруппировать 3009" transform="translate(494.08 -887.192)" clip-path="url(#clip-path-908)">
        <g id="Сгруппировать_3008" data-name="Сгруппировать 3008" transform="translate(-873.514 -494.504)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3007" data-name="Сгруппировать 3007" transform="translate(873.223 494.465)" style="isolation: isolate">
            <g id="Сгруппировать_3006" data-name="Сгруппировать 3006" transform="translate(0 0)" clip-path="url(#clip-path-900)">
              <g id="Сгруппировать_3005" data-name="Сгруппировать 3005" transform="translate(0.29 0.039)" clip-path="url(#clip-path-908)">
                <g id="Сгруппировать_3004" data-name="Сгруппировать 3004" transform="translate(-0.29 -0.039)" clip-path="url(#clip-path-912)">
                  <g id="Сгруппировать_3002" data-name="Сгруппировать 3002" transform="translate(-493.599 -215.381)" opacity="0">
                    <g id="Сгруппировать_3001" data-name="Сгруппировать 3001" transform="translate(0 0)">
                      <path id="Контур_2809" data-name="Контур 2809" d="M-1139.67-497.09H-537.3V-96.664H-1139.67Z" transform="translate(1139.67 497.09)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3003" data-name="Сгруппировать 3003" transform="translate(-493.599 -215.381)">
                    <path id="Контур_2810" data-name="Контур 2810" d="M-1139.67-497.09H-537.3V-96.664H-1139.67Z" transform="translate(1139.67 497.09)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3018" data-name="Сгруппировать 3018" transform="translate(496.715 -887.21)" clip-path="url(#clip-path-913)">
        <g id="Сгруппировать_3017" data-name="Сгруппировать 3017" transform="translate(-876.149 -494.487)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3016" data-name="Сгруппировать 3016" transform="translate(875.824 494.465)" style="isolation: isolate">
            <g id="Сгруппировать_3015" data-name="Сгруппировать 3015" transform="translate(0 0)" clip-path="url(#clip-path-905)">
              <g id="Сгруппировать_3014" data-name="Сгруппировать 3014" transform="translate(0.325 0.022)" clip-path="url(#clip-path-913)">
                <g id="Сгруппировать_3013" data-name="Сгруппировать 3013" transform="translate(-0.325 -0.022)" clip-path="url(#clip-path-917)">
                  <g id="Сгруппировать_3011" data-name="Сгруппировать 3011" transform="translate(-496.199 -215.381)" opacity="0">
                    <g id="Сгруппировать_3010" data-name="Сгруппировать 3010">
                      <path id="Контур_2815" data-name="Контур 2815" d="M-1145.75-497.05h602.372V-96.624H-1145.75Z" transform="translate(1145.75 497.05)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3012" data-name="Сгруппировать 3012" transform="translate(-496.199 -215.381)">
                    <path id="Контур_2816" data-name="Контур 2816" d="M-1145.75-497.05h602.372V-96.624H-1145.75Z" transform="translate(1145.75 497.05)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3027" data-name="Сгруппировать 3027" transform="translate(498.739 -887.235)" clip-path="url(#clip-path-918)">
        <g id="Сгруппировать_3026" data-name="Сгруппировать 3026" transform="translate(-878.172 -494.461)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3025" data-name="Сгруппировать 3025" transform="translate(877.99 494.032)" style="isolation: isolate">
            <g id="Сгруппировать_3024" data-name="Сгруппировать 3024" transform="translate(0 0)" clip-path="url(#clip-path-466)">
              <g id="Сгруппировать_3023" data-name="Сгруппировать 3023" transform="translate(0.182 0.429)" clip-path="url(#clip-path-918)">
                <g id="Сгруппировать_3022" data-name="Сгруппировать 3022" transform="translate(-0.182 -0.429)" clip-path="url(#clip-path-922)">
                  <g id="Сгруппировать_3020" data-name="Сгруппировать 3020" transform="translate(-498.366 -214.947)" opacity="0">
                    <g id="Сгруппировать_3019" data-name="Сгруппировать 3019">
                      <path id="Контур_2821" data-name="Контур 2821" d="M-1150.42-496.99h602.373V-96.564H-1150.42Z" transform="translate(1150.42 496.99)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3021" data-name="Сгруппировать 3021" transform="translate(-498.366 -214.947)">
                    <path id="Контур_2822" data-name="Контур 2822" d="M-1150.42-496.99h602.373V-96.564H-1150.42Z" transform="translate(1150.42 496.99)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3036" data-name="Сгруппировать 3036" transform="translate(501.521 -887.235)" clip-path="url(#clip-path-923)">
        <g id="Сгруппировать_3035" data-name="Сгруппировать 3035" transform="translate(-880.954 -494.461)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3034" data-name="Сгруппировать 3034" transform="translate(880.591 494.032)" style="isolation: isolate">
            <g id="Сгруппировать_3033" data-name="Сгруппировать 3033" transform="translate(0 0)" clip-path="url(#clip-path-466)">
              <g id="Сгруппировать_3032" data-name="Сгруппировать 3032" transform="translate(0.364 0.429)" clip-path="url(#clip-path-923)">
                <g id="Сгруппировать_3031" data-name="Сгруппировать 3031" transform="translate(-0.364 -0.429)" clip-path="url(#clip-path-927)">
                  <g id="Сгруппировать_3029" data-name="Сгруппировать 3029" transform="translate(-500.966 -214.947)" opacity="0">
                    <g id="Сгруппировать_3028" data-name="Сгруппировать 3028">
                      <path id="Контур_2827" data-name="Контур 2827" d="M-1156.84-496.99h602.373V-96.564H-1156.84Z" transform="translate(1156.84 496.99)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3030" data-name="Сгруппировать 3030" transform="translate(-500.966 -214.947)">
                    <path id="Контур_2828" data-name="Контур 2828" d="M-1156.84-496.99h602.373V-96.564H-1156.84Z" transform="translate(1156.84 496.99)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3045" data-name="Сгруппировать 3045" transform="translate(504.394 -887.192)" clip-path="url(#clip-path-928)">
        <g id="Сгруппировать_3044" data-name="Сгруппировать 3044" transform="translate(-883.828 -494.504)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3043" data-name="Сгруппировать 3043" transform="translate(883.624 494.465)" style="isolation: isolate">
            <g id="Сгруппировать_3042" data-name="Сгруппировать 3042" transform="translate(0 0)" clip-path="url(#clip-path-930)">
              <g id="Сгруппировать_3041" data-name="Сгруппировать 3041" transform="translate(0.204 0.039)" clip-path="url(#clip-path-928)">
                <g id="Сгруппировать_3040" data-name="Сгруппировать 3040" transform="translate(-0.204 -0.039)" clip-path="url(#clip-path-932)">
                  <g id="Сгруппировать_3038" data-name="Сгруппировать 3038" transform="translate(-503.999 -215.381)" opacity="0">
                    <g id="Сгруппировать_3037" data-name="Сгруппировать 3037" transform="translate(0 0)">
                      <path id="Контур_2833" data-name="Контур 2833" d="M-1163.47-497.09H-561.1V-96.664H-1163.47Z" transform="translate(1163.47 497.09)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3039" data-name="Сгруппировать 3039" transform="translate(-503.999 -215.381)">
                    <path id="Контур_2834" data-name="Контур 2834" d="M-1163.47-497.09H-561.1V-96.664H-1163.47Z" transform="translate(1163.47 497.09)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3054" data-name="Сгруппировать 3054" transform="translate(356.527 -880.172)" clip-path="url(#clip-path-933)">
        <g id="Сгруппировать_3053" data-name="Сгруппировать 3053" transform="translate(-735.96 -501.525)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3052" data-name="Сгруппировать 3052" transform="translate(735.848 501.399)" style="isolation: isolate">
            <g id="Сгруппировать_3051" data-name="Сгруппировать 3051" transform="translate(0 0)" clip-path="url(#clip-path-516)">
              <g id="Сгруппировать_3050" data-name="Сгруппировать 3050" transform="translate(0.113 0.126)" clip-path="url(#clip-path-933)">
                <g id="Сгруппировать_3049" data-name="Сгруппировать 3049" transform="translate(-0.113 -0.126)" clip-path="url(#clip-path-937)">
                  <g id="Сгруппировать_3047" data-name="Сгруппировать 3047" transform="translate(-356.223 -222.314)" opacity="0">
                    <g id="Сгруппировать_3046" data-name="Сгруппировать 3046">
                      <path id="Контур_2839" data-name="Контур 2839" d="M-822.26-513.29h602.372v400.426H-822.26Z" transform="translate(822.26 513.29)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3048" data-name="Сгруппировать 3048" transform="translate(-356.223 -222.314)">
                    <path id="Контур_2840" data-name="Контур 2840" d="M-822.26-513.29h602.372v400.426H-822.26Z" transform="translate(822.26 513.29)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3063" data-name="Сгруппировать 3063" transform="translate(359.296 -880.172)" clip-path="url(#clip-path-938)">
        <g id="Сгруппировать_3062" data-name="Сгруппировать 3062" transform="translate(-738.73 -501.525)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3061" data-name="Сгруппировать 3061" transform="translate(738.448 501.399)" style="isolation: isolate">
            <g id="Сгруппировать_3060" data-name="Сгруппировать 3060" transform="translate(0 0)" clip-path="url(#clip-path-471)">
              <g id="Сгруппировать_3059" data-name="Сгруппировать 3059" transform="translate(0.282 0.126)" clip-path="url(#clip-path-938)">
                <g id="Сгруппировать_3058" data-name="Сгруппировать 3058" transform="translate(-0.282 -0.126)" clip-path="url(#clip-path-942)">
                  <g id="Сгруппировать_3056" data-name="Сгруппировать 3056" transform="translate(-358.823 -222.314)" opacity="0">
                    <g id="Сгруппировать_3055" data-name="Сгруппировать 3055">
                      <path id="Контур_2845" data-name="Контур 2845" d="M-828.65-513.29h602.372v400.426H-828.65Z" transform="translate(828.65 513.29)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3057" data-name="Сгруппировать 3057" transform="translate(-358.823 -222.314)">
                    <path id="Контур_2846" data-name="Контур 2846" d="M-828.65-513.29h602.372v400.426H-828.65Z" transform="translate(828.65 513.29)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3072" data-name="Сгруппировать 3072" transform="translate(361.619 -880.172)" clip-path="url(#clip-path-943)">
        <g id="Сгруппировать_3071" data-name="Сгруппировать 3071" transform="translate(-741.052 -501.525)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3070" data-name="Сгруппировать 3070" transform="translate(741.048 501.399)" style="isolation: isolate">
            <g id="Сгруппировать_3069" data-name="Сгруппировать 3069" transform="translate(0 0)" clip-path="url(#clip-path-516)">
              <g id="Сгруппировать_3068" data-name="Сгруппировать 3068" transform="translate(0.004 0.126)" clip-path="url(#clip-path-943)">
                <g id="Сгруппировать_3067" data-name="Сгруппировать 3067" transform="translate(-0.004 -0.126)" clip-path="url(#clip-path-947)">
                  <g id="Сгруппировать_3065" data-name="Сгруппировать 3065" transform="translate(-361.423 -222.314)" opacity="0">
                    <g id="Сгруппировать_3064" data-name="Сгруппировать 3064">
                      <path id="Контур_2851" data-name="Контур 2851" d="M-834.01-513.29h602.372v400.426H-834.01Z" transform="translate(834.01 513.29)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3066" data-name="Сгруппировать 3066" transform="translate(-361.423 -222.314)">
                    <path id="Контур_2852" data-name="Контур 2852" d="M-834.01-513.29h602.372v400.426H-834.01Z" transform="translate(834.01 513.29)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3081" data-name="Сгруппировать 3081" transform="translate(364.665 -880.172)" clip-path="url(#clip-path-948)">
        <g id="Сгруппировать_3080" data-name="Сгруппировать 3080" transform="translate(-744.099 -501.525)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3079" data-name="Сгруппировать 3079" transform="translate(744.082 501.399)" style="isolation: isolate">
            <g id="Сгруппировать_3078" data-name="Сгруппировать 3078" transform="translate(0 0)" clip-path="url(#clip-path-516)">
              <g id="Сгруппировать_3077" data-name="Сгруппировать 3077" transform="translate(0.017 0.126)" clip-path="url(#clip-path-948)">
                <g id="Сгруппировать_3076" data-name="Сгруппировать 3076" transform="translate(-0.017 -0.126)" clip-path="url(#clip-path-952)">
                  <g id="Сгруппировать_3074" data-name="Сгруппировать 3074" transform="translate(-364.457 -222.314)" opacity="0">
                    <g id="Сгруппировать_3073" data-name="Сгруппировать 3073">
                      <path id="Контур_2857" data-name="Контур 2857" d="M-841.04-513.29h602.372v400.426H-841.04Z" transform="translate(841.04 513.29)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3075" data-name="Сгруппировать 3075" transform="translate(-364.457 -222.314)">
                    <path id="Контур_2858" data-name="Контур 2858" d="M-841.04-513.29h602.372v400.426H-841.04Z" transform="translate(841.04 513.29)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3090" data-name="Сгруппировать 3090" transform="translate(367.274 -880.232)" clip-path="url(#clip-path-953)">
        <g id="Сгруппировать_3089" data-name="Сгруппировать 3089" transform="translate(-746.708 -501.464)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3088" data-name="Сгруппировать 3088" transform="translate(746.682 501.399)" style="isolation: isolate">
            <g id="Сгруппировать_3087" data-name="Сгруппировать 3087" transform="translate(0 0)" clip-path="url(#clip-path-476)">
              <g id="Сгруппировать_3086" data-name="Сгруппировать 3086" transform="translate(0.026 0.065)" clip-path="url(#clip-path-953)">
                <g id="Сгруппировать_3085" data-name="Сгруппировать 3085" transform="translate(-0.026 -0.065)" clip-path="url(#clip-path-957)">
                  <g id="Сгруппировать_3083" data-name="Сгруппировать 3083" transform="translate(-367.057 -222.314)" opacity="0">
                    <g id="Сгруппировать_3082" data-name="Сгруппировать 3082">
                      <path id="Контур_2863" data-name="Контур 2863" d="M-847.06-513.15h602.373v400.426H-847.06Z" transform="translate(847.06 513.15)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3084" data-name="Сгруппировать 3084" transform="translate(-367.057 -222.314)">
                    <path id="Контур_2864" data-name="Контур 2864" d="M-847.06-513.15h602.373v400.426H-847.06Z" transform="translate(847.06 513.15)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3099" data-name="Сгруппировать 3099" transform="translate(370.524 -880.232)" clip-path="url(#clip-path-958)">
        <g id="Сгруппировать_3098" data-name="Сгруппировать 3098" transform="translate(-749.958 -501.464)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3097" data-name="Сгруппировать 3097" transform="translate(749.715 501.399)" style="isolation: isolate">
            <g id="Сгруппировать_3096" data-name="Сгруппировать 3096" transform="translate(0 0)" clip-path="url(#clip-path-506)">
              <g id="Сгруппировать_3095" data-name="Сгруппировать 3095" transform="translate(0.243 0.065)" clip-path="url(#clip-path-958)">
                <g id="Сгруппировать_3094" data-name="Сгруппировать 3094" transform="translate(-0.243 -0.065)" clip-path="url(#clip-path-962)">
                  <g id="Сгруппировать_3092" data-name="Сгруппировать 3092" transform="translate(-370.091 -222.314)" opacity="0">
                    <g id="Сгруппировать_3091" data-name="Сгруппировать 3091">
                      <path id="Контур_2869" data-name="Контур 2869" d="M-854.56-513.15h602.373v400.426H-854.56Z" transform="translate(854.56 513.15)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3093" data-name="Сгруппировать 3093" transform="translate(-370.091 -222.314)">
                    <path id="Контур_2870" data-name="Контур 2870" d="M-854.56-513.15h602.373v400.426H-854.56Z" transform="translate(854.56 513.15)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3108" data-name="Сгруппировать 3108" transform="translate(373.892 -880.172)" clip-path="url(#clip-path-963)">
        <g id="Сгруппировать_3107" data-name="Сгруппировать 3107" transform="translate(-753.325 -501.525)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3106" data-name="Сгруппировать 3106" transform="translate(753.182 501.399)" style="isolation: isolate">
            <g id="Сгруппировать_3105" data-name="Сгруппировать 3105" transform="translate(0 0)" clip-path="url(#clip-path-965)">
              <g id="Сгруппировать_3104" data-name="Сгруппировать 3104" transform="translate(0.143 0.126)" clip-path="url(#clip-path-963)">
                <g id="Сгруппировать_3103" data-name="Сгруппировать 3103" transform="translate(-0.143 -0.126)" clip-path="url(#clip-path-967)">
                  <g id="Сгруппировать_3101" data-name="Сгруппировать 3101" transform="translate(-373.558 -222.314)" opacity="0">
                    <g id="Сгруппировать_3100" data-name="Сгруппировать 3100">
                      <path id="Контур_2875" data-name="Контур 2875" d="M-862.33-513.29h602.373v400.426H-862.33Z" transform="translate(862.33 513.29)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3102" data-name="Сгруппировать 3102" transform="translate(-373.558 -222.314)">
                    <path id="Контур_2876" data-name="Контур 2876" d="M-862.33-513.29h602.373v400.426H-862.33Z" transform="translate(862.33 513.29)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3117" data-name="Сгруппировать 3117" transform="translate(379.339 -880.228)" clip-path="url(#clip-path-968)">
        <g id="Сгруппировать_3116" data-name="Сгруппировать 3116" transform="translate(-758.773 -501.469)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3115" data-name="Сгруппировать 3115" transform="translate(758.383 501.399)" style="isolation: isolate">
            <g id="Сгруппировать_3114" data-name="Сгруппировать 3114" transform="translate(0 0)" clip-path="url(#clip-path-516)">
              <g id="Сгруппировать_3113" data-name="Сгруппировать 3113" transform="translate(0.39 0.069)" clip-path="url(#clip-path-968)">
                <g id="Сгруппировать_3112" data-name="Сгруппировать 3112" transform="translate(-0.39 -0.069)" clip-path="url(#clip-path-972)">
                  <g id="Сгруппировать_3110" data-name="Сгруппировать 3110" transform="translate(-378.758 -222.314)" opacity="0">
                    <g id="Сгруппировать_3109" data-name="Сгруппировать 3109">
                      <path id="Контур_2881" data-name="Контур 2881" d="M-874.9-513.16h602.373v400.426H-874.9Z" transform="translate(874.9 513.16)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3111" data-name="Сгруппировать 3111" transform="translate(-378.758 -222.314)">
                    <path id="Контур_2882" data-name="Контур 2882" d="M-874.9-513.16h602.373v400.426H-874.9Z" transform="translate(874.9 513.16)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3126" data-name="Сгруппировать 3126" transform="translate(416.287 -808.286)" clip-path="url(#clip-path-973)">
        <g id="Сгруппировать_3125" data-name="Сгруппировать 3125" transform="translate(-795.721 -573.411)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3124" data-name="Сгруппировать 3124" transform="translate(795.652 573.337)" style="isolation: isolate">
            <g id="Сгруппировать_3123" data-name="Сгруппировать 3123" transform="translate(0 0)" clip-path="url(#clip-path-975)">
              <g id="Сгруппировать_3122" data-name="Сгруппировать 3122" transform="translate(0.069 0.074)" clip-path="url(#clip-path-973)">
                <g id="Сгруппировать_3121" data-name="Сгруппировать 3121" transform="translate(-0.069 -0.074)" clip-path="url(#clip-path-977)">
                  <g id="Сгруппировать_3119" data-name="Сгруппировать 3119" transform="translate(-416.027 -294.252)" opacity="0">
                    <g id="Сгруппировать_3118" data-name="Сгруппировать 3118" transform="translate(0 0)">
                      <path id="Контур_2887" data-name="Контур 2887" d="M-960.16-679.17h602.373v400.426H-960.16Z" transform="translate(960.16 679.17)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3120" data-name="Сгруппировать 3120" transform="translate(-416.027 -294.252)">
                    <path id="Контур_2888" data-name="Контур 2888" d="M-960.16-679.17h602.373v400.426H-960.16Z" transform="translate(960.16 679.17)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3131" data-name="Сгруппировать 3131" transform="translate(420.261 -808.286)" clip-path="url(#clip-path-978)">
        <g id="Сгруппировать_3130" data-name="Сгруппировать 3130" transform="translate(-799.695 -573.411)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3128" data-name="Сгруппировать 3128" transform="translate(379.625 279.085)" opacity="0">
            <g id="Сгруппировать_3127" data-name="Сгруппировать 3127" transform="translate(0 0)">
              <path id="Контур_2893" data-name="Контур 2893" d="M-969.33-679.17h602.373v400.426H-969.33Z" transform="translate(969.33 679.17)" fill="#c4d3e0"/>
            </g>
          </g>
          <g id="Сгруппировать_3129" data-name="Сгруппировать 3129" transform="translate(379.625 279.085)">
            <path id="Контур_2894" data-name="Контур 2894" d="M-969.33-679.17h602.373v400.426H-969.33Z" transform="translate(969.33 679.17)" fill="#c4d3e0"/>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3140" data-name="Сгруппировать 3140" transform="translate(421.951 -808.286)" clip-path="url(#clip-path-980)">
        <g id="Сгруппировать_3139" data-name="Сгруппировать 3139" transform="translate(-801.385 -573.411)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3138" data-name="Сгруппировать 3138" transform="translate(801.285 573.337)" style="isolation: isolate">
            <g id="Сгруппировать_3137" data-name="Сгруппировать 3137" transform="translate(0 0)" clip-path="url(#clip-path-975)">
              <g id="Сгруппировать_3136" data-name="Сгруппировать 3136" transform="translate(0.1 0.074)" clip-path="url(#clip-path-980)">
                <g id="Сгруппировать_3135" data-name="Сгруппировать 3135" transform="translate(-0.1 -0.074)" clip-path="url(#clip-path-984)">
                  <g id="Сгруппировать_3133" data-name="Сгруппировать 3133" transform="translate(-421.661 -294.252)" opacity="0">
                    <g id="Сгруппировать_3132" data-name="Сгруппировать 3132" transform="translate(0 0)">
                      <path id="Контур_2897" data-name="Контур 2897" d="M-973.23-679.17h602.372v400.426H-973.23Z" transform="translate(973.23 679.17)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3134" data-name="Сгруппировать 3134" transform="translate(-421.661 -294.252)">
                    <path id="Контур_2898" data-name="Контур 2898" d="M-973.23-679.17h602.372v400.426H-973.23Z" transform="translate(973.23 679.17)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3149" data-name="Сгруппировать 3149" transform="translate(425.288 -808.364)" clip-path="url(#clip-path-985)">
        <g id="Сгруппировать_3148" data-name="Сгруппировать 3148" transform="translate(-804.722 -573.333)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3147" data-name="Сгруппировать 3147" transform="translate(804.319 572.904)" style="isolation: isolate">
            <g id="Сгруппировать_3146" data-name="Сгруппировать 3146" clip-path="url(#clip-path-987)">
              <g id="Сгруппировать_3145" data-name="Сгруппировать 3145" transform="translate(0.403 0.429)" clip-path="url(#clip-path-985)">
                <g id="Сгруппировать_3144" data-name="Сгруппировать 3144" transform="translate(-0.403 -0.429)" clip-path="url(#clip-path-989)">
                  <g id="Сгруппировать_3142" data-name="Сгруппировать 3142" transform="translate(-424.694 -293.819)" opacity="0">
                    <g id="Сгруппировать_3141" data-name="Сгруппировать 3141">
                      <path id="Контур_2903" data-name="Контур 2903" d="M-980.93-678.99h602.373v400.426H-980.93Z" transform="translate(980.93 678.99)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3143" data-name="Сгруппировать 3143" transform="translate(-424.694 -293.819)">
                    <path id="Контур_2904" data-name="Контур 2904" d="M-980.93-678.99h602.373v400.426H-980.93Z" transform="translate(980.93 678.99)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3158" data-name="Сгруппировать 3158" transform="translate(428.911 -808.286)" clip-path="url(#clip-path-990)">
        <g id="Сгруппировать_3157" data-name="Сгруппировать 3157" transform="translate(-808.345 -573.411)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3156" data-name="Сгруппировать 3156" transform="translate(808.219 573.337)" style="isolation: isolate">
            <g id="Сгруппировать_3155" data-name="Сгруппировать 3155" transform="translate(0 0)" clip-path="url(#clip-path-975)">
              <g id="Сгруппировать_3154" data-name="Сгруппировать 3154" transform="translate(0.126 0.074)" clip-path="url(#clip-path-990)">
                <g id="Сгруппировать_3153" data-name="Сгруппировать 3153" transform="translate(-0.126 -0.074)" clip-path="url(#clip-path-994)">
                  <g id="Сгруппировать_3151" data-name="Сгруппировать 3151" transform="translate(-428.595 -294.252)" opacity="0">
                    <g id="Сгруппировать_3150" data-name="Сгруппировать 3150" transform="translate(0 0)">
                      <path id="Контур_2909" data-name="Контур 2909" d="M-989.29-679.17h602.373v400.426H-989.29Z" transform="translate(989.29 679.17)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3152" data-name="Сгруппировать 3152" transform="translate(-428.595 -294.252)">
                    <path id="Контур_2910" data-name="Контур 2910" d="M-989.29-679.17h602.373v400.426H-989.29Z" transform="translate(989.29 679.17)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3167" data-name="Сгруппировать 3167" transform="translate(433.145 -808.286)" clip-path="url(#clip-path-995)">
        <g id="Сгруппировать_3166" data-name="Сгруппировать 3166" transform="translate(-812.579 -573.411)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3165" data-name="Сгруппировать 3165" transform="translate(812.553 573.337)" style="isolation: isolate">
            <g id="Сгруппировать_3164" data-name="Сгруппировать 3164" transform="translate(0 0)" clip-path="url(#clip-path-997)">
              <g id="Сгруппировать_3163" data-name="Сгруппировать 3163" transform="translate(0.026 0.074)" clip-path="url(#clip-path-995)">
                <g id="Сгруппировать_3162" data-name="Сгруппировать 3162" transform="translate(-0.026 -0.074)" clip-path="url(#clip-path-999)">
                  <g id="Сгруппировать_3160" data-name="Сгруппировать 3160" transform="translate(-432.928 -294.252)" opacity="0">
                    <g id="Сгруппировать_3159" data-name="Сгруппировать 3159" transform="translate(0 0)">
                      <path id="Контур_2915" data-name="Контур 2915" d="M-999.06-679.17h602.372v400.426H-999.06Z" transform="translate(999.06 679.17)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3161" data-name="Сгруппировать 3161" transform="translate(-432.928 -294.252)">
                    <path id="Контур_2916" data-name="Контур 2916" d="M-999.06-679.17h602.372v400.426H-999.06Z" transform="translate(999.06 679.17)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3176" data-name="Сгруппировать 3176" transform="translate(436.326 -808.286)" clip-path="url(#clip-path-1000)">
        <g id="Сгруппировать_3175" data-name="Сгруппировать 3175" transform="translate(-815.76 -573.411)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3174" data-name="Сгруппировать 3174" transform="translate(815.586 573.337)" style="isolation: isolate">
            <g id="Сгруппировать_3173" data-name="Сгруппировать 3173" transform="translate(0 0)" clip-path="url(#clip-path-1002)">
              <g id="Сгруппировать_3172" data-name="Сгруппировать 3172" transform="translate(0.173 0.074)" clip-path="url(#clip-path-1000)">
                <g id="Сгруппировать_3171" data-name="Сгруппировать 3171" transform="translate(-0.173 -0.074)" clip-path="url(#clip-path-1004)">
                  <g id="Сгруппировать_3169" data-name="Сгруппировать 3169" transform="translate(-435.962 -294.252)" opacity="0">
                    <g id="Сгруппировать_3168" data-name="Сгруппировать 3168" transform="translate(0 0)">
                      <path id="Контур_2921" data-name="Контур 2921" d="M-1006.4-679.17h602.373v400.426H-1006.4Z" transform="translate(1006.4 679.17)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3170" data-name="Сгруппировать 3170" transform="translate(-435.962 -294.252)">
                    <path id="Контур_2922" data-name="Контур 2922" d="M-1006.4-679.17h602.373v400.426H-1006.4Z" transform="translate(1006.4 679.17)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3185" data-name="Сгруппировать 3185" transform="translate(510.067 -905.155)" clip-path="url(#clip-path-1005)">
        <g id="Сгруппировать_3184" data-name="Сгруппировать 3184" transform="translate(-889.5 -476.542)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3183" data-name="Сгруппировать 3183" transform="translate(889.258 476.264)" style="isolation: isolate">
            <g id="Сгруппировать_3182" data-name="Сгруппировать 3182" transform="translate(0 0)" clip-path="url(#clip-path-1007)">
              <g id="Сгруппировать_3181" data-name="Сгруппировать 3181" transform="translate(0.243 0.277)" clip-path="url(#clip-path-1005)">
                <g id="Сгруппировать_3180" data-name="Сгруппировать 3180" transform="translate(-0.243 -0.277)" clip-path="url(#clip-path-1009)">
                  <g id="Сгруппировать_3178" data-name="Сгруппировать 3178" transform="translate(-509.633 -197.179)" opacity="0">
                    <g id="Сгруппировать_3177" data-name="Сгруппировать 3177">
                      <path id="Контур_2927" data-name="Контур 2927" d="M-1176.56-455.64h602.373V-55.214H-1176.56Z" transform="translate(1176.56 455.64)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3179" data-name="Сгруппировать 3179" transform="translate(-509.633 -197.179)">
                    <path id="Контур_2928" data-name="Контур 2928" d="M-1176.56-455.64h602.373V-55.214H-1176.56Z" transform="translate(1176.56 455.64)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3190" data-name="Сгруппировать 3190" transform="translate(463.719 -846.634)" clip-path="url(#clip-path-1010)">
        <g id="Сгруппировать_3189" data-name="Сгруппировать 3189" transform="translate(-843.152 -535.063)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3187" data-name="Сгруппировать 3187" transform="translate(379.625 279.085)" opacity="0">
            <g id="Сгруппировать_3186" data-name="Сгруппировать 3186">
              <path id="Контур_2933" data-name="Контур 2933" d="M-1069.61-590.68h602.373v400.426H-1069.61Z" transform="translate(1069.61 590.68)" fill="#c4d3e0"/>
            </g>
          </g>
          <g id="Сгруппировать_3188" data-name="Сгруппировать 3188" transform="translate(379.625 279.085)">
            <path id="Контур_2934" data-name="Контур 2934" d="M-1069.61-590.68h602.373v400.426H-1069.61Z" transform="translate(1069.61 590.68)" fill="#c4d3e0"/>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3199" data-name="Сгруппировать 3199" transform="translate(325.801 -819.514)" clip-path="url(#clip-path-1012)">
        <g id="Сгруппировать_3198" data-name="Сгруппировать 3198" transform="translate(-705.235 -562.182)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3197" data-name="Сгруппировать 3197" transform="translate(705.079 562.07)" style="isolation: isolate">
            <g id="Сгруппировать_3196" data-name="Сгруппировать 3196" transform="translate(0 0)" clip-path="url(#clip-path-1014)">
              <g id="Сгруппировать_3195" data-name="Сгруппировать 3195" transform="translate(0.156 0.113)" clip-path="url(#clip-path-1012)">
                <g id="Сгруппировать_3194" data-name="Сгруппировать 3194" transform="translate(-0.156 -0.113)" clip-path="url(#clip-path-1016)">
                  <g id="Сгруппировать_3192" data-name="Сгруппировать 3192" transform="translate(-325.454 -282.985)" opacity="0">
                    <g id="Сгруппировать_3191" data-name="Сгруппировать 3191">
                      <path id="Контур_2937" data-name="Контур 2937" d="M-751.36-653.26h602.372v400.426H-751.36Z" transform="translate(751.36 653.26)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3193" data-name="Сгруппировать 3193" transform="translate(-325.454 -282.985)">
                    <path id="Контур_2938" data-name="Контур 2938" d="M-751.36-653.26h602.372v400.426H-751.36Z" transform="translate(751.36 653.26)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3208" data-name="Сгруппировать 3208" transform="translate(331.331 -812.923)" clip-path="url(#clip-path-1017)">
        <g id="Сгруппировать_3207" data-name="Сгруппировать 3207" transform="translate(-710.765 -568.774)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3206" data-name="Сгруппировать 3206" transform="translate(710.713 568.57)" style="isolation: isolate">
            <g id="Сгруппировать_3205" data-name="Сгруппировать 3205" transform="translate(0 0)" clip-path="url(#clip-path-1019)">
              <g id="Сгруппировать_3204" data-name="Сгруппировать 3204" transform="translate(0.052 0.204)" clip-path="url(#clip-path-1017)">
                <g id="Сгруппировать_3203" data-name="Сгруппировать 3203" transform="translate(-0.052 -0.204)" clip-path="url(#clip-path-1021)">
                  <g id="Сгруппировать_3201" data-name="Сгруппировать 3201" transform="translate(-331.088 -289.485)" opacity="0">
                    <g id="Сгруппировать_3200" data-name="Сгруппировать 3200" transform="translate(0 0)">
                      <path id="Контур_2943" data-name="Контур 2943" d="M-764.12-668.47h602.372v400.426H-764.12Z" transform="translate(764.12 668.47)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3202" data-name="Сгруппировать 3202" transform="translate(-331.088 -289.485)">
                    <path id="Контур_2944" data-name="Контур 2944" d="M-764.12-668.47h602.372v400.426H-764.12Z" transform="translate(764.12 668.47)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3217" data-name="Сгруппировать 3217" transform="translate(332.471 -806.032)" clip-path="url(#clip-path-1022)">
        <g id="Сгруппировать_3216" data-name="Сгруппировать 3216" transform="translate(-711.905 -575.664)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3215" data-name="Сгруппировать 3215" transform="translate(711.58 575.504)" style="isolation: isolate">
            <g id="Сгруппировать_3214" data-name="Сгруппировать 3214" transform="translate(0 0)" clip-path="url(#clip-path-1024)">
              <g id="Сгруппировать_3213" data-name="Сгруппировать 3213" transform="translate(0.325 0.16)" clip-path="url(#clip-path-1022)">
                <g id="Сгруппировать_3212" data-name="Сгруппировать 3212" transform="translate(-0.325 -0.16)" clip-path="url(#clip-path-1026)">
                  <g id="Сгруппировать_3210" data-name="Сгруппировать 3210" transform="translate(-331.955 -296.419)" opacity="0">
                    <g id="Сгруппировать_3209" data-name="Сгруппировать 3209">
                      <path id="Контур_2949" data-name="Контур 2949" d="M-766.75-684.37h602.372v400.426H-766.75Z" transform="translate(766.75 684.37)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3211" data-name="Сгруппировать 3211" transform="translate(-331.955 -296.419)">
                    <path id="Контур_2950" data-name="Контур 2950" d="M-766.75-684.37h602.372v400.426H-766.75Z" transform="translate(766.75 684.37)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3226" data-name="Сгруппировать 3226" transform="translate(333.823 -796.828)" clip-path="url(#clip-path-1027)">
        <g id="Сгруппировать_3225" data-name="Сгруппировать 3225" transform="translate(-713.257 -584.869)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3224" data-name="Сгруппировать 3224" transform="translate(712.88 584.605)" style="isolation: isolate">
            <g id="Сгруппировать_3223" data-name="Сгруппировать 3223" transform="translate(0)" clip-path="url(#clip-path-1029)">
              <g id="Сгруппировать_3222" data-name="Сгруппировать 3222" transform="translate(0.377 0.264)" clip-path="url(#clip-path-1027)">
                <g id="Сгруппировать_3221" data-name="Сгруппировать 3221" transform="translate(-0.377 -0.264)" clip-path="url(#clip-path-1031)">
                  <g id="Сгруппировать_3219" data-name="Сгруппировать 3219" transform="translate(-333.255 -305.52)" opacity="0">
                    <g id="Сгруппировать_3218" data-name="Сгруппировать 3218">
                      <path id="Контур_2955" data-name="Контур 2955" d="M-769.87-705.61H-167.5v400.426H-769.87Z" transform="translate(769.87 705.61)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3220" data-name="Сгруппировать 3220" transform="translate(-333.255 -305.52)">
                    <path id="Контур_2956" data-name="Контур 2956" d="M-769.87-705.61H-167.5v400.426H-769.87Z" transform="translate(769.87 705.61)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3235" data-name="Сгруппировать 3235" transform="translate(334.04 -811.605)" clip-path="url(#clip-path-1032)">
        <g id="Сгруппировать_3234" data-name="Сгруппировать 3234" transform="translate(-713.473 -570.091)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3233" data-name="Сгруппировать 3233" transform="translate(713.313 569.87)" style="isolation: isolate">
            <g id="Сгруппировать_3232" data-name="Сгруппировать 3232" transform="translate(0 0)" clip-path="url(#clip-path-1034)">
              <g id="Сгруппировать_3231" data-name="Сгруппировать 3231" transform="translate(0.16 0.221)" clip-path="url(#clip-path-1032)">
                <g id="Сгруппировать_3230" data-name="Сгруппировать 3230" transform="translate(-0.16 -0.221)" clip-path="url(#clip-path-1036)">
                  <g id="Сгруппировать_3228" data-name="Сгруппировать 3228" transform="translate(-333.688 -290.786)" opacity="0">
                    <g id="Сгруппировать_3227" data-name="Сгруппировать 3227">
                      <path id="Контур_2961" data-name="Контур 2961" d="M-770.1-671.51h602.372v400.426H-770.1Z" transform="translate(770.105 671.51)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3229" data-name="Сгруппировать 3229" transform="translate(-333.688 -290.786)">
                    <path id="Контур_2962" data-name="Контур 2962" d="M-770.1-671.51h602.372v400.426H-770.1Z" transform="translate(770.105 671.51)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3244" data-name="Сгруппировать 3244" transform="translate(334.82 -811.605)" clip-path="url(#clip-path-1037)">
        <g id="Сгруппировать_3243" data-name="Сгруппировать 3243" transform="translate(-714.253 -570.091)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3242" data-name="Сгруппировать 3242" transform="translate(714.18 569.87)" style="isolation: isolate">
            <g id="Сгруппировать_3241" data-name="Сгруппировать 3241" transform="translate(0 0)" clip-path="url(#clip-path-1034)">
              <g id="Сгруппировать_3240" data-name="Сгруппировать 3240" transform="translate(0.074 0.221)" clip-path="url(#clip-path-1037)">
                <g id="Сгруппировать_3239" data-name="Сгруппировать 3239" transform="translate(-0.074 -0.221)" clip-path="url(#clip-path-1041)">
                  <g id="Сгруппировать_3237" data-name="Сгруппировать 3237" transform="translate(-334.555 -290.786)" opacity="0">
                    <g id="Сгруппировать_3236" data-name="Сгруппировать 3236">
                      <path id="Контур_2967" data-name="Контур 2967" d="M-771.9-671.51h602.372v400.426H-771.9Z" transform="translate(771.9 671.51)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3238" data-name="Сгруппировать 3238" transform="translate(-334.555 -290.786)">
                    <path id="Контур_2968" data-name="Контур 2968" d="M-771.9-671.51h602.372v400.426H-771.9Z" transform="translate(771.9 671.51)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3253" data-name="Сгруппировать 3253" transform="translate(335.738 -811.605)" clip-path="url(#clip-path-1042)">
        <g id="Сгруппировать_3252" data-name="Сгруппировать 3252" transform="translate(-715.172 -570.091)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3251" data-name="Сгруппировать 3251" transform="translate(715.046 569.87)" style="isolation: isolate">
            <g id="Сгруппировать_3250" data-name="Сгруппировать 3250" transform="translate(0 0)" clip-path="url(#clip-path-1034)">
              <g id="Сгруппировать_3249" data-name="Сгруппировать 3249" transform="translate(0.126 0.221)" clip-path="url(#clip-path-1042)">
                <g id="Сгруппировать_3248" data-name="Сгруппировать 3248" transform="translate(-0.126 -0.221)" clip-path="url(#clip-path-1046)">
                  <g id="Сгруппировать_3246" data-name="Сгруппировать 3246" transform="translate(-335.422 -290.786)" opacity="0">
                    <g id="Сгруппировать_3245" data-name="Сгруппировать 3245">
                      <path id="Контур_2973" data-name="Контур 2973" d="M-774.02-671.51h602.372v400.426H-774.02Z" transform="translate(774.02 671.51)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3247" data-name="Сгруппировать 3247" transform="translate(-335.422 -290.786)">
                    <path id="Контур_2974" data-name="Контур 2974" d="M-774.02-671.51h602.372v400.426H-774.02Z" transform="translate(774.02 671.51)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3262" data-name="Сгруппировать 3262" transform="translate(336.518 -811.605)" clip-path="url(#clip-path-1047)">
        <g id="Сгруппировать_3261" data-name="Сгруппировать 3261" transform="translate(-715.952 -570.091)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3260" data-name="Сгруппировать 3260" transform="translate(715.913 569.87)" style="isolation: isolate">
            <g id="Сгруппировать_3259" data-name="Сгруппировать 3259" transform="translate(0 0)" clip-path="url(#clip-path-1034)">
              <g id="Сгруппировать_3258" data-name="Сгруппировать 3258" transform="translate(0.039 0.221)" clip-path="url(#clip-path-1047)">
                <g id="Сгруппировать_3257" data-name="Сгруппировать 3257" transform="translate(-0.039 -0.221)" clip-path="url(#clip-path-1051)">
                  <g id="Сгруппировать_3255" data-name="Сгруппировать 3255" transform="translate(-336.289 -290.786)" opacity="0">
                    <g id="Сгруппировать_3254" data-name="Сгруппировать 3254">
                      <path id="Контур_2979" data-name="Контур 2979" d="M-775.82-671.51h602.373v400.426H-775.82Z" transform="translate(775.82 671.51)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3256" data-name="Сгруппировать 3256" transform="translate(-336.289 -290.786)">
                    <path id="Контур_2980" data-name="Контур 2980" d="M-775.82-671.51h602.373v400.426H-775.82Z" transform="translate(775.82 671.51)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3271" data-name="Сгруппировать 3271" transform="translate(337.476 -811.605)" clip-path="url(#clip-path-1052)">
        <g id="Сгруппировать_3270" data-name="Сгруппировать 3270" transform="translate(-716.91 -570.091)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3269" data-name="Сгруппировать 3269" transform="translate(716.78 569.87)" style="isolation: isolate">
            <g id="Сгруппировать_3268" data-name="Сгруппировать 3268" transform="translate(0 0)" clip-path="url(#clip-path-1034)">
              <g id="Сгруппировать_3267" data-name="Сгруппировать 3267" transform="translate(0.13 0.221)" clip-path="url(#clip-path-1052)">
                <g id="Сгруппировать_3266" data-name="Сгруппировать 3266" transform="translate(-0.13 -0.221)" clip-path="url(#clip-path-1056)">
                  <g id="Сгруппировать_3264" data-name="Сгруппировать 3264" transform="translate(-337.155 -290.786)" opacity="0">
                    <g id="Сгруппировать_3263" data-name="Сгруппировать 3263">
                      <path id="Контур_2985" data-name="Контур 2985" d="M-778.03-671.51h602.372v400.426H-778.03Z" transform="translate(778.03 671.51)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3265" data-name="Сгруппировать 3265" transform="translate(-337.155 -290.786)">
                    <path id="Контур_2986" data-name="Контур 2986" d="M-778.03-671.51h602.372v400.426H-778.03Z" transform="translate(778.03 671.51)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3280" data-name="Сгруппировать 3280" transform="translate(338.425 -811.605)" clip-path="url(#clip-path-1057)">
        <g id="Сгруппировать_3279" data-name="Сгруппировать 3279" transform="translate(-717.859 -570.091)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3278" data-name="Сгруппировать 3278" transform="translate(717.647 569.87)" style="isolation: isolate">
            <g id="Сгруппировать_3277" data-name="Сгруппировать 3277" transform="translate(0 0)" clip-path="url(#clip-path-1034)">
              <g id="Сгруппировать_3276" data-name="Сгруппировать 3276" transform="translate(0.212 0.221)" clip-path="url(#clip-path-1057)">
                <g id="Сгруппировать_3275" data-name="Сгруппировать 3275" transform="translate(-0.212 -0.221)" clip-path="url(#clip-path-1061)">
                  <g id="Сгруппировать_3273" data-name="Сгруппировать 3273" transform="translate(-338.022 -290.786)" opacity="0">
                    <g id="Сгруппировать_3272" data-name="Сгруппировать 3272">
                      <path id="Контур_2991" data-name="Контур 2991" d="M-780.225-671.51h602.373v400.426H-780.225Z" transform="translate(780.225 671.51)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3274" data-name="Сгруппировать 3274" transform="translate(-338.022 -290.786)">
                    <path id="Контур_2992" data-name="Контур 2992" d="M-780.225-671.51h602.373v400.426H-780.225Z" transform="translate(780.225 671.51)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3289" data-name="Сгруппировать 3289" transform="translate(339.344 -811.605)" clip-path="url(#clip-path-1062)">
        <g id="Сгруппировать_3288" data-name="Сгруппировать 3288" transform="translate(-718.778 -570.091)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3287" data-name="Сгруппировать 3287" transform="translate(718.513 569.87)" style="isolation: isolate">
            <g id="Сгруппировать_3286" data-name="Сгруппировать 3286" transform="translate(0 0)" clip-path="url(#clip-path-1064)">
              <g id="Сгруппировать_3285" data-name="Сгруппировать 3285" transform="translate(0.264 0.221)" clip-path="url(#clip-path-1062)">
                <g id="Сгруппировать_3284" data-name="Сгруппировать 3284" transform="translate(-0.264 -0.221)" clip-path="url(#clip-path-1066)">
                  <g id="Сгруппировать_3282" data-name="Сгруппировать 3282" transform="translate(-338.889 -290.786)" opacity="0">
                    <g id="Сгруппировать_3281" data-name="Сгруппировать 3281">
                      <path id="Контур_2997" data-name="Контур 2997" d="M-782.34-671.51h602.373v400.426H-782.34Z" transform="translate(782.34 671.51)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3283" data-name="Сгруппировать 3283" transform="translate(-338.889 -290.786)">
                    <path id="Контур_2998" data-name="Контур 2998" d="M-782.34-671.51h602.373v400.426H-782.34Z" transform="translate(782.34 671.51)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3298" data-name="Сгруппировать 3298" transform="translate(340.124 -811.605)" clip-path="url(#clip-path-1067)">
        <g id="Сгруппировать_3297" data-name="Сгруппировать 3297" transform="translate(-719.558 -570.091)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3296" data-name="Сгруппировать 3296" transform="translate(719.38 569.87)" style="isolation: isolate">
            <g id="Сгруппировать_3295" data-name="Сгруппировать 3295" transform="translate(0 0)" clip-path="url(#clip-path-1034)">
              <g id="Сгруппировать_3294" data-name="Сгруппировать 3294" transform="translate(0.178 0.221)" clip-path="url(#clip-path-1067)">
                <g id="Сгруппировать_3293" data-name="Сгруппировать 3293" transform="translate(-0.178 -0.221)" clip-path="url(#clip-path-1071)">
                  <g id="Сгруппировать_3291" data-name="Сгруппировать 3291" transform="translate(-339.755 -290.786)" opacity="0">
                    <g id="Сгруппировать_3290" data-name="Сгруппировать 3290">
                      <path id="Контур_3003" data-name="Контур 3003" d="M-784.14-671.51h602.372v400.426H-784.14Z" transform="translate(784.14 671.51)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3292" data-name="Сгруппировать 3292" transform="translate(-339.755 -290.786)">
                    <path id="Контур_3004" data-name="Контур 3004" d="M-784.14-671.51h602.372v400.426H-784.14Z" transform="translate(784.14 671.51)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3307" data-name="Сгруппировать 3307" transform="translate(340.986 -811.605)" clip-path="url(#clip-path-1072)">
        <g id="Сгруппировать_3306" data-name="Сгруппировать 3306" transform="translate(-720.42 -570.091)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3305" data-name="Сгруппировать 3305" transform="translate(720.247 569.87)" style="isolation: isolate">
            <g id="Сгруппировать_3304" data-name="Сгруппировать 3304" transform="translate(0 0)" clip-path="url(#clip-path-1034)">
              <g id="Сгруппировать_3303" data-name="Сгруппировать 3303" transform="translate(0.173 0.221)" clip-path="url(#clip-path-1072)">
                <g id="Сгруппировать_3302" data-name="Сгруппировать 3302" transform="translate(-0.173 -0.221)" clip-path="url(#clip-path-1076)">
                  <g id="Сгруппировать_3300" data-name="Сгруппировать 3300" transform="translate(-340.622 -290.786)" opacity="0">
                    <g id="Сгруппировать_3299" data-name="Сгруппировать 3299">
                      <path id="Контур_3009" data-name="Контур 3009" d="M-786.135-671.51h602.373v400.426H-786.135Z" transform="translate(786.135 671.51)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3301" data-name="Сгруппировать 3301" transform="translate(-340.622 -290.786)">
                    <path id="Контур_3010" data-name="Контур 3010" d="M-786.135-671.51h602.373v400.426H-786.135Z" transform="translate(786.135 671.51)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3316" data-name="Сгруппировать 3316" transform="translate(341.766 -811.605)" clip-path="url(#clip-path-1077)">
        <g id="Сгруппировать_3315" data-name="Сгруппировать 3315" transform="translate(-721.2 -570.091)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3314" data-name="Сгруппировать 3314" transform="translate(721.114 569.87)" style="isolation: isolate">
            <g id="Сгруппировать_3313" data-name="Сгруппировать 3313" transform="translate(0 0)" clip-path="url(#clip-path-1034)">
              <g id="Сгруппировать_3312" data-name="Сгруппировать 3312" transform="translate(0.087 0.221)" clip-path="url(#clip-path-1077)">
                <g id="Сгруппировать_3311" data-name="Сгруппировать 3311" transform="translate(-0.087 -0.221)" clip-path="url(#clip-path-1081)">
                  <g id="Сгруппировать_3309" data-name="Сгруппировать 3309" transform="translate(-341.489 -290.786)" opacity="0">
                    <g id="Сгруппировать_3308" data-name="Сгруппировать 3308">
                      <path id="Контур_3015" data-name="Контур 3015" d="M-787.93-671.51h602.372v400.426H-787.93Z" transform="translate(787.93 671.51)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3310" data-name="Сгруппировать 3310" transform="translate(-341.489 -290.786)">
                    <path id="Контур_3016" data-name="Контур 3016" d="M-787.93-671.51h602.372v400.426H-787.93Z" transform="translate(787.93 671.51)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3325" data-name="Сгруппировать 3325" transform="translate(365.09 -777.014)" clip-path="url(#clip-path-1082)">
        <g id="Сгруппировать_3324" data-name="Сгруппировать 3324" transform="translate(-744.524 -604.682)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3323" data-name="Сгруппировать 3323" transform="translate(744.515 604.539)" style="isolation: isolate">
            <g id="Сгруппировать_3322" data-name="Сгруппировать 3322" transform="translate(0 0)" clip-path="url(#clip-path-1084)">
              <g id="Сгруппировать_3321" data-name="Сгруппировать 3321" transform="translate(0.009 0.143)" clip-path="url(#clip-path-1082)">
                <g id="Сгруппировать_3320" data-name="Сгруппировать 3320" transform="translate(-0.009 -0.143)" clip-path="url(#clip-path-1086)">
                  <g id="Сгруппировать_3318" data-name="Сгруппировать 3318" transform="translate(-364.89 -325.454)" opacity="0">
                    <g id="Сгруппировать_3317" data-name="Сгруппировать 3317" transform="translate(0 0)">
                      <path id="Контур_3021" data-name="Контур 3021" d="M-842.02-751.33h602.372V-350.9H-842.02Z" transform="translate(842.02 751.33)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3319" data-name="Сгруппировать 3319" transform="translate(-364.89 -325.454)">
                    <path id="Контур_3022" data-name="Контур 3022" d="M-842.02-751.33h602.372V-350.9H-842.02Z" transform="translate(842.02 751.33)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3334" data-name="Сгруппировать 3334" transform="translate(367.07 -774.969)" clip-path="url(#clip-path-1087)">
        <g id="Сгруппировать_3333" data-name="Сгруппировать 3333" transform="translate(-746.504 -606.728)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3332" data-name="Сгруппировать 3332" transform="translate(746.248 606.706)" style="isolation: isolate">
            <g id="Сгруппировать_3331" data-name="Сгруппировать 3331" transform="translate(0 0)" clip-path="url(#clip-path-501)">
              <g id="Сгруппировать_3330" data-name="Сгруппировать 3330" transform="translate(0.256 0.022)" clip-path="url(#clip-path-1087)">
                <g id="Сгруппировать_3329" data-name="Сгруппировать 3329" transform="translate(-0.256 -0.022)" clip-path="url(#clip-path-1091)">
                  <g id="Сгруппировать_3327" data-name="Сгруппировать 3327" transform="translate(-366.624 -327.621)" opacity="0">
                    <g id="Сгруппировать_3326" data-name="Сгруппировать 3326">
                      <path id="Контур_3027" data-name="Контур 3027" d="M-846.59-756.05h602.373v400.426H-846.59Z" transform="translate(846.59 756.05)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3328" data-name="Сгруппировать 3328" transform="translate(-366.624 -327.621)">
                    <path id="Контур_3028" data-name="Контур 3028" d="M-846.59-756.05h602.373v400.426H-846.59Z" transform="translate(846.59 756.05)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3343" data-name="Сгруппировать 3343" transform="translate(367.248 -767.372)" clip-path="url(#clip-path-1092)">
        <g id="Сгруппировать_3342" data-name="Сгруппировать 3342" transform="translate(-746.682 -614.325)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3341" data-name="Сгруппировать 3341" transform="translate(746.682 614.073)" style="isolation: isolate">
            <g id="Сгруппировать_3340" data-name="Сгруппировать 3340" transform="translate(0 0)" clip-path="url(#clip-path-1094)">
              <g id="Сгруппировать_3339" data-name="Сгруппировать 3339" transform="translate(0 0.251)" clip-path="url(#clip-path-1092)">
                <g id="Сгруппировать_3338" data-name="Сгруппировать 3338" transform="translate(0 -0.251)" clip-path="url(#clip-path-1096)">
                  <g id="Сгруппировать_3336" data-name="Сгруппировать 3336" transform="translate(-367.057 -334.988)" opacity="0">
                    <g id="Сгруппировать_3335" data-name="Сгруппировать 3335" transform="translate(0 0)">
                      <path id="Контур_3033" data-name="Контур 3033" d="M-847-773.58h602.372v400.426H-847Z" transform="translate(847 773.58)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3337" data-name="Сгруппировать 3337" transform="translate(-367.057 -334.988)">
                    <path id="Контур_3034" data-name="Контур 3034" d="M-847-773.58h602.372v400.426H-847Z" transform="translate(847 773.58)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3352" data-name="Сгруппировать 3352" transform="translate(374.16 -774.8)" clip-path="url(#clip-path-1097)">
        <g id="Сгруппировать_3351" data-name="Сгруппировать 3351" transform="translate(-753.594 -606.897)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3350" data-name="Сгруппировать 3350" transform="translate(753.182 606.706)" style="isolation: isolate">
            <g id="Сгруппировать_3349" data-name="Сгруппировать 3349" transform="translate(0 0)" clip-path="url(#clip-path-1099)">
              <g id="Сгруппировать_3348" data-name="Сгруппировать 3348" transform="translate(0.412 0.191)" clip-path="url(#clip-path-1097)">
                <g id="Сгруппировать_3347" data-name="Сгруппировать 3347" transform="translate(-0.412 -0.191)" clip-path="url(#clip-path-1101)">
                  <g id="Сгруппировать_3345" data-name="Сгруппировать 3345" transform="translate(-373.558 -327.621)" opacity="0">
                    <g id="Сгруппировать_3344" data-name="Сгруппировать 3344">
                      <path id="Контур_3039" data-name="Контур 3039" d="M-862.95-756.44h602.373v400.426H-862.95Z" transform="translate(862.95 756.44)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3346" data-name="Сгруппировать 3346" transform="translate(-373.558 -327.621)">
                    <path id="Контур_3040" data-name="Контур 3040" d="M-862.95-756.44h602.373v400.426H-862.95Z" transform="translate(862.95 756.44)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3361" data-name="Сгруппировать 3361" transform="translate(374.338 -767.203)" clip-path="url(#clip-path-1102)">
        <g id="Сгруппировать_3360" data-name="Сгруппировать 3360" transform="translate(-753.772 -614.494)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3359" data-name="Сгруппировать 3359" transform="translate(753.616 614.073)" style="isolation: isolate">
            <g id="Сгруппировать_3358" data-name="Сгруппировать 3358" transform="translate(0 0)" clip-path="url(#clip-path-1094)">
              <g id="Сгруппировать_3357" data-name="Сгруппировать 3357" transform="translate(0.156 0.42)" clip-path="url(#clip-path-1102)">
                <g id="Сгруппировать_3356" data-name="Сгруппировать 3356" transform="translate(-0.156 -0.42)" clip-path="url(#clip-path-1106)">
                  <g id="Сгруппировать_3354" data-name="Сгруппировать 3354" transform="translate(-373.991 -334.988)" opacity="0">
                    <g id="Сгруппировать_3353" data-name="Сгруппировать 3353" transform="translate(0 0)">
                      <path id="Контур_3045" data-name="Контур 3045" d="M-863.36-773.97h602.372v400.426H-863.36Z" transform="translate(863.36 773.97)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3355" data-name="Сгруппировать 3355" transform="translate(-373.991 -334.988)">
                    <path id="Контур_3046" data-name="Контур 3046" d="M-863.36-773.97h602.372v400.426H-863.36Z" transform="translate(863.36 773.97)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3370" data-name="Сгруппировать 3370" transform="translate(412.482 -1089.18)" clip-path="url(#clip-path-1107)">
        <g id="Сгруппировать_3369" data-name="Сгруппировать 3369" transform="translate(-791.916 -292.516)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3368" data-name="Сгруппировать 3368" transform="translate(791.751 292.086)" style="isolation: isolate">
            <g id="Сгруппировать_3367" data-name="Сгруппировать 3367" clip-path="url(#clip-path-1109)">
              <g id="Сгруппировать_3366" data-name="Сгруппировать 3366" transform="translate(0.165 0.431)" clip-path="url(#clip-path-1107)">
                <g id="Сгруппировать_3365" data-name="Сгруппировать 3365" transform="translate(-0.165 -0.431)" clip-path="url(#clip-path-1111)">
                  <g id="Сгруппировать_3363" data-name="Сгруппировать 3363" transform="translate(-412.127 -13.001)" opacity="0">
                    <g id="Сгруппировать_3362" data-name="Сгруппировать 3362" transform="translate(0 0)">
                      <path id="Контур_3051" data-name="Контур 3051" d="M-951.38-30.994h602.372V369.432H-951.38Z" transform="translate(951.38 30.994)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3364" data-name="Сгруппировать 3364" transform="translate(-412.127 -13.001)">
                    <path id="Контур_3052" data-name="Контур 3052" d="M-951.38-30.994h602.372V369.432H-951.38Z" transform="translate(951.38 30.994)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3375" data-name="Сгруппировать 3375" transform="translate(416.894 -1089.18)" clip-path="url(#clip-path-1112)">
        <g id="Сгруппировать_3374" data-name="Сгруппировать 3374" transform="translate(-796.328 -292.516)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3372" data-name="Сгруппировать 3372" transform="translate(379.625 279.085)" opacity="0">
            <g id="Сгруппировать_3371" data-name="Сгруппировать 3371" transform="translate(0 0)">
              <path id="Контур_3057" data-name="Контур 3057" d="M-961.56-30.994h602.372V369.432H-961.56Z" transform="translate(961.56 30.994)" fill="#c4d3e0"/>
            </g>
          </g>
          <g id="Сгруппировать_3373" data-name="Сгруппировать 3373" transform="translate(379.625 279.085)">
            <path id="Контур_3058" data-name="Контур 3058" d="M-961.56-30.994h602.372V369.432H-961.56Z" transform="translate(961.56 30.994)" fill="#c4d3e0"/>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3384" data-name="Сгруппировать 3384" transform="translate(419.707 -1089.18)" clip-path="url(#clip-path-1114)">
        <g id="Сгруппировать_3383" data-name="Сгруппировать 3383" transform="translate(-799.14 -292.516)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3382" data-name="Сгруппировать 3382" transform="translate(799.119 292.086)" style="isolation: isolate">
            <g id="Сгруппировать_3381" data-name="Сгруппировать 3381" transform="translate(0)" clip-path="url(#clip-path-1116)">
              <g id="Сгруппировать_3380" data-name="Сгруппировать 3380" transform="translate(0.022 0.431)" clip-path="url(#clip-path-1114)">
                <g id="Сгруппировать_3379" data-name="Сгруппировать 3379" transform="translate(-0.022 -0.431)" clip-path="url(#clip-path-1118)">
                  <g id="Сгруппировать_3377" data-name="Сгруппировать 3377" transform="translate(-419.494 -13.001)" opacity="0">
                    <g id="Сгруппировать_3376" data-name="Сгруппировать 3376" transform="translate(0 0)">
                      <path id="Контур_3061" data-name="Контур 3061" d="M-968.05-30.994h602.373V369.432H-968.05Z" transform="translate(968.05 30.994)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3378" data-name="Сгруппировать 3378" transform="translate(-419.494 -13.001)">
                    <path id="Контур_3062" data-name="Контур 3062" d="M-968.05-30.994h602.373V369.432H-968.05Z" transform="translate(968.05 30.994)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3393" data-name="Сгруппировать 3393" transform="translate(424.495 -1089.285)" clip-path="url(#clip-path-1119)">
        <g id="Сгруппировать_3392" data-name="Сгруппировать 3392" transform="translate(-803.929 -292.412)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3391" data-name="Сгруппировать 3391" transform="translate(803.886 292.086)" style="isolation: isolate">
            <g id="Сгруппировать_3390" data-name="Сгруппировать 3390" transform="translate(0)" clip-path="url(#clip-path-1121)">
              <g id="Сгруппировать_3389" data-name="Сгруппировать 3389" transform="translate(0.043 0.326)" clip-path="url(#clip-path-1119)">
                <g id="Сгруппировать_3388" data-name="Сгруппировать 3388" transform="translate(-0.043 -0.326)" clip-path="url(#clip-path-1123)">
                  <g id="Сгруппировать_3386" data-name="Сгруппировать 3386" transform="translate(-424.261 -13.001)" opacity="0">
                    <g id="Сгруппировать_3385" data-name="Сгруппировать 3385" transform="translate(0 0)">
                      <path id="Контур_3067" data-name="Контур 3067" d="M-979.1-30.753h602.373V369.673H-979.1Z" transform="translate(979.1 30.753)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3387" data-name="Сгруппировать 3387" transform="translate(-424.261 -13.001)">
                    <path id="Контур_3068" data-name="Контур 3068" d="M-979.1-30.753h602.373V369.673H-979.1Z" transform="translate(979.1 30.753)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3402" data-name="Сгруппировать 3402" transform="translate(428.499 -1089.18)" clip-path="url(#clip-path-1124)">
        <g id="Сгруппировать_3401" data-name="Сгруппировать 3401" transform="translate(-807.933 -292.516)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3400" data-name="Сгруппировать 3400" transform="translate(807.786 292.086)" style="isolation: isolate">
            <g id="Сгруппировать_3399" data-name="Сгруппировать 3399" clip-path="url(#clip-path-1126)">
              <g id="Сгруппировать_3398" data-name="Сгруппировать 3398" transform="translate(0.147 0.431)" clip-path="url(#clip-path-1124)">
                <g id="Сгруппировать_3397" data-name="Сгруппировать 3397" transform="translate(-0.147 -0.431)" clip-path="url(#clip-path-1128)">
                  <g id="Сгруппировать_3395" data-name="Сгруппировать 3395" transform="translate(-428.161 -13.001)" opacity="0">
                    <g id="Сгруппировать_3394" data-name="Сгруппировать 3394" transform="translate(0 0)">
                      <path id="Контур_3073" data-name="Контур 3073" d="M-988.34-30.994h602.373V369.432H-988.34Z" transform="translate(988.34 30.994)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3396" data-name="Сгруппировать 3396" transform="translate(-428.161 -13.001)">
                    <path id="Контур_3074" data-name="Контур 3074" d="M-988.34-30.994h602.373V369.432H-988.34Z" transform="translate(988.34 30.994)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3411" data-name="Сгруппировать 3411" transform="translate(435.923 -1089.18)" clip-path="url(#clip-path-1129)">
        <g id="Сгруппировать_3410" data-name="Сгруппировать 3410" transform="translate(-815.357 -292.516)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3409" data-name="Сгруппировать 3409" transform="translate(815.153 292.086)" style="isolation: isolate">
            <g id="Сгруппировать_3408" data-name="Сгруппировать 3408" transform="translate(0)" clip-path="url(#clip-path-1109)">
              <g id="Сгруппировать_3407" data-name="Сгруппировать 3407" transform="translate(0.204 0.431)" clip-path="url(#clip-path-1129)">
                <g id="Сгруппировать_3406" data-name="Сгруппировать 3406" transform="translate(-0.204 -0.431)" clip-path="url(#clip-path-1133)">
                  <g id="Сгруппировать_3404" data-name="Сгруппировать 3404" transform="translate(-435.528 -13.001)" opacity="0">
                    <g id="Сгруппировать_3403" data-name="Сгруппировать 3403" transform="translate(0 0)">
                      <path id="Контур_3079" data-name="Контур 3079" d="M-1005.47-30.994H-403.1V369.432H-1005.47Z" transform="translate(1005.47 30.994)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3405" data-name="Сгруппировать 3405" transform="translate(-435.528 -13.001)">
                    <path id="Контур_3080" data-name="Контур 3080" d="M-1005.47-30.994H-403.1V369.432H-1005.47Z" transform="translate(1005.47 30.994)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3420" data-name="Сгруппировать 3420" transform="translate(440.092 -1089.18)" clip-path="url(#clip-path-1134)">
        <g id="Сгруппировать_3419" data-name="Сгруппировать 3419" transform="translate(-819.526 -292.516)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3418" data-name="Сгруппировать 3418" transform="translate(819.487 292.086)" style="isolation: isolate">
            <g id="Сгруппировать_3417" data-name="Сгруппировать 3417" transform="translate(0)" clip-path="url(#clip-path-1109)">
              <g id="Сгруппировать_3416" data-name="Сгруппировать 3416" transform="translate(0.039 0.431)" clip-path="url(#clip-path-1134)">
                <g id="Сгруппировать_3415" data-name="Сгруппировать 3415" transform="translate(-0.039 -0.431)" clip-path="url(#clip-path-1138)">
                  <g id="Сгруппировать_3413" data-name="Сгруппировать 3413" transform="translate(-439.862 -13.001)" opacity="0">
                    <g id="Сгруппировать_3412" data-name="Сгруппировать 3412" transform="translate(0 0)">
                      <path id="Контур_3085" data-name="Контур 3085" d="M-1015.09-30.994h602.373V369.432H-1015.09Z" transform="translate(1015.09 30.994)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3414" data-name="Сгруппировать 3414" transform="translate(-439.862 -13.001)">
                    <path id="Контур_3086" data-name="Контур 3086" d="M-1015.09-30.994h602.373V369.432H-1015.09Z" transform="translate(1015.09 30.994)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3429" data-name="Сгруппировать 3429" transform="translate(443.962 -1089.295)" clip-path="url(#clip-path-1139)">
        <g id="Сгруппировать_3428" data-name="Сгруппировать 3428" transform="translate(-823.395 -292.402)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3427" data-name="Сгруппировать 3427" transform="translate(823.387 292.086)" style="isolation: isolate">
            <g id="Сгруппировать_3426" data-name="Сгруппировать 3426" clip-path="url(#clip-path-1141)">
              <g id="Сгруппировать_3425" data-name="Сгруппировать 3425" transform="translate(0.009 0.316)" clip-path="url(#clip-path-1139)">
                <g id="Сгруппировать_3424" data-name="Сгруппировать 3424" transform="translate(-0.009 -0.316)" clip-path="url(#clip-path-1143)">
                  <g id="Сгруппировать_3422" data-name="Сгруппировать 3422" transform="translate(-443.762 -13.001)" opacity="0">
                    <g id="Сгруппировать_3421" data-name="Сгруппировать 3421" transform="translate(0 0)">
                      <path id="Контур_3091" data-name="Контур 3091" d="M-1024.02-30.729h602.372V369.7H-1024.02Z" transform="translate(1024.02 30.729)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3423" data-name="Сгруппировать 3423" transform="translate(-443.762 -13.001)">
                    <path id="Контур_3092" data-name="Контур 3092" d="M-1024.02-30.729h602.372V369.7H-1024.02Z" transform="translate(1024.02 30.729)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3438" data-name="Сгруппировать 3438" transform="translate(450.025 -1089.295)" clip-path="url(#clip-path-1144)">
        <g id="Сгруппировать_3437" data-name="Сгруппировать 3437" transform="translate(-829.458 -292.402)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3436" data-name="Сгруппировать 3436" transform="translate(829.454 292.086)" style="isolation: isolate">
            <g id="Сгруппировать_3435" data-name="Сгруппировать 3435" clip-path="url(#clip-path-1141)">
              <g id="Сгруппировать_3434" data-name="Сгруппировать 3434" transform="translate(0.004 0.316)" clip-path="url(#clip-path-1144)">
                <g id="Сгруппировать_3433" data-name="Сгруппировать 3433" transform="translate(-0.004 -0.316)" clip-path="url(#clip-path-1148)">
                  <g id="Сгруппировать_3431" data-name="Сгруппировать 3431" transform="translate(-449.829 -13.001)" opacity="0">
                    <g id="Сгруппировать_3430" data-name="Сгруппировать 3430" transform="translate(0 0)">
                      <path id="Контур_3097" data-name="Контур 3097" d="M-1038.01-30.729h602.373V369.7H-1038.01Z" transform="translate(1038.01 30.729)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3432" data-name="Сгруппировать 3432" transform="translate(-449.829 -13.001)">
                    <path id="Контур_3098" data-name="Контур 3098" d="M-1038.01-30.729h602.373V369.7H-1038.01Z" transform="translate(1038.01 30.729)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3447" data-name="Сгруппировать 3447" transform="translate(456.313 -1089.18)" clip-path="url(#clip-path-1149)">
        <g id="Сгруппировать_3446" data-name="Сгруппировать 3446" transform="translate(-835.746 -292.516)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3445" data-name="Сгруппировать 3445" transform="translate(835.521 292.086)" style="isolation: isolate">
            <g id="Сгруппировать_3444" data-name="Сгруппировать 3444" transform="translate(0)" clip-path="url(#clip-path-1116)">
              <g id="Сгруппировать_3443" data-name="Сгруппировать 3443" transform="translate(0.225 0.431)" clip-path="url(#clip-path-1149)">
                <g id="Сгруппировать_3442" data-name="Сгруппировать 3442" transform="translate(-0.225 -0.431)" clip-path="url(#clip-path-1153)">
                  <g id="Сгруппировать_3440" data-name="Сгруппировать 3440" transform="translate(-455.896 -13.001)" opacity="0">
                    <g id="Сгруппировать_3439" data-name="Сгруппировать 3439" transform="translate(0 0)">
                      <path id="Контур_3103" data-name="Контур 3103" d="M-1052.52-30.994h602.372V369.432H-1052.52Z" transform="translate(1052.52 30.994)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3441" data-name="Сгруппировать 3441" transform="translate(-455.896 -13.001)">
                    <path id="Контур_3104" data-name="Контур 3104" d="M-1052.52-30.994h602.372V369.432H-1052.52Z" transform="translate(1052.52 30.994)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3456" data-name="Сгруппировать 3456" transform="translate(29.005 -1081.836)" clip-path="url(#clip-path-1154)">
        <g id="Сгруппировать_3455" data-name="Сгруппировать 3455" transform="translate(-408.438 -299.861)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3454" data-name="Сгруппировать 3454" transform="translate(408.227 299.453)" style="isolation: isolate">
            <g id="Сгруппировать_3453" data-name="Сгруппировать 3453" transform="translate(0)" clip-path="url(#clip-path-1156)">
              <g id="Сгруппировать_3452" data-name="Сгруппировать 3452" transform="translate(0.212 0.408)" clip-path="url(#clip-path-1154)">
                <g id="Сгруппировать_3451" data-name="Сгруппировать 3451" transform="translate(-0.212 -0.408)" clip-path="url(#clip-path-1158)">
                  <g id="Сгруппировать_3449" data-name="Сгруппировать 3449" transform="translate(-28.602 -20.368)" opacity="0">
                    <g id="Сгруппировать_3448" data-name="Сгруппировать 3448" transform="translate(0 0)">
                      <path id="Контур_3109" data-name="Контур 3109" d="M-66.489-47.941H535.883V352.485H-66.489Z" transform="translate(66.489 47.941)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3450" data-name="Сгруппировать 3450" transform="translate(-28.602 -20.368)">
                    <path id="Контур_3110" data-name="Контур 3110" d="M-66.489-47.941H535.883V352.485H-66.489Z" transform="translate(66.489 47.941)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3465" data-name="Сгруппировать 3465" transform="translate(39.138 -1081.836)" clip-path="url(#clip-path-1159)">
        <g id="Сгруппировать_3464" data-name="Сгруппировать 3464" transform="translate(-418.572 -299.861)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3463" data-name="Сгруппировать 3463" transform="translate(418.194 299.453)" style="isolation: isolate">
            <g id="Сгруппировать_3462" data-name="Сгруппировать 3462" clip-path="url(#clip-path-1156)">
              <g id="Сгруппировать_3461" data-name="Сгруппировать 3461" transform="translate(0.378 0.408)" clip-path="url(#clip-path-1159)">
                <g id="Сгруппировать_3460" data-name="Сгруппировать 3460" transform="translate(-0.378 -0.408)" clip-path="url(#clip-path-1163)">
                  <g id="Сгруппировать_3458" data-name="Сгруппировать 3458" transform="translate(-38.569 -20.368)" opacity="0">
                    <g id="Сгруппировать_3457" data-name="Сгруппировать 3457" transform="translate(0 0)">
                      <path id="Контур_3115" data-name="Контур 3115" d="M-89.873-47.941H512.5V352.485H-89.873Z" transform="translate(89.873 47.941)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3459" data-name="Сгруппировать 3459" transform="translate(-38.569 -20.368)">
                    <path id="Контур_3116" data-name="Контур 3116" d="M-89.873-47.941H512.5V352.485H-89.873Z" transform="translate(89.873 47.941)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3474" data-name="Сгруппировать 3474" transform="translate(48.547 -1082.114)" clip-path="url(#clip-path-1164)">
        <g id="Сгруппировать_3473" data-name="Сгруппировать 3473" transform="translate(-427.98 -299.583)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3472" data-name="Сгруппировать 3472" transform="translate(427.728 299.453)" style="isolation: isolate">
            <g id="Сгруппировать_3471" data-name="Сгруппировать 3471" transform="translate(0)" clip-path="url(#clip-path-1166)">
              <g id="Сгруппировать_3470" data-name="Сгруппировать 3470" transform="translate(0.253 0.13)" clip-path="url(#clip-path-1164)">
                <g id="Сгруппировать_3469" data-name="Сгруппировать 3469" transform="translate(-0.253 -0.13)" clip-path="url(#clip-path-1168)">
                  <g id="Сгруппировать_3467" data-name="Сгруппировать 3467" transform="translate(-48.103 -20.368)" opacity="0">
                    <g id="Сгруппировать_3466" data-name="Сгруппировать 3466" transform="translate(0 0)">
                      <path id="Контур_3121" data-name="Контур 3121" d="M-111.583-47.3H490.79V353.126H-111.583Z" transform="translate(111.583 47.3)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3468" data-name="Сгруппировать 3468" transform="translate(-48.103 -20.368)">
                    <path id="Контур_3122" data-name="Контур 3122" d="M-111.583-47.3H490.79V353.126H-111.583Z" transform="translate(111.583 47.3)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3483" data-name="Сгруппировать 3483" transform="translate(63.288 -1082.114)" clip-path="url(#clip-path-1169)">
        <g id="Сгруппировать_3482" data-name="Сгруппировать 3482" transform="translate(-442.722 -299.583)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3481" data-name="Сгруппировать 3481" transform="translate(442.462 299.453)" style="isolation: isolate">
            <g id="Сгруппировать_3480" data-name="Сгруппировать 3480" transform="translate(0)" clip-path="url(#clip-path-1166)">
              <g id="Сгруппировать_3479" data-name="Сгруппировать 3479" transform="translate(0.26 0.13)" clip-path="url(#clip-path-1169)">
                <g id="Сгруппировать_3478" data-name="Сгруппировать 3478" transform="translate(-0.26 -0.13)" clip-path="url(#clip-path-1173)">
                  <g id="Сгруппировать_3476" data-name="Сгруппировать 3476" transform="translate(-62.837 -20.368)" opacity="0">
                    <g id="Сгруппировать_3475" data-name="Сгруппировать 3475" transform="translate(0 0)">
                      <path id="Контур_3127" data-name="Контур 3127" d="M-145.6-47.3H456.773V353.126H-145.6Z" transform="translate(145.6 47.3)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3477" data-name="Сгруппировать 3477" transform="translate(-62.837 -20.368)">
                    <path id="Контур_3128" data-name="Контур 3128" d="M-145.6-47.3H456.773V353.126H-145.6Z" transform="translate(145.6 47.3)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3492" data-name="Сгруппировать 3492" transform="translate(78.573 -1081.836)" clip-path="url(#clip-path-1174)">
        <g id="Сгруппировать_3491" data-name="Сгруппировать 3491" transform="translate(-458.007 -299.861)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3490" data-name="Сгруппировать 3490" transform="translate(457.63 299.453)" style="isolation: isolate">
            <g id="Сгруппировать_3489" data-name="Сгруппировать 3489" transform="translate(0)" clip-path="url(#clip-path-1176)">
              <g id="Сгруппировать_3488" data-name="Сгруппировать 3488" transform="translate(0.377 0.408)" clip-path="url(#clip-path-1174)">
                <g id="Сгруппировать_3487" data-name="Сгруппировать 3487" transform="translate(-0.377 -0.408)" clip-path="url(#clip-path-1178)">
                  <g id="Сгруппировать_3485" data-name="Сгруппировать 3485" transform="translate(-78.005 -20.368)" opacity="0">
                    <g id="Сгруппировать_3484" data-name="Сгруппировать 3484" transform="translate(0 0)">
                      <path id="Контур_3133" data-name="Контур 3133" d="M-180.87-47.941H421.5V352.485H-180.87Z" transform="translate(180.87 47.941)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3486" data-name="Сгруппировать 3486" transform="translate(-78.005 -20.368)">
                    <path id="Контур_3134" data-name="Контур 3134" d="M-180.87-47.941H421.5V352.485H-180.87Z" transform="translate(180.87 47.941)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3501" data-name="Сгруппировать 3501" transform="translate(97.073 -1081.836)" clip-path="url(#clip-path-1179)">
        <g id="Сгруппировать_3500" data-name="Сгруппировать 3500" transform="translate(-476.507 -299.861)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3499" data-name="Сгруппировать 3499" transform="translate(476.264 299.453)" style="isolation: isolate">
            <g id="Сгруппировать_3498" data-name="Сгруппировать 3498" transform="translate(0)" clip-path="url(#clip-path-1181)">
              <g id="Сгруппировать_3497" data-name="Сгруппировать 3497" transform="translate(0.243 0.408)" clip-path="url(#clip-path-1179)">
                <g id="Сгруппировать_3496" data-name="Сгруппировать 3496" transform="translate(-0.243 -0.408)" clip-path="url(#clip-path-1183)">
                  <g id="Сгруппировать_3494" data-name="Сгруппировать 3494" transform="translate(-96.64 -20.368)" opacity="0">
                    <g id="Сгруппировать_3493" data-name="Сгруппировать 3493" transform="translate(0 0)">
                      <path id="Контур_3139" data-name="Контур 3139" d="M-223.56-47.941H378.812V352.485H-223.56Z" transform="translate(223.56 47.941)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3495" data-name="Сгруппировать 3495" transform="translate(-96.64 -20.368)">
                    <path id="Контур_3140" data-name="Контур 3140" d="M-223.56-47.941H378.812V352.485H-223.56Z" transform="translate(223.56 47.941)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3510" data-name="Сгруппировать 3510" transform="translate(108.939 -1081.836)" clip-path="url(#clip-path-1184)">
        <g id="Сгруппировать_3509" data-name="Сгруппировать 3509" transform="translate(-488.372 -299.861)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3508" data-name="Сгруппировать 3508" transform="translate(487.965 299.453)" style="isolation: isolate">
            <g id="Сгруппировать_3507" data-name="Сгруппировать 3507" transform="translate(0)" clip-path="url(#clip-path-1186)">
              <g id="Сгруппировать_3506" data-name="Сгруппировать 3506" transform="translate(0.407 0.408)" clip-path="url(#clip-path-1184)">
                <g id="Сгруппировать_3505" data-name="Сгруппировать 3505" transform="translate(-0.407 -0.408)" clip-path="url(#clip-path-1188)">
                  <g id="Сгруппировать_3503" data-name="Сгруппировать 3503" transform="translate(-108.34 -20.368)" opacity="0">
                    <g id="Сгруппировать_3502" data-name="Сгруппировать 3502" transform="translate(0 0)">
                      <path id="Контур_3145" data-name="Контур 3145" d="M-250.94-47.941H351.432V352.485H-250.94Z" transform="translate(250.94 47.941)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3504" data-name="Сгруппировать 3504" transform="translate(-108.34 -20.368)">
                    <path id="Контур_3146" data-name="Контур 3146" d="M-250.94-47.941H351.432V352.485H-250.94Z" transform="translate(250.94 47.941)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3519" data-name="Сгруппировать 3519" transform="translate(117.329 -1081.908)" clip-path="url(#clip-path-1189)">
        <g id="Сгруппировать_3518" data-name="Сгруппировать 3518" transform="translate(-496.762 -299.788)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3517" data-name="Сгруппировать 3517" transform="translate(496.632 299.453)" style="isolation: isolate">
            <g id="Сгруппировать_3516" data-name="Сгруппировать 3516" transform="translate(0)" clip-path="url(#clip-path-1191)">
              <g id="Сгруппировать_3515" data-name="Сгруппировать 3515" transform="translate(0.13 0.335)" clip-path="url(#clip-path-1189)">
                <g id="Сгруппировать_3514" data-name="Сгруппировать 3514" transform="translate(-0.13 -0.335)" clip-path="url(#clip-path-1193)">
                  <g id="Сгруппировать_3512" data-name="Сгруппировать 3512" transform="translate(-117.008 -20.368)" opacity="0">
                    <g id="Сгруппировать_3511" data-name="Сгруппировать 3511" transform="translate(0 0)">
                      <path id="Контур_3151" data-name="Контур 3151" d="M-270.3-47.774H332.073V352.652H-270.3Z" transform="translate(270.3 47.774)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3513" data-name="Сгруппировать 3513" transform="translate(-117.008 -20.368)">
                    <path id="Контур_3152" data-name="Контур 3152" d="M-270.3-47.774H332.073V352.652H-270.3Z" transform="translate(270.3 47.774)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3528" data-name="Сгруппировать 3528" transform="translate(132.119 -1081.836)" clip-path="url(#clip-path-1194)">
        <g id="Сгруппировать_3527" data-name="Сгруппировать 3527" transform="translate(-511.553 -299.861)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3526" data-name="Сгруппировать 3526" transform="translate(511.367 299.453)" style="isolation: isolate">
            <g id="Сгруппировать_3525" data-name="Сгруппировать 3525" transform="translate(0)" clip-path="url(#clip-path-1196)">
              <g id="Сгруппировать_3524" data-name="Сгруппировать 3524" transform="translate(0.186 0.408)" clip-path="url(#clip-path-1194)">
                <g id="Сгруппировать_3523" data-name="Сгруппировать 3523" transform="translate(-0.186 -0.408)" clip-path="url(#clip-path-1198)">
                  <g id="Сгруппировать_3521" data-name="Сгруппировать 3521" transform="translate(-131.742 -20.368)" opacity="0">
                    <g id="Сгруппировать_3520" data-name="Сгруппировать 3520" transform="translate(0 0)">
                      <path id="Контур_3157" data-name="Контур 3157" d="M-304.43-47.941H297.942V352.485H-304.43Z" transform="translate(304.43 47.941)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3522" data-name="Сгруппировать 3522" transform="translate(-131.742 -20.368)">
                    <path id="Контур_3158" data-name="Контур 3158" d="M-304.43-47.941H297.942V352.485H-304.43Z" transform="translate(304.43 47.941)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3537" data-name="Сгруппировать 3537" transform="translate(403.984 -920.067)" clip-path="url(#clip-path-1199)">
        <g id="Сгруппировать_3536" data-name="Сгруппировать 3536" transform="translate(-783.418 -461.63)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3535" data-name="Сгруппировать 3535" transform="translate(783.084 461.53)" style="isolation: isolate">
            <g id="Сгруппировать_3534" data-name="Сгруппировать 3534" clip-path="url(#clip-path-1126)">
              <g id="Сгруппировать_3533" data-name="Сгруппировать 3533" transform="translate(0.334 0.1)" clip-path="url(#clip-path-1199)">
                <g id="Сгруппировать_3532" data-name="Сгруппировать 3532" transform="translate(-0.334 -0.1)" clip-path="url(#clip-path-1203)">
                  <g id="Сгруппировать_3530" data-name="Сгруппировать 3530" transform="translate(-403.46 -182.445)" opacity="0">
                    <g id="Сгруппировать_3529" data-name="Сгруппировать 3529" transform="translate(0 0)">
                      <path id="Контур_3163" data-name="Контур 3163" d="M-931.77-421.23H-329.4V-20.8H-931.77Z" transform="translate(931.77 421.23)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3531" data-name="Сгруппировать 3531" transform="translate(-403.46 -182.445)">
                    <path id="Контур_3164" data-name="Контур 3164" d="M-931.77-421.23H-329.4V-20.8H-931.77Z" transform="translate(931.77 421.23)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3546" data-name="Сгруппировать 3546" transform="translate(408.495 -919.963)" clip-path="url(#clip-path-1204)">
        <g id="Сгруппировать_3545" data-name="Сгруппировать 3545" transform="translate(-787.929 -461.734)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3544" data-name="Сгруппировать 3544" transform="translate(787.851 461.53)" style="isolation: isolate">
            <g id="Сгруппировать_3543" data-name="Сгруппировать 3543" clip-path="url(#clip-path-1109)">
              <g id="Сгруппировать_3542" data-name="Сгруппировать 3542" transform="translate(0.078 0.204)" clip-path="url(#clip-path-1204)">
                <g id="Сгруппировать_3541" data-name="Сгруппировать 3541" transform="translate(-0.078 -0.204)" clip-path="url(#clip-path-1208)">
                  <g id="Сгруппировать_3539" data-name="Сгруппировать 3539" transform="translate(-408.227 -182.445)" opacity="0">
                    <g id="Сгруппировать_3538" data-name="Сгруппировать 3538" transform="translate(0 0)">
                      <path id="Контур_3169" data-name="Контур 3169" d="M-942.18-421.47h602.372V-21.044H-942.18Z" transform="translate(942.18 421.47)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3540" data-name="Сгруппировать 3540" transform="translate(-408.227 -182.445)">
                    <path id="Контур_3170" data-name="Контур 3170" d="M-942.18-421.47h602.372V-21.044H-942.18Z" transform="translate(942.18 421.47)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3555" data-name="Сгруппировать 3555" transform="translate(412.608 -920.067)" clip-path="url(#clip-path-1209)">
        <g id="Сгруппировать_3554" data-name="Сгруппировать 3554" transform="translate(-792.042 -461.63)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3553" data-name="Сгруппировать 3553" transform="translate(791.751 461.53)" style="isolation: isolate">
            <g id="Сгруппировать_3552" data-name="Сгруппировать 3552" transform="translate(0)" clip-path="url(#clip-path-1116)">
              <g id="Сгруппировать_3551" data-name="Сгруппировать 3551" transform="translate(0.29 0.1)" clip-path="url(#clip-path-1209)">
                <g id="Сгруппировать_3550" data-name="Сгруппировать 3550" transform="translate(-0.29 -0.1)" clip-path="url(#clip-path-1213)">
                  <g id="Сгруппировать_3548" data-name="Сгруппировать 3548" transform="translate(-412.127 -182.445)" opacity="0">
                    <g id="Сгруппировать_3547" data-name="Сгруппировать 3547" transform="translate(0 0)">
                      <path id="Контур_3175" data-name="Контур 3175" d="M-951.67-421.23H-349.3V-20.8H-951.67Z" transform="translate(951.67 421.23)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3549" data-name="Сгруппировать 3549" transform="translate(-412.127 -182.445)">
                    <path id="Контур_3176" data-name="Контур 3176" d="M-951.67-421.23H-349.3V-20.8H-951.67Z" transform="translate(951.67 421.23)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3564" data-name="Сгруппировать 3564" transform="translate(417.171 -920.076)" clip-path="url(#clip-path-1214)">
        <g id="Сгруппировать_3563" data-name="Сгруппировать 3563" transform="translate(-796.605 -461.621)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3562" data-name="Сгруппировать 3562" transform="translate(796.518 461.53)" style="isolation: isolate">
            <g id="Сгруппировать_3561" data-name="Сгруппировать 3561" clip-path="url(#clip-path-376)">
              <g id="Сгруппировать_3560" data-name="Сгруппировать 3560" transform="translate(0.087 0.091)" clip-path="url(#clip-path-1214)">
                <g id="Сгруппировать_3559" data-name="Сгруппировать 3559" transform="translate(-0.087 -0.091)" clip-path="url(#clip-path-1218)">
                  <g id="Сгруппировать_3557" data-name="Сгруппировать 3557" transform="translate(-416.894 -182.445)" opacity="0">
                    <g id="Сгруппировать_3556" data-name="Сгруппировать 3556" transform="translate(0 0)">
                      <path id="Контур_3181" data-name="Контур 3181" d="M-962.2-421.21h602.372V-20.784H-962.2Z" transform="translate(962.2 421.21)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3558" data-name="Сгруппировать 3558" transform="translate(-416.894 -182.445)">
                    <path id="Контур_3182" data-name="Контур 3182" d="M-962.2-421.21h602.372V-20.784H-962.2Z" transform="translate(962.2 421.21)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3573" data-name="Сгруппировать 3573" transform="translate(423.455 -919.963)" clip-path="url(#clip-path-1219)">
        <g id="Сгруппировать_3572" data-name="Сгруппировать 3572" transform="translate(-802.889 -461.734)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3571" data-name="Сгруппировать 3571" transform="translate(802.585 461.53)" style="isolation: isolate">
            <g id="Сгруппировать_3570" data-name="Сгруппировать 3570" clip-path="url(#clip-path-376)">
              <g id="Сгруппировать_3569" data-name="Сгруппировать 3569" transform="translate(0.303 0.204)" clip-path="url(#clip-path-1219)">
                <g id="Сгруппировать_3568" data-name="Сгруппировать 3568" transform="translate(-0.303 -0.204)" clip-path="url(#clip-path-1223)">
                  <g id="Сгруппировать_3566" data-name="Сгруппировать 3566" transform="translate(-422.961 -182.445)" opacity="0">
                    <g id="Сгруппировать_3565" data-name="Сгруппировать 3565" transform="translate(0 0)">
                      <path id="Контур_3187" data-name="Контур 3187" d="M-976.7-421.47h602.372V-21.044H-976.7Z" transform="translate(976.7 421.47)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3567" data-name="Сгруппировать 3567" transform="translate(-422.961 -182.445)">
                    <path id="Контур_3188" data-name="Контур 3188" d="M-976.7-421.47h602.372V-21.044H-976.7Z" transform="translate(976.7 421.47)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3582" data-name="Сгруппировать 3582" transform="translate(429.834 -919.963)" clip-path="url(#clip-path-1224)">
        <g id="Сгруппировать_3581" data-name="Сгруппировать 3581" transform="translate(-809.268 -461.734)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3580" data-name="Сгруппировать 3580" transform="translate(809.086 461.53)" style="isolation: isolate">
            <g id="Сгруппировать_3579" data-name="Сгруппировать 3579" transform="translate(0)" clip-path="url(#clip-path-1226)">
              <g id="Сгруппировать_3578" data-name="Сгруппировать 3578" transform="translate(0.182 0.204)" clip-path="url(#clip-path-1224)">
                <g id="Сгруппировать_3577" data-name="Сгруппировать 3577" transform="translate(-0.182 -0.204)" clip-path="url(#clip-path-1228)">
                  <g id="Сгруппировать_3575" data-name="Сгруппировать 3575" transform="translate(-429.461 -182.445)" opacity="0">
                    <g id="Сгруппировать_3574" data-name="Сгруппировать 3574" transform="translate(0 0)">
                      <path id="Контур_3193" data-name="Контур 3193" d="M-991.42-421.47h602.373V-21.044H-991.42Z" transform="translate(991.42 421.47)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3576" data-name="Сгруппировать 3576" transform="translate(-429.461 -182.445)">
                    <path id="Контур_3194" data-name="Контур 3194" d="M-991.42-421.47h602.373V-21.044H-991.42Z" transform="translate(991.42 421.47)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3591" data-name="Сгруппировать 3591" transform="translate(438.033 -919.963)" clip-path="url(#clip-path-1229)">
        <g id="Сгруппировать_3590" data-name="Сгруппировать 3590" transform="translate(-817.467 -461.734)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3589" data-name="Сгруппировать 3589" transform="translate(817.32 461.53)" style="isolation: isolate">
            <g id="Сгруппировать_3588" data-name="Сгруппировать 3588" transform="translate(0)" clip-path="url(#clip-path-1109)">
              <g id="Сгруппировать_3587" data-name="Сгруппировать 3587" transform="translate(0.147 0.204)" clip-path="url(#clip-path-1229)">
                <g id="Сгруппировать_3586" data-name="Сгруппировать 3586" transform="translate(-0.147 -0.204)" clip-path="url(#clip-path-1233)">
                  <g id="Сгруппировать_3584" data-name="Сгруппировать 3584" transform="translate(-437.695 -182.445)" opacity="0">
                    <g id="Сгруппировать_3583" data-name="Сгруппировать 3583" transform="translate(0 0)">
                      <path id="Контур_3199" data-name="Контур 3199" d="M-1010.34-421.47h602.373V-21.044H-1010.34Z" transform="translate(1010.34 421.47)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3585" data-name="Сгруппировать 3585" transform="translate(-437.695 -182.445)">
                    <path id="Контур_3200" data-name="Контур 3200" d="M-1010.34-421.47h602.373V-21.044H-1010.34Z" transform="translate(1010.34 421.47)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3600" data-name="Сгруппировать 3600" transform="translate(442.202 -919.963)" clip-path="url(#clip-path-1234)">
        <g id="Сгруппировать_3599" data-name="Сгруппировать 3599" transform="translate(-821.636 -461.734)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3598" data-name="Сгруппировать 3598" transform="translate(821.22 461.53)" style="isolation: isolate">
            <g id="Сгруппировать_3597" data-name="Сгруппировать 3597" clip-path="url(#clip-path-1236)">
              <g id="Сгруппировать_3596" data-name="Сгруппировать 3596" transform="translate(0.416 0.204)" clip-path="url(#clip-path-1234)">
                <g id="Сгруппировать_3595" data-name="Сгруппировать 3595" transform="translate(-0.416 -0.204)" clip-path="url(#clip-path-1238)">
                  <g id="Сгруппировать_3593" data-name="Сгруппировать 3593" transform="translate(-441.595 -182.445)" opacity="0">
                    <g id="Сгруппировать_3592" data-name="Сгруппировать 3592" transform="translate(0 0)">
                      <path id="Контур_3205" data-name="Контур 3205" d="M-1019.96-421.47h602.373V-21.044H-1019.96Z" transform="translate(1019.96 421.47)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3594" data-name="Сгруппировать 3594" transform="translate(-441.595 -182.445)">
                    <path id="Контур_3206" data-name="Контур 3206" d="M-1019.96-421.47h602.373V-21.044H-1019.96Z" transform="translate(1019.96 421.47)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3609" data-name="Сгруппировать 3609" transform="translate(446.072 -920.076)" clip-path="url(#clip-path-1239)">
        <g id="Сгруппировать_3608" data-name="Сгруппировать 3608" transform="translate(-825.506 -461.621)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3607" data-name="Сгруппировать 3607" transform="translate(825.12 461.53)" style="isolation: isolate">
            <g id="Сгруппировать_3606" data-name="Сгруппировать 3606" clip-path="url(#clip-path-376)">
              <g id="Сгруппировать_3605" data-name="Сгруппировать 3605" transform="translate(0.386 0.091)" clip-path="url(#clip-path-1239)">
                <g id="Сгруппировать_3604" data-name="Сгруппировать 3604" transform="translate(-0.386 -0.091)" clip-path="url(#clip-path-1243)">
                  <g id="Сгруппировать_3602" data-name="Сгруппировать 3602" transform="translate(-445.496 -182.445)" opacity="0">
                    <g id="Сгруппировать_3601" data-name="Сгруппировать 3601" transform="translate(0 0)">
                      <path id="Контур_3211" data-name="Контур 3211" d="M-1028.89-421.21h602.373V-20.784H-1028.89Z" transform="translate(1028.89 421.21)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3603" data-name="Сгруппировать 3603" transform="translate(-445.496 -182.445)">
                    <path id="Контур_3212" data-name="Контур 3212" d="M-1028.89-421.21h602.373V-20.784H-1028.89Z" transform="translate(1028.89 421.21)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3618" data-name="Сгруппировать 3618" transform="translate(452.135 -920.076)" clip-path="url(#clip-path-1244)">
        <g id="Сгруппировать_3617" data-name="Сгруппировать 3617" transform="translate(-831.569 -461.621)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3616" data-name="Сгруппировать 3616" transform="translate(831.187 461.53)" style="isolation: isolate">
            <g id="Сгруппировать_3615" data-name="Сгруппировать 3615" clip-path="url(#clip-path-376)">
              <g id="Сгруппировать_3614" data-name="Сгруппировать 3614" transform="translate(0.381 0.091)" clip-path="url(#clip-path-1244)">
                <g id="Сгруппировать_3613" data-name="Сгруппировать 3613" transform="translate(-0.381 -0.091)" clip-path="url(#clip-path-1248)">
                  <g id="Сгруппировать_3611" data-name="Сгруппировать 3611" transform="translate(-451.563 -182.445)" opacity="0">
                    <g id="Сгруппировать_3610" data-name="Сгруппировать 3610" transform="translate(0 0)">
                      <path id="Контур_3217" data-name="Контур 3217" d="M-1042.88-421.21h602.373V-20.784H-1042.88Z" transform="translate(1042.88 421.21)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3612" data-name="Сгруппировать 3612" transform="translate(-451.563 -182.445)">
                    <path id="Контур_3218" data-name="Контур 3218" d="M-1042.88-421.21h602.373V-20.784H-1042.88Z" transform="translate(1042.88 421.21)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3627" data-name="Сгруппировать 3627" transform="translate(458.423 -919.963)" clip-path="url(#clip-path-1249)">
        <g id="Сгруппировать_3626" data-name="Сгруппировать 3626" transform="translate(-837.857 -461.734)" clip-path="url(#clip-path-4)">
          <g id="Сгруппировать_3625" data-name="Сгруппировать 3625" transform="translate(837.688 461.53)" style="isolation: isolate">
            <g id="Сгруппировать_3624" data-name="Сгруппировать 3624" clip-path="url(#clip-path-1116)">
              <g id="Сгруппировать_3623" data-name="Сгруппировать 3623" transform="translate(0.169 0.204)" clip-path="url(#clip-path-1249)">
                <g id="Сгруппировать_3622" data-name="Сгруппировать 3622" transform="translate(-0.169 -0.204)" clip-path="url(#clip-path-1253)">
                  <g id="Сгруппировать_3620" data-name="Сгруппировать 3620" transform="translate(-458.063 -182.445)" opacity="0">
                    <g id="Сгруппировать_3619" data-name="Сгруппировать 3619" transform="translate(0 0)">
                      <path id="Контур_3223" data-name="Контур 3223" d="M-1057.39-421.47h602.372V-21.044H-1057.39Z" transform="translate(1057.39 421.47)" fill="#c4d3e0"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3621" data-name="Сгруппировать 3621" transform="translate(-458.063 -182.445)">
                    <path id="Контур_3224" data-name="Контур 3224" d="M-1057.39-421.47h602.372V-21.044H-1057.39Z" transform="translate(1057.39 421.47)" fill="#c4d3e0"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3636" data-name="Сгруппировать 3636" transform="translate(54.376 -726.892)" clip-path="url(#clip-path-1254)">
        <g id="Сгруппировать_3635" data-name="Сгруппировать 3635" transform="translate(-433.809 -654.805)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3634" data-name="Сгруппировать 3634" transform="translate(433.362 654.376)" style="isolation: isolate">
            <g id="Сгруппировать_3633" data-name="Сгруппировать 3633" transform="translate(0 0)" clip-path="url(#clip-path-1256)">
              <g id="Сгруппировать_3632" data-name="Сгруппировать 3632" transform="translate(0.448 0.429)" clip-path="url(#clip-path-1254)">
                <g id="Сгруппировать_3631" data-name="Сгруппировать 3631" transform="translate(-0.448 -0.429)" clip-path="url(#clip-path-1258)">
                  <g id="Сгруппировать_3629" data-name="Сгруппировать 3629" transform="translate(-53.737 -375.291)" opacity="0">
                    <g id="Сгруппировать_3628" data-name="Сгруппировать 3628" transform="translate(0 0)">
                      <path id="Контур_3229" data-name="Контур 3229" d="M-125.034-866.99H477.339v400.426H-125.034Z" transform="translate(125.034 866.99)" fill="url(#linear-gradient)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3630" data-name="Сгруппировать 3630" transform="translate(-53.737 -375.291)">
                    <path id="Контур_3230" data-name="Контур 3230" d="M-125.034-866.99H477.339v400.426H-125.034Z" transform="translate(125.034 866.99)" fill="url(#linear-gradient-2)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3645" data-name="Сгруппировать 3645" transform="translate(54.903 -730.259)" clip-path="url(#clip-path-1259)">
        <g id="Сгруппировать_3644" data-name="Сгруппировать 3644" transform="translate(-434.337 -651.438)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3643" data-name="Сгруппировать 3643" transform="translate(434.228 651.342)" style="isolation: isolate">
            <g id="Сгруппировать_3642" data-name="Сгруппировать 3642" transform="translate(0 0)" clip-path="url(#clip-path-1261)">
              <g id="Сгруппировать_3641" data-name="Сгруппировать 3641" transform="translate(0.108 0.095)" clip-path="url(#clip-path-1259)">
                <g id="Сгруппировать_3640" data-name="Сгруппировать 3640" transform="translate(-0.108 -0.095)" clip-path="url(#clip-path-1263)">
                  <g id="Сгруппировать_3638" data-name="Сгруппировать 3638" transform="translate(-54.604 -372.258)" opacity="0">
                    <g id="Сгруппировать_3637" data-name="Сгруппировать 3637" transform="translate(0 0)">
                      <path id="Контур_3235" data-name="Контур 3235" d="M-126.25-859.22H476.122v400.426H-126.25Z" transform="translate(126.25 859.22)" fill="url(#linear-gradient-3)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3639" data-name="Сгруппировать 3639" transform="translate(-54.604 -372.258)">
                    <path id="Контур_3236" data-name="Контур 3236" d="M-126.25-859.22H476.122v400.426H-126.25Z" transform="translate(126.25 859.22)" fill="url(#linear-gradient-4)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3654" data-name="Сгруппировать 3654" transform="translate(39.228 -827.588)" clip-path="url(#clip-path-1264)">
        <g id="Сгруппировать_3653" data-name="Сгруппировать 3653" transform="translate(-418.662 -554.109)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3652" data-name="Сгруппировать 3652" transform="translate(417.76 540.835)" style="isolation: isolate">
            <g id="Сгруппировать_3651" data-name="Сгруппировать 3651" transform="translate(0)" clip-path="url(#clip-path-1266)">
              <g id="Сгруппировать_3650" data-name="Сгруппировать 3650" transform="translate(0.901 13.274)" clip-path="url(#clip-path-1264)">
                <g id="Сгруппировать_3649" data-name="Сгруппировать 3649" transform="translate(-0.901 -2.44)" clip-path="url(#clip-path-1268)">
                  <g id="Сгруппировать_3647" data-name="Сгруппировать 3647" transform="translate(-38.136 -272.584)" opacity="0">
                    <g id="Сгруппировать_3646" data-name="Сгруппировать 3646">
                      <path id="Контур_3241" data-name="Контур 3241" d="M-90.08-634.63H512.293V-234.2H-90.08Z" transform="translate(90.08 634.63)" fill="url(#linear-gradient-5)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3648" data-name="Сгруппировать 3648" transform="translate(-38.136 -272.584)">
                    <path id="Контур_3242" data-name="Контур 3242" d="M-90.08-634.63H512.293V-234.2H-90.08Z" transform="translate(90.08 634.63)" fill="url(#linear-gradient-6)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3663" data-name="Сгруппировать 3663" transform="translate(29.704 -724.612)" clip-path="url(#clip-path-1269)">
        <g id="Сгруппировать_3662" data-name="Сгруппировать 3662" transform="translate(-409.137 -657.084)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3661" data-name="Сгруппировать 3661" transform="translate(408.66 656.976)" style="isolation: isolate">
            <g id="Сгруппировать_3660" data-name="Сгруппировать 3660" transform="translate(0 0)" clip-path="url(#clip-path-1271)">
              <g id="Сгруппировать_3659" data-name="Сгруппировать 3659" transform="translate(0.478 0.108)" clip-path="url(#clip-path-1269)">
                <g id="Сгруппировать_3658" data-name="Сгруппировать 3658" transform="translate(-0.478 -0.108)" clip-path="url(#clip-path-1273)">
                  <g id="Сгруппировать_3656" data-name="Сгруппировать 3656" transform="translate(-29.035 -377.891)" opacity="0">
                    <g id="Сгруппировать_3655" data-name="Сгруппировать 3655">
                      <path id="Контур_3247" data-name="Контур 3247" d="M-68.1-872.25H534.271v400.426H-68.1Z" transform="translate(68.102 872.25)" fill="url(#linear-gradient-7)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3657" data-name="Сгруппировать 3657" transform="translate(-29.035 -377.891)">
                    <path id="Контур_3248" data-name="Контур 3248" d="M-68.1-872.25H534.271v400.426H-68.1Z" transform="translate(68.102 872.25)" fill="url(#linear-gradient-8)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3672" data-name="Сгруппировать 3672" transform="translate(31.078 -731.633)" clip-path="url(#clip-path-1274)">
        <g id="Сгруппировать_3671" data-name="Сгруппировать 3671" transform="translate(-410.512 -650.064)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3670" data-name="Сгруппировать 3670" transform="translate(410.393 650.042)" style="isolation: isolate">
            <g id="Сгруппировать_3669" data-name="Сгруппировать 3669" transform="translate(0 0)" clip-path="url(#clip-path-1276)">
              <g id="Сгруппировать_3668" data-name="Сгруппировать 3668" transform="translate(0.118 0.022)" clip-path="url(#clip-path-1274)">
                <g id="Сгруппировать_3667" data-name="Сгруппировать 3667" transform="translate(-0.118 -0.022)" clip-path="url(#clip-path-1278)">
                  <g id="Сгруппировать_3665" data-name="Сгруппировать 3665" transform="translate(-30.769 -370.957)" opacity="0">
                    <g id="Сгруппировать_3664" data-name="Сгруппировать 3664" transform="translate(0 0)">
                      <path id="Контур_3253" data-name="Контур 3253" d="M-71.273-856.05H531.1v400.426H-71.273Z" transform="translate(71.273 856.05)" fill="url(#linear-gradient-9)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3666" data-name="Сгруппировать 3666" transform="translate(-30.769 -370.957)">
                    <path id="Контур_3254" data-name="Контур 3254" d="M-71.273-856.05H531.1v400.426H-71.273Z" transform="translate(71.273 856.05)" fill="url(#linear-gradient-10)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3681" data-name="Сгруппировать 3681" transform="translate(24.925 -829.23)" clip-path="url(#clip-path-1279)">
        <g id="Сгруппировать_3680" data-name="Сгруппировать 3680" transform="translate(-404.359 -552.467)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3679" data-name="Сгруппировать 3679" transform="translate(400.426 552.103)" style="isolation: isolate">
            <g id="Сгруппировать_3678" data-name="Сгруппировать 3678" transform="translate(0 0)" clip-path="url(#clip-path-1281)">
              <g id="Сгруппировать_3677" data-name="Сгруппировать 3677" transform="translate(3.933 0.364)" clip-path="url(#clip-path-1279)">
                <g id="Сгруппировать_3676" data-name="Сгруппировать 3676" transform="translate(-2.2 -0.364)" clip-path="url(#clip-path-1283)">
                  <g id="Сгруппировать_3674" data-name="Сгруппировать 3674" transform="translate(-22.535 -273.018)" opacity="0">
                    <g id="Сгруппировать_3673" data-name="Сгруппировать 3673" transform="translate(0 0)">
                      <path id="Контур_3259" data-name="Контур 3259" d="M-57.075-630.84H545.3v400.426H-57.075Z" transform="translate(57.075 630.84)" fill="url(#linear-gradient-11)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3675" data-name="Сгруппировать 3675" transform="translate(-22.535 -273.018)">
                    <path id="Контур_3260" data-name="Контур 3260" d="M-57.075-630.84H545.3v400.426H-57.075Z" transform="translate(57.075 630.84)" fill="url(#linear-gradient-12)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3690" data-name="Сгруппировать 3690" transform="translate(24.993 -895.032)" clip-path="url(#clip-path-1284)">
        <g id="Сгруппировать_3689" data-name="Сгруппировать 3689" transform="translate(-404.426 -486.665)" clip-path="url(#clip-path-1285)">
          <g id="Сгруппировать_3688" data-name="Сгруппировать 3688" transform="translate(401.726 486.665)" style="isolation: isolate">
            <g id="Сгруппировать_3687" data-name="Сгруппировать 3687" transform="translate(0)" clip-path="url(#clip-path-1286)">
              <g id="Сгруппировать_3686" data-name="Сгруппировать 3686" transform="translate(2.7)" clip-path="url(#clip-path-1284)">
                <g id="Сгруппировать_3685" data-name="Сгруппировать 3685" transform="translate(-2.267)" clip-path="url(#clip-path-1288)">
                  <g id="Сгруппировать_3683" data-name="Сгруппировать 3683" transform="translate(-22.535 -207.58)" opacity="0">
                    <g id="Сгруппировать_3682" data-name="Сгруппировать 3682">
                      <path id="Контур_3265" data-name="Контур 3265" d="M-57.231-479H545.142V-78.574H-57.231Z" transform="translate(57.231 479)" fill="#7e8299"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3684" data-name="Сгруппировать 3684" transform="translate(-22.535 -207.58)">
                    <path id="Контур_3266" data-name="Контур 3266" d="M-57.231-479H545.142V-78.574H-57.231Z" transform="translate(57.231 479)" fill="#7e8299"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3699" data-name="Сгруппировать 3699" transform="translate(82.109 -919.225)" clip-path="url(#clip-path-1289)">
        <g id="Сгруппировать_3698" data-name="Сгруппировать 3698" transform="translate(-461.543 -462.471)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3697" data-name="Сгруппировать 3697" transform="translate(461.53 461.53)" style="isolation: isolate">
            <g id="Сгруппировать_3696" data-name="Сгруппировать 3696" transform="translate(0)" clip-path="url(#clip-path-1291)">
              <g id="Сгруппировать_3695" data-name="Сгруппировать 3695" transform="translate(0.013 0.941)" clip-path="url(#clip-path-1289)">
                <g id="Сгруппировать_3694" data-name="Сгруппировать 3694" transform="translate(-0.013 -0.941)" clip-path="url(#clip-path-1293)">
                  <g id="Сгруппировать_3692" data-name="Сгруппировать 3692" transform="translate(-81.905 -182.445)" opacity="0">
                    <g id="Сгруппировать_3691" data-name="Сгруппировать 3691">
                      <path id="Контур_3271" data-name="Контур 3271" d="M-189.03-423.172H413.343V-22.746H-189.03Z" transform="translate(189.03 423.172)" fill="url(#linear-gradient-13)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3693" data-name="Сгруппировать 3693" transform="translate(-81.905 -182.445)">
                    <path id="Контур_3272" data-name="Контур 3272" d="M-189.03-423.172H413.343V-22.746H-189.03Z" transform="translate(189.03 423.172)" fill="url(#linear-gradient-14)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3708" data-name="Сгруппировать 3708" transform="translate(112.001 -936.028)" clip-path="url(#clip-path-1294)">
        <g id="Сгруппировать_3707" data-name="Сгруппировать 3707" transform="translate(-491.435 -445.669)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3706" data-name="Сгруппировать 3706" transform="translate(490.999 445.496)" style="isolation: isolate">
            <g id="Сгруппировать_3705" data-name="Сгруппировать 3705" transform="translate(0 0)" clip-path="url(#clip-path-1296)">
              <g id="Сгруппировать_3704" data-name="Сгруппировать 3704" transform="translate(0.436 0.173)" clip-path="url(#clip-path-1294)">
                <g id="Сгруппировать_3703" data-name="Сгруппировать 3703" transform="translate(-0.436 -0.173)" clip-path="url(#clip-path-1298)">
                  <g id="Сгруппировать_3701" data-name="Сгруппировать 3701" transform="translate(-111.374 -166.411)" opacity="0">
                    <g id="Сгруппировать_3700" data-name="Сгруппировать 3700" transform="translate(0 0)">
                      <path id="Контур_3277" data-name="Контур 3277" d="M-258.006-384.4H344.366V16.026H-258.006Z" transform="translate(258.006 384.4)" fill="url(#linear-gradient-15)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3702" data-name="Сгруппировать 3702" transform="translate(-111.374 -166.411)">
                    <path id="Контур_3278" data-name="Контур 3278" d="M-258.006-384.4H344.366V16.026H-258.006Z" transform="translate(258.006 384.4)" fill="url(#linear-gradient-16)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3717" data-name="Сгруппировать 3717" transform="translate(114.139 -927.997)" clip-path="url(#clip-path-1299)">
        <g id="Сгруппировать_3716" data-name="Сгруппировать 3716" transform="translate(-493.573 -453.699)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3715" data-name="Сгруппировать 3715" transform="translate(493.165 453.296)" style="isolation: isolate">
            <g id="Сгруппировать_3714" data-name="Сгруппировать 3714" transform="translate(0 0)" clip-path="url(#clip-path-1301)">
              <g id="Сгруппировать_3713" data-name="Сгруппировать 3713" transform="translate(0.407 0.403)" clip-path="url(#clip-path-1299)">
                <g id="Сгруппировать_3712" data-name="Сгруппировать 3712" transform="translate(-0.407 -0.403)" clip-path="url(#clip-path-1303)">
                  <g id="Сгруппировать_3710" data-name="Сгруппировать 3710" transform="translate(-113.541 -174.211)" opacity="0">
                    <g id="Сгруппировать_3709" data-name="Сгруппировать 3709">
                      <path id="Контур_3283" data-name="Контур 3283" d="M-262.94-402.93H339.432V-2.5H-262.94Z" transform="translate(262.94 402.93)" fill="url(#linear-gradient-17)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3711" data-name="Сгруппировать 3711" transform="translate(-113.541 -174.211)">
                    <path id="Контур_3284" data-name="Контур 3284" d="M-262.94-402.93H339.432V-2.5H-262.94Z" transform="translate(262.94 402.93)" fill="url(#linear-gradient-18)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3726" data-name="Сгруппировать 3726" transform="translate(8.573 -887.591)" clip-path="url(#clip-path-1304)">
        <g id="Сгруппировать_3725" data-name="Сгруппировать 3725" transform="translate(-388.007 -494.106)" clip-path="url(#clip-path-1285)">
          <g id="Сгруппировать_3724" data-name="Сгруппировать 3724" transform="translate(387.859 494.032)" style="isolation: isolate">
            <g id="Сгруппировать_3723" data-name="Сгруппировать 3723" transform="translate(0 0)" clip-path="url(#clip-path-1306)">
              <g id="Сгруппировать_3722" data-name="Сгруппировать 3722" transform="translate(0.149 0.074)" clip-path="url(#clip-path-1304)">
                <g id="Сгруппировать_3721" data-name="Сгруппировать 3721" transform="translate(-0.149 -0.074)" clip-path="url(#clip-path-1308)">
                  <g id="Сгруппировать_3719" data-name="Сгруппировать 3719" transform="translate(-8.234 -214.947)" opacity="0">
                    <g id="Сгруппировать_3718" data-name="Сгруппировать 3718" transform="translate(0 0)">
                      <path id="Контур_3289" data-name="Контур 3289" d="M-19.343-496.17H583.03V-95.744H-19.343Z" transform="translate(19.343 496.17)" fill="#7e8299"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3720" data-name="Сгруппировать 3720" transform="translate(-8.234 -214.947)">
                    <path id="Контур_3290" data-name="Контур 3290" d="M-19.343-496.17H583.03V-95.744H-19.343Z" transform="translate(19.343 496.17)" fill="#7e8299"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3735" data-name="Сгруппировать 3735" transform="translate(8.202 -862.456)" clip-path="url(#clip-path-1309)">
        <g id="Сгруппировать_3734" data-name="Сгруппировать 3734" transform="translate(-387.635 -519.241)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3733" data-name="Сгруппировать 3733" transform="translate(386.992 519.167)" style="isolation: isolate">
            <g id="Сгруппировать_3732" data-name="Сгруппировать 3732" transform="translate(0 0)" clip-path="url(#clip-path-1311)">
              <g id="Сгруппировать_3731" data-name="Сгруппировать 3731" transform="translate(0.644 0.074)" clip-path="url(#clip-path-1309)">
                <g id="Сгруппировать_3730" data-name="Сгруппировать 3730" transform="translate(-0.644 -0.074)" clip-path="url(#clip-path-1313)">
                  <g id="Сгруппировать_3728" data-name="Сгруппировать 3728" transform="translate(-7.367 -240.082)" opacity="0">
                    <g id="Сгруппировать_3727" data-name="Сгруппировать 3727">
                      <path id="Контур_3295" data-name="Контур 3295" d="M-18.485-554.17H583.887v400.426H-18.485Z" transform="translate(18.485 554.17)" fill="url(#linear-gradient-19)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3729" data-name="Сгруппировать 3729" transform="translate(-7.367 -240.082)">
                    <path id="Контур_3296" data-name="Контур 3296" d="M-18.485-554.17H583.887v400.426H-18.485Z" transform="translate(18.485 554.17)" fill="url(#linear-gradient-20)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3744" data-name="Сгруппировать 3744" transform="translate(46.281 -879.482)" clip-path="url(#clip-path-1314)">
        <g id="Сгруппировать_3743" data-name="Сгруппировать 3743" transform="translate(-425.715 -502.214)" clip-path="url(#clip-path-1315)">
          <g id="Сгруппировать_3742" data-name="Сгруппировать 3742" transform="translate(425.561 501.833)" style="isolation: isolate">
            <g id="Сгруппировать_3741" data-name="Сгруппировать 3741" transform="translate(0 0)" clip-path="url(#clip-path-1316)">
              <g id="Сгруппировать_3740" data-name="Сгруппировать 3740" transform="translate(0.154 0.382)" clip-path="url(#clip-path-1314)">
                <g id="Сгруппировать_3739" data-name="Сгруппировать 3739" transform="translate(-0.154 -0.382)" clip-path="url(#clip-path-1318)">
                  <g id="Сгруппировать_3737" data-name="Сгруппировать 3737" transform="translate(-45.936 -222.748)" opacity="0">
                    <g id="Сгруппировать_3736" data-name="Сгруппировать 3736">
                      <path id="Контур_3301" data-name="Контур 3301" d="M-106.355-514.881H496.017v400.426H-106.355Z" transform="translate(106.355 514.881)" fill="#464e5f"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3738" data-name="Сгруппировать 3738" transform="translate(-45.936 -222.748)">
                    <path id="Контур_3302" data-name="Контур 3302" d="M-106.355-514.881H496.017v400.426H-106.355Z" transform="translate(106.355 514.881)" fill="#464e5f"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3753" data-name="Сгруппировать 3753" transform="translate(70.451 -882.875)" clip-path="url(#clip-path-1319)">
        <g id="Сгруппировать_3752" data-name="Сгруппировать 3752" transform="translate(-449.885 -498.822)" clip-path="url(#clip-path-1320)">
          <g id="Сгруппировать_3751" data-name="Сгруппировать 3751" transform="translate(449.396 498.366)" style="isolation: isolate">
            <g id="Сгруппировать_3750" data-name="Сгруппировать 3750" transform="translate(0)" clip-path="url(#clip-path-1321)">
              <g id="Сгруппировать_3749" data-name="Сгруппировать 3749" transform="translate(0.489 0.456)" clip-path="url(#clip-path-1319)">
                <g id="Сгруппировать_3748" data-name="Сгруппировать 3748" transform="translate(-0.489 -0.456)" clip-path="url(#clip-path-1323)">
                  <g id="Сгруппировать_3746" data-name="Сгруппировать 3746" transform="translate(-69.771 -219.281)" opacity="0">
                    <g id="Сгруппировать_3745" data-name="Сгруппировать 3745" transform="translate(0 0)">
                      <path id="Контур_3307" data-name="Контур 3307" d="M-162.128-507.053H440.244v400.426H-162.128Z" transform="translate(162.128 507.053)" fill="#b5b5c3"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3747" data-name="Сгруппировать 3747" transform="translate(-69.771 -219.281)">
                    <path id="Контур_3308" data-name="Контур 3308" d="M-162.128-507.053H440.244v400.426H-162.128Z" transform="translate(162.128 507.053)" fill="#b5b5c3"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3762" data-name="Сгруппировать 3762" transform="translate(65.893 -880.704)" clip-path="url(#clip-path-1324)">
        <g id="Сгруппировать_3761" data-name="Сгруппировать 3761" transform="translate(-445.327 -500.993)" clip-path="url(#clip-path-1325)">
          <g id="Сгруппировать_3760" data-name="Сгруппировать 3760" transform="translate(445.062 500.533)" style="isolation: isolate">
            <g id="Сгруппировать_3759" data-name="Сгруппировать 3759" transform="translate(0 0)" clip-path="url(#clip-path-1326)">
              <g id="Сгруппировать_3758" data-name="Сгруппировать 3758" transform="translate(0.265 0.46)" clip-path="url(#clip-path-1324)">
                <g id="Сгруппировать_3757" data-name="Сгруппировать 3757" transform="translate(-0.265 -0.46)" clip-path="url(#clip-path-1328)">
                  <g id="Сгруппировать_3755" data-name="Сгруппировать 3755" transform="translate(-65.438 -221.448)" opacity="0">
                    <g id="Сгруппировать_3754" data-name="Сгруппировать 3754">
                      <path id="Контур_3313" data-name="Контур 3313" d="M-151.61-512.062H450.762v400.426H-151.61Z" transform="translate(151.61 512.062)" fill="#313846"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3756" data-name="Сгруппировать 3756" transform="translate(-65.438 -221.448)">
                    <path id="Контур_3314" data-name="Контур 3314" d="M-151.61-512.062H450.762v400.426H-151.61Z" transform="translate(151.61 512.062)" fill="#313846"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3771" data-name="Сгруппировать 3771" transform="translate(47.772 -862.01)" clip-path="url(#clip-path-1329)">
        <g id="Сгруппировать_3770" data-name="Сгруппировать 3770" transform="translate(-427.206 -519.687)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3769" data-name="Сгруппировать 3769" transform="translate(426.428 519.167)" style="isolation: isolate">
            <g id="Сгруппировать_3768" data-name="Сгруппировать 3768" transform="translate(0 0)" clip-path="url(#clip-path-1331)">
              <g id="Сгруппировать_3767" data-name="Сгруппировать 3767" transform="translate(0.778 0.52)" clip-path="url(#clip-path-1329)">
                <g id="Сгруппировать_3766" data-name="Сгруппировать 3766" transform="translate(-0.778 -0.52)" clip-path="url(#clip-path-1333)">
                  <g id="Сгруппировать_3764" data-name="Сгруппировать 3764" transform="translate(-46.803 -240.082)" opacity="0">
                    <g id="Сгруппировать_3763" data-name="Сгруппировать 3763">
                      <path id="Контур_3319" data-name="Контур 3319" d="M-109.8-555.2H492.578v400.426H-109.8Z" transform="translate(109.795 555.2)" fill="url(#linear-gradient-21)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3765" data-name="Сгруппировать 3765" transform="translate(-46.803 -240.082)">
                    <path id="Контур_3320" data-name="Контур 3320" d="M-109.8-555.2H492.578v400.426H-109.8Z" transform="translate(109.795 555.2)" fill="url(#linear-gradient-22)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3780" data-name="Сгруппировать 3780" transform="translate(41.023 -906.355)" clip-path="url(#clip-path-1334)">
        <g id="Сгруппировать_3779" data-name="Сгруппировать 3779" transform="translate(-420.457 -475.341)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3778" data-name="Сгруппировать 3778" transform="translate(420.361 474.964)" style="isolation: isolate">
            <g id="Сгруппировать_3777" data-name="Сгруппировать 3777" transform="translate(0)" clip-path="url(#clip-path-1336)">
              <g id="Сгруппировать_3776" data-name="Сгруппировать 3776" transform="translate(0.096 0.377)" clip-path="url(#clip-path-1334)">
                <g id="Сгруппировать_3775" data-name="Сгруппировать 3775" transform="translate(-0.096 -0.377)" clip-path="url(#clip-path-1338)">
                  <g id="Сгруппировать_3773" data-name="Сгруппировать 3773" transform="translate(-40.736 -195.879)" opacity="0">
                    <g id="Сгруппировать_3772" data-name="Сгруппировать 3772">
                      <path id="Контур_3325" data-name="Контур 3325" d="M-94.221-452.87H508.151V-52.444H-94.221Z" transform="translate(94.221 452.87)" fill="url(#linear-gradient-23)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3774" data-name="Сгруппировать 3774" transform="translate(-40.736 -195.879)">
                    <path id="Контур_3326" data-name="Контур 3326" d="M-94.221-452.87H508.151V-52.444H-94.221Z" transform="translate(94.221 452.87)" fill="url(#linear-gradient-24)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3789" data-name="Сгруппировать 3789" transform="translate(43.512 -918.605)" clip-path="url(#clip-path-1339)">
        <g id="Сгруппировать_3788" data-name="Сгруппировать 3788" transform="translate(-422.945 -463.091)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3787" data-name="Сгруппировать 3787" transform="translate(422.527 460.663)" style="isolation: isolate">
            <g id="Сгруппировать_3786" data-name="Сгруппировать 3786" transform="translate(0)" clip-path="url(#clip-path-1341)">
              <g id="Сгруппировать_3785" data-name="Сгруппировать 3785" transform="translate(0.418 2.428)" clip-path="url(#clip-path-1339)">
                <g id="Сгруппировать_3784" data-name="Сгруппировать 3784" transform="translate(-0.418 -2.428)" clip-path="url(#clip-path-1343)">
                  <g id="Сгруппировать_3782" data-name="Сгруппировать 3782" transform="translate(-42.903 -181.578)" opacity="0">
                    <g id="Сгруппировать_3781" data-name="Сгруппировать 3781">
                      <path id="Контур_3331" data-name="Контур 3331" d="M-99.964-424.6H502.409V-24.177H-99.964Z" transform="translate(99.964 424.603)" fill="url(#linear-gradient-25)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3783" data-name="Сгруппировать 3783" transform="translate(-42.903 -181.578)">
                    <path id="Контур_3332" data-name="Контур 3332" d="M-99.964-424.6H502.409V-24.177H-99.964Z" transform="translate(99.964 424.603)" fill="url(#linear-gradient-26)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3798" data-name="Сгруппировать 3798" transform="translate(38.502 -924.827)" clip-path="url(#clip-path-1344)">
        <g id="Сгруппировать_3797" data-name="Сгруппировать 3797" transform="translate(-417.935 -456.87)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3796" data-name="Сгруппировать 3796" transform="translate(416.894 455.463)" style="isolation: isolate">
            <g id="Сгруппировать_3795" data-name="Сгруппировать 3795" transform="translate(0)" clip-path="url(#clip-path-1346)">
              <g id="Сгруппировать_3794" data-name="Сгруппировать 3794" transform="translate(1.041 1.407)" clip-path="url(#clip-path-1344)">
                <g id="Сгруппировать_3793" data-name="Сгруппировать 3793" transform="translate(-1.041 -1.407)" clip-path="url(#clip-path-1348)">
                  <g id="Сгруппировать_3791" data-name="Сгруппировать 3791" transform="translate(-37.269 -176.378)" opacity="0">
                    <g id="Сгруппировать_3790" data-name="Сгруппировать 3790">
                      <path id="Контур_3337" data-name="Контур 3337" d="M-88.4-410.247H513.969V-9.821H-88.4Z" transform="translate(88.403 410.247)" fill="url(#linear-gradient-27)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3792" data-name="Сгруппировать 3792" transform="translate(-37.269 -176.378)">
                    <path id="Контур_3338" data-name="Контур 3338" d="M-88.4-410.247H513.969V-9.821H-88.4Z" transform="translate(88.403 410.247)" fill="url(#linear-gradient-28)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3807" data-name="Сгруппировать 3807" transform="translate(42.321 -905.587)" clip-path="url(#clip-path-1349)">
        <g id="Сгруппировать_3806" data-name="Сгруппировать 3806" transform="translate(-421.755 -476.109)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3805" data-name="Сгруппировать 3805" transform="translate(420.361 475.831)" style="isolation: isolate">
            <g id="Сгруппировать_3804" data-name="Сгруппировать 3804" transform="translate(0 0)" clip-path="url(#clip-path-1351)">
              <g id="Сгруппировать_3803" data-name="Сгруппировать 3803" transform="translate(1.394 0.278)" clip-path="url(#clip-path-1349)">
                <g id="Сгруппировать_3802" data-name="Сгруппировать 3802" transform="translate(-1.394 -0.278)" clip-path="url(#clip-path-1353)">
                  <g id="Сгруппировать_3800" data-name="Сгруппировать 3800" transform="translate(-40.736 -196.746)" opacity="0">
                    <g id="Сгруппировать_3799" data-name="Сгруппировать 3799">
                      <path id="Контур_3343" data-name="Контур 3343" d="M-97.217-454.642H505.156V-54.216H-97.217Z" transform="translate(97.217 454.642)" fill="url(#linear-gradient-29)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3801" data-name="Сгруппировать 3801" transform="translate(-40.736 -196.746)">
                    <path id="Контур_3344" data-name="Контур 3344" d="M-97.217-454.642H505.156V-54.216H-97.217Z" transform="translate(97.217 454.642)" fill="url(#linear-gradient-30)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3816" data-name="Сгруппировать 3816" transform="translate(423.344 -928.08)" clip-path="url(#clip-path-1354)">
        <g id="Сгруппировать_3815" data-name="Сгруппировать 3815" transform="translate(-802.778 -453.617)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3814" data-name="Сгруппировать 3814" transform="translate(802.152 453.296)" style="isolation: isolate">
            <g id="Сгруппировать_3813" data-name="Сгруппировать 3813" transform="translate(0 0)" clip-path="url(#clip-path-1356)">
              <g id="Сгруппировать_3812" data-name="Сгруппировать 3812" transform="translate(0.626 0.321)" clip-path="url(#clip-path-1354)">
                <g id="Сгруппировать_3811" data-name="Сгруппировать 3811" transform="translate(-0.626 -0.321)" clip-path="url(#clip-path-1358)">
                  <g id="Сгруппировать_3809" data-name="Сгруппировать 3809" transform="translate(-422.527 -174.211)" opacity="0">
                    <g id="Сгруппировать_3808" data-name="Сгруппировать 3808">
                      <path id="Контур_3349" data-name="Контур 3349" d="M-976.444-402.74h602.372V-2.314H-976.444Z" transform="translate(976.444 402.74)" fill="url(#linear-gradient-31)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3810" data-name="Сгруппировать 3810" transform="translate(-422.527 -174.211)">
                    <path id="Контур_3350" data-name="Контур 3350" d="M-976.444-402.74h602.372V-2.314H-976.444Z" transform="translate(976.444 402.74)" fill="url(#linear-gradient-32)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3825" data-name="Сгруппировать 3825" transform="translate(427.93 -919.915)" clip-path="url(#clip-path-1359)">
        <g id="Сгруппировать_3824" data-name="Сгруппировать 3824" transform="translate(-807.364 -461.781)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3823" data-name="Сгруппировать 3823" transform="translate(806.919 461.53)" style="isolation: isolate">
            <g id="Сгруппировать_3822" data-name="Сгруппировать 3822" clip-path="url(#clip-path-1301)">
              <g id="Сгруппировать_3821" data-name="Сгруппировать 3821" transform="translate(0.445 0.251)" clip-path="url(#clip-path-1359)">
                <g id="Сгруппировать_3820" data-name="Сгруппировать 3820" transform="translate(-0.445 -0.251)" clip-path="url(#clip-path-1363)">
                  <g id="Сгруппировать_3818" data-name="Сгруппировать 3818" transform="translate(-427.294 -182.445)" opacity="0">
                    <g id="Сгруппировать_3817" data-name="Сгруппировать 3817" transform="translate(0 0)">
                      <path id="Контур_3355" data-name="Контур 3355" d="M-987.026-421.58h602.372V-21.154H-987.026Z" transform="translate(987.026 421.58)" fill="url(#linear-gradient-33)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3819" data-name="Сгруппировать 3819" transform="translate(-427.294 -182.445)">
                    <path id="Контур_3356" data-name="Контур 3356" d="M-987.026-421.58h602.372V-21.154H-987.026Z" transform="translate(987.026 421.58)" fill="url(#linear-gradient-34)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3834" data-name="Сгруппировать 3834" transform="translate(466.759 -715.451)" clip-path="url(#clip-path-1364)">
        <g id="Сгруппировать_3833" data-name="Сгруппировать 3833" transform="translate(-846.193 -666.246)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3832" data-name="Сгруппировать 3832" transform="translate(845.922 666.077)" style="isolation: isolate">
            <g id="Сгруппировать_3831" data-name="Сгруппировать 3831" transform="translate(0 0)" clip-path="url(#clip-path-1366)">
              <g id="Сгруппировать_3830" data-name="Сгруппировать 3830" transform="translate(0.271 0.169)" clip-path="url(#clip-path-1364)">
                <g id="Сгруппировать_3829" data-name="Сгруппировать 3829" transform="translate(-0.271 -0.169)" clip-path="url(#clip-path-1368)">
                  <g id="Сгруппировать_3827" data-name="Сгруппировать 3827" transform="translate(-466.297 -386.992)" opacity="0">
                    <g id="Сгруппировать_3826" data-name="Сгруппировать 3826" transform="translate(0 0)">
                      <path id="Контур_3361" data-name="Контур 3361" d="M-1076.625-893.39h602.372v400.426h-602.372Z" transform="translate(1076.625 893.39)" fill="url(#linear-gradient-35)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3828" data-name="Сгруппировать 3828" transform="translate(-466.297 -386.992)">
                    <path id="Контур_3362" data-name="Контур 3362" d="M-1076.625-893.39h602.372v400.426h-602.372Z" transform="translate(1076.625 893.39)" fill="url(#linear-gradient-36)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3843" data-name="Сгруппировать 3843" transform="translate(486.193 -718.879)" clip-path="url(#clip-path-1369)">
        <g id="Сгруппировать_3842" data-name="Сгруппировать 3842" transform="translate(-865.627 -662.818)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3841" data-name="Сгруппировать 3841" transform="translate(865.423 662.61)" style="isolation: isolate">
            <g id="Сгруппировать_3840" data-name="Сгруппировать 3840" transform="translate(0 0)" clip-path="url(#clip-path-1371)">
              <g id="Сгруппировать_3839" data-name="Сгруппировать 3839" transform="translate(0.204 0.208)" clip-path="url(#clip-path-1369)">
                <g id="Сгруппировать_3838" data-name="Сгруппировать 3838" transform="translate(-0.204 -0.208)" clip-path="url(#clip-path-1373)">
                  <g id="Сгруппировать_3836" data-name="Сгруппировать 3836" transform="translate(-485.798 -383.525)" opacity="0">
                    <g id="Сгруппировать_3835" data-name="Сгруппировать 3835" transform="translate(0 0)">
                      <path id="Контур_3367" data-name="Контур 3367" d="M-1121.47-885.48H-519.1v400.426H-1121.47Z" transform="translate(1121.47 885.48)" fill="url(#linear-gradient-37)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3837" data-name="Сгруппировать 3837" transform="translate(-485.798 -383.525)">
                    <path id="Контур_3368" data-name="Контур 3368" d="M-1121.47-885.48H-519.1v400.426H-1121.47Z" transform="translate(1121.47 885.48)" fill="url(#linear-gradient-38)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3852" data-name="Сгруппировать 3852" transform="translate(486.628 -817.828)" clip-path="url(#clip-path-1374)">
        <g id="Сгруппировать_3851" data-name="Сгруппировать 3851" transform="translate(-866.062 -563.868)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3850" data-name="Сгруппировать 3850" transform="translate(865.856 550.369)" style="isolation: isolate">
            <g id="Сгруппировать_3849" data-name="Сгруппировать 3849" transform="translate(0 0)" clip-path="url(#clip-path-1376)">
              <g id="Сгруппировать_3848" data-name="Сгруппировать 3848" transform="translate(0.205 13.499)" clip-path="url(#clip-path-1374)">
                <g id="Сгруппировать_3847" data-name="Сгруппировать 3847" transform="translate(-0.205 -2.232)" clip-path="url(#clip-path-1378)">
                  <g id="Сгруппировать_3845" data-name="Сгруппировать 3845" transform="translate(-486.232 -282.552)" opacity="0">
                    <g id="Сгруппировать_3844" data-name="Сгруппировать 3844" transform="translate(0 0)">
                      <path id="Контур_3373" data-name="Контур 3373" d="M-1122.474-657.15H-520.1v400.426h-602.372Z" transform="translate(1122.474 657.15)" fill="url(#linear-gradient-39)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3846" data-name="Сгруппировать 3846" transform="translate(-486.232 -282.552)">
                    <path id="Контур_3374" data-name="Контур 3374" d="M-1122.474-657.15H-520.1v400.426h-602.372Z" transform="translate(1122.474 657.15)" fill="url(#linear-gradient-40)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3861" data-name="Сгруппировать 3861" transform="translate(507.752 -713.137)" clip-path="url(#clip-path-1379)">
        <g id="Сгруппировать_3860" data-name="Сгруппировать 3860" transform="translate(-887.185 -668.56)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3859" data-name="Сгруппировать 3859" transform="translate(886.658 668.243)" style="isolation: isolate">
            <g id="Сгруппировать_3858" data-name="Сгруппировать 3858" transform="translate(0 0)" clip-path="url(#clip-path-1381)">
              <g id="Сгруппировать_3857" data-name="Сгруппировать 3857" transform="translate(0.528 0.316)" clip-path="url(#clip-path-1379)">
                <g id="Сгруппировать_3856" data-name="Сгруппировать 3856" transform="translate(-0.528 -0.316)" clip-path="url(#clip-path-1383)">
                  <g id="Сгруппировать_3854" data-name="Сгруппировать 3854" transform="translate(-507.033 -389.159)" opacity="0">
                    <g id="Сгруппировать_3853" data-name="Сгруппировать 3853" transform="translate(0 0)">
                      <path id="Контур_3379" data-name="Контур 3379" d="M-1171.218-898.73h602.372V-498.3h-602.372Z" transform="translate(1171.218 898.73)" fill="url(#linear-gradient-41)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3855" data-name="Сгруппировать 3855" transform="translate(-507.033 -389.159)">
                    <path id="Контур_3380" data-name="Контур 3380" d="M-1171.218-898.73h602.372V-498.3h-602.372Z" transform="translate(1171.218 898.73)" fill="url(#linear-gradient-42)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3870" data-name="Сгруппировать 3870" transform="translate(511.336 -720.274)" clip-path="url(#clip-path-1384)">
        <g id="Сгруппировать_3869" data-name="Сгруппировать 3869" transform="translate(-890.77 -661.422)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3868" data-name="Сгруппировать 3868" transform="translate(890.558 661.31)" style="isolation: isolate">
            <g id="Сгруппировать_3867" data-name="Сгруппировать 3867" transform="translate(0 0)" clip-path="url(#clip-path-1276)">
              <g id="Сгруппировать_3866" data-name="Сгруппировать 3866" transform="translate(0.212 0.113)" clip-path="url(#clip-path-1384)">
                <g id="Сгруппировать_3865" data-name="Сгруппировать 3865" transform="translate(-0.212 -0.113)" clip-path="url(#clip-path-1388)">
                  <g id="Сгруппировать_3863" data-name="Сгруппировать 3863" transform="translate(-510.933 -382.225)" opacity="0">
                    <g id="Сгруппировать_3862" data-name="Сгруппировать 3862" transform="translate(0 0)">
                      <path id="Контур_3385" data-name="Контур 3385" d="M-1179.49-882.26h602.372v400.426H-1179.49Z" transform="translate(1179.49 882.26)" fill="url(#linear-gradient-43)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3864" data-name="Сгруппировать 3864" transform="translate(-510.933 -382.225)">
                    <path id="Контур_3386" data-name="Контур 3386" d="M-1179.49-882.26h602.372v400.426H-1179.49Z" transform="translate(1179.49 882.26)" fill="url(#linear-gradient-44)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3879" data-name="Сгруппировать 3879" transform="translate(491.84 -819.497)" clip-path="url(#clip-path-1389)">
        <g id="Сгруппировать_3878" data-name="Сгруппировать 3878" transform="translate(-871.273 -562.2)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3877" data-name="Сгруппировать 3877" transform="translate(871.057 562.07)" style="isolation: isolate">
            <g id="Сгруппировать_3876" data-name="Сгруппировать 3876" transform="translate(0 0)" clip-path="url(#clip-path-1391)">
              <g id="Сгруппировать_3875" data-name="Сгруппировать 3875" transform="translate(0.217 0.13)" clip-path="url(#clip-path-1389)">
                <g id="Сгруппировать_3874" data-name="Сгруппировать 3874" transform="translate(-0.217 -0.13)" clip-path="url(#clip-path-1393)">
                  <g id="Сгруппировать_3872" data-name="Сгруппировать 3872" transform="translate(-491.432 -282.985)" opacity="0">
                    <g id="Сгруппировать_3871" data-name="Сгруппировать 3871">
                      <path id="Контур_3391" data-name="Контур 3391" d="M-1134.5-653.3h602.372v400.426H-1134.5Z" transform="translate(1134.5 653.3)" fill="url(#linear-gradient-45)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3873" data-name="Сгруппировать 3873" transform="translate(-491.432 -282.985)">
                    <path id="Контур_3392" data-name="Контур 3392" d="M-1134.5-653.3h602.372v400.426H-1134.5Z" transform="translate(1134.5 653.3)" fill="url(#linear-gradient-46)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3888" data-name="Сгруппировать 3888" transform="translate(457.73 -886.399)" clip-path="url(#clip-path-1394)">
        <g id="Сгруппировать_3887" data-name="Сгруппировать 3887" transform="translate(-837.163 -495.297)" clip-path="url(#clip-path-1320)">
          <g id="Сгруппировать_3886" data-name="Сгруппировать 3886" transform="translate(836.821 494.899)" style="isolation: isolate">
            <g id="Сгруппировать_3885" data-name="Сгруппировать 3885" transform="translate(0 0)" clip-path="url(#clip-path-1396)">
              <g id="Сгруппировать_3884" data-name="Сгруппировать 3884" transform="translate(0.342 0.399)" clip-path="url(#clip-path-1394)">
                <g id="Сгруппировать_3883" data-name="Сгруппировать 3883" transform="translate(-0.342 -0.399)" clip-path="url(#clip-path-1398)">
                  <g id="Сгруппировать_3881" data-name="Сгруппировать 3881" transform="translate(-457.196 -215.814)" opacity="0">
                    <g id="Сгруппировать_3880" data-name="Сгруппировать 3880" transform="translate(0 0)">
                      <path id="Контур_3397" data-name="Контур 3397" d="M-1055.79-498.92h602.373V-98.494H-1055.79Z" transform="translate(1055.79 498.92)" fill="#b5b5c3"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3882" data-name="Сгруппировать 3882" transform="translate(-457.196 -215.814)">
                    <path id="Контур_3398" data-name="Контур 3398" d="M-1055.79-498.92h602.373V-98.494H-1055.79Z" transform="translate(1055.79 498.92)" fill="#b5b5c3"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3897" data-name="Сгруппировать 3897" transform="translate(430.913 -911.602)" clip-path="url(#clip-path-1399)">
        <g id="Сгруппировать_3896" data-name="Сгруппировать 3896" transform="translate(-810.347 -470.095)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3895" data-name="Сгруппировать 3895" transform="translate(809.953 469.764)" style="isolation: isolate">
            <g id="Сгруппировать_3894" data-name="Сгруппировать 3894" transform="translate(0 0)" clip-path="url(#clip-path-1401)">
              <g id="Сгруппировать_3893" data-name="Сгруппировать 3893" transform="translate(0.394 0.331)" clip-path="url(#clip-path-1399)">
                <g id="Сгруппировать_3892" data-name="Сгруппировать 3892" transform="translate(-0.394 -0.331)" clip-path="url(#clip-path-1403)">
                  <g id="Сгруппировать_3890" data-name="Сгруппировать 3890" transform="translate(-430.328 -190.679)" opacity="0">
                    <g id="Сгруппировать_3889" data-name="Сгруппировать 3889">
                      <path id="Контур_3403" data-name="Контур 3403" d="M-993.91-440.763h602.372V-40.337H-993.91Z" transform="translate(993.91 440.763)" fill="url(#linear-gradient-47)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3891" data-name="Сгруппировать 3891" transform="translate(-430.328 -190.679)">
                    <path id="Контур_3404" data-name="Контур 3404" d="M-993.91-440.763h602.372V-40.337H-993.91Z" transform="translate(993.91 440.763)" fill="url(#linear-gradient-48)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3906" data-name="Сгруппировать 3906" transform="translate(513.898 -878.837)" clip-path="url(#clip-path-1404)">
        <g id="Сгруппировать_3905" data-name="Сгруппировать 3905" transform="translate(-893.331 -502.86)" clip-path="url(#clip-path-1320)">
          <g id="Сгруппировать_3904" data-name="Сгруппировать 3904" transform="translate(893.158 502.699)" style="isolation: isolate">
            <g id="Сгруппировать_3903" data-name="Сгруппировать 3903" transform="translate(0 0)" clip-path="url(#clip-path-1406)">
              <g id="Сгруппировать_3902" data-name="Сгруппировать 3902" transform="translate(0.173 0.16)" clip-path="url(#clip-path-1404)">
                <g id="Сгруппировать_3901" data-name="Сгруппировать 3901" transform="translate(-0.173 -0.16)" clip-path="url(#clip-path-1408)">
                  <g id="Сгруппировать_3899" data-name="Сгруппировать 3899" transform="translate(-513.533 -223.615)" opacity="0">
                    <g id="Сгруппировать_3898" data-name="Сгруппировать 3898">
                      <path id="Контур_3409" data-name="Контур 3409" d="M-1185.4-516.37h602.373v400.426H-1185.4Z" transform="translate(1185.4 516.37)" fill="#b5b5c3"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3900" data-name="Сгруппировать 3900" transform="translate(-513.533 -223.615)">
                    <path id="Контур_3410" data-name="Контур 3410" d="M-1185.4-516.37h602.373v400.426H-1185.4Z" transform="translate(1185.4 516.37)" fill="#b5b5c3"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3915" data-name="Сгруппировать 3915" transform="translate(489.846 -897.913)" clip-path="url(#clip-path-1409)">
        <g id="Сгруппировать_3914" data-name="Сгруппировать 3914" transform="translate(-869.28 -483.783)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3913" data-name="Сгруппировать 3913" transform="translate(868.89 483.631)" style="isolation: isolate">
            <g id="Сгруппировать_3912" data-name="Сгруппировать 3912" transform="translate(0 0)" clip-path="url(#clip-path-1411)">
              <g id="Сгруппировать_3911" data-name="Сгруппировать 3911" transform="translate(0.39 0.152)" clip-path="url(#clip-path-1409)">
                <g id="Сгруппировать_3910" data-name="Сгруппировать 3910" transform="translate(-0.39 -0.152)" clip-path="url(#clip-path-1413)">
                  <g id="Сгруппировать_3908" data-name="Сгруппировать 3908" transform="translate(-489.265 -204.547)" opacity="0">
                    <g id="Сгруппировать_3907" data-name="Сгруппировать 3907" transform="translate(0 0)">
                      <path id="Контур_3415" data-name="Контур 3415" d="M-1129.9-472.35h602.373V-71.924H-1129.9Z" transform="translate(1129.9 472.35)" fill="url(#linear-gradient-49)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3909" data-name="Сгруппировать 3909" transform="translate(-489.265 -204.547)">
                    <path id="Контур_3416" data-name="Контур 3416" d="M-1129.9-472.35h602.373V-71.924H-1129.9Z" transform="translate(1129.9 472.35)" fill="url(#linear-gradient-50)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3924" data-name="Сгруппировать 3924" transform="translate(483.574 -913.042)" clip-path="url(#clip-path-1414)">
        <g id="Сгруппировать_3923" data-name="Сгруппировать 3923" transform="translate(-863.008 -468.654)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3922" data-name="Сгруппировать 3922" transform="translate(861.956 468.464)" style="isolation: isolate">
            <g id="Сгруппировать_3921" data-name="Сгруппировать 3921" clip-path="url(#clip-path-1416)">
              <g id="Сгруппировать_3920" data-name="Сгруппировать 3920" transform="translate(1.052 0.191)" clip-path="url(#clip-path-1414)">
                <g id="Сгруппировать_3919" data-name="Сгруппировать 3919" transform="translate(-1.052 -0.191)" clip-path="url(#clip-path-1418)">
                  <g id="Сгруппировать_3917" data-name="Сгруппировать 3917" transform="translate(-482.331 -189.379)" opacity="0">
                    <g id="Сгруппировать_3916" data-name="Сгруппировать 3916">
                      <path id="Контур_3421" data-name="Контур 3421" d="M-1115.427-437.44h602.373V-37.014h-602.373Z" transform="translate(1115.427 437.44)" fill="url(#linear-gradient-51)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3918" data-name="Сгруппировать 3918" transform="translate(-482.331 -189.379)">
                    <path id="Контур_3422" data-name="Контур 3422" d="M-1115.427-437.44h602.373V-37.014h-602.373Z" transform="translate(1115.427 437.44)" fill="url(#linear-gradient-52)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3933" data-name="Сгруппировать 3933" transform="translate(485.393 -922.007)" clip-path="url(#clip-path-1419)">
        <g id="Сгруппировать_3932" data-name="Сгруппировать 3932" transform="translate(-864.827 -459.689)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3931" data-name="Сгруппировать 3931" transform="translate(864.556 458.93)" style="isolation: isolate">
            <g id="Сгруппировать_3930" data-name="Сгруппировать 3930" transform="translate(0 0)" clip-path="url(#clip-path-1421)">
              <g id="Сгруппировать_3929" data-name="Сгруппировать 3929" transform="translate(0.271 0.76)" clip-path="url(#clip-path-1419)">
                <g id="Сгруппировать_3928" data-name="Сгруппировать 3928" transform="translate(-0.271 -0.76)" clip-path="url(#clip-path-1423)">
                  <g id="Сгруппировать_3926" data-name="Сгруппировать 3926" transform="translate(-484.932 -179.845)" opacity="0">
                    <g id="Сгруппировать_3925" data-name="Сгруппировать 3925">
                      <path id="Контур_3427" data-name="Контур 3427" d="M-1119.625-416.753h602.372V-16.327h-602.372Z" transform="translate(1119.625 416.753)" fill="url(#linear-gradient-53)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3927" data-name="Сгруппировать 3927" transform="translate(-484.932 -179.845)">
                    <path id="Контур_3428" data-name="Контур 3428" d="M-1119.625-416.753h602.372V-16.327h-602.372Z" transform="translate(1119.625 416.753)" fill="url(#linear-gradient-54)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3942" data-name="Сгруппировать 3942" transform="translate(496.468 -903.066)" clip-path="url(#clip-path-1424)">
        <g id="Сгруппировать_3941" data-name="Сгруппировать 3941" transform="translate(-875.902 -478.63)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3940" data-name="Сгруппировать 3940" transform="translate(875.824 476.698)" style="isolation: isolate">
            <g id="Сгруппировать_3939" data-name="Сгруппировать 3939" transform="translate(0 0)" clip-path="url(#clip-path-1426)">
              <g id="Сгруппировать_3938" data-name="Сгруппировать 3938" transform="translate(0.078 1.933)" clip-path="url(#clip-path-1424)">
                <g id="Сгруппировать_3937" data-name="Сгруппировать 3937" transform="translate(-0.078 -1.933)" clip-path="url(#clip-path-1428)">
                  <g id="Сгруппировать_3935" data-name="Сгруппировать 3935" transform="translate(-496.199 -197.613)" opacity="0">
                    <g id="Сгруппировать_3934" data-name="Сгруппировать 3934" transform="translate(0 0)">
                      <path id="Контур_3433" data-name="Контур 3433" d="M-1145.18-460.46h602.373V-60.034H-1145.18Z" transform="translate(1145.18 460.46)" fill="url(#linear-gradient-55)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3936" data-name="Сгруппировать 3936" transform="translate(-496.199 -197.613)">
                    <path id="Контур_3434" data-name="Контур 3434" d="M-1145.18-460.46h602.373V-60.034H-1145.18Z" transform="translate(1145.18 460.46)" fill="url(#linear-gradient-56)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3951" data-name="Сгруппировать 3951" transform="translate(525.603 -853.472)" clip-path="url(#clip-path-1429)">
        <g id="Сгруппировать_3950" data-name="Сгруппировать 3950" transform="translate(-905.036 -528.225)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3949" data-name="Сгруппировать 3949" transform="translate(904.859 527.401)" style="isolation: isolate">
            <g id="Сгруппировать_3948" data-name="Сгруппировать 3948" transform="translate(0 0)" clip-path="url(#clip-path-1431)">
              <g id="Сгруппировать_3947" data-name="Сгруппировать 3947" transform="translate(0.178 0.824)" clip-path="url(#clip-path-1429)">
                <g id="Сгруппировать_3946" data-name="Сгруппировать 3946" transform="translate(-0.178 -0.824)" clip-path="url(#clip-path-1433)">
                  <g id="Сгруппировать_3944" data-name="Сгруппировать 3944" transform="translate(-525.234 -248.316)" opacity="0">
                    <g id="Сгруппировать_3943" data-name="Сгруппировать 3943">
                      <path id="Контур_3439" data-name="Контур 3439" d="M-1212.41-574.9h602.373v400.426H-1212.41Z" transform="translate(1212.41 574.901)" fill="url(#linear-gradient-57)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3945" data-name="Сгруппировать 3945" transform="translate(-525.234 -248.316)">
                    <path id="Контур_3440" data-name="Контур 3440" d="M-1212.41-574.9h602.373v400.426H-1212.41Z" transform="translate(1212.41 574.901)" fill="url(#linear-gradient-58)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3960" data-name="Сгруппировать 3960" transform="translate(523.079 -809.284)" clip-path="url(#clip-path-1434)">
        <g id="Сгруппировать_3959" data-name="Сгруппировать 3959" transform="translate(-902.512 -572.413)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3958" data-name="Сгруппировать 3958" transform="translate(902.259 571.604)" style="isolation: isolate">
            <g id="Сгруппировать_3957" data-name="Сгруппировать 3957" clip-path="url(#clip-path-1436)">
              <g id="Сгруппировать_3956" data-name="Сгруппировать 3956" transform="translate(0.254 0.809)" clip-path="url(#clip-path-1434)">
                <g id="Сгруппировать_3955" data-name="Сгруппировать 3955" transform="translate(-0.254 -0.809)" clip-path="url(#clip-path-1438)">
                  <g id="Сгруппировать_3953" data-name="Сгруппировать 3953" transform="translate(-522.634 -292.519)" opacity="0">
                    <g id="Сгруппировать_3952" data-name="Сгруппировать 3952" transform="translate(0 0)">
                      <path id="Контур_3445" data-name="Контур 3445" d="M-1206.585-676.867h602.372v400.426h-602.372Z" transform="translate(1206.585 676.867)" fill="url(#linear-gradient-59)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3954" data-name="Сгруппировать 3954" transform="translate(-522.634 -292.519)">
                    <path id="Контур_3446" data-name="Контур 3446" d="M-1206.585-676.867h602.372v400.426h-602.372Z" transform="translate(1206.585 676.867)" fill="url(#linear-gradient-60)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3969" data-name="Сгруппировать 3969" transform="translate(525.13 -921.09)" clip-path="url(#clip-path-1439)">
        <g id="Сгруппировать_3968" data-name="Сгруппировать 3968" transform="translate(-904.564 -460.607)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3967" data-name="Сгруппировать 3967" transform="translate(903.559 459.797)" style="isolation: isolate">
            <g id="Сгруппировать_3966" data-name="Сгруппировать 3966" clip-path="url(#clip-path-1441)">
              <g id="Сгруппировать_3965" data-name="Сгруппировать 3965" transform="translate(1.005 0.81)" clip-path="url(#clip-path-1439)">
                <g id="Сгруппировать_3964" data-name="Сгруппировать 3964" transform="translate(-1.005 -0.81)" clip-path="url(#clip-path-1443)">
                  <g id="Сгруппировать_3962" data-name="Сгруппировать 3962" transform="translate(-523.934 -180.712)" opacity="0">
                    <g id="Сгруппировать_3961" data-name="Сгруппировать 3961" transform="translate(0 0)">
                      <path id="Контур_3451" data-name="Контур 3451" d="M-1211.32-418.87h602.373V-18.444H-1211.32Z" transform="translate(1211.32 418.87)" fill="url(#linear-gradient-61)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3963" data-name="Сгруппировать 3963" transform="translate(-523.934 -180.712)">
                    <path id="Контур_3452" data-name="Контур 3452" d="M-1211.32-418.87h602.373V-18.444H-1211.32Z" transform="translate(1211.32 418.87)" fill="url(#linear-gradient-62)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3982" data-name="Сгруппировать 3982" transform="translate(519.952 -879.635)" clip-path="url(#clip-path-1444)">
        <g id="Сгруппировать_3981" data-name="Сгруппировать 3981" transform="translate(-899.385 -502.061)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3980" data-name="Сгруппировать 3980" transform="translate(896.192 500.099)" style="isolation: isolate">
            <g id="Сгруппировать_3979" data-name="Сгруппировать 3979" transform="translate(0 0)" clip-path="url(#clip-path-1446)">
              <g id="Сгруппировать_3978" data-name="Сгруппировать 3978" transform="translate(3.194 1.962)" clip-path="url(#clip-path-1444)">
                <g id="Сгруппировать_3977" data-name="Сгруппировать 3977" transform="translate(-2.327 -1.962)" clip-path="url(#clip-path-1448)">
                  <g id="Сгруппировать_3975" data-name="Сгруппировать 3975" transform="translate(-517.434 -221.014)" opacity="0">
                    <g id="Сгруппировать_3974" data-name="Сгруппировать 3974">
                      <path id="Контур_3463" data-name="Контур 3463" d="M-1199.37-514.528H-597V-114.1H-1199.37Z" transform="translate(1199.37 514.528)" fill="url(#linear-gradient-63)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3976" data-name="Сгруппировать 3976" transform="translate(-517.434 -221.014)">
                    <path id="Контур_3464" data-name="Контур 3464" d="M-1199.37-514.528H-597V-114.1H-1199.37Z" transform="translate(1199.37 514.528)" fill="url(#linear-gradient-64)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_3991" data-name="Сгруппировать 3991" transform="translate(532.918 -894.72)" clip-path="url(#clip-path-1449)">
        <g id="Сгруппировать_3990" data-name="Сгруппировать 3990" transform="translate(-912.352 -486.977)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3989" data-name="Сгруппировать 3989" transform="translate(912.226 486.665)" style="isolation: isolate">
            <g id="Сгруппировать_3988" data-name="Сгруппировать 3988" transform="translate(0)" clip-path="url(#clip-path-1451)">
              <g id="Сгруппировать_3987" data-name="Сгруппировать 3987" transform="translate(0.126 0.312)" clip-path="url(#clip-path-1449)">
                <g id="Сгруппировать_3986" data-name="Сгруппировать 3986" transform="translate(-0.126 -0.312)" clip-path="url(#clip-path-1453)">
                  <g id="Сгруппировать_3984" data-name="Сгруппировать 3984" transform="translate(-532.601 -207.58)" opacity="0">
                    <g id="Сгруппировать_3983" data-name="Сгруппировать 3983" transform="translate(0 0)">
                      <path id="Контур_3469" data-name="Контур 3469" d="M-1229.29-479.72h602.372V-79.294H-1229.29Z" transform="translate(1229.29 479.72)" fill="url(#linear-gradient-65)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3985" data-name="Сгруппировать 3985" transform="translate(-532.601 -207.58)">
                    <path id="Контур_3470" data-name="Контур 3470" d="M-1229.29-479.72h602.372V-79.294H-1229.29Z" transform="translate(1229.29 479.72)" fill="url(#linear-gradient-66)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_4000" data-name="Сгруппировать 4000" transform="translate(525.992 -911.336)" clip-path="url(#clip-path-1454)">
        <g id="Сгруппировать_3999" data-name="Сгруппировать 3999" transform="translate(-905.426 -470.361)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_3998" data-name="Сгруппировать 3998" transform="translate(900.525 469.33)" style="isolation: isolate">
            <g id="Сгруппировать_3997" data-name="Сгруппировать 3997" transform="translate(0 0)" clip-path="url(#clip-path-1456)">
              <g id="Сгруппировать_3996" data-name="Сгруппировать 3996" transform="translate(4.901 1.03)" clip-path="url(#clip-path-1454)">
                <g id="Сгруппировать_3995" data-name="Сгруппировать 3995" transform="translate(-2.301 -1.03)" clip-path="url(#clip-path-1458)">
                  <g id="Сгруппировать_3993" data-name="Сгруппировать 3993" transform="translate(-523.501 -190.246)" opacity="0">
                    <g id="Сгруппировать_3992" data-name="Сгруппировать 3992">
                      <path id="Контур_3475" data-name="Контур 3475" d="M-1213.309-441.378h602.373V-40.952h-602.373Z" transform="translate(1213.309 441.378)" fill="url(#linear-gradient-67)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_3994" data-name="Сгруппировать 3994" transform="translate(-523.501 -190.246)">
                    <path id="Контур_3476" data-name="Контур 3476" d="M-1213.309-441.378h602.373V-40.952h-602.373Z" transform="translate(1213.309 441.378)" fill="url(#linear-gradient-68)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_4009" data-name="Сгруппировать 4009" transform="translate(531.677 -915.3)" clip-path="url(#clip-path-1459)">
        <g id="Сгруппировать_4008" data-name="Сгруппировать 4008" transform="translate(-911.11 -466.397)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_4007" data-name="Сгруппировать 4007" transform="translate(910.059 464.997)" style="isolation: isolate">
            <g id="Сгруппировать_4006" data-name="Сгруппировать 4006" transform="translate(0 0)" clip-path="url(#clip-path-1461)">
              <g id="Сгруппировать_4005" data-name="Сгруппировать 4005" transform="translate(1.051 1.4)" clip-path="url(#clip-path-1459)">
                <g id="Сгруппировать_4004" data-name="Сгруппировать 4004" transform="translate(-1.051 -1.4)" clip-path="url(#clip-path-1463)">
                  <g id="Сгруппировать_4002" data-name="Сгруппировать 4002" transform="translate(-530.434 -185.912)" opacity="0">
                    <g id="Сгруппировать_4001" data-name="Сгруппировать 4001" transform="translate(0 0)">
                      <path id="Контур_3481" data-name="Контур 3481" d="M-1226.425-432.231h602.373V-31.8h-602.373Z" transform="translate(1226.425 432.231)" fill="url(#linear-gradient-69)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_4003" data-name="Сгруппировать 4003" transform="translate(-530.434 -185.912)">
                    <path id="Контур_3482" data-name="Контур 3482" d="M-1226.425-432.231h602.373V-31.8h-602.373Z" transform="translate(1226.425 432.231)" fill="url(#linear-gradient-70)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_4018" data-name="Сгруппировать 4018" transform="translate(540.636 -901.753)" clip-path="url(#clip-path-1464)">
        <g id="Сгруппировать_4017" data-name="Сгруппировать 4017" transform="translate(-920.07 -479.944)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_4016" data-name="Сгруппировать 4016" transform="translate(920.026 477.564)" style="isolation: isolate">
            <g id="Сгруппировать_4015" data-name="Сгруппировать 4015" transform="translate(0 0)" clip-path="url(#clip-path-1466)">
              <g id="Сгруппировать_4014" data-name="Сгруппировать 4014" transform="translate(0.043 2.379)" clip-path="url(#clip-path-1464)">
                <g id="Сгруппировать_4013" data-name="Сгруппировать 4013" transform="translate(-0.043 -2.379)" clip-path="url(#clip-path-1468)">
                  <g id="Сгруппировать_4011" data-name="Сгруппировать 4011" transform="translate(-540.402 -198.48)" opacity="0">
                    <g id="Сгруппировать_4010" data-name="Сгруппировать 4010">
                      <path id="Контур_3487" data-name="Контур 3487" d="M-1247.1-463.49h602.372V-63.064H-1247.1Z" transform="translate(1247.1 463.49)" fill="url(#linear-gradient-71)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_4012" data-name="Сгруппировать 4012" transform="translate(-540.402 -198.48)">
                    <path id="Контур_3488" data-name="Контур 3488" d="M-1247.1-463.49h602.372V-63.064H-1247.1Z" transform="translate(1247.1 463.49)" fill="url(#linear-gradient-72)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_4027" data-name="Сгруппировать 4027" transform="translate(518.474 -785.608)" clip-path="url(#clip-path-1469)">
        <g id="Сгруппировать_4026" data-name="Сгруппировать 4026" transform="translate(-897.908 -596.089)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_4025" data-name="Сгруппировать 4025" transform="translate(897.492 595.872)" style="isolation: isolate">
            <g id="Сгруппировать_4024" data-name="Сгруппировать 4024" transform="translate(0 0)" clip-path="url(#clip-path-1471)">
              <g id="Сгруппировать_4023" data-name="Сгруппировать 4023" transform="translate(0.416 0.217)" clip-path="url(#clip-path-1469)">
                <g id="Сгруппировать_4022" data-name="Сгруппировать 4022" transform="translate(-0.416 -0.217)" clip-path="url(#clip-path-1473)">
                  <g id="Сгруппировать_4020" data-name="Сгруппировать 4020" transform="translate(-517.867 -316.787)" opacity="0">
                    <g id="Сгруппировать_4019" data-name="Сгруппировать 4019">
                      <path id="Контур_3493" data-name="Контур 3493" d="M-1195.961-731.5h602.373v400.426h-602.373Z" transform="translate(1195.961 731.5)" fill="url(#linear-gradient-73)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_4021" data-name="Сгруппировать 4021" transform="translate(-517.867 -316.787)">
                    <path id="Контур_3494" data-name="Контур 3494" d="M-1195.961-731.5h602.373v400.426h-602.373Z" transform="translate(1195.961 731.5)" fill="url(#linear-gradient-74)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_4036" data-name="Сгруппировать 4036" transform="translate(547.04 -725.221)" clip-path="url(#clip-path-1474)">
        <g id="Сгруппировать_4035" data-name="Сгруппировать 4035" transform="translate(-926.473 -656.475)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_4034" data-name="Сгруппировать 4034" transform="translate(926.094 655.243)" style="isolation: isolate">
            <g id="Сгруппировать_4033" data-name="Сгруппировать 4033" transform="translate(0 0)" clip-path="url(#clip-path-1476)">
              <g id="Сгруппировать_4032" data-name="Сгруппировать 4032" transform="translate(0.38 1.233)" clip-path="url(#clip-path-1474)">
                <g id="Сгруппировать_4031" data-name="Сгруппировать 4031" transform="translate(-0.38 -1.233)" clip-path="url(#clip-path-1478)">
                  <g id="Сгруппировать_4029" data-name="Сгруппировать 4029" transform="translate(-546.469 -376.158)" opacity="0">
                    <g id="Сгруппировать_4028" data-name="Сгруппировать 4028" transform="translate(0 0)">
                      <path id="Контур_3499" data-name="Контур 3499" d="M-1261.877-870.845H-659.5v400.426h-602.372Z" transform="translate(1261.877 870.845)" fill="url(#linear-gradient-75)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_4030" data-name="Сгруппировать 4030" transform="translate(-546.469 -376.158)">
                    <path id="Контур_3500" data-name="Контур 3500" d="M-1261.877-870.845H-659.5v400.426h-602.372Z" transform="translate(1261.877 870.845)" fill="url(#linear-gradient-76)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_4045" data-name="Сгруппировать 4045" transform="translate(532.987 -779.511)" clip-path="url(#clip-path-1479)">
        <g id="Сгруппировать_4044" data-name="Сгруппировать 4044" transform="translate(-912.421 -602.186)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_4043" data-name="Сгруппировать 4043" transform="translate(912.226 599.339)" style="isolation: isolate">
            <g id="Сгруппировать_4042" data-name="Сгруппировать 4042" transform="translate(0 0)" clip-path="url(#clip-path-1481)">
              <g id="Сгруппировать_4041" data-name="Сгруппировать 4041" transform="translate(0.195 2.847)" clip-path="url(#clip-path-1479)">
                <g id="Сгруппировать_4040" data-name="Сгруппировать 4040" transform="translate(-0.195 -2.413)" clip-path="url(#clip-path-1483)">
                  <g id="Сгруппировать_4038" data-name="Сгруппировать 4038" transform="translate(-532.601 -320.687)" opacity="0">
                    <g id="Сгруппировать_4037" data-name="Сгруппировать 4037">
                      <path id="Контур_3505" data-name="Контур 3505" d="M-1229.45-745.569h602.372v400.426H-1229.45Z" transform="translate(1229.45 745.569)" fill="url(#linear-gradient-77)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_4039" data-name="Сгруппировать 4039" transform="translate(-532.601 -320.687)">
                    <path id="Контур_3506" data-name="Контур 3506" d="M-1229.45-745.569h602.372v400.426H-1229.45Z" transform="translate(1229.45 745.569)" fill="url(#linear-gradient-78)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_4054" data-name="Сгруппировать 4054" transform="translate(530.059 -720.816)" clip-path="url(#clip-path-1484)">
        <g id="Сгруппировать_4053" data-name="Сгруппировать 4053" transform="translate(-909.493 -660.881)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_4052" data-name="Сгруппировать 4052" transform="translate(908.759 660.876)" style="isolation: isolate">
            <g id="Сгруппировать_4051" data-name="Сгруппировать 4051" transform="translate(0 0)" clip-path="url(#clip-path-1486)">
              <g id="Сгруппировать_4050" data-name="Сгруппировать 4050" transform="translate(0.734 0.004)" clip-path="url(#clip-path-1484)">
                <g id="Сгруппировать_4049" data-name="Сгруппировать 4049" transform="translate(-0.734 -0.004)" clip-path="url(#clip-path-1488)">
                  <g id="Сгруппировать_4047" data-name="Сгруппировать 4047" transform="translate(-529.134 -381.791)" opacity="0">
                    <g id="Сгруппировать_4046" data-name="Сгруппировать 4046" transform="translate(0 0)">
                      <path id="Контур_3511" data-name="Контур 3511" d="M-1222.694-881.01h602.373v400.426h-602.373Z" transform="translate(1222.694 881.01)" fill="url(#linear-gradient-79)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_4048" data-name="Сгруппировать 4048" transform="translate(-529.134 -381.791)">
                    <path id="Контур_3512" data-name="Контур 3512" d="M-1222.694-881.01h602.373v400.426h-602.373Z" transform="translate(1222.694 881.01)" fill="url(#linear-gradient-80)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_4063" data-name="Сгруппировать 4063" transform="translate(517.944 -832.944)" clip-path="url(#clip-path-1489)">
        <g id="Сгруппировать_4062" data-name="Сгруппировать 4062" transform="translate(-897.378 -548.753)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_4061" data-name="Сгруппировать 4061" transform="translate(897.058 548.636)" style="isolation: isolate">
            <g id="Сгруппировать_4060" data-name="Сгруппировать 4060" transform="translate(0)" clip-path="url(#clip-path-1491)">
              <g id="Сгруппировать_4059" data-name="Сгруппировать 4059" transform="translate(0.319 0.117)" clip-path="url(#clip-path-1489)">
                <g id="Сгруппировать_4058" data-name="Сгруппировать 4058" transform="translate(-0.319 -0.117)" clip-path="url(#clip-path-1493)">
                  <g id="Сгруппировать_4056" data-name="Сгруппировать 4056" transform="translate(-517.434 -269.551)" opacity="0">
                    <g id="Сгруппировать_4055" data-name="Сгруппировать 4055" transform="translate(0 0)">
                      <path id="Контур_3517" data-name="Контур 3517" d="M-1194.737-622.27h602.372v400.426h-602.372Z" transform="translate(1194.737 622.27)" fill="url(#linear-gradient-81)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_4057" data-name="Сгруппировать 4057" transform="translate(-517.434 -269.551)">
                    <path id="Контур_3518" data-name="Контур 3518" d="M-1194.737-622.27h602.372v400.426h-602.372Z" transform="translate(1194.737 622.27)" fill="url(#linear-gradient-82)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Сгруппировать_4072" data-name="Сгруппировать 4072" transform="translate(540.708 -873.498)" clip-path="url(#clip-path-1494)">
        <g id="Сгруппировать_4071" data-name="Сгруппировать 4071" transform="translate(-920.142 -508.199)" clip-path="url(#clip-path-2)">
          <g id="Сгруппировать_4070" data-name="Сгруппировать 4070" transform="translate(917.86 505.733)" style="isolation: isolate">
            <g id="Сгруппировать_4069" data-name="Сгруппировать 4069" transform="translate(0)" clip-path="url(#clip-path-1496)">
              <g id="Сгруппировать_4068" data-name="Сгруппировать 4068" transform="translate(2.282 2.466)" clip-path="url(#clip-path-1494)">
                <g id="Сгруппировать_4067" data-name="Сгруппировать 4067" transform="translate(-2.282 -2.466)" clip-path="url(#clip-path-1498)">
                  <g id="Сгруппировать_4065" data-name="Сгруппировать 4065" transform="translate(-538.235 -226.648)" opacity="0">
                    <g id="Сгруппировать_4064" data-name="Сгруппировать 4064">
                      <path id="Контур_3523" data-name="Контур 3523" d="M-1247.267-528.691h602.372v400.426h-602.372Z" transform="translate(1247.267 528.691)" fill="url(#linear-gradient-83)"/>
                    </g>
                  </g>
                  <g id="Сгруппировать_4066" data-name="Сгруппировать 4066" transform="translate(-538.235 -226.648)">
                    <path id="Контур_3524" data-name="Контур 3524" d="M-1247.267-528.691h602.372v400.426h-602.372Z" transform="translate(1247.267 528.691)" fill="url(#linear-gradient-84)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "FloorPlanEditorImage"
}
</script>

<style scoped>

</style>
